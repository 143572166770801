import { SaveOutlined, CloseOutlined } from '@ant-design/icons';
import { Form, Button, Spin, Row, Col, Space, Input } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { StatePieceStatus } from '../../../enums';
import { IAddCustomer } from '../../../interfaces/customer/data';
import { IAddCustomerFormValues } from '../../../interfaces/customer/IAddCustomerFormValues';
import { ContentHeader } from '../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { Unauthorized } from '../../../shared/components/unauthorized/Unauthorized';
import { ROW_GUTTER } from '../../../shared/Constants';
import { AppDispatch, RootState } from '../../../store';
import { addCustomer } from '../../../store/customerSlice/CustomerActions';

export const AddCustomer = (props: any) => {
    const { appSlice } = useSelector((state: RootState) => state);
    const { customer } = useSelector((state: RootState) => state.customerSlice.detailsPage);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const onFinish = (values: IAddCustomerFormValues) => {
        const body: IAddCustomer = {
            ...(values as any),
        };
        dispatch(addCustomer(body));
    };

    useEffect(() => {
        if (customer.createStatus === StatePieceStatus.Success) {
            navigate('/customers', { replace: true });
        }
    }, [customer.createStatus]);

    if (appSlice.auth.validationCompleted && !appSlice.auth.customers.create) {
        return <Unauthorized />;
    }

    return (
        <React.Fragment>
            <Form
                form={form}
                name="control-hooks"
                onFinish={onFinish}
                layout="vertical"
                onValuesChange={(_changedValue, values) => {
                    form.setFieldsValue(values);
                }}
            >
                <ContentHeader
                    title={`Adding a new customer`}
                    breadcrumbs={[
                        {
                            breadcrumbName: 'Customers',
                            path: '/customers',
                        },
                        { breadcrumbName: 'Adding a new customer', path: window.location.pathname },
                    ]}
                >
                    <Space>
                        <Button
                            icon={<CloseOutlined />}
                            onClick={() => navigate(-1)}
                            disabled={customer.createStatus === StatePieceStatus.IsFetching}
                        >
                            Close
                        </Button>

                        {appSlice.auth.customers.create && (
                            <Button
                                type="primary"
                                htmlType="submit"
                                icon={<SaveOutlined />}
                                loading={customer.createStatus === StatePieceStatus.IsFetching}
                            >
                                Save and add
                            </Button>
                        )}
                    </Space>
                </ContentHeader>

                <div className="page-content">
                    <Spin
                        spinning={
                            customer.fetchStatus === StatePieceStatus.IsFetching || customer.createStatus === StatePieceStatus.IsFetching
                        }
                    >
                        {/* CUSTOMER INFORMATION */}
                        <Row>
                            <Col span={24}>
                                <Space>
                                    <label className="group-label">Customer Information</label>
                                </Space>
                            </Col>
                        </Row>
                        <Row gutter={ROW_GUTTER}>
                            <Col span={6}>
                                <label className="property required">Name</label>
                                <Form.Item name="name" rules={[{ required: true, message: "'Name' is required", max: 255 }]}>
                                    <Input placeholder="Provide a name..." />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <label className="property required">Search name</label>
                                <Form.Item
                                    id="searchname"
                                    name="searchName"
                                    rules={[{ required: true, message: "'Search name' is required", max: 255 }]}
                                >
                                    <Input placeholder="Provide a search name..." />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Spin>
                </div>
            </Form>
        </React.Fragment>
    );
};
