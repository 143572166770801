import { CheckOutlined, PlusCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Button, notification, Space, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { StatePieceStatus } from '../../../../../enums';
import { IBankAccountLookupListTableRow } from '../../../../../interfaces/lookup/bankAccount';
import { ContentHeader } from '../../../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { NOTIFICATION_PLACEMENT } from '../../../../../shared/Constants';
import { AppDispatch, RootState } from '../../../../../store';
import { fetchBankAccounts, updateDefaultBankAccount } from '../../../../../store/lookupSlice/LookupActions';
import { resetState } from '../../../../../store/lookupSlice/LookupSlice';

export const BankAccountLookupListOverview = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { bankAccounts } = useSelector((state: RootState) => state.lookupSlice);
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
    const [selectedTableRecords, setSelectedTabelRecords] = useState<IBankAccountLookupListTableRow[]>([]);

    const columns: ColumnsType<IBankAccountLookupListTableRow> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'BBAN',
            dataIndex: 'bban',
            key: 'bban',
        },
        {
            title: 'IBAN',
            dataIndex: 'iban',
            key: 'iban',
        },
        {
            title: 'BIC',
            dataIndex: 'bic',
            key: 'bic',
        },
        {
            title: 'SEPA',
            dataIndex: 'sepa',
            key: 'sepa',
            render: (sepa: boolean) => <Space size="middle">{sepa ? 'Yes' : 'No'}</Space>,
        },
        {
            title: 'Default',
            dataIndex: 'isDefault',
            key: 'isDefault',
            render: (def: boolean) => <Space size="middle">{def ? 'Yes' : 'No'}</Space>,
        },

        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: 100,
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        type="text"
                        icon={<RightOutlined />}
                        onClick={() => navigate(`/admin/lookup-lists/bank-account/${record.id}`)}
                    />
                </Space>
            ),
        },
    ];

    const rowSelection = {
        selectedRowKeys,
        onChange: (selRowKeys: React.Key[], selectedRows: IBankAccountLookupListTableRow[]) => {
            setSelectedRowKeys(selRowKeys.map((r) => parseInt(r.toString())));
            setSelectedTabelRecords(selectedRows);
        },
    };

    useEffect(() => {
        if (bankAccounts.model.fetchStatus === StatePieceStatus.Success) {
            if (selectedTableRecords.length > 0) {
                let newSelectedTableRecords = [...selectedTableRecords].map((r) => {
                    let newData = bankAccounts.model.data.filter((d) => d.id === r.id)[0];
                    return {
                        id: newData.id,
                        name: newData.name,
                        bban: newData.bban,
                        iban: newData.iban,
                        bic: newData.bic,
                        sepa: newData.sepa,
                        isDefault: newData.isDefault,
                    } as IBankAccountLookupListTableRow;
                });
                setSelectedTabelRecords(newSelectedTableRecords);
            }
        }
    }, [bankAccounts.model.fetchStatus]);

    useEffect(() => {
        if (bankAccounts.model.updateStatus === StatePieceStatus.Success) {
            navigate('/admin/lookup-lists/bank-account', { replace: true });
        }
    }, [bankAccounts.model.updateStatus]);

    useEffect(() => {
        dispatch(fetchBankAccounts());

        return () => {
            dispatch(resetState());
        };
    }, []);

    useEffect(() => {
        if (bankAccounts.model.fetchStatus === StatePieceStatus.Error) {
            notification.error({
                message: 'An error occurred.',
                duration: 0,
                description: 'An error occurred while retrieving the data. Please contact your administrator if the problem persists.',
                placement: NOTIFICATION_PLACEMENT,
            });
        }
    }, [bankAccounts.model.fetchStatus]);

    return (
        <div className="app-page">
            <ContentHeader
                title="Manage bankaccounts lookup list"
                breadcrumbs={[
                    {
                        breadcrumbName: 'Admin',
                        path: '/admin',
                    },
                    {
                        breadcrumbName: 'Manage lookup lists',
                        path: '/admin/lookup-lists',
                    },
                    {
                        breadcrumbName: 'Bank accounts',
                        path: '/admin/lookup-lists/bank-account',
                    },
                ]}
            >
                <>
                    {selectedTableRecords.length === 1 && (
                        <Tooltip title={selectedTableRecords[0].isDefault ? 'Selected bankaccount is already the default.' : undefined}>
                            <Button
                                disabled={selectedTableRecords[0].isDefault}
                                icon={<CheckOutlined />}
                                onClick={() => dispatch(updateDefaultBankAccount(selectedTableRecords[0].id))}
                                loading={bankAccounts.model.updateStatus === StatePieceStatus.IsFetching}
                            >
                                Set as default
                            </Button>
                        </Tooltip>
                    )}

                    <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => navigate('/admin/lookup-lists/bank-account/add')}>
                        Add new option
                    </Button>
                </>
            </ContentHeader>

            <div className="page-content">
                <Table
                    size="small"
                    bordered={true}
                    loading={bankAccounts.model.fetchStatus === StatePieceStatus.IsFetching}
                    dataSource={bankAccounts.model.data.map((option, i) => {
                        return { ...option, key: i } as IBankAccountLookupListTableRow;
                    })}
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                    }}
                    columns={columns}
                    pagination={{
                        defaultPageSize: 50,
                    }}
                />
            </div>
        </div>
    );
};
