import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Spin, Row, Col, Space, Form, Input, Select, Button, Checkbox } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { StatePieceStatus } from '../../../enums';
import { IAddBeneficiary, IAddBeneficiaryFormValues } from '../../../interfaces/beneficiaries';
import { ContentHeader } from '../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { Unauthorized } from '../../../shared/components/unauthorized/Unauthorized';
import { ROW_GUTTER } from '../../../shared/Constants';
import { AppDispatch, RootState } from '../../../store';
import { addBeneficiary } from '../../../store/beneficiarySlice/BeneficiaryActions';
import {
    fetchBankCostOptionsLookupList,
    fetchCountriesLookupList,
    fetchCurrenciesLookupList,
} from '../../../store/lookupSlice/LookupActions';

export const AddBeneficiaries = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { appSlice } = useSelector((state: RootState) => state);
    const { detailsPage } = useSelector((state: RootState) => state.beneficiarySlice);
    const { countries, bankCostOptions, currencies } = useSelector((state: RootState) => state.lookupSlice);
    const [form] = Form.useForm();

    const isLoadingLookupLists =
        countries.lookup.fetchStatus === StatePieceStatus.IsFetching || bankCostOptions.lookup.fetchStatus === StatePieceStatus.IsFetching;

    const onFinish = (values: IAddBeneficiaryFormValues) => {
        const body: IAddBeneficiary = {
            ...(values as any),
        };
        dispatch(addBeneficiary(body));
    };

    /**
     * OnInit
     */
    useEffect(() => {
        dispatch(fetchCountriesLookupList());
        dispatch(fetchBankCostOptionsLookupList());
        dispatch(fetchCurrenciesLookupList());
    }, []);

    /**
     * OnInit
     */
    useEffect(() => {
        if (detailsPage.beneficiary.createStatus === StatePieceStatus.Success) {
            navigate('/beneficiaries', { replace: true });
        }
    }, [detailsPage.beneficiary.createStatus]);

    if (appSlice.auth.validationCompleted && !appSlice.auth.beneficiaries.create) {
        return <Unauthorized />;
    }

    return (
        <React.Fragment>
            <Form
                form={form}
                name="control-hooks"
                onFinish={onFinish}
                layout="vertical"
                initialValues={
                    {
                        sepa: false,
                    } as IAddBeneficiaryFormValues
                }
            >
                <ContentHeader
                    title={`Adding a new beneficiary`}
                    breadcrumbs={[
                        {
                            breadcrumbName: 'Beneficiaries',
                            path: '/beneficiaries',
                        },
                        {
                            breadcrumbName: 'Adding a new beneficiary',
                            path: window.location.pathname,
                        },
                    ]}
                >
                    <Space>
                        <Button
                            icon={<CloseOutlined />}
                            onClick={() => navigate(-1)}
                            disabled={detailsPage.beneficiary.updateStatus === StatePieceStatus.IsFetching || isLoadingLookupLists}
                        >
                            Close
                        </Button>

                        {appSlice.auth.beneficiaries.create && (
                            <Button
                                type="primary"
                                htmlType="submit"
                                icon={<SaveOutlined />}
                                loading={detailsPage.beneficiary.createStatus === StatePieceStatus.IsFetching}
                                disabled={isLoadingLookupLists}
                            >
                                Save and add
                            </Button>
                        )}
                    </Space>
                </ContentHeader>

                <div className="page-content">
                    <Spin spinning={detailsPage.beneficiary.createStatus === StatePieceStatus.IsFetching}>
                        {/* Beneficiary INFORMATION */}
                        <Row>
                            <Col span={24}>
                                <Space>
                                    <label className="group-label">Beneficiary Information</label>
                                </Space>
                            </Col>
                        </Row>
                        <Row gutter={ROW_GUTTER}>
                            <Col span={6}>
                                <label className="property required">Name</label>
                                <Form.Item name="name" rules={[{ required: true, message: "'Name' is required", max: 255 }]}>
                                    <Input placeholder="Provide a name..." />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <label className="property required">Search name</label>
                                <Form.Item
                                    id="searchname"
                                    name="searchName"
                                    rules={[{ required: true, message: "'Search name' is required", max: 255 }]}
                                >
                                    <Input placeholder="Provide a search name..." />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <label className="property required">Currency</label>
                                <Form.Item name="currencyId" rules={[{ required: true, message: "'Currency' is required" }]}>
                                    <Select
                                        showSearch
                                        placeholder="Select a currency"
                                        filterOption={(input, option) =>
                                            (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                                        }
                                    >
                                        {currencies.lookup.data
                                            .filter((f) => !f.isDeleted)
                                            .map((currency) => (
                                                <Select.Option key={currency.key} value={currency.key} disabled={currency.isDeleted}>
                                                    {currency.description}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        {/* Address INFORMATION */}
                        <Row className="margin-top">
                            <Col span={24}>
                                <Space>
                                    <label className="group-label">Address information</label>
                                </Space>
                            </Col>
                        </Row>
                        <Row gutter={ROW_GUTTER}>
                            <Col span={6}>
                                <label className="property required">Address line</label>
                                <Form.Item
                                    name="addressline1"
                                    rules={[{ required: true, message: "'Address line' is required", max: 255 }]}
                                >
                                    <Input placeholder="Provide an address..." />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <label className="property required">Address line 2</label>
                                <Form.Item name="addressline2" rules={[{ max: 255 }]}>
                                    <Input placeholder="Provide an address..." />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <label className="property required">Postal code</label>
                                <Form.Item name="postalCode" rules={[{ required: true, message: "'Postal code' is required", max: 50 }]}>
                                    <Input placeholder="Provide a postal code..." />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <label className="property required">City</label>
                                <Form.Item name="city" rules={[{ required: true, message: "'City' is required", max: 50 }]}>
                                    <Input placeholder="Provide a city..." />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={ROW_GUTTER}>
                            <Col span={6}>
                                <label className="property required">Country</label>
                                <Form.Item name="countryId" rules={[{ required: true, message: "'Country' is required" }]}>
                                    <Select
                                        showSearch
                                        placeholder="Select a country..."
                                        filterOption={(input, option) =>
                                            (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                                        }
                                    >
                                        {countries.lookup.data
                                            .filter((f) => !f.isDeleted)
                                            .map((country) => (
                                                <Select.Option key={country.key} value={country.key} disabled={country.isDeleted}>
                                                    {country.description}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        {/* Banking INFORMATION */}
                        <Row className="margin-top">
                            <Col span={24}>
                                <Space>
                                    <label className="group-label">Banking information</label>
                                </Space>
                            </Col>
                        </Row>
                        <Row gutter={ROW_GUTTER}>
                            <Col span={6}>
                                <label className="property">Bank name</label>
                                <Form.Item name="bankName" rules={[{ max: 255 }]}>
                                    <Input placeholder="Provide bank name..." />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={ROW_GUTTER}>
                            <Col span={6}>
                                <label className="property">IBAN</label>
                                <Form.Item name="iban" rules={[{ max: 34, message: "'IBAN' cannot be longer than 34 characters" }]}>
                                    <Input placeholder="Provide IBAN..." />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <label className="property">Account number</label>
                                <Form.Item name="accountNumber" rules={[{ max: 255 }]}>
                                    <Input placeholder="Provide account number..." />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <label className="property">BIC</label>
                                <Form.Item name="bic" rules={[{ max: 255 }]}>
                                    <Input placeholder="Provide BIC..." />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <label className="property required">SEPA</label>
                                <Form.Item name="sepa" rules={[{ required: true, message: "'SEPA' is required" }]}>
                                    <Checkbox />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={ROW_GUTTER}>
                            <Col span={6}>
                                <label className="property">Address line</label>
                                <Form.Item name="bankAddress" rules={[{ max: 255 }]}>
                                    <Input placeholder="Address Line" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <label className="property">Postal code</label>
                                <Form.Item name="bankPostalCode" rules={[{ max: 255 }]}>
                                    <Input placeholder="Postal code" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <label className="property">City</label>
                                <Form.Item name="bankCity" rules={[{ max: 255 }]}>
                                    <Input placeholder="City" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <label className="property">Country</label>
                                <Form.Item name="bankCountryId" rules={[{ required: false, message: "'Bank country' is required" }]}>
                                    <Select
                                        showSearch
                                        placeholder="Select a country..."
                                        filterOption={(input, option) =>
                                            (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                                        }
                                    >
                                        {countries.lookup.data
                                            .filter((f) => !f.isDeleted)
                                            .map((country) => (
                                                <Select.Option key={country.key} value={country.key} disabled={country.isDeleted}>
                                                    {country.description}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        {/* Transaction INFORMATION */}
                        <Row className="margin-top">
                            <Col span={24}>
                                <Space>
                                    <label className="group-label">Transaction information</label>
                                </Space>
                            </Col>
                        </Row>
                        <Row gutter={ROW_GUTTER}>
                            <Col span={4}>
                                <label className="property">Bank costs</label>
                                <Form.Item name="bankCostOptionId">
                                    <Select
                                        showSearch
                                        placeholder="Select bank cost..."
                                        filterOption={(input, option) =>
                                            (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                                        }
                                    >
                                        {bankCostOptions.lookup.data
                                            .filter((f) => !f.isDeleted)
                                            .map((bankCostOption) => (
                                                <Select.Option
                                                    key={bankCostOption.key}
                                                    value={bankCostOption.key}
                                                    disabled={bankCostOption.isDeleted}
                                                >
                                                    {bankCostOption.description}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <label className="property">Payment description</label>
                                <Form.Item name="paymentDescription">
                                    <Input placeholder="Provide payment description..." />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <label className="property">Transit number</label>
                                <Form.Item name="bankTransitNumber" rules={[{ max: 255 }]}>
                                    <Input placeholder="Provide bank transit number..." />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <label className="property">Fixed code</label>
                                <Form.Item
                                    name="fixedCode"
                                    rules={[{ max: 10, message: "'Fixed code' cannot be longer than 10 characters" }]}
                                >
                                    <Input placeholder="Provide fixed code..." />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Spin>
                </div>
            </Form>
        </React.Fragment>
    );
};
