import { DeleteFilled, EditOutlined, ExclamationCircleOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Col, App as AntApp, Row, Space, Spin, Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { BeneficiaryStatuses, StatePieceStatus } from '../../../enums';
import { IAddBeneficiaryComment } from '../../../interfaces/beneficiaries/data/IAddBeneficiaryComment';
import { IDeleteBeneficiaryComment } from '../../../interfaces/beneficiaries/IDeleteBeneficiaryComment';
import { ContentHeader } from '../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { Comments } from '../../../shared/components/comments/Comments';
import { FetchResult } from '../../../shared/components/fetchResult/FetchResult';
import { Unauthorized } from '../../../shared/components/unauthorized/Unauthorized';
import { ROW_GUTTER } from '../../../shared/Constants';
import { generateReturnUrl, getReturnUrl } from '../../../shared/helpers';
import { isError } from '../../../shared/helpers/StatusHelpers';
import { AppDispatch, RootState } from '../../../store';
import {
    addBeneficiaryComment,
    deleteBeneficiary,
    deleteBeneficiaryComment,
    fetchBeneficiaryById,
    fetchBeneficiaryComments,
} from '../../../store/beneficiarySlice/BeneficiaryActions';
import { resetState } from '../../../store/beneficiarySlice/BeneficiarySlice';
import { BeneficiarytatusChangeModal } from '../beneficiaryStatusChangeModal/BeneficiaryStatusChangeModal';
import { NotFound } from '../../notFound/NotFound';

export const BeneficiariesDetails = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const { modal } = AntApp.useApp();
    const { appSlice } = useSelector((state: RootState) => state);
    const { beneficiary, beneficiaryComments } = useSelector((state: RootState) => state.beneficiarySlice.detailsPage);
    const [statusChangerVisible, setStatusChangerVisible] = useState(false);
    const beneficiaryId = params.id ? parseInt(params.id) : undefined;

    const isLoadingContent = () => {
        return beneficiary.fetchStatus === StatePieceStatus.IsFetching || beneficiaryComments.fetchStatus === StatePieceStatus.IsFetching;
    };

    const removeBeneficiary = () => {
        modal.confirm({
            title: 'Delete beneficiary',
            icon: <ExclamationCircleOutlined />,
            content: "Are you sure you want to delete this beneficiary? This action can't be undone.",
            okText: 'Confirm',
            cancelText: 'Cancel',
            onOk: () => {
                if (beneficiaryId) dispatch(deleteBeneficiary(beneficiaryId));
            },
        });
    };

    useEffect(() => {
        if (beneficiaryId) {
            dispatch(fetchBeneficiaryById(beneficiaryId));
            dispatch(fetchBeneficiaryComments(beneficiaryId));
        }

        return () => {
            dispatch(resetState());
        };
    }, []);

    useEffect(() => {
        if (beneficiary.deleteStatus === StatePieceStatus.Success) {
            let returnUrl = getReturnUrl();
            navigate(returnUrl ? returnUrl : '/beneficiaries', { replace: true });
        }
    }, [beneficiary.deleteStatus]);

    const beneficiaryName = beneficiary.data?.name || beneficiary.data?.searchName;
    const statusChangerInvalidStatus = beneficiary.data?.status.code === BeneficiaryStatuses.Approved;

    if (appSlice.auth.validationCompleted && !appSlice.auth.beneficiaries.view) {
        return <Unauthorized />;
    }

    if (!beneficiaryId) {
        return <NotFound />;
    }

    return (
        <React.Fragment>
            <ContentHeader
                title={`Beneficiary #${params.id} ${beneficiaryName || ''}`}
                breadcrumbs={[
                    {
                        breadcrumbName: 'Beneficiaries',
                        path: '/beneficiaries',
                    },
                    {
                        breadcrumbName: beneficiary.data?.name || beneficiary.data?.searchName || '-',
                        path: `/beneficiaries/${params.id}`,
                    },
                ]}
            >
                {!isError(beneficiary.fetchStatus) && (
                    <Space>
                        {appSlice.auth.beneficiaries.delete && (
                            <Button
                                type="primary"
                                danger
                                icon={<DeleteFilled />}
                                loading={isLoadingContent()}
                                onClick={() => removeBeneficiary()}
                            >
                                Delete beneficiary
                            </Button>
                        )}

                        {appSlice.auth.beneficiaries.changeStatus && (
                            <Tooltip
                                title={
                                    statusChangerInvalidStatus && !appSlice.auth.admin.view
                                        ? 'This beneficiary has a status that you are not authorized to change.'
                                        : undefined
                                }
                            >
                                <Button
                                    type="primary"
                                    icon={<UndoOutlined />}
                                    loading={isLoadingContent() || appSlice.me.info.data === null}
                                    onClick={() => setStatusChangerVisible(true)}
                                    disabled={statusChangerInvalidStatus && !appSlice.auth.admin.view}
                                >
                                    Change status
                                </Button>
                            </Tooltip>
                        )}

                        {appSlice.auth.beneficiaries.edit && (
                            <Button
                                type="default"
                                icon={<EditOutlined />}
                                onClick={() => navigate(`${generateReturnUrl(`${window.location.pathname}/edit`)}`)}
                                disabled={beneficiary.fetchStatus !== StatePieceStatus.Success}
                                loading={isLoadingContent()}
                            >
                                Edit beneficiary details
                            </Button>
                        )}
                    </Space>
                )}
            </ContentHeader>

            <div className="page-content">
                <Spin spinning={beneficiary.fetchStatus === StatePieceStatus.IsFetching}>
                    {isError(beneficiary.fetchStatus) ? (
                        <FetchResult status={beneficiary.fetchStatus} about={'Beneficiary'} />
                    ) : (
                        <>
                            {/* Beneficiary INFORMATION */}
                            <Row>
                                <Col span={24}>
                                    <Space>
                                        <label className="group-label">Beneficiary Information</label>
                                    </Space>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={6}>
                                    <label className="property">Name</label>
                                    <p>{beneficiary.data?.name || '-'}</p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">Search name</label>
                                    <p>{beneficiary.data?.searchName || '-'}</p>
                                </Col>
                                <Col span={6}>&nbsp;</Col>
                                <Col span={6}>
                                    <label className="property">Currency</label>
                                    <p>
                                        <Tooltip title={beneficiary.data?.currency?.description}>
                                            {beneficiary.data?.currency?.code || '-'}
                                        </Tooltip>
                                    </p>
                                </Col>
                            </Row>

                            {/* Address INFORMATION */}
                            <Row className="margin-top">
                                <Col span={24}>
                                    <Space>
                                        <label className="group-label">Address information</label>
                                    </Space>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={6}>
                                    <label className="property">Address line</label>
                                    <p>{beneficiary.data?.addressline1 || '-'}</p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">Address line 2</label>
                                    <p>{beneficiary.data?.addressline2 || '-'}</p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">Postal code</label>
                                    <p>{beneficiary.data?.postalCode || '-'}</p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">City</label>
                                    <p>{beneficiary.data?.city || '-'}</p>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={4}>
                                    <label className="property">Country</label>
                                    {beneficiary.data && beneficiary.data.country ? (
                                        <Tooltip title={beneficiary.data.country.description} placement="topLeft">
                                            <p>{beneficiary.data?.country.code || '-'}</p>
                                        </Tooltip>
                                    ) : (
                                        <p>-</p>
                                    )}
                                </Col>
                            </Row>

                            {/* Banking INFORMATION */}
                            <Row className="margin-top">
                                <Col span={24}>
                                    <Space>
                                        <label className="group-label">Banking information</label>
                                    </Space>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={6}>
                                    <label className="property">Bank name</label>
                                    <p>{beneficiary.data?.bankName || '-'}</p>
                                </Col>
                            </Row>

                            <Row gutter={ROW_GUTTER}>
                                <Col span={6}>
                                    <label className="property">IBAN</label>
                                    <p>{beneficiary.data?.iban || '-'}</p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">Account number</label>
                                    <p>{beneficiary.data?.accountNumber || '-'}</p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">BIC</label>
                                    <p>{beneficiary.data?.bic || '-'}</p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">SEPA</label>
                                    <p>{beneficiary.data?.sepa ? 'Yes' : 'No'}</p>
                                </Col>
                            </Row>

                            <Row gutter={ROW_GUTTER}>
                                <Col span={6}>
                                    <label className="property">Address line</label>
                                    <p>{beneficiary.data?.bankAddress || '-'}</p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">Postal Code</label>
                                    <p>{beneficiary.data?.bankPostalCode || '-'}</p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">City</label>
                                    <p>{beneficiary.data?.bankCity || '-'}</p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">Country</label>
                                    {beneficiary.data && beneficiary.data.bankCountry ? (
                                        <Tooltip title={beneficiary.data.bankCountry.description} placement="topLeft">
                                            <p>{beneficiary.data?.bankCountry.code || '-'}</p>
                                        </Tooltip>
                                    ) : (
                                        <p>-</p>
                                    )}
                                </Col>
                            </Row>

                            {/* Transaction INFORMATION */}

                            <Row className="margin-top">
                                <Col span={24}>
                                    <Space>
                                        <label className="group-label">Transaction information</label>
                                    </Space>
                                </Col>
                            </Row>

                            <Row gutter={ROW_GUTTER}>
                                <Col span={4}>
                                    <label className="property">Bank costs</label>
                                    {beneficiary.data && beneficiary.data.bankCostOption ? (
                                        <Tooltip title={beneficiary.data.bankCostOption.description} placement="topLeft">
                                            <p>{beneficiary.data?.bankCostOption.code || '-'}</p>
                                        </Tooltip>
                                    ) : (
                                        <p>-</p>
                                    )}
                                </Col>
                                <Col span={6}>
                                    <label className="property">Payment description</label>
                                    <p>{beneficiary.data?.paymentDescription || '-'}</p>
                                </Col>
                                <Col span={4}>
                                    <label className="property">Transit number</label>
                                    <p>{beneficiary.data?.bankTransitNumber || '-'}</p>
                                </Col>
                                <Col span={4}>
                                    <label className="property">Fixed code</label>
                                    <p>{beneficiary.data?.fixedCode || '-'}</p>
                                </Col>
                            </Row>

                            {/* STATUS INFORMATION */}
                            <Row className="margin-top">
                                <Col span={24}>
                                    <label className="group-label">Status Information</label>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={6}>
                                    <label className="property">Status</label>
                                    <p>
                                        <Tag>{beneficiary.data?.status?.description || '-'}</Tag>
                                    </p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">Changed on</label>
                                    <p className="capitalize">
                                        {beneficiary.data?.statusChangedDate
                                            ? dayjs(beneficiary.data?.statusChangedDate).format('MMMM D, YYYY HH:mm')
                                            : '-'}
                                    </p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">Changed by</label>
                                    <p>{beneficiary.data?.statusChangedByUser?.name || '-'}</p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">Comment</label>
                                    <p>{beneficiary.data?.statusChangedComment || '-'}</p>
                                </Col>
                            </Row>

                            {/* INTERNAL INFORMATION */}
                            <Row className="margin-top">
                                <Col span={24}>
                                    <label className="group-label">Internal Information</label>
                                </Col>
                            </Row>

                            <Row gutter={ROW_GUTTER}>
                                <Col span={12}>
                                    <label className="property">Created by</label>
                                    <p>{beneficiary.data?.createdByUser.name || '-'}</p>
                                </Col>
                                <Col span={12}>
                                    <label className="property">Created on</label>
                                    <p className="capitalize">
                                        {beneficiary.data?.createdDate
                                            ? dayjs(beneficiary.data?.createdDate).format('MMMM D, YYYY HH:mm')
                                            : '-'}
                                    </p>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={12}>
                                    <label className="property">Last modified by</label>
                                    <p>{beneficiary.data?.modifiedByUser?.name || '-'}</p>
                                </Col>
                                <Col span={12}>
                                    <label className="property">Last modified on</label>
                                    <p className="capitalize">
                                        {beneficiary.data?.modifiedDate
                                            ? dayjs(beneficiary.data?.modifiedDate).format('MMMM D, YYYY HH:mm')
                                            : '-'}
                                    </p>
                                </Col>
                            </Row>

                            <Comments
                                canCreateComment={appSlice.auth.beneficiaries.comment}
                                comments={beneficiaryComments.data}
                                fetching={beneficiaryComments.fetchStatus === StatePieceStatus.IsFetching}
                                submitting={beneficiaryComments.createStatus === StatePieceStatus.IsFetching}
                                onFinish={(values) => {
                                    const body: IAddBeneficiaryComment = {
                                        beneficiaryId: beneficiary.data?.id,
                                        ...(values as any),
                                    };

                                    dispatch(addBeneficiaryComment(body));
                                }}
                                onDeleteComment={(commentId: number) => {
                                    if (beneficiaryId) {
                                        const body: IDeleteBeneficiaryComment = {
                                            beneficiaryId: beneficiaryId,
                                            commentId: commentId,
                                        };

                                        dispatch(deleteBeneficiaryComment(body));
                                    }
                                }}
                            />
                        </>
                    )}
                </Spin>
            </div>

            <BeneficiarytatusChangeModal
                beneficiaries={beneficiary.data ? [{ ...beneficiary.data }] : []}
                open={statusChangerVisible}
                onCancel={() => setStatusChangerVisible(false)}
            />
        </React.Fragment>
    );
};
