import CustomerSlice from './customerSlice/CustomerSlice';
import { configureStore } from '@reduxjs/toolkit';
import AppSlice from './appSlice/AppSlice';
import BeneficiarySlice from './beneficiarySlice/BeneficiarySlice';
import FeeSlice from './feeSlice/FeeSlice';
import LookupSlice from './lookupSlice/LookupSlice';
import InvoiceSlice from './invoiceSlice/InvoiceSlice';
import UserSlice from './userSlice/UserSlice';
import DocumentSlice from './documentSlice/DocumentSlice';

export const store = configureStore({
    reducer: {
        appSlice: AppSlice,
        beneficiarySlice: BeneficiarySlice,
        feeSlice: FeeSlice,
        lookupSlice: LookupSlice,
        customerSlice: CustomerSlice,
        invoiceSlice: InvoiceSlice,
        userSlice: UserSlice,
        documentSlice: DocumentSlice,
    },
    // This allows Date objects and other non-serializable objects to be added to the stores.
    // See: https://redux.js.org/faq/organizing-state#can-i-put-functions-promises-or-other-non-serializable-items-in-my-store-state
    // and: https://stackoverflow.com/questions/61704805/getting-an-error-a-non-serializable-value-was-detected-in-the-state-when-using
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
