import React from 'react';
import { DeleteOutlined, DownloadOutlined, ExclamationCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, App as AntApp, Space, Table, Tooltip } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StatePieceStatus } from '../../../../enums';
import { IManualDocumentTableRow } from '../../../../interfaces/documents/IManualDocumentTableRow';
import { ContentHeader } from '../../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { AppDispatch, RootState } from '../../../../store';
import { deleteManualDocument, downloadDocument, fetchManualDocuments } from '../../../../store/documentSlice/DocumentActions';
import { appendManualDocument, resetState } from '../../../../store/documentSlice/DocumentSlice';
import { DocumentTabNavigation } from '../../documentTabNavigation/DocumentTabNavigation';
import { AddManualDocumentModal } from './addManualDocumentModal/AddManualDocumentModal';
import dayjs from 'dayjs';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { SearchBar } from '../../../../shared/components/filters/SearchBar';
import { HighlightedText } from '../../../../shared/components/highlightedText/HighlightedText';
import { Unauthorized } from '../../../../shared/components/unauthorized/Unauthorized';
import { calculateOverviewTableHeight } from '../../../../shared/helpers/TableHeightHelper';
import { IDocument } from '../../../../interfaces/documents/data/IDocument';

export const ManualDocumentsOverview = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { modal } = AntApp.useApp();
    const { documentSlice, appSlice } = useSelector((state: RootState) => state);
    const [uploadModalVisible, setUploadModalVisible] = useState(false);
    const [tableRenderHeight, setTableRenderHeight] = useState<number>();

    const [filtering, setFiltering] = useState<{
        pagination?: TablePaginationConfig;
        filters?: Record<string, FilterValue | null>;
        _sorter?: SorterResult<IManualDocumentTableRow> | SorterResult<IManualDocumentTableRow>[];
        searchQuery?: string;
    }>({});

    const columns: ColumnsType<IManualDocumentTableRow> = [
        {
            title: 'Document Id',
            dataIndex: 'document',
            key: 'document',
            render: (document: IDocument) => (
                <HighlightedText text={document.id.toString() || ''} textToHighlight={filtering.searchQuery} />
            ),
        },
        {
            title: 'File',
            dataIndex: 'subject',
            key: 'subject',
            render: (_, record) => <HighlightedText text={record.subject || ''} textToHighlight={filtering.searchQuery} />,
        },
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
            render: (text: string) => <>{text ? dayjs(text).format('DD-MM-YYYY - HH:mm') : ''}</>,
        },
        {
            title: 'Created by',
            dataIndex: 'createdByUser',
            key: 'createdByUser',
            render: (_, record) => (
                <>
                    {record.createdBy && record.createdBy.initials && (
                        <Tooltip title={record.createdBy.name}>
                            <HighlightedText text={record.createdBy.initials || ''} textToHighlight={filtering.searchQuery} />
                        </Tooltip>
                    )}
                </>
            ),
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: 100,
            fixed: 'right',
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip title="Download">
                        <Button
                            type="text"
                            icon={<DownloadOutlined />}
                            onClick={() => {
                                dispatch(downloadDocument({ id: record.document.id, fileName: record.document.fileName || '' }));
                            }}
                        />
                    </Tooltip>
                    {appSlice.auth.documents.manuals.delete && (
                        <Tooltip title="Delete">
                            <Button
                                type="text"
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                    modal.confirm({
                                        title: `Delete '${record.document.fileName}'`,
                                        icon: <ExclamationCircleOutlined />,
                                        content: "Are you sure you want to delete this manual? This action can't be undone.",
                                        okText: 'Confirm',
                                        cancelText: 'Cancel',
                                        onOk: () => {
                                            dispatch(deleteManualDocument(record.id));
                                        },
                                    });
                                }}
                            />
                        </Tooltip>
                    )}
                </Space>
            ),
        },
    ];

    const fetchManuals = () => {
        const sorter = filtering._sorter as SorterResult<IManualDocumentTableRow[]>;
        dispatch(
            fetchManualDocuments({
                pageSize: filtering.pagination?.pageSize,
                pageNumber: filtering.pagination?.current,
                sorter,
                searchQuery: filtering.searchQuery,
            })
        );
    };

    useEffect(() => {
        let renderTableHeight = calculateOverviewTableHeight();
        setTableRenderHeight(renderTableHeight);

        dispatch(fetchManualDocuments({}));

        return () => {
            dispatch(resetState());
        };
    }, []);

    React.useEffect(() => {
        fetchManuals();
    }, [filtering]);

    if (appSlice.auth.validationCompleted && !appSlice.auth.documents.manuals.view) {
        return <Unauthorized />;
    }

    return (
        <div className="app-page">
            <ContentHeader
                title="Manuals"
                tabNavigation={<DocumentTabNavigation />}
                hideBackButton
                breadcrumbs={[
                    {
                        breadcrumbName: 'Documents',
                        path: '/documents',
                    },
                    {
                        breadcrumbName: 'Manuals',
                        path: '/documents/manuals',
                    },
                ]}
            >
                {appSlice.auth.documents.manuals.create && (
                    <Button
                        type="primary"
                        icon={<PlusCircleOutlined />}
                        onClick={() => setUploadModalVisible(true)}
                        disabled={
                            documentSlice.deleteManualStatus === StatePieceStatus.IsFetching ||
                            documentSlice.documentDownloadStatus === StatePieceStatus.IsFetching
                        }
                    >
                        Upload new manual
                    </Button>
                )}
            </ContentHeader>

            <div className="page-content">
                <SearchBar
                    loading={documentSlice.manualDocuments.fetchStatus === StatePieceStatus.IsFetching}
                    placeholder="Search in manuals..."
                    onSearch={(value) => {
                        setFiltering({ ...filtering, searchQuery: value });
                    }}
                />

                <Table
                    size="small"
                    bordered={true}
                    scroll={{ y: tableRenderHeight }}
                    columns={columns}
                    loading={
                        documentSlice.manualDocuments.fetchStatus === StatePieceStatus.IsFetching ||
                        documentSlice.deleteManualStatus === StatePieceStatus.IsFetching ||
                        documentSlice.documentDownloadStatus === StatePieceStatus.IsFetching
                    }
                    dataSource={
                        // Map to an array of IUserTableRow
                        documentSlice.manualDocuments.data.items.map((i) => {
                            return { ...i, key: i.id.toString() } as IManualDocumentTableRow;
                        })
                    }
                    pagination={{
                        current: documentSlice.manualDocuments.data.paging.pageNumber,
                        pageSize: documentSlice.manualDocuments.data.paging.pageSize,
                        total: documentSlice.manualDocuments.data.paging.totalItems,
                        showSizeChanger: true,
                    }}
                    onChange={(pagination, filters, _sorter) => {
                        setFiltering({ ...filtering, pagination, filters, _sorter: _sorter });
                    }}
                />
            </div>

            <AddManualDocumentModal
                open={uploadModalVisible}
                onCancel={() => setUploadModalVisible(false)}
                onUploadSuccess={(newDocument) => {
                    setUploadModalVisible(false);
                    dispatch(appendManualDocument(newDocument));
                }}
            />
        </div>
    );
};
