import { Result } from 'antd';
import { StatePieceStatus } from '../../../enums';

export interface IFetchResultProps {
    status: StatePieceStatus | undefined;
    about: string;
}

/**
 * Renders a fitting Result component if the status is NotFound or UnAuthorized.
 */
export const FetchResult = (props: IFetchResultProps) => {
    const { status, about } = props;
    if (status === undefined) return null;

    if (status === StatePieceStatus.NotFound) {
        return (
            <Result
                status={404}
                title={`${about} not found`}
                subTitle={`It looks like the ${about.toLowerCase()} you requested doesn't exist.`}
            />
        );
    }
    if (status === StatePieceStatus.UnAuthorized) {
        return <Result status={403} title={`Unauthorized`} subTitle={`You are not authorized to view this ${about.toLowerCase()}`} />;
    }

    return null;
};
