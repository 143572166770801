import { Result } from 'antd';

import './NotFound.less';

export const NotFound = () => {
    return (
        <div className="app-page notfound">
            <Result status="404" title="404" subTitle="Sorry, the page you visited does not exist." />
        </div>
    );
};
