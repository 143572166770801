import { IBeneficiaryComment } from './../../interfaces/beneficiaries/data/IBeneficiaryComment';
import {
    addBeneficiary,
    addBeneficiaryComment,
    deleteBeneficiary,
    deleteBeneficiaryComment,
    fetchBeneficiaries,
    fetchBeneficiaryById,
    fetchBeneficiaryComments,
    updateBeneficiary,
    updateBeneficiaryStatuses,
} from './BeneficiaryActions';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatePieceStatus } from '../../enums';
import { IStatePiece } from '../../interfaces';
import { IBeneficiary } from '../../interfaces/beneficiaries';
import { IPaginatedResult } from '../../interfaces/IPaginatedResult';

export interface BeneficiarySliceState {
    beneficiaries: IStatePiece<IPaginatedResult<IBeneficiary[]>>;
    detailsPage: {
        beneficiary: IStatePiece<IBeneficiary | null>;
        beneficiaryComments: IStatePiece<IBeneficiaryComment[]>;
        /**
         * Edit item navigation ID's
         */
        eids: number[];
    };
    updateBeneficiaryStatusesStatus: StatePieceStatus;
}

const initialState: BeneficiarySliceState = {
    beneficiaries: {
        data: {
            items: [],
            links: {},
            paging: {
                pageNumber: 0,
                pageSize: 0,
                totalItems: 0,
                totalPages: 0,
            },
        },
        fetchStatus: StatePieceStatus.None,
    },
    detailsPage: {
        beneficiary: {
            data: null,
            fetchStatus: StatePieceStatus.None,
            deleteStatus: StatePieceStatus.None,
        },
        beneficiaryComments: {
            data: [],
            fetchStatus: StatePieceStatus.None,
            createStatus: StatePieceStatus.None,
            deleteStatus: StatePieceStatus.None,
        },
        eids: [],
    },
    updateBeneficiaryStatusesStatus: StatePieceStatus.None,
};

export const beneficiarySlice = createSlice({
    name: 'beneficiary',
    initialState,
    reducers: {
        resetState: () => initialState,
        resetDetailsPage: (state) => {
            state.detailsPage = initialState.detailsPage;
        },
        setEids: (state, action: PayloadAction<number[]>) => {
            state.detailsPage.eids = action.payload;
        },
    },

    extraReducers(builder) {
        //#region fetchBeneficiaries
        builder.addCase(fetchBeneficiaries.pending, (state) => {
            state.beneficiaries.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchBeneficiaries.fulfilled, (state, action) => {
            state.beneficiaries.data = action.payload;
            state.beneficiaries.fetchStatus = StatePieceStatus.Success;
            state.detailsPage.beneficiary.createStatus = StatePieceStatus.None;
            return state;
        });
        builder.addCase(fetchBeneficiaries.rejected, (state) => {
            state.beneficiaries.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchBeneficiaryById
        builder.addCase(fetchBeneficiaryById.pending, (state) => {
            state.detailsPage.beneficiary.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchBeneficiaryById.fulfilled, (state, action) => {
            state.detailsPage.beneficiary.data = action.payload;
            state.detailsPage.beneficiary.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchBeneficiaryById.rejected, (state, action) => {
            switch (action.error.message) {
                case '403':
                    state.detailsPage.beneficiary.fetchStatus = StatePieceStatus.UnAuthorized;
                    break;
                case '404':
                    state.detailsPage.beneficiary.fetchStatus = StatePieceStatus.NotFound;
                    break;
                default:
                    state.detailsPage.beneficiary.fetchStatus = StatePieceStatus.Error;
            }
            return state;
        });
        //#endregion

        //#region addBeneficiary
        builder.addCase(addBeneficiary.pending, (state) => {
            state.detailsPage.beneficiary.createStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(addBeneficiary.fulfilled, (state, action) => {
            state.detailsPage.beneficiary.data = action.payload;
            state.detailsPage.beneficiary.createStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(addBeneficiary.rejected, (state) => {
            state.detailsPage.beneficiary.createStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region updateBeneficiary
        builder.addCase(updateBeneficiary.pending, (state) => {
            state.detailsPage.beneficiary.updateStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(updateBeneficiary.fulfilled, (state, action) => {
            state.detailsPage.beneficiary.data = action.payload;
            state.detailsPage.beneficiary.updateStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(updateBeneficiary.rejected, (state) => {
            state.detailsPage.beneficiary.updateStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchBeneficiaryComments
        builder.addCase(fetchBeneficiaryComments.pending, (state) => {
            state.detailsPage.beneficiaryComments.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchBeneficiaryComments.fulfilled, (state, action) => {
            state.detailsPage.beneficiaryComments.data = action.payload;
            state.detailsPage.beneficiaryComments.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchBeneficiaryComments.rejected, (state) => {
            state.detailsPage.beneficiaryComments.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region addFeeComment
        builder.addCase(addBeneficiaryComment.pending, (state) => {
            state.detailsPage.beneficiaryComments.createStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(addBeneficiaryComment.fulfilled, (state, action) => {
            let comments: IBeneficiaryComment[] = [...state.detailsPage.beneficiaryComments.data];
            comments.push(action.payload);

            state.detailsPage.beneficiaryComments.data = comments;
            state.detailsPage.beneficiaryComments.createStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(addBeneficiaryComment.rejected, (state) => {
            state.detailsPage.beneficiaryComments.createStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region deleteBeneficiary
        builder.addCase(deleteBeneficiary.pending, (state) => {
            state.detailsPage.beneficiary.deleteStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(deleteBeneficiary.fulfilled, (state, action) => {
            let beneficiaries = [...state.beneficiaries.data.items].filter((f) => f.id !== action.payload.id);
            state.beneficiaries.data.items = beneficiaries;
            state.detailsPage.beneficiary.deleteStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(deleteBeneficiary.rejected, (state) => {
            state.detailsPage.beneficiary.deleteStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region deleteBeneficiaryComment
        builder.addCase(deleteBeneficiaryComment.pending, (state) => {
            state.detailsPage.beneficiaryComments.deleteStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(deleteBeneficiaryComment.fulfilled, (state, action) => {
            let comments = [...state.detailsPage.beneficiaryComments.data].filter((f) => f.id !== action.payload.id);
            state.detailsPage.beneficiaryComments.data = comments;
            state.detailsPage.beneficiaryComments.deleteStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(deleteBeneficiaryComment.rejected, (state) => {
            state.detailsPage.beneficiaryComments.deleteStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region updateInvoiceStatuses
        builder.addCase(updateBeneficiaryStatuses.pending, (state) => {
            state.updateBeneficiaryStatusesStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(updateBeneficiaryStatuses.fulfilled, (state, action) => {
            let updatedBeneficiaries = [...state.beneficiaries.data.items];
            let updatedDetail = state.detailsPage?.beneficiary?.data ? { ...state.detailsPage.beneficiary.data } : null;

            action.payload.forEach((updatedBeneficiary: IBeneficiary) => {
                // Update the list
                updatedBeneficiaries = updatedBeneficiaries.map((existingBeneficiary) => {
                    if (existingBeneficiary.id === updatedBeneficiary.id) {
                        return updatedBeneficiary;
                    }
                    return existingBeneficiary;
                });

                // Update the detail
                if (updatedDetail && updatedDetail.id === updatedBeneficiary.id) {
                    updatedDetail = updatedBeneficiary;
                }
            });

            //update beneficiary list
            state.beneficiaries.data.items = updatedBeneficiaries;
            //update beneficiary detail
            state.detailsPage.beneficiary.data = updatedDetail;
            state.updateBeneficiaryStatusesStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(updateBeneficiaryStatuses.rejected, (state) => {
            state.updateBeneficiaryStatusesStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion
    },
});

// Action creators are generated for each case reducer function
export const { resetState, setEids, resetDetailsPage } = beneficiarySlice.actions;

export default beneficiarySlice.reducer;
