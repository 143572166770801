import { IInvoice } from './../../interfaces/invoices/data/IInvoice';
import { IFeeComment } from './../../interfaces/fees/data/IFeeComment';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatePieceStatus } from '../../enums';
import { IStatePiece } from '../../interfaces';
import { IFee } from '../../interfaces/fees';
import {
    fetchFees,
    fetchFeeById,
    updateFee,
    addFeeComment,
    addFee,
    fetchFeeComments,
    deleteFee,
    deleteFeeComment,
    fetchFeeInvoices,
    duplicateFees,
    updateFeeStatuses,
    fetchFeeExportData,
    generatePaymentFile,
    fetchPaymentDocumensByFeeId,
    deleteFeesBatch,
} from './FeeActions';
import { IPaginatedResult } from '../../interfaces/IPaginatedResult';
import { IPaymentDocument } from '../../interfaces/documents/data/IPaymentDocument';

export interface FeeSliceState {
    fees: IStatePiece<IPaginatedResult<IFee[]>>;
    detailFee: {
        fee: IStatePiece<IFee | null>;
        feeComments: IStatePiece<IFeeComment[]>;
        invoices: IStatePiece<IInvoice[]>;
        payments: IStatePiece<IPaymentDocument[]>;
        addInvoiceModalVisible: boolean;
        duplicateFeesModalVisible: boolean;
        /**
         * Edit item navigation ID's
         */
        eids: number[];
    };
    updateFeeStatusesStatus: StatePieceStatus;
    exportFeesStatus: StatePieceStatus;
    generatePaymentStatus: StatePieceStatus;
    deleteFeesBatchStatus: StatePieceStatus;
}

const initialState: FeeSliceState = {
    fees: {
        data: {
            items: [],
            links: {},
            paging: {
                pageNumber: 0,
                pageSize: 0,
                totalItems: 0,
                totalPages: 0,
            },
        },
        createStatus: StatePieceStatus.None,
        fetchStatus: StatePieceStatus.None,
    },
    detailFee: {
        fee: {
            data: null,
            createStatus: StatePieceStatus.None,
            fetchStatus: StatePieceStatus.None,
            deleteStatus: StatePieceStatus.None,
        },
        feeComments: {
            data: [],
            fetchStatus: StatePieceStatus.None,
            createStatus: StatePieceStatus.None,
            deleteStatus: StatePieceStatus.None,
        },
        invoices: {
            data: [],
            fetchStatus: StatePieceStatus.None,
            createStatus: StatePieceStatus.None,
        },
        payments: {
            data: [],
            fetchStatus: StatePieceStatus.None,
            createStatus: StatePieceStatus.None,
        },
        addInvoiceModalVisible: false,
        duplicateFeesModalVisible: false,
        eids: [],
    },
    updateFeeStatusesStatus: StatePieceStatus.None,
    exportFeesStatus: StatePieceStatus.None,
    generatePaymentStatus: StatePieceStatus.None,
    deleteFeesBatchStatus: StatePieceStatus.None,
};

export const feeSlice = createSlice({
    name: 'fee',
    initialState,
    reducers: {
        resetState: () => initialState,
        resetDetailsPage: (state) => {
            state.detailFee = initialState.detailFee;
        },
        toggleAddInvoiceModal: (state) => {
            state.detailFee.addInvoiceModalVisible = !state.detailFee.addInvoiceModalVisible;
        },
        toggleDuplicateFeesModal: (state) => {
            state.detailFee.duplicateFeesModalVisible = !state.detailFee.duplicateFeesModalVisible;
        },
        setEids: (state, action: PayloadAction<number[]>) => {
            state.detailFee.eids = action.payload;
        },
        updateFeeInvoices: (state, action: PayloadAction<{ feeId: number; invoices: IInvoice[] }>) => {
            state.fees.data.items = [...state.fees.data.items].map((fee) => {
                if (fee.id === action.payload.feeId) {
                    fee.invoices = action.payload.invoices;
                }
                return fee;
            });
        },
    },

    extraReducers(builder) {
        //fetchFees
        builder.addCase(fetchFees.pending, (state) => {
            state.fees.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchFees.fulfilled, (state, action) => {
            // TODO
            state.fees.data = action.payload;
            state.fees.fetchStatus = StatePieceStatus.Success;
            state.detailFee.fee.createStatus = StatePieceStatus.None;
            return state;
        });
        builder.addCase(fetchFees.rejected, (state) => {
            state.fees.fetchStatus = StatePieceStatus.Error;
            return state;
        });

        //fetchFeeById
        builder.addCase(fetchFeeById.pending, (state) => {
            state.detailFee.fee.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchFeeById.fulfilled, (state, action) => {
            state.detailFee = {
                ...state.detailFee,
                fee: {
                    ...state.detailFee.fee,
                    data: action.payload,
                    fetchStatus: StatePieceStatus.Success,
                },
                invoices: {
                    data: action.payload.invoices,
                    fetchStatus: StatePieceStatus.Success,
                },
            };
            return state;
        });
        builder.addCase(fetchFeeById.rejected, (state, action) => {
            switch (action.error.message) {
                case '403':
                    state.detailFee.fee.fetchStatus = StatePieceStatus.UnAuthorized;
                    break;
                case '404':
                    state.detailFee.fee.fetchStatus = StatePieceStatus.NotFound;
                    break;
                default:
                    state.detailFee.fee.fetchStatus = StatePieceStatus.Error;
            }
            return state;
        });

        //#region updateFee
        builder.addCase(updateFee.pending, (state) => {
            state.detailFee.fee.updateStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(updateFee.fulfilled, (state, action) => {
            //update fee list
            let updatedFees = [...state.fees.data.items].map((fee) => {
                if (fee.id === action.payload.id) {
                    return action.payload;
                }
                return fee;
            });
            state.fees.data.items = updatedFees;

            //update feedetail
            state.detailFee.fee.data = action.payload;
            state.detailFee.fee.updateStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(updateFee.rejected, (state) => {
            state.detailFee.fee.updateStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region updateFeeStatuses
        builder.addCase(updateFeeStatuses.pending, (state) => {
            state.updateFeeStatusesStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(updateFeeStatuses.fulfilled, (state, action) => {
            let updatedFees = [...state.fees.data.items];
            let updatedDetail = state.detailFee?.fee?.data ? { ...state.detailFee.fee.data } : null;

            action.payload.forEach((updatedFee: IFee) => {
                // Update the list
                updatedFees = updatedFees.map((existingFee) => {
                    if (existingFee.id === updatedFee.id) {
                        return updatedFee;
                    }
                    return existingFee;
                });

                // Update the detail
                if (updatedDetail && updatedDetail.id === updatedFee.id) {
                    updatedDetail = updatedFee;
                }
            });

            //update fee list
            state.fees.data.items = updatedFees;
            //update feedetail
            state.detailFee.fee.data = updatedDetail;
            state.updateFeeStatusesStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(updateFeeStatuses.rejected, (state) => {
            state.updateFeeStatusesStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region addFee
        builder.addCase(addFee.pending, (state) => {
            state.detailFee.fee.createStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(addFee.fulfilled, (state, action) => {
            state.fees.data.items = [...state.fees.data.items, action.payload];

            state.detailFee.fee.data = action.payload;
            state.detailFee.fee.createStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(addFee.rejected, (state) => {
            state.detailFee.fee.createStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchFeeComments
        builder.addCase(fetchFeeComments.pending, (state) => {
            state.detailFee.feeComments.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchFeeComments.fulfilled, (state, action) => {
            state.detailFee.feeComments.data = action.payload;
            state.detailFee.feeComments.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchFeeComments.rejected, (state) => {
            state.detailFee.feeComments.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region addFeeComment
        builder.addCase(addFeeComment.pending, (state) => {
            state.detailFee.feeComments.createStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(addFeeComment.fulfilled, (state, action) => {
            let comments: IFeeComment[] = [...state.detailFee.feeComments.data];
            comments.push(action.payload);

            state.detailFee.feeComments.data = comments;
            state.detailFee.feeComments.createStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(addFeeComment.rejected, (state) => {
            state.detailFee.feeComments.createStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region deleteFee
        builder.addCase(deleteFee.pending, (state) => {
            state.detailFee.fee.deleteStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(deleteFee.fulfilled, (state, action) => {
            let fees = [...state.fees.data.items].filter((f) => f.id !== action.payload.id);
            state.fees.data.items = fees;
            state.detailFee.fee.deleteStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(deleteFee.rejected, (state) => {
            state.detailFee.fee.deleteStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region deleteFeesBatch
        builder.addCase(deleteFeesBatch.pending, (state) => {
            state.deleteFeesBatchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(deleteFeesBatch.fulfilled, (state, action) => {
            let fees = [...state.fees.data.items].filter((f) => action.payload.filter((x) => x.id === f.id).length === 0);
            state.fees.data.items = fees;
            state.deleteFeesBatchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(deleteFeesBatch.rejected, (state) => {
            state.deleteFeesBatchStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region deleteFeeComment
        builder.addCase(deleteFeeComment.pending, (state) => {
            state.detailFee.feeComments.deleteStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(deleteFeeComment.fulfilled, (state, action) => {
            let comments = [...state.detailFee.feeComments.data].filter((f) => f.id !== action.payload.id);
            state.detailFee.feeComments.data = comments;
            state.detailFee.feeComments.deleteStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(deleteFeeComment.rejected, (state) => {
            state.detailFee.feeComments.deleteStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchFeeInvoices
        builder.addCase(fetchFeeInvoices.pending, (state) => {
            state.detailFee.invoices.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchFeeInvoices.fulfilled, (state, action) => {
            state.detailFee.invoices.data = action.payload;
            state.detailFee.invoices.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchFeeInvoices.rejected, (state) => {
            state.detailFee.invoices.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region duplicateFees
        builder.addCase(duplicateFees.pending, (state) => {
            state.fees.createStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(duplicateFees.fulfilled, (state, action) => {
            //no need to update as we refresh the dataset
            state.detailFee.duplicateFeesModalVisible = false;
            state.fees.createStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(duplicateFees.rejected, (state) => {
            state.fees.createStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchFeeExportData
        builder.addCase(fetchFeeExportData.pending, (state) => {
            state.exportFeesStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchFeeExportData.fulfilled, (state, action) => {
            state.exportFeesStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchFeeExportData.rejected, (state) => {
            state.exportFeesStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region generatePaymentFile
        builder.addCase(generatePaymentFile.pending, (state) => {
            state.generatePaymentStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(generatePaymentFile.fulfilled, (state, action) => {
            let updatedFees = action.payload;

            for (let i = 0; i < updatedFees.length; i++) {
                const updatedFee = updatedFees[i];
                state.fees.data.items = [
                    ...state.fees.data.items.map((f) => {
                        if (f.id === updatedFee.id) {
                            return updatedFee;
                        }
                        return f;
                    }),
                ];
            }

            state.generatePaymentStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(generatePaymentFile.rejected, (state) => {
            state.generatePaymentStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchPaymentDocumensByFeeId
        builder.addCase(fetchPaymentDocumensByFeeId.pending, (state) => {
            state.detailFee.payments.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchPaymentDocumensByFeeId.fulfilled, (state, action) => {
            state.detailFee.payments.data = action.payload;
            state.detailFee.payments.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchPaymentDocumensByFeeId.rejected, (state) => {
            state.detailFee.payments.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion
    },
});

// Action creators are generated for each case reducer function
export const { resetState, toggleAddInvoiceModal, toggleDuplicateFeesModal, setEids, updateFeeInvoices, resetDetailsPage } =
    feeSlice.actions;

export default feeSlice.reducer;
