import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select, Space, Spin } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { StatePieceStatus } from '../../../../../enums';
import { IAddCountryLookupListFormValues } from '../../../../../interfaces/lookup/country';
import { IAddCountry } from '../../../../../interfaces/lookup/country/data';
import { ContentHeader } from '../../../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { FetchResult } from '../../../../../shared/components/fetchResult/FetchResult';
import { ROW_GUTTER } from '../../../../../shared/Constants';
import { isError } from '../../../../../shared/helpers';
import { AppDispatch, RootState } from '../../../../../store';
import { addCountry, fetchCurrenciesLookupList } from '../../../../../store/lookupSlice/LookupActions';

export const CountryLookupListAdd = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { lookupSlice } = useSelector((state: RootState) => state);
    const { currencies } = useSelector((state: RootState) => state.lookupSlice);

    const onFinish = (values: IAddCountryLookupListFormValues) => {
        const body: IAddCountry = {
            ...(values as any),
        };

        dispatch(addCountry(body));
    };

    useEffect(() => {
        if (lookupSlice.detailsPage.country.createStatus === StatePieceStatus.Success) {
            navigate('/admin/lookup-lists/country', { replace: true });
        }
    }, [lookupSlice.detailsPage.country.createStatus]);

    useEffect(() => {
        dispatch(fetchCurrenciesLookupList());
    }, []);

    return (
        <Form
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            layout="vertical"
            onValuesChange={(changedValue: any, values) => {
                form.setFieldsValue(values);
            }}
        >
            <ContentHeader
                title="Manage country lookup list"
                breadcrumbs={[
                    {
                        breadcrumbName: 'Admin',
                        path: '/admin',
                    },
                    {
                        breadcrumbName: 'Manage lookup lists',
                        path: '/admin/lookup-lists',
                    },
                    {
                        breadcrumbName: 'Country',
                        path: '/admin/lookup-lists/country',
                    },
                    {
                        breadcrumbName: 'Create',
                        path: `/admin/lookup-lists/country/add`,
                    },
                ]}
            >
                {!isError(lookupSlice.detailsPage.country.fetchStatus) && (
                    <Form.Item>
                        <Space>
                            <Button
                                icon={<CloseOutlined />}
                                onClick={() => navigate(-1)}
                                disabled={
                                    lookupSlice.detailsPage.country.updateStatus === StatePieceStatus.IsFetching ||
                                    lookupSlice.detailsPage.country.fetchStatus === StatePieceStatus.IsFetching
                                }
                            >
                                Close
                            </Button>
                            <Button
                                htmlType="submit"
                                type="primary"
                                icon={<SaveOutlined />}
                                loading={
                                    lookupSlice.detailsPage.country.updateStatus === StatePieceStatus.IsFetching ||
                                    lookupSlice.detailsPage.country.fetchStatus === StatePieceStatus.IsFetching
                                }
                            >
                                Save and close
                            </Button>
                        </Space>
                    </Form.Item>
                )}
            </ContentHeader>

            <div className="page-content">
                <Spin spinning={lookupSlice.detailsPage.country.fetchStatus === StatePieceStatus.IsFetching}>
                    {isError(lookupSlice.detailsPage.country.fetchStatus) ? (
                        <FetchResult status={lookupSlice.detailsPage.country.fetchStatus} about={'Country'} />
                    ) : (
                        <>
                            <Row>
                                <Col span={24}>
                                    <Space>
                                        <label className="group-label">Country option Information</label>
                                    </Space>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={8}>
                                    <label className="property required">Code</label>
                                    <Form.Item name="code" rules={[{ required: true, message: "'Code' is required" }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <label className="property required">Description</label>
                                    <Form.Item name="description" rules={[{ required: true, message: "'Description' is required" }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <label className="property required">Default currency</label>
                                    <Form.Item
                                        name="defaultCurrencyId"
                                        rules={[{ required: true, message: "'Default currency' is required" }]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Select a currency"
                                            filterOption={(input, option) =>
                                                (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                                            }
                                        >
                                            {currencies.lookup.data
                                                .filter((f) => !f.isDeleted)
                                                .map((currency) => (
                                                    <Select.Option key={currency.key} value={currency.key} disabled={currency.isDeleted}>
                                                        {currency.description}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )}
                </Spin>
            </div>
        </Form>
    );
};
