import { DeleteFilled, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Result, Row, Space, Spin, App as AntApp } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { StatePieceStatus } from '../../../../../enums';
import { ContentHeader } from '../../../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { FetchResult } from '../../../../../shared/components/fetchResult/FetchResult';
import { ROW_GUTTER } from '../../../../../shared/Constants';
import { isError } from '../../../../../shared/helpers';
import { AppDispatch, RootState } from '../../../../../store';
import { deleteBankCostOption, fetchBankCostOptionById } from '../../../../../store/lookupSlice/LookupActions';
import { resetState } from '../../../../../store/lookupSlice/LookupSlice';
import { NotFound } from '../../../../notFound/NotFound';

export const BankCostLookupListDetail = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const { modal } = AntApp.useApp();
    const { lookupSlice } = useSelector((state: RootState) => state);
    const isLoading =
        lookupSlice.detailsPage.bankCostOption.updateStatus === StatePieceStatus.IsFetching ||
        lookupSlice.detailsPage.bankCostOption.fetchStatus === StatePieceStatus.IsFetching;
    const itemId = params.id ? parseInt(params.id) : undefined;

    const removeBankCostOption = () => {
        modal.confirm({
            title: 'Delete bank cost option',
            icon: <ExclamationCircleOutlined />,
            content: "Are you sure you want to delete this bank cost option? This action can't be undone.",
            okText: 'Confirm',
            cancelText: 'Cancel',
            onOk: () => {
                if (itemId) dispatch(deleteBankCostOption(itemId));
            },
        });
    };

    useEffect(() => {
        if (lookupSlice.detailsPage.bankCostOption.deleteStatus === StatePieceStatus.Success) {
            navigate('/admin/lookup-lists/bankcost', { replace: true });
        }
    }, [lookupSlice.detailsPage.bankCostOption.deleteStatus]);

    useEffect(() => {
        if (itemId) {
            dispatch(fetchBankCostOptionById(itemId));
        }

        return () => {
            dispatch(resetState());
        };
    }, [itemId]);

    if (lookupSlice.detailsPage.bankCostOption.fetchStatus === StatePieceStatus.Error) {
        return <Result status="warning" title="Data wasn't found or something went wrong." />;
    }

    if (!itemId) {
        return <NotFound />;
    }

    return (
        <React.Fragment>
            <ContentHeader
                title={`Bank cost option #${params.id}`}
                breadcrumbs={[
                    {
                        breadcrumbName: 'Admin',
                        path: '/admin',
                    },
                    {
                        breadcrumbName: 'Manage lookup lists',
                        path: '/admin/lookup-lists',
                    },
                    {
                        breadcrumbName: 'Bank cost',
                        path: '/admin/lookup-lists/bankcost',
                    },
                    {
                        breadcrumbName: lookupSlice.detailsPage.bankCostOption.data?.code || '-',
                        path: `/admin/lookup-lists/bankcost/${params.id}`,
                    },
                ]}
            >
                {!isError(lookupSlice.detailsPage.bankCostOption.fetchStatus) && (
                    <>
                        <Button type="primary" danger icon={<DeleteFilled />} loading={isLoading} onClick={() => removeBankCostOption()}>
                            Delete option
                        </Button>
                        <Button
                            type="default"
                            icon={<EditOutlined />}
                            onClick={() => navigate(`/admin/lookup-lists/bankcost/${params.id}/edit`)}
                        >
                            Edit option details
                        </Button>
                    </>
                )}
            </ContentHeader>

            <div className="page-content">
                <Spin spinning={lookupSlice.detailsPage.bankCostOption.fetchStatus === StatePieceStatus.IsFetching}>
                    {isError(lookupSlice.detailsPage.bankCostOption.fetchStatus) ? (
                        <FetchResult status={lookupSlice.detailsPage.bankCostOption.fetchStatus} about={'Bank cost'} />
                    ) : (
                        <>
                            <Row>
                                <Col span={24}>
                                    <Space>
                                        <label className="group-label">Bank cost option Information</label>
                                    </Space>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={8}>
                                    <label className="property required">Id</label>
                                    <p>{lookupSlice.detailsPage.bankCostOption?.data?.id || '-'}</p>
                                </Col>
                                <Col span={8}>
                                    <label className="property required">Code</label>
                                    <p>{lookupSlice.detailsPage.bankCostOption?.data?.code || '-'}</p>
                                </Col>
                                <Col span={8}>
                                    <label className="property required">Description</label>
                                    <p>{lookupSlice.detailsPage.bankCostOption?.data?.description || '-'}</p>
                                </Col>
                            </Row>

                            {/* INTERNAL INFORMATION */}
                            <Row className="margin-top">
                                <Col span={24}>
                                    <label className="group-label">Internal Information</label>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={8}>
                                    <label className="property">Created by</label>
                                    <p>{lookupSlice.detailsPage.bankCostOption.data?.createdBy?.name || '-'}</p>
                                </Col>
                                <Col span={8}>
                                    <label className="property">Created on</label>
                                    <p className="capitalize">
                                        {lookupSlice.detailsPage.bankCostOption.data?.created
                                            ? dayjs(lookupSlice.detailsPage.bankCostOption.data?.created).format('MMMM D, YYYY HH:mm')
                                            : '-'}
                                    </p>
                                </Col>
                                <Col span={8}>&nbsp;</Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={8}>
                                    <label className="property">Last modified by</label>
                                    <p>{lookupSlice.detailsPage.bankCostOption.data?.modifiedBy?.name || '-'}</p>
                                </Col>
                                <Col span={8}>
                                    <label className="property">Last modified on</label>
                                    <p className="capitalize">
                                        {lookupSlice.detailsPage.bankCostOption.data?.modified
                                            ? dayjs(lookupSlice.detailsPage.bankCostOption.data?.modified).format('MMMM D, YYYY HH:mm')
                                            : '-'}
                                    </p>
                                </Col>
                                <Col span={8}>&nbsp;</Col>
                            </Row>
                        </>
                    )}
                </Spin>
            </div>
        </React.Fragment>
    );
};
