import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Space, Spin } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { StatePieceStatus } from '../../../../../enums';
import { IAddFeeKindLookupListFormValues } from '../../../../../interfaces/lookup/feeKind';
import { IAddFeeKind } from '../../../../../interfaces/lookup/feeKind/data';
import { ContentHeader } from '../../../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { FetchResult } from '../../../../../shared/components/fetchResult/FetchResult';
import { ROW_GUTTER } from '../../../../../shared/Constants';
import { isError } from '../../../../../shared/helpers';
import { AppDispatch, RootState } from '../../../../../store';
import { addKindOfFee } from '../../../../../store/lookupSlice/LookupActions';

export const KindOfFeeLookupListAdd = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { lookupSlice } = useSelector((state: RootState) => state);

    const onFinish = (values: IAddFeeKindLookupListFormValues) => {
        const body: IAddFeeKind = {
            ...(values as any),
        };

        dispatch(addKindOfFee(body));
    };

    useEffect(() => {
        if (lookupSlice.detailsPage.kindOfFee.createStatus === StatePieceStatus.Success) {
            navigate('/admin/lookup-lists/kind-of-fee', { replace: true });
        }
    }, [lookupSlice.detailsPage.kindOfFee.createStatus]);

    return (
        <Form
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            layout="vertical"
            onValuesChange={(changedValue: any, values) => {
                form.setFieldsValue(values);
            }}
        >
            <ContentHeader
                title="Manage kinf of fees lookup list"
                breadcrumbs={[
                    {
                        breadcrumbName: 'Admin',
                        path: '/admin',
                    },
                    {
                        breadcrumbName: 'Manage lookup lists',
                        path: '/admin/lookup-lists',
                    },
                    {
                        breadcrumbName: 'Kind of fee',
                        path: '/admin/lookup-lists/kind-of-fee',
                    },
                    {
                        breadcrumbName: 'Create',
                        path: `/admin/lookup-lists/kind-of-fee/add`,
                    },
                ]}
            >
                {!isError(lookupSlice.detailsPage.kindOfFee.fetchStatus) && (
                    <Form.Item>
                        <Space>
                            <Button
                                icon={<CloseOutlined />}
                                onClick={() => navigate(-1)}
                                disabled={
                                    lookupSlice.detailsPage.kindOfFee.updateStatus === StatePieceStatus.IsFetching ||
                                    lookupSlice.detailsPage.kindOfFee.fetchStatus === StatePieceStatus.IsFetching
                                }
                            >
                                Close
                            </Button>
                            <Button
                                htmlType="submit"
                                type="primary"
                                icon={<SaveOutlined />}
                                loading={
                                    lookupSlice.detailsPage.kindOfFee.updateStatus === StatePieceStatus.IsFetching ||
                                    lookupSlice.detailsPage.kindOfFee.fetchStatus === StatePieceStatus.IsFetching
                                }
                            >
                                Save and close
                            </Button>
                        </Space>
                    </Form.Item>
                )}
            </ContentHeader>

            <div className="page-content">
                <Spin spinning={lookupSlice.detailsPage.kindOfFee.fetchStatus === StatePieceStatus.IsFetching}>
                    {isError(lookupSlice.detailsPage.kindOfFee.fetchStatus) ? (
                        <FetchResult status={lookupSlice.detailsPage.kindOfFee.fetchStatus} about={'Kind of fee'} />
                    ) : (
                        <>
                            <Row>
                                <Col span={24}>
                                    <Space>
                                        <label className="group-label">Kind of fee option Information</label>
                                    </Space>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={8}>
                                    <label className="property required">Code</label>
                                    <Form.Item name="code" rules={[{ required: true, message: "'Code' is required" }]}>
                                        <Input maxLength={10} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <label className="property required">Description</label>
                                    <Form.Item name="description" rules={[{ required: true, message: "'Description' is required" }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>&nbsp;</Col>
                            </Row>
                        </>
                    )}
                </Spin>
            </div>
        </Form>
    );
};
