import { StatePieceStatus } from './../../enums/StatePieceStatus';

/**
 * Returns true if the status is NotFound, Error, or UnAuthorized.
 */
export const isError = (status: StatePieceStatus | undefined) => {
    if (status === undefined) {
        return false;
    }

    return status === StatePieceStatus.NotFound || status === StatePieceStatus.Error || status === StatePieceStatus.UnAuthorized;
};
