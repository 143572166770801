import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Form, Space, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { StatePieceStatus } from '../../../enums';
import { IAddInvoice } from '../../../interfaces/invoices/data/IAddInvoice';
import { IAddInvoiceFormValues } from '../../../interfaces/invoices/IAddInvoiceFormValues';
import { ContentHeader } from '../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { AppDispatch, RootState } from '../../../store';
import { addInvoice } from '../../../store/invoiceSlice/InvoiceActions';
import { AddInvoiceFormFields } from './components/addInvoiceFormFields/AddInvoiceFormFields';
import './AddInvoice.less';
import { Unauthorized } from '../../../shared/components/unauthorized/Unauthorized';

export const AddInvoice = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { appSlice, invoiceSlice } = useSelector((state: RootState) => state);
    const { fees } = useSelector((state: RootState) => state.feeSlice);
    const { customers } = useSelector((state: RootState) => state.customerSlice);
    const [form] = Form.useForm();

    const isLoadingData = fees.fetchStatus === StatePieceStatus.IsFetching || customers.fetchStatus === StatePieceStatus.IsFetching;

    const onFinish = (values: IAddInvoiceFormValues) => {
        const body: IAddInvoice = {
            ...(values as any),
        };

        dispatch(addInvoice(body));
    };

    useEffect(() => {
        if (invoiceSlice.detailPage.invoice.createStatus === StatePieceStatus.Success) {
            navigate('/invoices', { replace: true });
        }
    }, [invoiceSlice.detailPage.invoice.createStatus]);

    if (appSlice.auth.validationCompleted && !appSlice.auth.invoices.create) {
        return <Unauthorized />;
    }

    return (
        <React.Fragment>
            <Form
                form={form}
                name="control-hooks"
                onFinish={onFinish}
                layout="vertical"
                onValuesChange={(changedValue: any, values) => {
                    form.setFieldsValue(values);
                }}
            >
                <ContentHeader
                    title={`Create a new invoice`}
                    breadcrumbs={[
                        {
                            breadcrumbName: 'Invoices',
                            path: '/invoices',
                        },
                        {
                            breadcrumbName: 'Create',
                            path: `/invoices/add`,
                        },
                    ]}
                >
                    <Form.Item>
                        <Space>
                            <Button
                                icon={<CloseOutlined />}
                                onClick={() => navigate(-1)}
                                disabled={invoiceSlice.detailPage.invoice.updateStatus === StatePieceStatus.IsFetching || isLoadingData}
                            >
                                Close
                            </Button>
                            <Button
                                htmlType="submit"
                                type="primary"
                                icon={<SaveOutlined />}
                                loading={invoiceSlice.detailPage.invoice.updateStatus === StatePieceStatus.IsFetching || isLoadingData}
                            >
                                Save and close
                            </Button>
                        </Space>
                    </Form.Item>
                </ContentHeader>

                <div className="page-content">
                    <Spin spinning={invoiceSlice.detailPage.invoice.fetchStatus === StatePieceStatus.IsFetching}>
                        <AddInvoiceFormFields form={form} />
                    </Spin>
                </div>
            </Form>
        </React.Fragment>
    );
};
