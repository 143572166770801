import { DeleteFilled, EditOutlined, ExclamationCircleOutlined, FileAddOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Col, App as AntApp, Row, Space, Spin, Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { StatePieceStatus } from '../../../enums';
import { IAddFeeComment } from '../../../interfaces/fees/IAddFeeComment';
import { IDeleteFeeComment } from '../../../interfaces/fees/IDeleteFeeComment';
import { ContentHeader } from '../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { Comments } from '../../../shared/components/comments/Comments';
import { FetchResult } from '../../../shared/components/fetchResult/FetchResult';
import { ROW_GUTTER } from '../../../shared/Constants';
import { fee_isEditable, generateReturnUrl, getReturnUrl, isError } from '../../../shared/helpers';
import { AppDispatch, RootState } from '../../../store';
import {
    addFeeComment,
    deleteFee,
    deleteFeeComment,
    fetchFeeById,
    fetchFeeComments,
    fetchFeeInvoices,
    fetchPaymentDocumensByFeeId,
} from '../../../store/feeSlice/FeeActions';
import { resetState, toggleAddInvoiceModal } from '../../../store/feeSlice/FeeSlice';
import { FeeInvoiceTable } from '../components/feeInvoiceTable/FeeInvoiceTable';
import './FeeDetail.less';
import { FeeStatusChangeModal } from '../feeStatusChangeModal/FeeStatusChangeModal';
import { Unauthorized } from '../../../shared/components/unauthorized/Unauthorized';
import { resetInvoiceCreateStatus } from '../../../store/invoiceSlice/InvoiceSlice';
import { formatAsCurrency } from '../../../shared/helpers/formatHelper';
import { PaymentDocumentTable } from '../components/paymentDocumentTable/PaymentDocumentTable';
import { AddInvoiceModal } from '../components/addInvoiceModal/AddInvoiceModal';
import { NotFound } from '../../notFound/NotFound';

export const FeeDetail = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const params = useParams();
    const { modal } = AntApp.useApp();
    const { appSlice, feeSlice, invoiceSlice } = useSelector((state: RootState) => state);
    const [statusChangerVisible, setStatusChangerVisible] = useState(false);
    const feeIsEditable = fee_isEditable(feeSlice.detailFee?.fee?.data);
    const feeId = params.id ? parseInt(params.id) : undefined;

    const hasInvoices = () => {
        if (feeSlice.detailFee && feeSlice.detailFee.invoices && feeSlice.detailFee.invoices.data.length > 0) {
            return true;
        }

        return false;
    };

    const isLoadingContent = () => {
        return (
            feeSlice.detailFee?.fee.fetchStatus === StatePieceStatus.IsFetching ||
            feeSlice.detailFee?.feeComments.fetchStatus === StatePieceStatus.IsFetching
        );
    };

    const removeFee = () => {
        modal.confirm({
            title: 'Delete fee',
            icon: <ExclamationCircleOutlined />,
            content: "Are you sure you want to delete this fee? This action can't be undone.",
            okText: 'Confirm',
            cancelText: 'Cancel',
            onOk: () => {
                if (feeId) dispatch(deleteFee(feeId));
            },
        });
    };

    //when creating a invoice from the modal is done
    React.useEffect(() => {
        if (invoiceSlice.detailPage.invoice.createStatus === StatePieceStatus.Success) {
            dispatch(fetchFeeInvoices(feeId!));
            dispatch(resetInvoiceCreateStatus());
        }
    }, [invoiceSlice.invoices.data]);

    React.useEffect(() => {
        if (feeId) {
            dispatch(fetchFeeById(feeId));
            dispatch(fetchFeeComments(feeId));
            dispatch(fetchPaymentDocumensByFeeId(feeId));
        }

        return () => {
            dispatch(resetState());
        };
    }, [feeId]);

    useEffect(() => {
        if (feeSlice.detailFee.fee.deleteStatus === StatePieceStatus.Success) {
            let returnUrl = getReturnUrl();
            navigate(returnUrl ? returnUrl : '/fees', { replace: true });
        }
    }, [feeSlice.detailFee.fee.deleteStatus]);

    const pageTitle = isError(feeSlice.detailFee?.fee.fetchStatus)
        ? `Fee #${params.id}`
        : `Fee #${params.id} for ${feeSlice.detailFee?.fee.data?.beneficiary?.searchName || '-'}`;

    if (appSlice.auth.validationCompleted && !appSlice.auth.fees.view) {
        return <Unauthorized />;
    }

    if (!feeId) {
        return <NotFound />;
    }

    return (
        <React.Fragment>
            <ContentHeader
                title={pageTitle}
                breadcrumbs={[
                    {
                        breadcrumbName: 'Fees',
                        path: '/fees',
                    },
                    {
                        breadcrumbName: feeSlice.detailFee?.fee.data?.beneficiary.searchName || '-',
                        path: `/fees/${feeId}`,
                    },
                ]}
            >
                {!isError(feeSlice.detailFee?.fee.fetchStatus) && (
                    <Space>
                        {appSlice.auth.fees.delete && (
                            <Tooltip title={!feeIsEditable ? 'This fee cannot be deleted because of its status.' : undefined}>
                                <Button
                                    type="primary"
                                    danger
                                    icon={<DeleteFilled />}
                                    loading={isLoadingContent()}
                                    onClick={() => removeFee()}
                                    disabled={!feeIsEditable}
                                >
                                    Delete fee
                                </Button>
                            </Tooltip>
                        )}

                        {appSlice.auth.fees.changeStatus && (
                            <Tooltip
                                title={
                                    !feeIsEditable && !appSlice.auth.admin.view
                                        ? 'This fee has a status that you are not authorized to change.'
                                        : undefined
                                }
                            >
                                <Button
                                    type="primary"
                                    icon={<UndoOutlined />}
                                    loading={isLoadingContent() || appSlice.me.info.data === null}
                                    onClick={() => setStatusChangerVisible(true)}
                                    disabled={!feeIsEditable && !appSlice.auth.admin.view}
                                >
                                    Change status
                                </Button>
                            </Tooltip>
                        )}

                        {appSlice.auth.fees.edit && (
                            <Tooltip title={!feeIsEditable ? 'The fee cannot be updated with its current status' : undefined}>
                                <Button
                                    type="default"
                                    icon={<EditOutlined />}
                                    onClick={() => navigate(`${generateReturnUrl(`edit`)}`)}
                                    disabled={!feeIsEditable}
                                >
                                    Edit fee details
                                </Button>
                            </Tooltip>
                        )}
                    </Space>
                )}
            </ContentHeader>

            <div className="page-content">
                <Spin spinning={isLoadingContent()}>
                    {isError(feeSlice.detailFee?.fee.fetchStatus) ? (
                        <FetchResult status={feeSlice.detailFee?.fee.fetchStatus} about={'Fee'} />
                    ) : (
                        <>
                            {/* FEE INFORMATION */}
                            <Row>
                                <Col span={24}>
                                    <Space>
                                        <label className="group-label">Fee Information</label>
                                    </Space>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={6}>
                                    <label className="property">Generated Fee ID</label>
                                    <p>{feeSlice.detailFee?.fee.data?.id || '-'}</p>
                                </Col>

                                <Col span={6}>
                                    <label className="property">Region</label>
                                    <p>{feeSlice.detailFee?.fee.data?.region?.description || '-'}</p>
                                </Col>
                                <Col span={4}>
                                    <label className="property">Type</label>
                                    <p>{feeSlice.detailFee?.fee.data?.kindOfFee?.description || '-'}</p>
                                </Col>
                                <Col span={4}>
                                    <label className="property">Category</label>
                                    <p>{feeSlice.detailFee?.fee.data?.category?.description || '-'}</p>
                                </Col>
                                <Col span={4}>&nbsp;</Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={6}>
                                    <label className="property">Currency</label>
                                    <p>{feeSlice.detailFee?.fee.data?.currency.description || '-'}</p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">Amount</label>
                                    <p>{formatAsCurrency(feeSlice.detailFee?.fee.data?.amount, false) || '-'}</p>
                                </Col>
                                <Col span={4}>
                                    <label className="property">Converted</label>
                                    <p>{formatAsCurrency(feeSlice.detailFee?.fee.data?.amountInEur, true) || '-'}</p>
                                </Col>
                                <Col span={4}>
                                    <label className="property">Billable</label>
                                    <p>{feeSlice.detailFee?.fee.data?.billableOption?.description || '-'}</p>
                                </Col>
                            </Row>

                            {/* CLIENT INFORMATION */}
                            <Row className="margin-top">
                                <Col span={24}>
                                    <label className="group-label">Client Information</label>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={12}>
                                    <label className="property">Project</label>
                                    <p>
                                        <Tooltip title={feeSlice.detailFee?.fee.data?.project?.description}>
                                            {feeSlice.detailFee?.fee.data?.project?.code || '-'}
                                        </Tooltip>
                                    </p>
                                </Col>
                                <Col span={12}>
                                    <label className="property">Brand name</label>
                                    <p>{feeSlice.detailFee?.fee.data?.brandName || '-'}</p>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={12}>
                                    <label className="property">Beneficiary</label>
                                    <p>
                                        <Tooltip title={feeSlice.detailFee?.fee.data?.beneficiary?.name}>
                                            {feeSlice.detailFee?.fee.data?.beneficiary?.searchName || '-'}
                                        </Tooltip>
                                    </p>
                                </Col>
                                <Col span={12}>
                                    <label className="property">Marketing authorization holder</label>
                                    <p>{feeSlice.detailFee?.fee.data?.maHolder?.description || '-'}</p>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={12}>
                                    <label className="property">Procedure code</label>
                                    <p>{feeSlice.detailFee?.fee.data?.procedureCode || '-'}</p>
                                </Col>
                                <Col span={12}>
                                    <label className="property">Variable code</label>
                                    <p>{feeSlice.detailFee?.fee.data?.variableCode || '-'}</p>
                                </Col>
                            </Row>

                            {/* PAYMENT INFORMATION */}
                            <Row className="margin-top">
                                <Col span={24}>
                                    <label className="group-label">Payment Information</label>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={6}>
                                    <label className="property">Desired payment date</label>
                                    <p className="capitalize">
                                        {feeSlice.detailFee?.fee.data?.paymentDate
                                            ? dayjs(feeSlice.detailFee?.fee.data?.paymentDate).format('MMMM D, YYYY HH:mm')
                                            : '-'}
                                    </p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">Invoice number</label>
                                    <p>{feeSlice.detailFee?.fee.data?.invoiceNumber || '-'}</p>
                                </Col>
                                <Col span={12}>
                                    <label className="property">Reason of payment</label>
                                    <p>{feeSlice.detailFee?.fee.data?.reasonOfPayment || '-'}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <label className="property">Payment description</label>
                                    <p>{feeSlice.detailFee?.fee.data?.paymentDescription || '-'}</p>
                                </Col>
                            </Row>

                            {/* STATUS INFORMATION */}
                            <Row className="margin-top">
                                <Col span={24}>
                                    <label className="group-label">Status Information</label>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={6}>
                                    <label className="property">Status</label>
                                    <p>
                                        <Tag>{feeSlice.detailFee?.fee.data?.status?.description || '-'}</Tag>
                                    </p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">Changed on</label>
                                    <p className="capitalize">
                                        {feeSlice.detailFee?.fee.data?.statusChangedDate
                                            ? dayjs(feeSlice.detailFee?.fee.data?.statusChangedDate).format('MMMM D, YYYY HH:mm')
                                            : '-'}
                                    </p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">Changed by</label>
                                    <p>{feeSlice.detailFee?.fee.data?.statusChangedByUser?.name || '-'}</p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">Comment</label>
                                    <p>{feeSlice.detailFee?.fee.data?.statusChangedComment || '-'}</p>
                                </Col>
                            </Row>

                            {/* INTERNAL INFORMATION */}
                            <Row className="margin-top">
                                <Col span={24}>
                                    <label className="group-label">Internal Information</label>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={12}>
                                    <label className="property">Created by</label>
                                    <p>{feeSlice.detailFee?.fee.data?.createdByUser.name || '-'}</p>
                                </Col>
                                <Col span={12}>
                                    <label className="property">Created on</label>
                                    <p className="capitalize">
                                        {feeSlice.detailFee?.fee.data?.createdDate
                                            ? dayjs(feeSlice.detailFee?.fee.data?.createdDate).format('MMMM D, YYYY HH:mm')
                                            : '-'}
                                    </p>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={12}>
                                    <label className="property">Last modified by</label>
                                    <p>{feeSlice.detailFee?.fee.data?.modifiedByUser?.name || '-'}</p>
                                </Col>
                                <Col span={12}>
                                    <label className="property">Last modified on</label>
                                    <p className="capitalize">
                                        {feeSlice.detailFee?.fee.data?.modifiedDate
                                            ? dayjs(feeSlice.detailFee?.fee.data?.modifiedDate).format('MMMM D, YYYY HH:mm')
                                            : '-'}
                                    </p>
                                </Col>
                            </Row>

                            <Comments
                                canCreateComment={appSlice.auth.fees.comment}
                                comments={feeSlice.detailFee?.feeComments.data}
                                submitting={feeSlice.detailFee.feeComments.createStatus === StatePieceStatus.IsFetching}
                                fetching={feeSlice.detailFee.feeComments.fetchStatus === StatePieceStatus.IsFetching}
                                onFinish={(values) => {
                                    const body: IAddFeeComment = {
                                        id: feeSlice.detailFee.fee.data?.id,
                                        ...(values as any),
                                    };

                                    dispatch(addFeeComment(body));
                                }}
                                onDeleteComment={(commentId: number) => {
                                    if (feeId) {
                                        const body: IDeleteFeeComment = {
                                            feeId: feeId,
                                            commentId: commentId,
                                        };

                                        dispatch(deleteFeeComment(body));
                                    }
                                }}
                            />

                            {/* INVOICES */}
                            <Row className="margin-top">
                                <Col span={24}>
                                    <label className="group-label">Invoices</label>
                                    <div className="invoices">
                                        {!hasInvoices() && (
                                            <div className="no-invoices">
                                                <p>This fee isn't found in any invoice.</p>

                                                {appSlice.auth.invoices.create && (
                                                    <Button
                                                        type="primary"
                                                        icon={<FileAddOutlined />}
                                                        onClick={() => dispatch(toggleAddInvoiceModal())}
                                                    >
                                                        Create an invoice
                                                    </Button>
                                                )}
                                            </div>
                                        )}

                                        {hasInvoices() && (
                                            <Spin spinning={feeSlice.detailFee.invoices.fetchStatus === StatePieceStatus.IsFetching}>
                                                <FeeInvoiceTable />

                                                {appSlice.auth.invoices.create && (
                                                    <Button
                                                        className="add-invoice-btn"
                                                        type="primary"
                                                        icon={<FileAddOutlined />}
                                                        onClick={() => dispatch(toggleAddInvoiceModal())}
                                                    >
                                                        Add an invoice
                                                    </Button>
                                                )}
                                            </Spin>
                                        )}
                                    </div>
                                </Col>
                            </Row>

                            {/* PAYMENT DOCUMENTS */}
                            <Row className="margin-top">
                                <Col span={24}>
                                    <label className="group-label">Payment documents</label>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={24}>
                                    <Spin spinning={feeSlice.detailFee.payments.fetchStatus === StatePieceStatus.IsFetching}>
                                        <PaymentDocumentTable />
                                    </Spin>
                                </Col>
                            </Row>
                        </>
                    )}

                    <AddInvoiceModal
                        isOpen={feeSlice.detailFee.addInvoiceModalVisible}
                        defaultSelectedFeeId={feeSlice.detailFee?.fee.data?.id}
                        defaultAmount={feeSlice.detailFee?.fee.data?.amountInEur}
                        reasonOfPayment={feeSlice.detailFee?.fee.data?.reasonOfPayment}
                    />
                </Spin>
            </div>
            {feeSlice.detailFee?.fee.data !== null && feeSlice.detailFee?.fee.data !== undefined && (
                <FeeStatusChangeModal
                    fees={[{ ...feeSlice.detailFee.fee.data }]}
                    open={statusChangerVisible}
                    onCancel={() => setStatusChangerVisible(false)}
                />
            )}
        </React.Fragment>
    );
};
