import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Alert, Button, Col, DatePicker, Form, Input, InputNumber, Result, Row, Select, Space, Spin, Tag } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { StatePieceStatus } from '../../../enums';
import { IUpdateFee } from '../../../interfaces/fees/data/IUpdateFee';
import { IDeleteFeeComment } from '../../../interfaces/fees/IDeleteFeeComment';
import { IUpdateFeeFormValues } from '../../../interfaces/fees/IUpdateFeeFormValues';
import { ContentHeader } from '../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { Comments } from '../../../shared/components/comments/Comments';
import { FetchResult } from '../../../shared/components/fetchResult/FetchResult';
import { MultiEditNavigation } from '../../../shared/components/multiEditNavigation/MultiEditNavigation';
import { Unauthorized } from '../../../shared/components/unauthorized/Unauthorized';
import { DECIMAL_SEPARATOR, ROW_GUTTER } from '../../../shared/Constants';
import { fee_isEditable, getReturnUrl, QueryParamHelpers } from '../../../shared/helpers';
import { isError } from '../../../shared/helpers/StatusHelpers';
import { AppDispatch, RootState } from '../../../store';
import { fetchBeneficiaryById } from '../../../store/beneficiarySlice/BeneficiaryActions';
import { deleteFeeComment, fetchFeeById, fetchFeeComments, updateFee } from '../../../store/feeSlice/FeeActions';
import { resetDetailsPage, setEids } from '../../../store/feeSlice/FeeSlice';
import {
    fetchBeneficiariesLookupList,
    fetchBillableOptionsLookupList,
    fetchCategoriesAsLookupList,
    fetchCurrenciesLookupList,
    fetchKindOfFeesLookupList,
    fetchMaHoldersLookupList,
    fetchProjectsLookupList,
    fetchRegionsLookupList,
} from '../../../store/lookupSlice/LookupActions';
import { FeeInvoiceTable } from '../components/feeInvoiceTable/FeeInvoiceTable';

import './FeeEdit.less';
import { NotFound } from '../../notFound/NotFound';

export const FeeEdit = () => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { appSlice, feeSlice, lookupSlice } = useSelector((state: RootState) => state);
    const { beneficiaries, detailsPage } = useSelector((state: RootState) => state.beneficiarySlice);
    const { regions, projects, feeStatuses, kindOfFees, categories, billableOptions, maHolders, currencies } = useSelector(
        (state: RootState) => state.lookupSlice
    );
    const [formIsPrisinte, setFormIsPrisinte] = useState(true);
    const [form] = Form.useForm();
    const feeIsEditable = fee_isEditable(feeSlice.detailFee?.fee?.data);
    const feeId = params.id ? parseInt(params.id) : undefined;

    const isLoadingLookupLists =
        beneficiaries.fetchStatus === StatePieceStatus.IsFetching ||
        maHolders.lookup.fetchStatus === StatePieceStatus.IsFetching ||
        regions.lookup.fetchStatus === StatePieceStatus.IsFetching ||
        projects.lookup.fetchStatus === StatePieceStatus.IsFetching ||
        kindOfFees.lookup.fetchStatus === StatePieceStatus.IsFetching ||
        categories.lookup.fetchStatus === StatePieceStatus.IsFetching ||
        feeStatuses.fetchStatus === StatePieceStatus.IsFetching ||
        billableOptions.lookup.fetchStatus === StatePieceStatus.IsFetching;

    const hasInvoices = () => {
        if (
            feeSlice.detailFee.fee &&
            feeSlice.detailFee.fee.data &&
            feeSlice.detailFee.fee.data.invoices &&
            feeSlice.detailFee.fee.data.invoices.length > 0
        ) {
            return true;
        }

        return false;
    };

    const onBeneficiaryChange = (beneficiaryId: number) => {
        dispatch(fetchBeneficiaryById(beneficiaryId));
    };

    //triggered by onBeneficiaryChange.
    useEffect(() => {
        if (detailsPage.beneficiary.fetchStatus === StatePieceStatus.Success) {
            form.setFieldsValue({
                currencyId: detailsPage.beneficiary.data?.currency?.id || undefined,
            });
        }
    }, [detailsPage.beneficiary.fetchStatus]);

    const onFinish = (values: IUpdateFeeFormValues) => {
        if (feeIsEditable) {
            const body: IUpdateFee = {
                id: feeSlice.detailFee.fee.data?.id,
                statusId: feeSlice.detailFee.fee.data?.status.id,
                ...(values as any),
            };

            dispatch(updateFee(body));
        }
    };

    useEffect(() => {
        if (feeSlice.detailFee.eids.length === 0 && feeSlice.detailFee.fee.updateStatus === StatePieceStatus.Success) {
            let returnUrl = getReturnUrl();
            navigate(returnUrl ? returnUrl : '/fees', { replace: true });
        }
    }, [feeSlice.detailFee.fee.updateStatus]);

    useEffect(() => {
        setFormIsPrisinte(true);

        const eids = QueryParamHelpers.getEditIds();
        if (eids.isValid) {
            dispatch(setEids(eids.data));
        }

        if (feeId) {
            dispatch(fetchFeeById(feeId));
            dispatch(fetchFeeComments(feeId));
        }
    }, [feeId]);

    React.useEffect(() => {
        if (feeSlice.detailFee.fee.data) {
            form.setFieldsValue({
                id: feeSlice.detailFee.fee.data.id,
                beneficiaryId: feeSlice.detailFee.fee.data.beneficiary?.id,
                amount: feeSlice.detailFee.fee.data.amount,
                maHolderId: feeSlice.detailFee.fee.data.maHolder?.id,
                brandName: feeSlice.detailFee.fee.data.brandName,
                projectId: feeSlice.detailFee.fee.data.project?.id,
                categoryId: feeSlice.detailFee.fee.data.category?.id,
                kindOfFeeId: feeSlice.detailFee.fee.data.kindOfFee?.id,
                regionId: feeSlice.detailFee.fee.data.region?.id,
                paymentDescription: feeSlice.detailFee.fee.data.paymentDescription,
                reasonOfPayment: feeSlice.detailFee.fee.data.reasonOfPayment,
                procedureCode: feeSlice.detailFee.fee.data.procedureCode,
                variableCode: feeSlice.detailFee.fee.data.variableCode,
                invoiceNumber: feeSlice.detailFee.fee.data.invoiceNumber,
                paymentDate: feeSlice.detailFee.fee.data.paymentDate ? dayjs(feeSlice.detailFee.fee.data.paymentDate) : undefined,
                billableOptionId: feeSlice.detailFee.fee.data.billableOption?.id,
                currencyId: feeSlice.detailFee.fee.data.currency?.id,
            } as IUpdateFeeFormValues);
        }
    }, [feeSlice.detailFee.fee.data]);

    /**
     * OnInit
     */
    useEffect(() => {
        dispatch(fetchRegionsLookupList());
        dispatch(fetchProjectsLookupList());
        dispatch(fetchCategoriesAsLookupList());
        dispatch(fetchKindOfFeesLookupList());
        dispatch(fetchBillableOptionsLookupList());
        dispatch(fetchBeneficiariesLookupList());
        dispatch(fetchMaHoldersLookupList());
        dispatch(fetchCurrenciesLookupList());

        return () => {
            dispatch(resetDetailsPage());
        };
    }, []);

    if (feeSlice.detailFee.fee.fetchStatus === StatePieceStatus.Error) {
        return <Result status="warning" title="Data wasn't found or something went wrong." />;
    }

    if (appSlice.auth.validationCompleted && !appSlice.auth.fees.edit) {
        return <Unauthorized />;
    }

    if (!feeId) {
        return <NotFound />;
    }

    return (
        <React.Fragment>
            <Form
                form={form}
                name="control-hooks"
                onFinish={onFinish}
                layout="vertical"
                onValuesChange={(changedValue: any, values) => {
                    form.setFieldsValue(values);
                    if (formIsPrisinte) {
                        setFormIsPrisinte(false);
                    }
                }}
                initialValues={
                    {
                        id: feeId,
                    } as IUpdateFeeFormValues
                }
            >
                <ContentHeader
                    title={`Fee #${params.id} for ${feeSlice.detailFee?.fee.data?.beneficiary.searchName || '-'}`}
                    hideBackButton={feeSlice.detailFee.eids.length > 0} // Hide the back button if the current view is the multi edit
                    breadcrumbs={[
                        {
                            breadcrumbName: 'Fees',
                            path: '/fees',
                        },
                        {
                            breadcrumbName: feeSlice.detailFee?.fee.data?.beneficiary.searchName || '-',
                            path: `/fees/${params.id}`,
                        },
                        {
                            breadcrumbName: 'Edit',
                            path: `/fees/${params.id}/edit`,
                        },
                    ]}
                >
                    {!isError(feeSlice.detailFee.fee.fetchStatus) && (
                        <Form.Item>
                            <Space>
                                {feeId && feeSlice.detailFee.eids.length > 0 ? (
                                    <MultiEditNavigation
                                        disabled={
                                            feeSlice.detailFee.fee.updateStatus === StatePieceStatus.IsFetching || isLoadingLookupLists
                                        }
                                        currentId={feeId}
                                        eids={feeSlice.detailFee.eids}
                                        returnUrlFallback={'/fees'}
                                        updateStatus={feeSlice.detailFee.fee.updateStatus}
                                        formIsPrisinte={formIsPrisinte}
                                        submitForm={() => form.submit()}
                                        isEditable={feeIsEditable}
                                    />
                                ) : (
                                    <>
                                        <Button
                                            icon={<CloseOutlined />}
                                            onClick={() => navigate(-1)}
                                            disabled={
                                                feeSlice.detailFee.fee.updateStatus === StatePieceStatus.IsFetching || isLoadingLookupLists
                                            }
                                        >
                                            Close
                                        </Button>
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                            icon={<SaveOutlined />}
                                            loading={
                                                feeSlice.detailFee.fee.updateStatus === StatePieceStatus.IsFetching || isLoadingLookupLists
                                            }
                                            disabled={!feeIsEditable}
                                        >
                                            Save and close
                                        </Button>
                                    </>
                                )}
                            </Space>
                        </Form.Item>
                    )}
                </ContentHeader>

                <div className="page-content">
                    {!feeIsEditable && feeSlice.detailFee.fee.fetchStatus === StatePieceStatus.Success && (
                        <Alert
                            banner
                            message={`This fee cannot be updated because it has the status ${feeSlice.detailFee.fee.data?.status.code}. Please contact your administrator if you wish to update the status.`}
                            type="info"
                        />
                    )}
                    <Spin spinning={feeSlice.detailFee?.fee.fetchStatus === StatePieceStatus.IsFetching}>
                        {isError(feeSlice.detailFee.fee.fetchStatus) ? (
                            <FetchResult status={feeSlice.detailFee.fee.fetchStatus} about={'Fee'} />
                        ) : (
                            <>
                                {/* CLIENT INFORMATION */}
                                <Row>
                                    <Col span={24}>
                                        <label className="group-label">Client Information</label>
                                    </Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={12}>
                                        <label className="property required">Project</label>
                                        <Form.Item name="projectId" rules={[{ required: true, message: "'Project' is required" }]}>
                                            <Select
                                                showSearch
                                                placeholder="Select a project"
                                                disabled={!feeIsEditable}
                                                filterOption={(input, option) =>
                                                    (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                                                }
                                            >
                                                {projects.lookup.data
                                                    .filter(
                                                        (p) =>
                                                            !p.isDeleted ||
                                                            (p.isDeleted && p.key === feeSlice.detailFee.fee.data?.project?.id)
                                                    )
                                                    .map((p) => (
                                                        <Select.Option key={p.key} value={p.key}>
                                                            {p.value}
                                                        </Select.Option>
                                                    ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <label className="property">Brand name</label>
                                        <Form.Item name="brandName" rules={[{ max: 70 }]}>
                                            <Input disabled={!feeIsEditable} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={ROW_GUTTER}>
                                    <Col span={12}>
                                        <label className="property required">Beneficiary</label>
                                        <Form.Item name="beneficiaryId" rules={[{ required: true, message: "'Beneficiary' is required" }]}>
                                            <Select
                                                showSearch
                                                placeholder="Select a beneficiary"
                                                onChange={onBeneficiaryChange}
                                                disabled={!feeIsEditable}
                                                filterOption={(input, option) =>
                                                    (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                                                }
                                            >
                                                {lookupSlice.beneficiaries.data
                                                    .filter(
                                                        (b) =>
                                                            !b.isDeleted ||
                                                            (b.isDeleted && b.key === feeSlice.detailFee.fee.data?.beneficiary.id)
                                                    )
                                                    .map((b) => {
                                                        return (
                                                            <Select.Option key={b.key} value={b.key}>
                                                                {b.value}
                                                            </Select.Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <label className="property">Marketing authorization holder</label>
                                        <Form.Item name="maHolderId">
                                            <Select
                                                showSearch
                                                placeholder="Select a MA holder"
                                                disabled={!feeIsEditable}
                                                filterOption={(input, option) =>
                                                    (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                                                }
                                            >
                                                {maHolders.lookup.data
                                                    .filter(
                                                        (m) =>
                                                            !m.isDeleted ||
                                                            (m.isDeleted && m.key === feeSlice.detailFee.fee.data?.maHolder.id)
                                                    )
                                                    .map((m) => {
                                                        return (
                                                            <Select.Option key={m.key} value={m.key}>
                                                                {m.value}
                                                            </Select.Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={12}>
                                        <label className="property">Procedure code</label>
                                        <Form.Item name="procedureCode" rules={[{ max: 50 }]}>
                                            <Input disabled={!feeIsEditable} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <label className="property">Variable code</label>
                                        <Form.Item name="variableCode" rules={[{ max: 50 }]}>
                                            <Input disabled={!feeIsEditable} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                {/* FEE INFORMATION */}
                                <Row className="margin-top">
                                    <Col span={24}>
                                        <Space>
                                            <label className="group-label">Fee Information</label>
                                        </Space>
                                    </Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={8}>
                                        <label className="property">Generated Fee ID</label>
                                        <p>{feeSlice.detailFee?.fee.data?.id || '-'}</p>
                                    </Col>
                                    <Col span={16}>&nbsp;</Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={8}>
                                        <label className="property">Type</label>
                                        <Form.Item name="kindOfFeeId">
                                            <Select placeholder="Select a kind of fee" disabled={!feeIsEditable}>
                                                {kindOfFees.lookup.data
                                                    .filter(
                                                        (k) =>
                                                            !k.isDeleted ||
                                                            (k.isDeleted && k.key === feeSlice.detailFee.fee.data?.kindOfFee?.id)
                                                    )
                                                    .map((k) => (
                                                        <Select.Option key={k.key} value={k.key}>
                                                            {k.description}
                                                        </Select.Option>
                                                    ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <label className="property required">Category</label>
                                        <Form.Item name="categoryId" rules={[{ required: true, message: "'Category' is required" }]}>
                                            <Select
                                                showSearch
                                                placeholder="Select a category"
                                                disabled={!feeIsEditable}
                                                filterOption={(input, option) =>
                                                    (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                                                }
                                            >
                                                {categories.lookup.data
                                                    .filter(
                                                        (c) =>
                                                            !c.isDeleted ||
                                                            (c.isDeleted && c.key === feeSlice.detailFee.fee.data?.category?.id)
                                                    )
                                                    .map((c) => (
                                                        <Select.Option key={c.key} value={c.key}>
                                                            {c.description}
                                                        </Select.Option>
                                                    ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <label className="property">Billable</label>
                                        <Form.Item name="billableOptionId">
                                            <Select placeholder="Select a option" disabled={!feeIsEditable}>
                                                {billableOptions.lookup.data
                                                    .filter(
                                                        (bo) =>
                                                            !bo.isDeleted ||
                                                            (bo.isDeleted && bo.key === feeSlice.detailFee.fee.data?.billableOption?.id)
                                                    )
                                                    .map((bo) => (
                                                        <Select.Option key={bo.key} value={bo.key}>
                                                            {bo.description}
                                                        </Select.Option>
                                                    ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={8}>
                                        <label className="property">Region</label>
                                        <Form.Item name="regionId">
                                            <Select
                                                showSearch
                                                placeholder="Select a region"
                                                disabled={!feeIsEditable}
                                                filterOption={(input, option) =>
                                                    (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                                                }
                                            >
                                                {regions.lookup.data
                                                    .filter(
                                                        (r) =>
                                                            !r.isDeleted ||
                                                            (r.isDeleted && r.key === feeSlice.detailFee.fee.data?.region?.id)
                                                    )
                                                    .map((region) => (
                                                        <Select.Option key={region.key} value={region.key}>
                                                            {region.description}
                                                        </Select.Option>
                                                    ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <label className="property required">Amount</label>
                                        <Form.Item name="amount" rules={[{ required: true, message: "'Amount' is required" }]}>
                                            <InputNumber decimalSeparator={DECIMAL_SEPARATOR} disabled={!feeIsEditable}></InputNumber>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <label className="property required">Currency</label>
                                        <Form.Item name="currencyId" rules={[{ required: true, message: "'Currency' is required" }]}>
                                            <Select
                                                showSearch
                                                placeholder="Select a currency"
                                                disabled={!feeIsEditable}
                                                filterOption={(input, option) =>
                                                    (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                                                }
                                            >
                                                {currencies.lookup.data
                                                    .filter(
                                                        (c) =>
                                                            !c.isDeleted ||
                                                            (c.isDeleted && c.key === feeSlice.detailFee.fee.data?.currency?.id)
                                                    )
                                                    .map((currency) => (
                                                        <Select.Option key={currency.key} value={currency.key}>
                                                            {currency.description}
                                                        </Select.Option>
                                                    ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                {/* PAYMENT INFORMATION */}
                                <Row className="margin-top">
                                    <Col span={24}>
                                        <label className="group-label">Payment Information</label>
                                    </Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={6}>
                                        <label className="property">Desired payment date</label>
                                        <Form.Item name="paymentDate">
                                            <DatePicker disabled={!feeIsEditable} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property">Invoice number</label>
                                        <Form.Item name="invoiceNumber" rules={[{ max: 255 }]}>
                                            <Input disabled={!feeIsEditable} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <label className="property required">Reason of payment</label>
                                        <Form.Item
                                            name="reasonOfPayment"
                                            rules={[{ required: true, message: "'Reason of payment' is required", max: 255 }]}
                                        >
                                            <Input disabled={!feeIsEditable} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <label className="property">Payment description</label>
                                        <p>{feeSlice.detailFee?.fee.data?.paymentDescription || '-'}</p>
                                    </Col>
                                </Row>

                                {/* STATUS INFORMATION */}
                                <Row className="margin-top">
                                    <Col span={24}>
                                        <label className="group-label">Status Information</label>
                                    </Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={6}>
                                        <label className="property">Status</label>
                                        <p>
                                            <Tag>{feeSlice.detailFee?.fee.data?.status?.description || '-'}</Tag>
                                        </p>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property">Changed on</label>
                                        <p className="capitalize">
                                            {feeSlice.detailFee?.fee.data?.statusChangedDate
                                                ? dayjs(feeSlice.detailFee?.fee.data?.statusChangedDate).format('MMMM D, YYYY HH:mm')
                                                : '-'}
                                        </p>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property">Changed by</label>
                                        <p>{feeSlice.detailFee?.fee.data?.statusChangedByUser?.name || '-'}</p>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property">Comment</label>
                                        <p>{feeSlice.detailFee?.fee.data?.statusChangedComment || '-'}</p>
                                    </Col>
                                </Row>

                                {/* INTERNAL INFORMATION */}
                                <Row className="margin-top">
                                    <Col span={24}>
                                        <label className="group-label">Internal Information</label>
                                    </Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={12}>
                                        <label className="property">Created by</label>
                                        <p>{feeSlice.detailFee?.fee.data?.createdByUser.name || '-'}</p>
                                    </Col>
                                    <Col span={12}>
                                        <label className="property">Created on</label>
                                        <p className="capitalize">
                                            {feeSlice.detailFee?.fee.data?.createdDate
                                                ? dayjs(feeSlice.detailFee?.fee.data?.createdDate).format('MMMM D, YYYY HH:mm')
                                                : '-'}
                                        </p>
                                    </Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={12}>
                                        <label className="property">Last modified by</label>
                                        <p>{feeSlice.detailFee?.fee.data?.modifiedByUser?.name || '-'}</p>
                                    </Col>
                                    <Col span={12}>
                                        <label className="property">Last modified on</label>
                                        <p className="capitalize">
                                            {feeSlice.detailFee?.fee.data?.modifiedDate
                                                ? dayjs(feeSlice.detailFee?.fee.data?.modifiedDate).format('MMMM D, YYYY HH:mm')
                                                : '-'}
                                        </p>
                                    </Col>
                                </Row>

                                <Comments
                                    canCreateComment={appSlice.auth.fees.comment}
                                    comments={feeSlice.detailFee.feeComments.data}
                                    fetching={feeSlice.detailFee.feeComments.fetchStatus === StatePieceStatus.IsFetching}
                                    onDeleteComment={(commentId: number) => {
                                        if (feeId) {
                                            const body: IDeleteFeeComment = {
                                                feeId: feeId,
                                                commentId: commentId,
                                            };

                                            dispatch(deleteFeeComment(body));
                                        }
                                    }}
                                />

                                {/* INVOICES */}
                                <Row className="margin-top">
                                    <Col span={24}>
                                        <label className="group-label">Invoices</label>
                                        <div className="invoices">
                                            {!hasInvoices() && (
                                                <div className="no-invoices">
                                                    <p>This fee isn't found in any invoice.</p>
                                                    {/* <Button type="primary" icon={<FileAddOutlined />}>
                                                        Create an invoice
                                                    </Button> */}
                                                </div>
                                            )}

                                            {hasInvoices() && (
                                                <React.Fragment>
                                                    <FeeInvoiceTable disabled={!feeIsEditable} />
                                                    {/* <Button type="primary" icon={<FileAddOutlined />}>
                                                        Add an invoice
                                                    </Button> */}
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Spin>
                </div>
            </Form>
        </React.Fragment>
    );
};
