import { ICustomer } from '../../interfaces/customer/data/ICustomer';
import { fetchCustomers, fetchCustomerById, addCustomer, updateCustomer, deleteCustomer } from './CustomerActions';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatePieceStatus } from '../../enums';
import { IStatePiece } from '../../interfaces';
import { IPaginatedResult } from '../../interfaces/IPaginatedResult';

export interface CustomerSliceState {
    customers: IStatePiece<IPaginatedResult<ICustomer[]>>;
    detailsPage: {
        customer: IStatePiece<ICustomer | null>;
        /**
         * Edit item navigation ID's
         */
        eids: number[];
    };
}

const initialState: CustomerSliceState = {
    customers: {
        data: {
            items: [],
            links: {},
            paging: {
                pageNumber: 0,
                pageSize: 0,
                totalItems: 0,
                totalPages: 0,
            },
        },
        fetchStatus: StatePieceStatus.None,
        deleteStatus: StatePieceStatus.None,
    },
    detailsPage: {
        customer: {
            data: null,
            fetchStatus: StatePieceStatus.None,
            deleteStatus: StatePieceStatus.None,
        },
        eids: [],
    },
};

export const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        resetState: () => initialState,
        setEids: (state, action: PayloadAction<number[]>) => {
            state.detailsPage.eids = action.payload;
        },
    },

    extraReducers(builder) {
        //#region fetchCustomers
        builder.addCase(fetchCustomers.pending, (state) => {
            state.customers.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchCustomers.fulfilled, (state, action) => {
            state.customers.data = action.payload;
            state.customers.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchCustomers.rejected, (state) => {
            state.customers.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchCustomerById
        builder.addCase(fetchCustomerById.pending, (state) => {
            state.detailsPage.customer.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchCustomerById.fulfilled, (state, action) => {
            state.detailsPage.customer.data = action.payload;
            state.detailsPage.customer.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchCustomerById.rejected, (state) => {
            state.detailsPage.customer.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region addCustomer
        builder.addCase(addCustomer.pending, (state) => {
            state.detailsPage.customer.createStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(addCustomer.fulfilled, (state, action) => {
            state.detailsPage.customer.data = action.payload;
            state.detailsPage.customer.createStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(addCustomer.rejected, (state) => {
            state.detailsPage.customer.createStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region updateCustomer
        builder.addCase(updateCustomer.pending, (state) => {
            state.detailsPage.customer.updateStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(updateCustomer.fulfilled, (state, action) => {
            state.detailsPage.customer.data = action.payload;
            state.detailsPage.customer.updateStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(updateCustomer.rejected, (state) => {
            state.detailsPage.customer.updateStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region deleteCustomer
        builder.addCase(deleteCustomer.pending, (state) => {
            state.detailsPage.customer.deleteStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(deleteCustomer.fulfilled, (state, action) => {
            let customers = [...state.customers.data.items].filter((f) => f.id !== action.payload.id);
            state.customers.data.items = customers;
            state.detailsPage.customer.deleteStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(deleteCustomer.rejected, (state) => {
            state.detailsPage.customer.deleteStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion
    },
});

// Action creators are generated for each case reducer function
export const { resetState, setEids } = customerSlice.actions;

export default customerSlice.reducer;
