import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Space, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useDispatch, useSelector } from 'react-redux';
import { IDocument } from '../../../../interfaces/documents/data/IDocument';
import { IPaymentDocumentTableRow } from '../../../../interfaces/documents/IPaymentDocumentTableRow';
import { AppDispatch, RootState } from '../../../../store';
import { downloadDocument } from '../../../../store/documentSlice/DocumentActions';
import dayjs from 'dayjs';
import './PaymentDocumentTable.less';

export interface IPaymentDocumentTableProps {}

export const PaymentDocumentTable = (props: IPaymentDocumentTableProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { feeSlice } = useSelector((state: RootState) => state);

    const columns: ColumnsType<IPaymentDocumentTableRow> = [
        {
            title: 'Document #',
            dataIndex: 'document',
            key: 'document',
            render: (doc: IDocument) => <>{doc.id}</>,
        },
        {
            title: 'Filename',
            dataIndex: 'document',
            key: 'document',
            render: (doc: IDocument) => <>{doc.fileName}</>,
        },
        {
            title: 'Created',
            dataIndex: 'document',
            key: 'document',
            render: (doc: IDocument) => <>{doc?.created && dayjs(doc.created).format('DD-MM-YYYY')}</>,
        },
        {
            title: 'Created by',
            dataIndex: 'document',
            key: 'document',
            render: (doc: IDocument) => <>{doc?.createdBy && <Tooltip title={doc.createdBy.name}>{doc.createdBy.initials}</Tooltip>}</>,
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: 100,
            fixed: 'right',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        type="text"
                        icon={<CloudDownloadOutlined />}
                        onClick={() => dispatch(downloadDocument({ id: record.document.id, fileName: record.document.fileName || '' }))}
                    />
                </Space>
            ),
        },
    ];

    return (
        <Table
            size="small"
            bordered={true}
            columns={columns}
            pagination={false}
            dataSource={
                // Map fees to an array of IPaymentDocumentTableRow
                feeSlice.detailFee?.payments.data.map((i) => {
                    return {
                        ...i,
                        key: i.id,
                    } as IPaymentDocumentTableRow;
                })
            }
        />
    );
};
