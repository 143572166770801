import { PlusCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Space, Table, Tag, Tooltip } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { StatePieceStatus } from '../../../../enums';
import { ITableFilter } from '../../../../interfaces';
import { IUserTableRow } from '../../../../interfaces/users/IUserTableRow';
import { ContentHeader } from '../../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { SearchBar } from '../../../../shared/components/filters/SearchBar';
import { HighlightedText } from '../../../../shared/components/highlightedText/HighlightedText';
import { generateReturnUrl, getFilteringValues, tableHasFiltersActive, updateQueryParams } from '../../../../shared/helpers';
import { calculateOverviewTableHeight } from '../../../../shared/helpers/TableHeightHelper';
import { AppDispatch, RootState } from '../../../../store';
import { fetchUsers } from '../../../../store/userSlice/UserActions';
import { resetState } from '../../../../store/userSlice/UserSlice';
import { AdminTabNavigation } from '../../adminTabNavigation/AdminTabNavigation';
import './AdminUsersOverview.less';

export const AdminUsersOverview = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { userSlice } = useSelector((state: RootState) => state);
    const [tableRenderHeight, setTableRenderHeight] = useState<number>();
    const [columns, setColumns] = useState<ColumnsType<IUserTableRow>>([]);

    const [filtering, setFiltering] = useState<ITableFilter>(
        getFilteringValues(window.location.search, columns, userSlice.users.data.paging.totalItems)
    );

    const resetFilters = () => {
        setFiltering({});
        navigate({ search: '' }, { replace: true });
    };

    const updateQuery = (
        filters: Record<string, FilterValue | null> | undefined,
        sorter: SorterResult<IUserTableRow> | undefined,
        searchQuery: string,
        pagination: TablePaginationConfig | undefined
    ) => {
        setFiltering({ ...filtering, _sorter: sorter, filters: filters, searchQuery: searchQuery, pagination: pagination });
        let newQueryUrl = updateQueryParams(filters, sorter, searchQuery, pagination);
        navigate({ search: newQueryUrl }, { replace: true });
    };

    useEffect(() => {
        let renderTableHeight = calculateOverviewTableHeight();
        setTableRenderHeight(renderTableHeight);

        return () => {
            dispatch(resetState());
        };
    }, []);

    /**
     * Create a useEffect based on the params. If the param changes to blanco and the properties
     * '_sorter' or 'filters' exists on state 'filtering' then the user navigated back to this page
     * from the left main menu.
     *
     * If '_sorter' or 'filters' does not exists the user was already on this page without any filters
     * or sortings active.
     */
    useEffect(() => {
        if (window.location.search === '' && (filtering._sorter || filtering.filters)) {
            resetFilters();
        }
    }, [window.location.search]);

    useEffect(() => {
        setColumns([
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                render: (_, record) => (
                    <span>
                        <HighlightedText text={record.name || ''} textToHighlight={filtering.searchQuery} />
                    </span>
                ),
                sorter: true,
            },
            {
                title: 'Initials',
                dataIndex: 'initials',
                key: 'initials',
                render: (_, record) => (
                    <span>
                        <HighlightedText text={record.initials || ''} textToHighlight={filtering.searchQuery} />
                    </span>
                ),
                sorter: true,
            },
            {
                title: 'Username',
                dataIndex: 'UserPrincipalName',
                key: 'userPrincipalName',
                render: (_, record) => (
                    <span>
                        <HighlightedText text={record.userPrincipalName || ''} textToHighlight={filtering.searchQuery} />
                    </span>
                ),
                sorter: true,
            },
            {
                title: 'Roles',
                dataIndex: 'permissions',
                key: 'permissions',
                render: (_, record) => (
                    <span>
                        {record.roles.map((userRole, i) => {
                            return (
                                <Tooltip title={userRole.description} key={i}>
                                    <Tag key={userRole.code}>{userRole.code}</Tag>
                                </Tooltip>
                            );
                        })}
                    </span>
                ),
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                width: 100,
                fixed: 'right',
                render: (_, record) => (
                    <Space size="middle">
                        <Button type="text" icon={<RightOutlined />} onClick={() => navigate(`${generateReturnUrl(`${record.id}`)}`)} />
                    </Space>
                ),
            },
        ]);
    }, [userSlice.users.data, filtering]);

    useEffect(() => {
        const sorter = filtering._sorter as SorterResult<IUserTableRow[]>;
        dispatch(
            fetchUsers({
                pageNumber: filtering.pagination?.current,
                sorter,
                searchQuery: filtering.searchQuery,
                pageSize: filtering.pagination?.pageSize,
            })
        );
    }, [filtering]);

    /**
     * We use a random key attribute to force a re-render on the component. This is required
     * because we cannot update the defaultValue of the AntDesign's search input component. We need
     * the ability to clear the value for resetting.
     *
     * See more: https://stackoverflow.com/questions/30626030/can-you-force-a-react-component-to-rerender-without-calling-setstate
     * @returns
     */
    const renderSearchBar = () => {
        return (
            <SearchBar
                key={Math.random()}
                loading={userSlice.users.fetchStatus === StatePieceStatus.IsFetching}
                placeholder="Search users by name or e-mail..."
                onSearch={(value) => {
                    updateQuery(
                        { ...filtering.filters },
                        { ...(filtering._sorter as SorterResult<IUserTableRow>) },
                        value,
                        filtering.pagination
                    );
                }}
                defaultValue={filtering?.searchQuery}
            />
        );
    };

    return (
        <React.Fragment>
            <ContentHeader
                title="Manage users"
                tabNavigation={<AdminTabNavigation />}
                hideBackButton
                breadcrumbs={[
                    {
                        breadcrumbName: 'Admin',
                        path: '/admin',
                    },
                    {
                        breadcrumbName: 'Manage users',
                        path: '/admin/users',
                    },
                ]}
            >
                <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => navigate(`add`)}>
                    Add new user
                </Button>
            </ContentHeader>

            <div className="page-content">
                <div className="searchbar-wrapper">
                    {renderSearchBar()}

                    {tableHasFiltersActive(filtering) && (
                        <Button onClick={() => resetFilters()} className="reset-filters-button">
                            Reset filters
                        </Button>
                    )}
                </div>

                <Table
                    size="small"
                    bordered={true}
                    scroll={{ x: true, y: tableRenderHeight }}
                    columns={columns}
                    loading={userSlice.users.fetchStatus === StatePieceStatus.IsFetching}
                    dataSource={
                        // Map to an array of IUserTableRow
                        userSlice.users.data.items.map((i) => {
                            return { ...i, key: i.id.toString() } as IUserTableRow;
                        })
                    }
                    pagination={{
                        current: userSlice.users.data.paging.pageNumber,
                        pageSize: userSlice.users.data.paging.pageSize,
                        total: userSlice.users.data.paging.totalItems,
                        defaultPageSize: 100,
                    }}
                    onChange={(pagination, filters, _sorter) => {
                        updateQuery(filters, _sorter as SorterResult<IUserTableRow>, filtering.searchQuery || '', pagination);
                    }}
                />
            </div>
        </React.Fragment>
    );
};
