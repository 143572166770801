import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Result, Row, Space, Spin } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { StatePieceStatus } from '../../../../../enums';
import { ContentHeader } from '../../../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { FetchResult } from '../../../../../shared/components/fetchResult/FetchResult';
import { ROW_GUTTER } from '../../../../../shared/Constants';
import { isError } from '../../../../../shared/helpers';
import { AppDispatch, RootState } from '../../../../../store';
import { fetchInvoiceStatusById } from '../../../../../store/lookupSlice/LookupActions';
import { resetState } from '../../../../../store/lookupSlice/LookupSlice';
import { NotFound } from '../../../../notFound/NotFound';

export const InvoiceStatusLookupListDetail = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const { lookupSlice } = useSelector((state: RootState) => state);
    const itemId = params.id ? parseInt(params.id) : undefined;

    useEffect(() => {
        if (lookupSlice.detailsPage.invoiceStatus.deleteStatus === StatePieceStatus.Success) {
            navigate('/admin/lookup-lists/invoice-status', { replace: true });
        }
    }, [lookupSlice.detailsPage.invoiceStatus.deleteStatus]);

    useEffect(() => {
        if (itemId) {
            dispatch(fetchInvoiceStatusById(itemId));
        }

        return () => {
            dispatch(resetState());
        };
    }, [itemId]);

    if (lookupSlice.detailsPage.invoiceStatus.fetchStatus === StatePieceStatus.Error) {
        return <Result status="warning" title="Data wasn't found or something went wrong." />;
    }

    if (!itemId) {
        return <NotFound />;
    }

    return (
        <React.Fragment>
            <ContentHeader
                title={`Invoice status #${params.id}`}
                breadcrumbs={[
                    {
                        breadcrumbName: 'Admin',
                        path: '/admin',
                    },
                    {
                        breadcrumbName: 'Manage lookup lists',
                        path: '/admin/lookup-lists',
                    },
                    {
                        breadcrumbName: 'Invoice status',
                        path: '/admin/lookup-lists/invoice-status',
                    },
                    {
                        breadcrumbName: lookupSlice.detailsPage.invoiceStatus.data?.code || '-',
                        path: `/admin/lookup-lists/invoice-status/${params.id}`,
                    },
                ]}
            >
                {!isError(lookupSlice.detailsPage.invoiceStatus.fetchStatus) && (
                    <>
                        <Button
                            type="default"
                            icon={<EditOutlined />}
                            onClick={() => navigate(`/admin/lookup-lists/invoice-status/${params.id}/edit`)}
                        >
                            Edit option details
                        </Button>
                    </>
                )}
            </ContentHeader>

            <div className="page-content">
                <Spin spinning={lookupSlice.detailsPage.invoiceStatus.fetchStatus === StatePieceStatus.IsFetching}>
                    {isError(lookupSlice.detailsPage.invoiceStatus.fetchStatus) ? (
                        <FetchResult status={lookupSlice.detailsPage.invoiceStatus.fetchStatus} about={'Invoice status'} />
                    ) : (
                        <>
                            <Row>
                                <Col span={24}>
                                    <Space>
                                        <label className="group-label">Invoice status option Information</label>
                                    </Space>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={8}>
                                    <label className="property required">Id</label>
                                    <p>{lookupSlice.detailsPage.invoiceStatus?.data?.id || '-'}</p>
                                </Col>
                                <Col span={8}>
                                    <label className="property required">Code</label>
                                    <p>{lookupSlice.detailsPage.invoiceStatus?.data?.code || '-'}</p>
                                </Col>
                                <Col span={8}>
                                    <label className="property required">Description</label>
                                    <p>{lookupSlice.detailsPage.invoiceStatus?.data?.description || '-'}</p>
                                </Col>
                            </Row>

                            {/* INTERNAL INFORMATION */}
                            <Row className="margin-top">
                                <Col span={24}>
                                    <label className="group-label">Internal Information</label>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={8}>
                                    <label className="property">Created by</label>
                                    <p>{lookupSlice.detailsPage.invoiceStatus.data?.createdBy?.name || '-'}</p>
                                </Col>
                                <Col span={8}>
                                    <label className="property">Created on</label>
                                    <p className="capitalize">
                                        {lookupSlice.detailsPage.invoiceStatus.data?.created
                                            ? dayjs(lookupSlice.detailsPage.invoiceStatus.data?.created).format('MMMM D, YYYY HH:mm')
                                            : '-'}
                                    </p>
                                </Col>
                                <Col span={8}>&nbsp;</Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={8}>
                                    <label className="property">Last modified by</label>
                                    <p>{lookupSlice.detailsPage.invoiceStatus.data?.modifiedBy?.name || '-'}</p>
                                </Col>
                                <Col span={8}>
                                    <label className="property">Last modified on</label>
                                    <p className="capitalize">
                                        {lookupSlice.detailsPage.invoiceStatus.data?.modified
                                            ? dayjs(lookupSlice.detailsPage.invoiceStatus.data?.modified).format('MMMM D, YYYY HH:mm')
                                            : '-'}
                                    </p>
                                </Col>
                                <Col span={8}>&nbsp;</Col>
                            </Row>
                        </>
                    )}
                </Spin>
            </div>
        </React.Fragment>
    );
};
