import { SaveOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Radio, Row, Space, Tooltip } from 'antd';
import React from 'react';

export interface IStatusChangeModalFormValues {
    code: string;
    comment: string;
}

export interface IStatusChangeModalProps {
    title: string;
    open: boolean;
    onCancel: () => void;
    loading?: boolean;
    isFetchingStatuses?: boolean;

    initialValues?: IStatusChangeModalFormValues;
    statuses: { code: string; description: string }[];
    isOptionAllowed: (code: string) => boolean;
    onFinish: (values: IStatusChangeModalFormValues) => void;
}

export const StatusChangeModal = (props: IStatusChangeModalProps) => {
    const [form] = Form.useForm();

    React.useEffect(() => {
        if (!props.open) {
            form.resetFields();
        }
    }, [props.open]);

    return (
        <Modal open={props.open} onCancel={() => props.onCancel()} footer={null} title={props.title}>
            <Form
                form={form}
                name="control-hooks"
                onFinish={props.onFinish}
                layout="vertical"
                initialValues={props.initialValues}
                onValuesChange={(_changedValue: any, values) => {
                    form.setFieldsValue(values);
                }}
            >
                <div>
                    <label className="property">Status</label>
                    <Form.Item name="code" rules={[{ required: true, message: "'Status' is required" }]}>
                        <Radio.Group>
                            <Space direction="vertical">
                                {props.statuses.map((s) => {
                                    const isDisabled = !props.isOptionAllowed(s.code);
                                    return (
                                        <Tooltip
                                            title={isDisabled ? 'You are not authorized to select this status.' : undefined}
                                            key={s.code}
                                        >
                                            <Radio disabled={isDisabled} value={s.code}>
                                                {s.description}
                                            </Radio>
                                        </Tooltip>
                                    );
                                })}
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </div>

                <div>
                    <label className="property">Comment</label>
                    <Form.Item name="comment" rules={[{ max: 255 }]}>
                        <Input.TextArea placeholder="Provide a comment here..." />
                    </Form.Item>
                </div>

                <div className="custom-modal-footer">
                    <Row justify="end">
                        <Button onClick={() => props.onCancel()} disabled={props.loading}>
                            Cancel
                        </Button>

                        <Form.Item>
                            <Button htmlType="submit" type="primary" icon={<SaveOutlined />} loading={props.loading}>
                                Save and close
                            </Button>
                        </Form.Item>
                    </Row>
                </div>
            </Form>
        </Modal>
    );
};
