import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Result, Row, Space, Spin } from 'antd';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { StatePieceStatus } from '../../../../../enums';
import { IUpdateProjectLookupListFormValues } from '../../../../../interfaces/lookup/project';
import { IUpdateProject } from '../../../../../interfaces/lookup/project/data';
import { ContentHeader } from '../../../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { FetchResult } from '../../../../../shared/components/fetchResult/FetchResult';
import { ROW_GUTTER } from '../../../../../shared/Constants';
import { isError } from '../../../../../shared/helpers';
import { AppDispatch, RootState } from '../../../../../store';
import { fetchProjectById, updateProject } from '../../../../../store/lookupSlice/LookupActions';
import { resetState } from '../../../../../store/lookupSlice/LookupSlice';
import { NotFound } from '../../../../notFound/NotFound';

export const ProjectLookupListEdit = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const [form] = Form.useForm();
    const { lookupSlice } = useSelector((state: RootState) => state);
    const itemId = params.id ? parseInt(params.id) : undefined;

    const onFinish = (values: IUpdateProjectLookupListFormValues) => {
        const body: IUpdateProject = {
            id: lookupSlice.detailsPage.project.data?.id,
            ...(values as any),
        };

        dispatch(updateProject(body));
    };

    useEffect(() => {
        if (lookupSlice.detailsPage.project.updateStatus === StatePieceStatus.Success) {
            navigate('/admin/lookup-lists/project', { replace: true });
        }
    }, [lookupSlice.detailsPage.project.updateStatus]);

    useEffect(() => {
        if (itemId) {
            dispatch(fetchProjectById(itemId));
        }

        return () => {
            dispatch(resetState());
        };
    }, [itemId]);

    useEffect(() => {
        if (lookupSlice.detailsPage.project.data) {
            form.setFieldsValue({
                id: lookupSlice.detailsPage.project.data.id,
                code: lookupSlice.detailsPage.project.data.code,
                description: lookupSlice.detailsPage.project.data.description,
            } as IUpdateProjectLookupListFormValues);
        }
    }, [lookupSlice.detailsPage.project.data]);

    if (lookupSlice.detailsPage.project.fetchStatus === StatePieceStatus.Error) {
        return <Result status="warning" title="Data wasn't found or something went wrong." />;
    }

    if (!itemId) {
        return <NotFound />;
    }

    return (
        <Form
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            layout="vertical"
            onValuesChange={(changedValue: any, values) => {
                form.setFieldsValue(values);
            }}
        >
            <ContentHeader
                title="Manage project lookup list"
                breadcrumbs={[
                    {
                        breadcrumbName: 'Admin',
                        path: '/admin',
                    },
                    {
                        breadcrumbName: 'Manage lookup lists',
                        path: '/admin/lookup-lists',
                    },
                    {
                        breadcrumbName: 'Project',
                        path: '/admin/lookup-lists/project',
                    },
                    {
                        breadcrumbName: lookupSlice.detailsPage.project.data?.description || '-',
                        path: `/admin/lookup-lists/project/${params.id}`,
                    },
                    {
                        breadcrumbName: 'Edit',
                        path: `/admin/lookup-lists/project/${params.id}/edit`,
                    },
                ]}
            >
                {!isError(lookupSlice.detailsPage.project.fetchStatus) && (
                    <Form.Item>
                        <Space>
                            <Button
                                icon={<CloseOutlined />}
                                onClick={() => navigate(-1)}
                                disabled={
                                    lookupSlice.detailsPage.project.updateStatus === StatePieceStatus.IsFetching ||
                                    lookupSlice.detailsPage.project.fetchStatus === StatePieceStatus.IsFetching
                                }
                            >
                                Close
                            </Button>
                            <Button
                                htmlType="submit"
                                type="primary"
                                icon={<SaveOutlined />}
                                loading={
                                    lookupSlice.detailsPage.project.updateStatus === StatePieceStatus.IsFetching ||
                                    lookupSlice.detailsPage.project.fetchStatus === StatePieceStatus.IsFetching
                                }
                            >
                                Save and close
                            </Button>
                        </Space>
                    </Form.Item>
                )}
            </ContentHeader>

            <div className="page-content">
                <Spin spinning={lookupSlice.detailsPage.project.fetchStatus === StatePieceStatus.IsFetching}>
                    {isError(lookupSlice.detailsPage.project.fetchStatus) ? (
                        <FetchResult status={lookupSlice.detailsPage.project.fetchStatus} about={'Project'} />
                    ) : (
                        <>
                            <Row>
                                <Col span={24}>
                                    <Space>
                                        <label className="group-label">Project Information</label>
                                    </Space>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={8}>
                                    <label className="property required">Code</label>
                                    <Form.Item name="code" rules={[{ required: true, message: "'Code' is required" }]}>
                                        <Input maxLength={20} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <label className="property required">Description</label>
                                    <Form.Item name="description" rules={[{ required: true, message: "'Description' is required" }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>&nbsp;</Col>
                            </Row>

                            {/* INTERNAL INFORMATION */}
                            <Row className="margin-top">
                                <Col span={24}>
                                    <label className="group-label">Internal Information</label>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={8}>
                                    <label className="property">Created by</label>
                                    <p>{lookupSlice.detailsPage.project?.data?.createdBy?.name || '-'}</p>
                                </Col>
                                <Col span={8}>
                                    <label className="property">Created on</label>
                                    <p className="capitalize">
                                        {lookupSlice.detailsPage.project.data?.created
                                            ? dayjs(lookupSlice.detailsPage.project.data?.created).format('MMMM D, YYYY HH:mm')
                                            : '-'}
                                    </p>
                                </Col>
                                <Col span={8}>&nbsp;</Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={8}>
                                    <label className="property">Last modified by</label>
                                    <p>{lookupSlice.detailsPage.project.data?.modifiedBy?.name || '-'}</p>
                                </Col>
                                <Col span={8}>
                                    <label className="property">Last modified on</label>
                                    <p className="capitalize">
                                        {lookupSlice.detailsPage.project.data?.modified
                                            ? dayjs(lookupSlice.detailsPage.project.data?.modified).format('MMMM D, YYYY HH:mm')
                                            : '-'}
                                    </p>
                                </Col>
                                <Col span={8}>&nbsp;</Col>
                            </Row>
                        </>
                    )}
                </Spin>
            </div>
        </Form>
    );
};
