import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppRoles, InvoiceStatuses, StatePieceStatus } from '../../../enums';
import { IInvoice } from '../../../interfaces/invoices';
import { StatusChangeModal } from '../../../shared/components/statusChangeModal/StatusChangeModal';
import { AppDispatch, RootState } from '../../../store';
import { fetchMe } from '../../../store/appSlice/AppActions';
import { updateInvoiceStatuses } from '../../../store/invoiceSlice/InvoiceActions';
import { fetchInvoiceStatuses } from '../../../store/lookupSlice/LookupActions';

export interface IInvoiceStatusChangeModalProps {
    invoices: IInvoice[];
    open: boolean;
    onCancel: () => void;
}
export const InvoiceStatusChangeModal = (props: IInvoiceStatusChangeModalProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { invoiceSlice, lookupSlice, appSlice } = useSelector((state: RootState) => state);

    const isOptionAllowed = (code: string) => {
        // Check if the user is allowed to assign this status.
        if (appSlice.me?.info?.data) {
            if (appSlice.me.info.data.roles.filter((r) => r.code === AppRoles.Admin).length > 0) {
                return true;
            }

            let currentStatusCode = invoiceSlice.detailPage.invoice.data?.status.code;
            if (currentStatusCode === code) {
                return false;
            }

            switch (code) {
                case InvoiceStatuses.Draft:
                    if (currentStatusCode === InvoiceStatuses.QueuedForApproval || currentStatusCode === InvoiceStatuses.Hold) {
                        return (
                            appSlice.me.info.data.roles.filter(
                                (r) => r.code === AppRoles.DataEntry || r.code === AppRoles.DataEntryPlus || r.code === AppRoles.Approver
                            ).length > 0
                        );
                    }

                    return false;
                case InvoiceStatuses.Hold:
                    return (
                        appSlice.me.info.data.roles.filter(
                            (r) => r.code === AppRoles.DataEntry || r.code === AppRoles.DataEntryPlus || r.code === AppRoles.Approver
                        ).length > 0
                    );

                case InvoiceStatuses.QueuedForApproval:
                    return (
                        appSlice.me.info.data.roles.filter(
                            (r) => r.code === AppRoles.DataEntry || r.code === AppRoles.DataEntryPlus || r.code === AppRoles.Approver
                        ).length > 0
                    );
                case InvoiceStatuses.Paid:
                    return appSlice.me.info.data.roles.filter((r) => r.code === AppRoles.Payer).length > 0;
                case InvoiceStatuses.ApprovedForPayment:
                    return appSlice.me.info.data.roles.filter((r) => r.code === AppRoles.Approver).length > 0;
            }
        }

        return false;
    };

    const onFinish = (values: any) => {
        dispatch(
            updateInvoiceStatuses(
                props.invoices.map((i) => {
                    return {
                        invoiceId: i.id,
                        statusCode: values.code,
                        comment: values.comment || '',
                    };
                })
            )
        );
    };

    React.useEffect(() => {
        if (
            lookupSlice.invoiceStatuses.fetchStatus === StatePieceStatus.None ||
            lookupSlice.invoiceStatuses.fetchStatus === StatePieceStatus.Error
        ) {
            dispatch(fetchInvoiceStatuses());
        }

        if (appSlice.me?.info?.data && appSlice.me.info.fetchStatus === StatePieceStatus.None) {
            dispatch(fetchMe());
        }
    }, []);

    React.useEffect(() => {
        if (invoiceSlice.updateInvoiceStatusesStatus === StatePieceStatus.Success) {
            props.onCancel();
        }
    }, [invoiceSlice.updateInvoiceStatusesStatus]);

    const title =
        props.invoices.length === 1
            ? `Change the status of invoice #${props.invoices[0].id}`
            : 'Change the status of the selected invoices';

    return (
        <StatusChangeModal
            title={title}
            open={props.open}
            onCancel={props.onCancel}
            loading={invoiceSlice.updateInvoiceStatusesStatus === StatePieceStatus.IsFetching}
            statuses={lookupSlice.invoiceStatuses.data}
            isOptionAllowed={(s) => isOptionAllowed(s)}
            onFinish={onFinish}
        />
    );
};
