import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import { Button, Col, Modal, Row, Typography } from 'antd';
import Card from 'antd/lib/card/Card';
import Paragraph from 'antd/lib/typography/Paragraph';
import { useSelector } from 'react-redux';
import { ContentHeader } from '../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { ROW_GUTTER } from '../../shared/Constants';
import { RootState } from '../../store';

import './Profile.less';

export const Profile = () => {
    const { instance } = useMsal();
    const { confirm } = Modal;
    const { appSlice } = useSelector((state: RootState) => state);

    const showConfirm = () => {
        confirm({
            title: 'Are you sure you want to log out?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Log out',
            onOk() {
                instance.logout();
            },
        });
    };

    return (
        <div className="app-page">
            <ContentHeader title="MY PROFILE"></ContentHeader>

            <div className="page-content">
                <Row gutter={ROW_GUTTER}>
                    <Col span={12}>
                        <Card title="About me">
                            <Row gutter={ROW_GUTTER}>
                                <Col span={6}>
                                    <Typography.Text strong>Name</Typography.Text>
                                </Col>
                                <Col>{appSlice.me.msGraph.data?.displayName || "-"}</Col>
                            </Row>
                            <Row gutter={[16, 24]} >
                                <Col span={6}>
                                    <Typography.Text strong>Emailaddress</Typography.Text>
                                </Col>
                                <Col>{appSlice.me.msGraph.data?.mail || "-"}</Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={6}>
                                    <Typography.Text strong>Jobtitle</Typography.Text>
                                </Col>
                                <Col>{appSlice.me.msGraph.data?.jobTitle || "-"}</Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card title="Log out">
                            <Typography>
                                <Paragraph>Log out of your account.</Paragraph>
                            </Typography>
                            <div className="logout-card-actions">
                                <Button type="primary" danger onClick={() => showConfirm()}>
                                    Logout
                                </Button>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
