import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Form, Input, InputNumber, Result, Row, Select, Space, Spin, Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { StatePieceStatus } from '../../../enums';
import { IUpdateFeeFormValues } from '../../../interfaces/fees/IUpdateFeeFormValues';
import { IUpdateInvoice } from '../../../interfaces/invoices/data/IUpdateInvoice';
import { IDeleteInvoiceComment } from '../../../interfaces/invoices/IDeleteInvoiceComment';
import { IUpdateInvoiceFormValues } from '../../../interfaces/invoices/IUpdateInvoiceFormValues';
import { ContentHeader } from '../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { Comments } from '../../../shared/components/comments/Comments';
import { FetchResult } from '../../../shared/components/fetchResult/FetchResult';
import { MultiEditNavigation } from '../../../shared/components/multiEditNavigation/MultiEditNavigation';
import { Unauthorized } from '../../../shared/components/unauthorized/Unauthorized';
import { DECIMAL_SEPARATOR, ROW_GUTTER } from '../../../shared/Constants';
import { getReturnUrl, invoice_isEditable, QueryParamHelpers } from '../../../shared/helpers';
import { isError } from '../../../shared/helpers/StatusHelpers';
import { AppDispatch, RootState } from '../../../store';
import { deleteInvoiceComment, fetchInvoiceById, fetchInvoiceComments, updateInvoice } from '../../../store/invoiceSlice/InvoiceActions';
import { resetDetailsPage, setEids } from '../../../store/invoiceSlice/InvoiceSlice';
import { fetchCategoriesAsLookupList, fetchCustomersLookupList } from '../../../store/lookupSlice/LookupActions';

import './InvoiceEdit.less';
import { NotFound } from '../../notFound/NotFound';

export const InvoiceEdit = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const params = useParams();
    const [form] = Form.useForm();
    const { appSlice, invoiceSlice } = useSelector((state: RootState) => state);
    const { customers } = useSelector((state: RootState) => state.lookupSlice);
    const [formIsPrisinte, setFormIsPrisinte] = useState(true);
    const invoiceId = params.id ? parseInt(params.id) : undefined;

    const invoiceIsEditable = invoice_isEditable(invoiceSlice.detailPage?.invoice?.data);
    const isLoading =
        invoiceSlice.detailPage?.invoice.fetchStatus === StatePieceStatus.IsFetching ||
        invoiceSlice.detailPage?.invoiceComments.fetchStatus === StatePieceStatus.IsFetching;

    const onFinish = (values: IUpdateInvoiceFormValues) => {
        if (invoiceIsEditable) {
            const body: IUpdateInvoice = {
                id: invoiceSlice.detailPage.invoice.data?.id,
                statusId: invoiceSlice.detailPage.invoice.data?.status.id,
                ...(values as any),
            };

            dispatch(updateInvoice(body));
        }
    };

    useEffect(() => {
        if (invoiceSlice.detailPage.invoice.updateStatus === StatePieceStatus.Success) {
            let returnUrl = getReturnUrl();
            navigate(returnUrl ? returnUrl : '/invoices', { replace: true });
        }
    }, [invoiceSlice.detailPage.invoice.updateStatus]);

    useEffect(() => {
        setFormIsPrisinte(true);
        const eids = QueryParamHelpers.getEditIds();
        if (eids.isValid) {
            dispatch(setEids(eids.data));
        }

        if (invoiceId) {
            dispatch(fetchInvoiceById(invoiceId));
            dispatch(fetchInvoiceComments(invoiceId));
        }
    }, [invoiceId]);

    React.useEffect(() => {
        if (invoiceSlice.detailPage.invoice.data) {
            form.setFieldsValue({
                id: invoiceSlice.detailPage.invoice.data.id,
                customerId: invoiceSlice.detailPage.invoice.data.customer?.id,
                categoryId: invoiceSlice.detailPage.invoice.data.fee.category?.id,
                contactPerson: invoiceSlice.detailPage.invoice.data.contactPerson,
                amount: invoiceSlice.detailPage.invoice.data.amount,
                reasonOfPayment: invoiceSlice.detailPage.invoice.data.reasonOfPayment,
            } as IUpdateInvoiceFormValues);
        }
    }, [invoiceSlice.detailPage.invoice.data]);

    /**
     * OnInit
     */
    useEffect(() => {
        dispatch(fetchCustomersLookupList());
        dispatch(fetchCategoriesAsLookupList());

        return () => {
            dispatch(resetDetailsPage());
        };
    }, []);

    if (invoiceSlice.detailPage.invoice.fetchStatus === StatePieceStatus.Error) {
        return <Result status="warning" title="Data wasn't found or something went wrong." />;
    }

    if (appSlice.auth.validationCompleted && !appSlice.auth.invoices.edit) {
        return <Unauthorized />;
    }

    if (!invoiceId) {
        return <NotFound />;
    }

    return (
        <React.Fragment>
            <Form
                form={form}
                name="control-hooks"
                onFinish={onFinish}
                layout="vertical"
                onValuesChange={(changedValue: any, values) => {
                    form.setFieldsValue(values);
                    if (formIsPrisinte) {
                        setFormIsPrisinte(false);
                    }
                }}
                initialValues={
                    {
                        id: invoiceId,
                    } as IUpdateFeeFormValues
                }
            >
                <ContentHeader
                    title={`Invoice #${params.id} for ${invoiceSlice.detailPage?.invoice.data?.fee?.beneficiary?.name || '-'}`}
                    hideBackButton={invoiceSlice.detailPage.eids.length > 0} // Hide the back button if the current view is the multi edit
                    breadcrumbs={[
                        {
                            breadcrumbName: 'Invoices',
                            path: '/invoices',
                        },
                        {
                            breadcrumbName: invoiceSlice.detailPage?.invoice.data?.fee?.beneficiary?.name || '-',
                            path: `/invoices/${params.id}`,
                        },
                        {
                            breadcrumbName: 'Edit',
                            path: `/invoices/${params.id}/edit`,
                        },
                    ]}
                >
                    {!isError(invoiceSlice.detailPage.invoice.fetchStatus) && (
                        <Form.Item>
                            <Space>
                                {invoiceId && invoiceSlice.detailPage.eids.length > 0 ? (
                                    <MultiEditNavigation
                                        disabled={invoiceSlice.detailPage.invoice.updateStatus === StatePieceStatus.IsFetching || isLoading}
                                        currentId={invoiceId}
                                        eids={invoiceSlice.detailPage.eids}
                                        returnUrlFallback={'/invoices'}
                                        updateStatus={invoiceSlice.detailPage.invoice.updateStatus}
                                        formIsPrisinte={formIsPrisinte}
                                        submitForm={() => form.submit()}
                                        isEditable={invoiceIsEditable}
                                    />
                                ) : (
                                    <>
                                        <Button
                                            icon={<CloseOutlined />}
                                            onClick={() => navigate(-1)}
                                            disabled={
                                                invoiceSlice.detailPage.invoice.updateStatus === StatePieceStatus.IsFetching || isLoading
                                            }
                                        >
                                            Close
                                        </Button>
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                            icon={<SaveOutlined />}
                                            loading={
                                                invoiceSlice.detailPage.invoice.updateStatus === StatePieceStatus.IsFetching || isLoading
                                            }
                                            disabled={!invoiceIsEditable}
                                        >
                                            Save and close
                                        </Button>
                                    </>
                                )}
                            </Space>
                        </Form.Item>
                    )}
                </ContentHeader>

                <div className="page-content">
                    {!invoiceIsEditable && invoiceSlice.detailPage.invoice.fetchStatus === StatePieceStatus.Success && (
                        <Alert
                            banner
                            message={`This invoice cannot be updated because it has the status ${invoiceSlice.detailPage.invoice?.data?.status.code}. Please contact your administrator if you wish to update the status.`}
                            type="info"
                        />
                    )}

                    <Spin spinning={invoiceSlice.detailPage?.invoice.fetchStatus === StatePieceStatus.IsFetching}>
                        {isError(invoiceSlice.detailPage.invoice.fetchStatus) ? (
                            <FetchResult status={invoiceSlice.detailPage.invoice.fetchStatus} about={'Invoice'} />
                        ) : (
                            <>
                                {/* INVOICE INFORMATION */}
                                <Row>
                                    <Col span={24}>
                                        <Space>
                                            <label className="group-label">Invoice Information</label>
                                        </Space>
                                    </Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={6}>
                                        <label className="property">Generated Invoice ID</label>
                                        <p>{invoiceSlice.detailPage?.invoice.data?.id || '-'}</p>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property">Billable</label>
                                        <p>{invoiceSlice.detailPage?.invoice.data?.fee?.billableOption?.description || '-'}</p>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property">Amount</label>
                                        <Form.Item name="amount">
                                            <InputNumber decimalSeparator={DECIMAL_SEPARATOR} disabled={!invoiceIsEditable}></InputNumber>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property">Category</label>
                                        <p>{invoiceSlice.detailPage?.invoice.data?.fee?.category?.description || '-'}</p>
                                    </Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={6}>
                                        <label className="property">Type</label>
                                        <p>{invoiceSlice.detailPage?.invoice.data?.fee?.kindOfFee?.description || '-'}</p>
                                    </Col>
                                    <Col span={18}>&nbsp;</Col>
                                </Row>

                                {/* CLIENT INFORMATION */}
                                <Row className="margin-top">
                                    <Col span={24}>
                                        <label className="group-label">Client Information</label>
                                    </Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={6}>
                                        <label className="property">Project</label>
                                        <p>
                                            <Tooltip title={invoiceSlice.detailPage?.invoice.data?.fee?.project?.description}>
                                                {invoiceSlice.detailPage?.invoice.data?.fee?.project?.code || '-'}
                                            </Tooltip>
                                        </p>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property">Brand name</label>
                                        <p>{invoiceSlice.detailPage?.invoice.data?.fee?.brandName || '-'}</p>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property">Beneficiary</label>
                                        <p>
                                            <Tooltip title={invoiceSlice.detailPage?.invoice.data?.fee?.beneficiary?.name}>
                                                {invoiceSlice.detailPage?.invoice.data?.fee?.beneficiary?.searchName || '-'}
                                            </Tooltip>
                                        </p>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property">Marketing authorization holder</label>
                                        <p>{invoiceSlice.detailPage?.invoice.data?.fee?.maHolder?.description || '-'}</p>
                                    </Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={6}>
                                        <label className="property required">Customer</label>
                                        <Form.Item name="customerId" rules={[{ required: true, message: "'Customer' is required" }]}>
                                            <Select
                                                placeholder="Select a customer"
                                                showSearch
                                                disabled={!invoiceIsEditable}
                                                filterOption={(input, option) =>
                                                    (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                                                }
                                            >
                                                {customers.data
                                                    .filter(
                                                        (c) =>
                                                            !c.isDeleted ||
                                                            (c.isDeleted && c.key === invoiceSlice.detailPage.invoice.data?.customer.id)
                                                    )
                                                    .map((c) => (
                                                        <Select.Option key={c.key} value={c.key}>
                                                            {c.value}
                                                        </Select.Option>
                                                    ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property">Contact person</label>
                                        <Form.Item name="contactPerson" rules={[{ max: 255 }]}>
                                            <Input disabled={!invoiceIsEditable} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>&nbsp;</Col>
                                </Row>

                                {/* PAYMENT INFORMATION */}
                                <Row className="margin-top">
                                    <Col span={24}>
                                        <label className="group-label">Payment Information</label>
                                    </Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={6}>
                                        <label className="property">Desired payment date</label>
                                        <p className="capitalize">
                                            {invoiceSlice.detailPage?.invoice.data?.fee?.paymentDate
                                                ? dayjs(invoiceSlice.detailPage?.invoice.data?.fee?.paymentDate).format(
                                                      'MMMM D, YYYY HH:mm'
                                                  )
                                                : '-'}
                                        </p>
                                    </Col>
                                    <Col span={18}>
                                        <label className="property required">Reason of payment</label>
                                        <Form.Item
                                            name="reasonOfPayment"
                                            rules={[{ required: true, message: "'Reason of Payment' is required", max: 255 }]}
                                        >
                                            <Input disabled={!invoiceIsEditable} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <label className="property">Payment description</label>
                                        <p>{invoiceSlice.detailPage?.invoice.data?.fee?.paymentDescription || '-'}</p>
                                    </Col>
                                </Row>

                                {/* STATUS INFORMATION */}
                                <Row className="margin-top">
                                    <Col span={24}>
                                        <label className="group-label">Status Information</label>
                                    </Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={6}>
                                        <label className="property">Status</label>
                                        <p>
                                            <Tag>{invoiceSlice.detailPage?.invoice.data?.status?.description || '-'}</Tag>
                                        </p>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property">Changed on</label>
                                        <p className="capitalize">
                                            {invoiceSlice.detailPage?.invoice.data?.statusChangedDate
                                                ? dayjs(invoiceSlice.detailPage?.invoice.data?.statusChangedDate).format(
                                                      'MMMM D, YYYY HH:mm'
                                                  )
                                                : '-'}
                                        </p>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property">Changed by</label>
                                        <p>{invoiceSlice.detailPage?.invoice.data?.statusChangedByUser?.name || '-'}</p>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property">Comment</label>
                                        <p>{invoiceSlice.detailPage?.invoice.data?.statusChangedComment || '-'}</p>
                                    </Col>
                                </Row>

                                {/* INTERNAL INFORMATION */}
                                <Row className="margin-top">
                                    <Col span={24}>
                                        <label className="group-label">Internal Information</label>
                                    </Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={12}>
                                        <label className="property">Created by</label>
                                        <p>{invoiceSlice.detailPage?.invoice.data?.createdByUser.name || '-'}</p>
                                    </Col>
                                    <Col span={12}>
                                        <label className="property">Created on</label>
                                        <p className="capitalize">
                                            {invoiceSlice.detailPage?.invoice.data?.createdDate
                                                ? dayjs(invoiceSlice.detailPage?.invoice.data?.createdDate).format('MMMM D, YYYY HH:mm')
                                                : '-'}
                                        </p>
                                    </Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={12}>
                                        <label className="property">Last modified by</label>
                                        <p>{invoiceSlice.detailPage?.invoice.data?.modifiedByUser?.name || '-'}</p>
                                    </Col>
                                    <Col span={12}>
                                        <label className="property">Last modified on</label>
                                        <p className="capitalize">
                                            {invoiceSlice.detailPage?.invoice.data?.modifiedDate
                                                ? dayjs(invoiceSlice.detailPage?.invoice.data?.modifiedDate).format('MMMM D, YYYY HH:mm')
                                                : '-'}
                                        </p>
                                    </Col>
                                </Row>

                                <Comments
                                    canCreateComment={appSlice.auth.invoices.comment}
                                    comments={invoiceSlice.detailPage?.invoiceComments.data}
                                    submitting={invoiceSlice.detailPage.invoiceComments.createStatus === StatePieceStatus.IsFetching}
                                    fetching={invoiceSlice.detailPage.invoiceComments.fetchStatus === StatePieceStatus.IsFetching}
                                    onDeleteComment={(commentId: number) => {
                                        if (invoiceId) {
                                            const body: IDeleteInvoiceComment = {
                                                invoiceId: invoiceId,
                                                commentId: commentId,
                                            };

                                            dispatch(deleteInvoiceComment(body));
                                        }
                                    }}
                                />
                            </>
                        )}
                    </Spin>
                </div>
            </Form>
        </React.Fragment>
    );
};
