import { createAsyncThunk } from '@reduxjs/toolkit';
import { IGraphUser } from '../../interfaces/users/IGraphUser';
import { fetchAPI, fetchMsGraph } from '../../MSALConfig';
import { IUser } from '../../interfaces/users/data';

export const fetchMe = createAsyncThunk('users/fetchMe', async () => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/me`, {});
        const result: IUser = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchMeGraph = createAsyncThunk('users/fetchMeGraph', async () => {
    try {
        const response = await fetchMsGraph(`https://graph.microsoft.com/v1.0/me`, {});
        const result: IGraphUser = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchMyPhoto = createAsyncThunk('users/fetchMyPhoto', async () => {
    try {
        return new Promise<string>(async (resolve, reject) => {
            const response: Response = await fetchMsGraph(`https://graph.microsoft.com/v1.0/me/photo/$value`, {});

            if (response.status === 200) {
                const blob = await response.blob();
                const newBlob = new Blob([blob]);
                const blobUrl = window.URL.createObjectURL(newBlob);
                return resolve(blobUrl);
            }

            return reject(null);
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
});
