import { CheckOutlined, PlusCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Button, notification, Space, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { StatePieceStatus } from '../../../../../enums';
import { IBillableLookupListTableRow } from '../../../../../interfaces/lookup/billable';
import { ContentHeader } from '../../../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { NOTIFICATION_PLACEMENT } from '../../../../../shared/Constants';
import { AppDispatch, RootState } from '../../../../../store';
import { fetchBillableOptions, updateDefaultBillableOption } from '../../../../../store/lookupSlice/LookupActions';
import { resetState } from '../../../../../store/lookupSlice/LookupSlice';

export const BillableLookupListOverview = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { billableOptions } = useSelector((state: RootState) => state.lookupSlice);

    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
    const [selectedTableRecords, setSelectedTabelRecords] = useState<IBillableLookupListTableRow[]>([]);

    const columns: ColumnsType<IBillableLookupListTableRow> = [
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Default',
            dataIndex: 'isDefault',
            key: 'isDefault',
            width: 100,
            render: (def: boolean) => <Space size="middle">{def ? 'Yes' : 'No'}</Space>,
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: 100,
            render: (_, record) => (
                <Space size="middle">
                    <Button type="text" icon={<RightOutlined />} onClick={() => navigate(`/admin/lookup-lists/billable/${record.id}`)} />
                </Space>
            ),
        },
    ];

    const rowSelection = {
        selectedRowKeys,
        onChange: (selRowKeys: React.Key[], selectedRows: IBillableLookupListTableRow[]) => {
            setSelectedRowKeys(selRowKeys.map((r) => parseInt(r.toString())));
            setSelectedTabelRecords(selectedRows);
        },
    };

    useEffect(() => {
        if (billableOptions.model.fetchStatus === StatePieceStatus.Success) {
            if (selectedTableRecords.length > 0) {
                let newSelectedTableRecords = [...selectedTableRecords].map((r) => {
                    let newData = billableOptions.model.data.filter((d) => d.id === r.id)[0];
                    return {
                        id: newData.id,
                        description: newData.description,
                        isDefault: newData.isDefault,
                    } as IBillableLookupListTableRow;
                });
                setSelectedTabelRecords(newSelectedTableRecords);
            }
        }
    }, [billableOptions.model.fetchStatus]);

    useEffect(() => {
        if (billableOptions.model.updateStatus === StatePieceStatus.Success) {
            navigate('/admin/lookup-lists/billable', { replace: true });
        }
    }, [billableOptions.model.updateStatus]);

    useEffect(() => {
        dispatch(fetchBillableOptions());

        return () => {
            dispatch(resetState());
        };
    }, []);

    useEffect(() => {
        if (billableOptions.model.fetchStatus === StatePieceStatus.Error) {
            notification.error({
                message: 'An error occurred.',
                duration: 0,
                description: 'An error occurred while retrieving the data. Please contact your administrator if the problem persists.',
                placement: NOTIFICATION_PLACEMENT,
            });
        }
    }, [billableOptions.model.fetchStatus]);

    return (
        <div className="app-page">
            <ContentHeader
                title="Manage billable option lookup list"
                breadcrumbs={[
                    {
                        breadcrumbName: 'Admin',
                        path: '/admin',
                    },
                    {
                        breadcrumbName: 'Manage lookup lists',
                        path: '/admin/lookup-lists',
                    },
                    {
                        breadcrumbName: 'Billable option',
                        path: '/admin/lookup-lists/billable',
                    },
                ]}
            >
                <>
                    {selectedTableRecords.length === 1 && (
                        <Tooltip title={selectedTableRecords[0].isDefault ? 'Selected billable option is already the default.' : undefined}>
                            <Button
                                disabled={selectedTableRecords[0].isDefault}
                                icon={<CheckOutlined />}
                                onClick={() => dispatch(updateDefaultBillableOption(selectedTableRecords[0].id))}
                                loading={billableOptions.model.fetchStatus === StatePieceStatus.IsFetching}
                            >
                                Set as default
                            </Button>
                        </Tooltip>
                    )}

                    <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => navigate('/admin/lookup-lists/billable/add')}>
                        Add new option
                    </Button>
                </>
            </ContentHeader>

            <div className="page-content">
                <Table
                    size="small"
                    bordered={true}
                    loading={billableOptions.model.fetchStatus === StatePieceStatus.IsFetching}
                    dataSource={billableOptions.model.data.map((option, i) => {
                        return { ...option, key: i } as IBillableLookupListTableRow;
                    })}
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                    }}
                    columns={columns}
                    pagination={{
                        defaultPageSize: 50,
                    }}
                />
            </div>
        </div>
    );
};
