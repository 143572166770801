import { RightOutlined } from '@ant-design/icons';
import { Button, Space, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ICustomer } from '../../../../interfaces/customer/data';
import { IInvoiceTableRow } from '../../../../interfaces/invoices/IInvoiceTableRow';
import { IInvoice } from '../../../../interfaces/invoices';
import { generateReturnUrl } from '../../../../shared/helpers';
import { formatAsCurrency } from '../../../../shared/helpers/formatHelper';
import { RootState } from '../../../../store';
import './FeeInvoiceTable.less';
import dayjs from 'dayjs';

export interface IFeeInvoiceTableProps {
    disabled?: boolean;
}

export const FeeInvoiceTable = (props: IFeeInvoiceTableProps) => {
    const { feeSlice } = useSelector((state: RootState) => state);
    const navigate = useNavigate();

    const columns: ColumnsType<IInvoiceTableRow> = [
        {
            title: 'Invoice #',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Created date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (text: string) => <>{text && dayjs(text).format('DD-MM-YYYY')}</>,
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            key: 'customer',
            render: (customer: ICustomer) => <>{customer && customer.searchName}</>,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount: number) => <>{formatAsCurrency(amount)}</>,
        },

        {
            title: 'Invoice status',
            dataIndex: 'status',
            key: 'status',
            render: (_, invoice: IInvoice) => (
                <>{invoice.status && invoice.status.code && <Tooltip title={invoice.status.description}>{invoice.status.code}</Tooltip>}</>
            ),
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: 65,
            fixed: 'right',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        type="text"
                        icon={<RightOutlined />}
                        onClick={() => {
                            var returnUrl = generateReturnUrl(`/invoices/${record.id}`, true);
                            navigate(returnUrl);
                        }}
                        disabled={props.disabled}
                    />
                </Space>
            ),
        },
    ];

    return (
        <Table
            size="small"
            bordered={true}
            columns={columns}
            pagination={false}
            dataSource={
                // Map fees to an array of IFeeTableRow
                feeSlice.detailFee?.invoices.data.map((i) => {
                    return { ...i, key: i.id } as IInvoiceTableRow;
                })
            }
        />
    );
};
