import { RightOutlined } from '@ant-design/icons';
import { Button, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useNavigate } from 'react-router';
import { IAdminLookupListTableRow } from '../../../interfaces/lookup';
import { ContentHeader } from '../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { AdminTabNavigation } from '../adminTabNavigation/AdminTabNavigation';

export const AdminLookupLists = () => {
    const navigate = useNavigate();

    const columns: ColumnsType<IAdminLookupListTableRow> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: 100,
            render: (_, record) => (
                <Space size="middle">
                    <Button type="text" icon={<RightOutlined />} onClick={() => navigate(`/admin/lookup-lists/${record.link}`)} />
                </Space>
            ),
        },
    ];

    const data = [
        {
            name: 'Bank accounts',
            link: 'bank-account',
        },
        {
            name: 'Bank costs',
            link: 'bankcost',
        },
        {
            name: 'Beneficiary statuses',
            link: 'beneficiary-status',
        },
        {
            name: 'Billable option',
            link: 'billable',
        },
        {
            name: 'Category',
            link: 'category',
        },
        {
            name: 'Country',
            link: 'country',
        },
        {
            name: 'Currency',
            link: 'currency',
        },
        {
            name: 'Fee statuses',
            link: 'fee-status',
        },
        {
            name: 'Invoice statuses',
            link: 'invoice-status',
        },
        {
            name: 'Kind of Fee',
            link: 'kind-of-fee',
        },
        {
            name: 'MA Holder',
            link: 'ma-holder',
        },
        {
            name: 'Project',
            link: 'project',
        },
        {
            name: 'Region',
            link: 'region',
        },
    ];

    return (
        <div className="app-page">
            <ContentHeader
                title="Manage lookup lists"
                tabNavigation={<AdminTabNavigation />}
                hideBackButton
                breadcrumbs={[
                    {
                        breadcrumbName: 'Admin',
                        path: '/admin',
                    },
                    {
                        breadcrumbName: 'Manage lookup lists',
                        path: '/admin/lookup-lists',
                    },
                ]}
            ></ContentHeader>

            <div className="page-content">
                <Table
                    size="small"
                    bordered={true}
                    pagination={{ defaultPageSize: 50 }}
                    dataSource={data.map((d, i) => {
                        return { ...d, key: i } as IAdminLookupListTableRow;
                    })}
                    columns={columns}
                />
            </div>
        </div>
    );
};
