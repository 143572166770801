import { IInvoiceDocumentFilterParams } from './../../interfaces/documents/IInvoiceDocumentFilterParams';
import { ExportHelpers } from './../../shared/helpers/ExportHelpers';
import { IManualDocument } from './../../interfaces/documents/data/IManualDocument';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAPI } from '../../MSALConfig';
import { notification } from 'antd';
import { NOTIFICATION_PLACEMENT } from '../../shared/Constants';
import { IInvoiceDocument } from '../../interfaces/documents/data/IInvoiceDocument';
import { IPaginatedResult } from '../../interfaces/IPaginatedResult';
import { constructQueryParams } from '../../shared/helpers/FilterHelpers';
import { IManualDocumentFilterParams } from '../../interfaces/documents/IManualDocumentFilterParams';
import { IPaymentDocumentFilterParams } from '../../interfaces/documents/IPaymentDocumentFilterParams';
import { IPaymentDocument } from '../../interfaces/documents/data/IPaymentDocument';

const appendPaymentFilterParams = (props: IInvoiceDocumentFilterParams, params: string) => {
    return params;
};

export const fetchManualDocuments = createAsyncThunk('documents/fetchManualDocuments', async (props: IManualDocumentFilterParams) => {
    try {
        let params = constructQueryParams(props);
        params = appendPaymentFilterParams(props, params);

        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/manuals/documents${params}`, {});

        if (response.status !== 200) {
            throw new Error('');
        }

        const result: IPaginatedResult<IManualDocument[]> = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        notification.error({
            message: 'An error occurred.',
            description: 'An error occurred while retrieving the manuals. Please contact your administrator if the problem persists.',
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        throw e;
    }
});

export const deleteManualDocument = createAsyncThunk('documents/deleteManualDocument', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/manuals/documents/${id}`, {
            method: 'DELETE',
        });

        if (response.status !== 200) {
            throw new Error('');
        }
        notification.success({
            message: 'Manual deleted.',
            description: `The manual has been deleted sucessfully.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return id;
    } catch (e) {
        console.error(e);
        notification.error({
            message: 'An error occurred.',
            description: 'An error occurred while deleting the manual. Please contact your administrator if the problem persists.',
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        throw e;
    }
});

export const downloadDocument = createAsyncThunk('documents/downloadDocument', async (props: { id: number; fileName: string }) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/documents/${props.id}/$value`, {});

        if (response.status !== 200) {
            throw new Error('');
        }

        const blob: any = await response.blob();
        ExportHelpers.downloadBlob(blob, props.fileName);

        return true;
    } catch (e) {
        console.error(e);
        notification.error({
            message: 'An error occurred.',
            description: 'The document could not be downloaded.',
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        throw e;
    }
});

export const fetchAllInvoiceDocuments = createAsyncThunk('invoices/documents', async (props: IInvoiceDocumentFilterParams) => {
    try {
        let params = constructQueryParams(props);
        params = appendPaymentFilterParams(props, params);

        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/invoices/documents${params}`, {});
        const result: IPaginatedResult<IInvoiceDocument[]> = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        notification.error({
            message: 'An error occurred.',
            duration: 0,
            description:
                'An error occurred while retrieving the invoice documents. Please contact your administrator if the problem persists.',
            placement: NOTIFICATION_PLACEMENT,
        });
        throw e;
    }
});

export const fetchAllPaymentDocuments = createAsyncThunk(
    'documents/fetchAllPaymentDocuments',
    async (props: IPaymentDocumentFilterParams) => {
        try {
            let params = constructQueryParams(props);
            params = appendPaymentFilterParams(props, params);

            const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/payments/documents${params}`, {});
            const result: IPaginatedResult<IPaymentDocument[]> = await response.json();
            return result;
        } catch (e) {
            console.error(e);
            notification.error({
                message: 'An error occurred.',
                duration: 0,
                description:
                    'An error occurred while retrieving the payment documents. Please contact your administrator if the problem persists.',
                placement: NOTIFICATION_PLACEMENT,
            });
            throw e;
        }
    }
);
