import { IInvoice } from '../../interfaces/invoices/data/IInvoice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatePieceStatus } from '../../enums';
import { IStatePiece } from '../../interfaces';
import { IInvoiceComment } from '../../interfaces/invoices/data/IInvoiceComment';
import {
    addInvoice,
    addInvoiceComment,
    deleteInvoice,
    deleteInvoiceComment,
    deleteInvoicesBatch,
    fetchInvoiceById,
    fetchInvoiceComments,
    fetchInvoiceDocuments,
    fetchInvoices,
    fetchInvoicesExportAsPDF,
    fetchInvoicesExportData,
    updateInvoice,
    updateInvoiceStatuses,
} from './InvoiceActions';
import { IPaginatedResult } from '../../interfaces/IPaginatedResult';
import { IInvoiceDocument } from '../../interfaces/documents/data/IInvoiceDocument';

export interface InvoiceSliceState {
    invoices: IStatePiece<IPaginatedResult<IInvoice[]>>;
    detailPage: {
        invoice: IStatePiece<IInvoice | null>;
        invoiceComments: IStatePiece<IInvoiceComment[]>;
        invoiceDocuments: IStatePiece<IInvoiceDocument[]>;

        /**
         * Edit item navigation ID's
         */
        eids: number[];
    };
    updateInvoiceStatusesStatus: StatePieceStatus;
    exportInvoicesStatus: StatePieceStatus;
    exportInvoicesAsPfdStatus: StatePieceStatus;
    deleteInvoicesBatchStatus: StatePieceStatus;
}

const initialState: InvoiceSliceState = {
    invoices: {
        data: {
            items: [],
            links: {},
            paging: {
                pageNumber: 0,
                pageSize: 0,
                totalItems: 0,
                totalPages: 0,
            },
        },
        fetchStatus: StatePieceStatus.None,
    },

    detailPage: {
        invoice: {
            data: null,
            fetchStatus: StatePieceStatus.None,
            deleteStatus: StatePieceStatus.None,
        },
        invoiceComments: {
            data: [],
            fetchStatus: StatePieceStatus.None,
            createStatus: StatePieceStatus.None,
            deleteStatus: StatePieceStatus.None,
        },
        invoiceDocuments: {
            data: [],
            fetchStatus: StatePieceStatus.None,
            createStatus: StatePieceStatus.None,
        },

        eids: [],
    },
    updateInvoiceStatusesStatus: StatePieceStatus.None,
    exportInvoicesStatus: StatePieceStatus.None,
    exportInvoicesAsPfdStatus: StatePieceStatus.None,
    deleteInvoicesBatchStatus: StatePieceStatus.None,
};

export const invoiceSlice = createSlice({
    name: 'invoice',
    initialState,
    reducers: {
        resetState: () => initialState,
        resetDetailsPage: (state) => {
            state.detailPage = initialState.detailPage;
        },
        resetInvoiceCreateStatus: (state) => {
            state.detailPage.invoice.createStatus = StatePieceStatus.None;
        },
        setEids: (state, action: PayloadAction<number[]>) => {
            state.detailPage.eids = action.payload;
        },
    },

    extraReducers(builder) {
        //fetchInvoices
        builder.addCase(fetchInvoices.pending, (state) => {
            state.invoices.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchInvoices.fulfilled, (state, action) => {
            state.invoices.data = action.payload;
            state.invoices.fetchStatus = StatePieceStatus.Success;
            state.detailPage.invoice.createStatus = StatePieceStatus.None;
            return state;
        });
        builder.addCase(fetchInvoices.rejected, (state) => {
            state.invoices.fetchStatus = StatePieceStatus.Error;
            return state;
        });

        //fetchInvoiceById
        builder.addCase(fetchInvoiceById.pending, (state) => {
            state.detailPage.invoice.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchInvoiceById.fulfilled, (state, action) => {
            state.detailPage = {
                ...state.detailPage,
                invoice: {
                    ...state.detailPage.invoice,
                    data: action.payload,
                    fetchStatus: StatePieceStatus.Success,
                },
            };
            return state;
        });
        builder.addCase(fetchInvoiceById.rejected, (state, action) => {
            switch (action.error.message) {
                case '403':
                    state.detailPage.invoice.fetchStatus = StatePieceStatus.UnAuthorized;
                    break;
                case '404':
                    state.detailPage.invoice.fetchStatus = StatePieceStatus.NotFound;
                    break;
                default:
                    state.detailPage.invoice.fetchStatus = StatePieceStatus.Error;
            }
            return state;
        });

        //#region updateInvoice
        builder.addCase(updateInvoice.pending, (state) => {
            state.detailPage.invoice.updateStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(updateInvoice.fulfilled, (state, action) => {
            //update invoice list
            let updatedInvoices = [...state.invoices.data.items].map((invoice) => {
                if (invoice.id === action.payload.id) {
                    return action.payload;
                }
                return invoice;
            });
            state.invoices.data.items = updatedInvoices;

            //update invoicedetail
            state.detailPage.invoice.data = action.payload;
            state.detailPage.invoice.updateStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(updateInvoice.rejected, (state) => {
            state.detailPage.invoice.updateStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region updateInvoiceStatuses
        builder.addCase(updateInvoiceStatuses.pending, (state) => {
            state.updateInvoiceStatusesStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(updateInvoiceStatuses.fulfilled, (state, action) => {
            let updatedInvoices = [...state.invoices.data.items];
            let updatedDetail = state.detailPage?.invoice?.data ? { ...state.detailPage.invoice.data } : null;

            action.payload.forEach((updatedInvoice: IInvoice) => {
                // Update the list
                updatedInvoices = updatedInvoices.map((existingInvoice) => {
                    if (existingInvoice.id === updatedInvoice.id) {
                        return updatedInvoice;
                    }
                    return existingInvoice;
                });

                // Update the detail
                if (updatedDetail && updatedDetail.id === updatedInvoice.id) {
                    updatedDetail = updatedInvoice;
                }
            });

            //update invoice list
            state.invoices.data.items = updatedInvoices;
            //update invoice detail
            state.detailPage.invoice.data = updatedDetail;
            state.updateInvoiceStatusesStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(updateInvoiceStatuses.rejected, (state) => {
            state.updateInvoiceStatusesStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region addInvoice
        builder.addCase(addInvoice.pending, (state) => {
            state.detailPage.invoice.createStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(addInvoice.fulfilled, (state, action) => {
            state.invoices.data.items = [...state.invoices.data.items, action.payload];

            state.detailPage.invoice.data = action.payload;
            state.detailPage.invoice.createStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(addInvoice.rejected, (state) => {
            state.detailPage.invoice.createStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchInvoiceComments
        builder.addCase(fetchInvoiceComments.pending, (state) => {
            state.detailPage.invoiceComments.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchInvoiceComments.fulfilled, (state, action) => {
            state.detailPage.invoiceComments.data = action.payload;
            state.detailPage.invoiceComments.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchInvoiceComments.rejected, (state) => {
            state.detailPage.invoiceComments.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region addInvoiceComment
        builder.addCase(addInvoiceComment.pending, (state) => {
            state.detailPage.invoiceComments.createStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(addInvoiceComment.fulfilled, (state, action) => {
            let comments: IInvoiceComment[] = [...state.detailPage.invoiceComments.data];
            comments.push(action.payload);

            state.detailPage.invoiceComments.data = comments;
            state.detailPage.invoiceComments.createStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(addInvoiceComment.rejected, (state) => {
            state.detailPage.invoiceComments.createStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region deleteInvoice
        builder.addCase(deleteInvoice.pending, (state) => {
            state.detailPage.invoice.deleteStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(deleteInvoice.fulfilled, (state, action) => {
            let invoices = [...state.invoices.data.items].filter((f) => f.id !== action.payload.id);
            state.invoices.data.items = invoices;
            state.detailPage.invoice.deleteStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(deleteInvoice.rejected, (state) => {
            state.detailPage.invoice.deleteStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region deleteInvoicesBatch
        builder.addCase(deleteInvoicesBatch.pending, (state) => {
            state.deleteInvoicesBatchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(deleteInvoicesBatch.fulfilled, (state, action) => {
            let invoices = [...state.invoices.data.items].filter((f) => action.payload.filter((x) => x.id === f.id).length === 0);
            state.invoices.data.items = invoices;
            state.deleteInvoicesBatchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(deleteInvoicesBatch.rejected, (state) => {
            state.deleteInvoicesBatchStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region deleteInvoiceComment
        builder.addCase(deleteInvoiceComment.pending, (state) => {
            state.detailPage.invoiceComments.deleteStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(deleteInvoiceComment.fulfilled, (state, action) => {
            let comments = [...state.detailPage.invoiceComments.data].filter((f) => f.id !== action.payload.id);
            state.detailPage.invoiceComments.data = comments;
            state.detailPage.invoiceComments.deleteStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(deleteInvoiceComment.rejected, (state) => {
            state.detailPage.invoiceComments.deleteStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchInvoicesExportData
        builder.addCase(fetchInvoicesExportData.pending, (state) => {
            state.exportInvoicesStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchInvoicesExportData.fulfilled, (state, action) => {
            state.exportInvoicesStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchInvoicesExportData.rejected, (state) => {
            state.exportInvoicesStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchInvoicesExportAsPDF
        builder.addCase(fetchInvoicesExportAsPDF.pending, (state) => {
            state.exportInvoicesAsPfdStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchInvoicesExportAsPDF.fulfilled, (state, action) => {
            let newInvoices = state.invoices.data.items.map((invoice) => {
                let updatedInvoice = action.payload.filter((i) => i.id === invoice.id);

                if (updatedInvoice.length > 0 && invoice.id === updatedInvoice[0].id) {
                    return updatedInvoice[0];
                }

                return invoice;
            });
            state.invoices.data.items = newInvoices;

            if (state.detailPage.invoice.data !== null && action.payload.length > 0) {
                state.detailPage.invoice.data = action.payload[0];
            }

            state.exportInvoicesAsPfdStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchInvoicesExportAsPDF.rejected, (state) => {
            state.exportInvoicesAsPfdStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchInvoiceDocuments
        builder.addCase(fetchInvoiceDocuments.pending, (state) => {
            state.detailPage.invoiceDocuments.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchInvoiceDocuments.fulfilled, (state, action) => {
            state.detailPage.invoiceDocuments.data = action.payload;
            state.detailPage.invoiceDocuments.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchInvoiceDocuments.rejected, (state) => {
            state.detailPage.invoiceDocuments.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion
    },
});

// Action creators are generated for each case reducer function
export const { resetState, resetInvoiceCreateStatus, setEids, resetDetailsPage } = invoiceSlice.actions;

export default invoiceSlice.reducer;
