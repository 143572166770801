import { ColumnsType } from 'antd/lib/table';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { IBeneficiaryTableRow } from '../../interfaces/beneficiaries';
import { ICustomerTableRow } from '../../interfaces/customer/ICustomerTableRow';
import { IFeeTableRow, IInvoiceTableRow } from '../../interfaces/fees';
import { ITableFilter } from '../../interfaces/ITableFilter';
import { IUserTableRow } from '../../interfaces/users';

// #region TableFilters
export const getFilteringValues: any = (urlPath: string, columns: ColumnsType<IFeeTableRow>, totalItems: number) => {
    if (urlPath && urlPath !== '') {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        let reservedParams = ['sortBy', 'sortDirection', 'query', 'page', 'pageSize'];

        let activeFilters: any = {};
        Object.keys(params)
            .filter((k) => reservedParams.indexOf(k) === -1)
            .map((key) => {
                //multi
                if (params[key].includes('|')) {
                    let values = params[key].split('|');
                    activeFilters[key] = values;
                }
                //single
                else {
                    let values = params[key];
                    activeFilters[key] = [values];
                }

                return key;
            });

        return {
            _sorter: {
                order: params.sortDirection,
                columnKey: params.sortBy,
                field: params.sortBy,
                column: columns.filter((c) => c.key === params.sortBy).length > 0 ? columns.filter((c) => c.key === params.sortBy)[0] : {},
            },
            searchQuery: params.query ? params.query : undefined,
            filters: activeFilters,
            pagination: {
                current: params.page,
                pageSize: params.pageSize,
                total: totalItems,
                showSizeChanger: true,
            },
        };
    }
    return {};
};

export const updateQueryParams = (
    filters: Record<string, FilterValue | null> | undefined,
    sorter:
        | SorterResult<IFeeTableRow>
        | SorterResult<IInvoiceTableRow>
        | SorterResult<IBeneficiaryTableRow>
        | SorterResult<ICustomerTableRow>
        | SorterResult<IUserTableRow>
        | undefined,
    searchQuery: string,
    pagination: TablePaginationConfig | undefined
) => {
    let newQueryUrl = '';
    if (sorter && sorter.columnKey && sorter.order) {
        newQueryUrl += `sortBy=${sorter.columnKey}&sortDirection=${sorter.order}`;
    }

    if (searchQuery && searchQuery.length > 0) {
        newQueryUrl += newQueryUrl.length === 0 ? '' : '&';
        newQueryUrl += `query=${encodeURIComponent(searchQuery)}`;
    }

    if (filters && Object.keys(filters).length > 0) {
        let filterKeys = Object.keys(filters);

        //keep track of the keys that have values. We need to know this to know
        //wehter we should ad an &-char in the URL.
        let countValidKeysWithValues = 0;

        //loop trough all the keys
        for (let ki = 0; ki < filterKeys.length; ki++) {
            const filterKey = filterKeys[ki];

            if (filters[filterKey]) {
                let filterValues = filters[filterKey];

                //if a key also has values
                if (filterValues) {
                    //if url already has a value or this is not the first value of the loop, add the &-char
                    if (newQueryUrl !== '' || countValidKeysWithValues > 0) {
                        newQueryUrl += `&`;
                    }

                    //append the keyName
                    newQueryUrl += `${filterKey}=`;

                    //append the values for the key
                    for (let vi = 0; vi < filterValues.length; vi++) {
                        const filterValue = filterValues[vi];
                        newQueryUrl += `${encodeURIComponent(filterValue)}`;

                        if (vi + 1 < filterValues.length) {
                            newQueryUrl += `|`;
                        }
                    }

                    countValidKeysWithValues++;
                }
            }
        }
    }

    if (pagination) {
        newQueryUrl += newQueryUrl.length === 0 ? '' : '&';
        newQueryUrl += `page=${pagination.current}&pageSize=${pagination.pageSize}`;
    }

    return newQueryUrl;
};

/**
 * Returns wether the table has any active filters.
 * @returns
 */
export const tableHasFiltersActive = (filtering: ITableFilter) => {
    //check Sorting
    if (filtering && (filtering._sorter as SorterResult<IFeeTableRow>)?.column) return true;

    //check searchQuery
    if (filtering && filtering.searchQuery && filtering.searchQuery.trim() !== '') return true;

    //check filters. Loop trough values because AntDesign sets value to null after
    //unchecking a filter (instead of removing it from the array).
    if (filtering && filtering.filters && Object.keys(filtering.filters as object).length > 0) {
        let filterValues = Object.values(filtering.filters as object);
        let foundActiveFilters = filterValues.filter((f) => f !== null).length > 0;
        return foundActiveFilters;
    }

    return false;
};
// #endregion

export const QueryParamHelpers = {
    getEditIds: (): { isValid: boolean; data: number[] } => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if (params.eids === undefined) {
            return {
                isValid: false,
                data: [],
            };
        }

        const result: number[] = [];
        const splittedParams = params.eids.split('|');
        splittedParams.forEach((id) => {
            const parsed = parseInt(id);
            if (!isNaN(parsed)) {
                result.push(parsed);
            }
        });

        return {
            isValid: result.length > 1,
            data: result,
        };
    },
    appendEidsQueryParam: (url: string, selectedTableRecords: { id: number }[]): string => {
        var queryParams = ``;
        if (selectedTableRecords.length > 0) {
            queryParams += url.indexOf('?') !== -1 ? '&eids=' : '?eids=';

            selectedTableRecords.forEach((r, i) => {
                queryParams += r.id.toString();
                queryParams += i >= selectedTableRecords.length - 1 ? '' : '|';
            });
        }

        return url + queryParams;
    },
};
