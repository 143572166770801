import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppRoles, FeeStatuses, StatePieceStatus } from '../../../enums';
import { IFee } from '../../../interfaces/fees';
import { StatusChangeModal } from '../../../shared/components/statusChangeModal/StatusChangeModal';
import { AppDispatch, RootState } from '../../../store';
import { fetchMe } from '../../../store/appSlice/AppActions';
import { updateFeeStatuses } from '../../../store/feeSlice/FeeActions';
import { fetchFeeStatuses } from '../../../store/lookupSlice/LookupActions';

export interface IFeeStatusChangeModalProps {
    fees: IFee[];
    open: boolean;
    onCancel: () => void;
}
export const FeeStatusChangeModal = (props: IFeeStatusChangeModalProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { feeSlice, lookupSlice, appSlice } = useSelector((state: RootState) => state);

    const isOptionAllowed = (code: string) => {
        // Check if the user is allowed to assign this status.
        if (appSlice.me?.info?.data) {
            if (appSlice.me.info.data.roles.filter((r) => r.code === AppRoles.Admin).length > 0) {
                return true;
            }

            let currentStatusCode = feeSlice.detailFee.fee.data?.status.code;
            if (currentStatusCode === code) {
                return false;
            }

            switch (code) {
                case FeeStatuses.Draft:
                    if (currentStatusCode === FeeStatuses.VoucherPayment || currentStatusCode === FeeStatuses.QueuedForApproval) {
                        return (
                            appSlice.me.info.data.roles.filter(
                                (r) => r.code === AppRoles.DataEntry || r.code === AppRoles.DataEntryPlus || r.code === AppRoles.Approver
                            ).length > 0
                        );
                    }

                    return false;
                case FeeStatuses.VoucherPayment:
                    return (
                        appSlice.me.info.data.roles.filter((r) => r.code === AppRoles.DataEntry || r.code === AppRoles.DataEntryPlus)
                            .length > 0
                    );
                case FeeStatuses.QueuedForApproval:
                    return (
                        appSlice.me.info.data.roles.filter(
                            (r) => r.code === AppRoles.DataEntry || r.code === AppRoles.DataEntryPlus || r.code === AppRoles.Approver
                        ).length > 0
                    );
                case FeeStatuses.Paid:
                    return appSlice.me.info.data.roles.filter((r) => r.code === AppRoles.Payer).length > 0;
                case FeeStatuses.ApprovedForPayment:
                    return appSlice.me.info.data.roles.filter((r) => r.code === AppRoles.Approver).length > 0;
            }
        }

        return false;
    };

    const onFinish = (values: any) => {
        dispatch(
            updateFeeStatuses(
                props.fees.map((f) => {
                    return {
                        feeId: f.id,
                        statusCode: values.code,
                        comment: values.comment || '',
                    };
                })
            )
        );
    };

    React.useEffect(() => {
        if (
            lookupSlice.feeStatuses.fetchStatus === StatePieceStatus.None ||
            lookupSlice.feeStatuses.fetchStatus === StatePieceStatus.Error
        ) {
            dispatch(fetchFeeStatuses());
        }

        if (appSlice.me?.info?.data && appSlice.me.info.fetchStatus === StatePieceStatus.None) {
            dispatch(fetchMe());
        }
    }, []);

    React.useEffect(() => {
        if (feeSlice.updateFeeStatusesStatus === StatePieceStatus.Success) {
            props.onCancel();
        }
    }, [feeSlice.updateFeeStatusesStatus]);

    const title = props.fees.length === 1 ? `Change the status of fee #${props.fees[0].id}` : 'Change the status of the selected fees';
    return (
        <StatusChangeModal
            title={title}
            open={props.open}
            onCancel={props.onCancel}
            loading={feeSlice.updateFeeStatusesStatus === StatePieceStatus.IsFetching}
            statuses={lookupSlice.feeStatuses.data}
            isOptionAllowed={(s) => isOptionAllowed(s)}
            onFinish={onFinish}
        />
    );
};
