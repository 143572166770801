import { fetchAPI } from '../../MSALConfig';

/**
 * Helpers for export functions.
 */
export const ExportHelpers = {
    /**
     * @param endpoint URL
     * @param itemIds IDs which will be added as query params
     * @param fileName The name of the downloaded file
     */
    exportCsv: async (endpoint: string, itemIds: string[] | number[], fileName?: string) => {
        if (itemIds.length === 0) {
            console.error('Empty array passed.');
            return;
        }

        let params = '?';

        itemIds.forEach((itemIds) => {
            if (params.length > 1) params += '&';
            params += `id=${itemIds}`;
        });

        const response = await fetchAPI(`${endpoint}${params}`, {});
        const result: any = await response.text();

        let encodedUri = encodeURI(result);

        // If hashtags are not replaced the csv will break after it encounters one. So replace all.
        while (encodedUri.indexOf('#') !== -1) {
            encodedUri = encodedUri.replace('#', '%23');
        }

        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodedUri;
        hiddenElement.target = '_blank';
        hiddenElement.download = fileName ? fileName : `export.csv`;
        hiddenElement.click();
    },

    /**
     * Downloads a provided blob
     * @param blob
     * @param fileName
     */
    downloadBlob: (blob: any, fileName: string) => {
        var a = document.createElement('a');
        document.body.appendChild(a);
        //@ts-ignore
        a.style = 'display: none';

        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    },
};
