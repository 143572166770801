import { Configuration, PublicClientApplication } from '@azure/msal-browser';

/**
 * Config file for MSAL.
 */
export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID!,
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID!}`,
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        claimsBasedCachingEnabled: true,
    },
};

export const msalInstance = await PublicClientApplication.createPublicClientApplication(msalConfig);

const scopes = {
    synthonScopes: [`${process.env.REACT_APP_API_RESOURCE_ID!}/access_as_user`],
    graphScopes: ['User.Read'],
};

/**
 * Make an authenticated call to the Synthon API.
 */
export const fetchAPI = async (url: string, options: any) => {
    const account = msalInstance.getAllAccounts()[0];
    const authResult = await msalInstance.acquireTokenSilent({ scopes: scopes.synthonScopes, account: account });

    let headers = new Headers();
    let bearer = 'Bearer ' + authResult.accessToken;
    headers.append('Authorization', bearer);
    headers.append('Content-type', 'application/json');

    return fetch(url, {
        ...options,
        headers: headers,
    });
};

/**
 * Make an authenticated call to the Synthon API.
 */
export const uploadAPI = async (url: string, options: any) => {
    const account = msalInstance.getAllAccounts()[0];
    const authResult = await msalInstance.acquireTokenSilent({ scopes: scopes.synthonScopes, account: account });

    let headers = new Headers();
    let bearer = 'Bearer ' + authResult.accessToken;
    headers.append('Authorization', bearer);

    return fetch(url, {
        ...options,
        headers: headers,
    });
};

/**
 * Make an authenticated call to the Synthon API.
 */
export const uploadDocumentAPI = async (url: string, options: any) => {
    const account = msalInstance.getAllAccounts()[0];
    const authResult = await msalInstance.acquireTokenSilent({ scopes: scopes.synthonScopes, account: account });

    let headers = new Headers();
    let bearer = 'Bearer ' + authResult.accessToken;
    headers.append('Authorization', bearer);

    return fetch(url, {
        ...options,
        headers: headers,
    });
};

/**
 * Make an authenticated call to the MSGraph
 */
export const fetchMsGraph = async (url: string, options: any) => {
    const account = msalInstance.getAllAccounts()[0];
    const authResult = await msalInstance.acquireTokenSilent({ scopes: scopes.graphScopes, account: account });

    let headers = new Headers();
    let bearer = 'Bearer ' + authResult.accessToken;
    headers.append('Authorization', bearer);
    headers.append('Content-type', 'application/json');

    return fetch(url, {
        ...options,
        headers: headers,
    });
};
