import Highlighter from 'react-highlight-words';

export interface IHighlightedTextProps {
    text: string;
    textToHighlight?: string;
}

export const HighlightedText = (props: IHighlightedTextProps) => {
    const { text, textToHighlight } = props;

    if (textToHighlight) {
        if (text.toLowerCase().indexOf(textToHighlight.toLowerCase()) !== -1) {
            return (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[textToHighlight]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            );
        }
    }

    return <>{text}</>;
};
