import { ExportHelpers } from './../../shared/helpers/ExportHelpers';
import { IDuplicateFee } from './../../interfaces/fees/data/IDuplicateFee';
import { IInvoice } from './../../interfaces/invoices/data/IInvoice';
import { IFeeComment } from './../../interfaces/fees/data/IFeeComment';
import { IAddFee } from './../../interfaces/fees/data/IAddFee';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAPI } from '../../MSALConfig';
import { IFee, IUpdateFeeStatusCode } from '../../interfaces/fees';
import { notification } from 'antd';
import { IUpdateFee } from '../../interfaces/fees/data/IUpdateFee';
import { NOTIFICATION_PLACEMENT } from '../../shared/Constants';
import { IAddFeeComment } from '../../interfaces/fees/IAddFeeComment';
import { IDeleteFeeComment } from '../../interfaces/fees/IDeleteFeeComment';
import { IFeeFilterParams } from '../../interfaces/fees/IFeeFilterParams';
import { constructQueryParams } from '../../shared/helpers/FilterHelpers';
import { IPaginatedResult } from '../../interfaces/IPaginatedResult';
import { IPaymentDocument } from '../../interfaces/documents/data/IPaymentDocument';
import { IErrorResponse } from '../../interfaces/error/IErrorReponse';

const appendFeeFilterParams = (props: IFeeFilterParams, params: string) => {
    if (params === '') params = '?';

    if (props.statusCode) {
        props.statusCode.forEach((element) => {
            if (params.length > 1) params += '&';
            params += `StatusCode=${element}`;
        });
    }

    if (props.kindOfFee) {
        props.kindOfFee.forEach((element) => {
            if (params.length > 1) params += '&';
            params += `KindOfFee=${element}`;
        });
    }

    if (props.currenyCode) {
        props.currenyCode.forEach((element) => {
            if (params.length > 1) params += '&';
            params += `CurrencyCode=${element}`;
        });
    }

    if (props.billableOptionId) {
        props.billableOptionId.forEach((element) => {
            if (params.length > 1) params += '&';
            params += `BillableOptionId=${element}`;
        });
    }

    if (props.projectId) {
        props.projectId.forEach((element) => {
            if (params.length > 1) params += '&';
            params += `ProjectId=${element}`;
        });
    }

    if (props.regionId) {
        props.regionId.forEach((element) => {
            if (params.length > 1) params += '&';
            params += `RegionId=${element}`;
        });
    }

    if (props.maHolderId) {
        props.maHolderId.forEach((element) => {
            if (params.length > 1) params += '&';
            params += `MaHolderId=${element}`;
        });
    }

    if (props.beneficiaryId) {
        props.beneficiaryId.forEach((element) => {
            if (params.length > 1) params += '&';
            params += `BeneficiaryId=${element}`;
        });
    }

    if (props.createdById) {
        props.createdById.forEach((element) => {
            if (params.length > 1) params += '&';
            params += `CreatedById=${element}`;
        });
    }

    if (props.categoryId) {
        props.categoryId.forEach((element) => {
            if (params.length > 1) params += '&';
            params += `CategoryId=${element}`;
        });
    }

    if (props.procedureCode) {
        if (params.length > 1) params += '&';
        params += `ProcedureCode=${props.procedureCode}`;
    }

    if (props.reasonOfPayment) {
        if (params.length > 1) params += '&';
        params += `ReasonOfPayment=${props.reasonOfPayment}`;
    }

    return params;
};

export const fetchFees = createAsyncThunk('fees/fetchFees', async (props: IFeeFilterParams) => {
    try {
        if (props.sorter && props.sorter.field && props.sorter.order) {
            if (props.sorter.field === 'beneficiary') {
                props.sorter.field = 'beneficiary.searchName';
            }
        }

        let params = constructQueryParams(props);
        params = appendFeeFilterParams(props, params);
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/fees${params}`, {});
        const result: IPaginatedResult<IFee[]> = await response.json();

        if (response.status !== 200) {
            throw new Error('Could not fetch fees.');
        }

        return result;
    } catch (e) {
        console.error(e);
        notification.error({
            message: 'An error occurred.',
            duration: 0,
            description: 'An error occurred while retrieving the fees. Please contact your administrator if the problem persists.',
            placement: NOTIFICATION_PLACEMENT,
        });
        throw e;
    }
});

export const fetchFeeById = createAsyncThunk('fees/fetchFeeById', async (feeId: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/fees/${feeId}`, {});

        if (response.status !== 200) {
            throw new Error(response.status.toString());
        }

        const result: IFee = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchFeeComments = createAsyncThunk('fees/fetchFeeComments', async (feeId: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/fees/${feeId}/comments`, {});
        const result: IFeeComment[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchFeeInvoices = createAsyncThunk('fees/fetchFeeInvoices', async (feeId: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/fees/${feeId}/invoices`, {});
        const result: IInvoice[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const generatePaymentFile = createAsyncThunk('fees/generatePaymentFile', async (itemIds: string[] | number[]) => {
    try {
        let params = '?';
        itemIds.forEach((itemIds) => {
            if (params.length > 1) params += '&';
            params += `id=${itemIds}`;
        });

        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/payments/generatePaymentFile${params}`, {});
        const result: any = await response.json();

        //if error is returned
        if (result.code && result.message) {
            throw result;
        }

        notification.success({
            message: 'Payment file created',
            description: `The payment file has been successfully created.`,
            placement: NOTIFICATION_PLACEMENT,
        });

        return result as IFee[];
    } catch (e: any) {
        console.error(e);
        let err = e as IErrorResponse;

        notification.error({
            message: 'An error occurred',
            description: `Could not create a payment file. Reason: ${err.message}. Code: ${err.code}.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });

        throw e;
    }
});

export const fetchFeeExportData = createAsyncThunk('fees/fetchFeeExportData', async (itemIds: string[] | number[]) => {
    try {
        await ExportHelpers.exportCsv(`${process.env.REACT_APP_API_URL}/fees/export`, itemIds, 'fees.csv');
        return true;
    } catch (e) {
        console.error(e);

        notification.error({
            message: 'An error occurred',
            description: `Could not export the fees. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        throw e;
    }
});

export const updateFee = createAsyncThunk('fee/updateFee', async (fee: IUpdateFee) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/fees/${fee.id}`, {
            method: 'PATCH',
            body: JSON.stringify(fee),
        });

        if (response.status !== 200) {
            if (response.status === 409) {
                throw new Error(
                    'Could not update the fee. The fee is already updated by another user. Please refresh the page to retrieve new data.'
                );
            }
            throw new Error('Could not update the fee. Please contact your administrator if the problem persists.');
        }

        const result: IFee = await response.json();
        notification.success({
            message: 'Fee updated',
            description: `The fee has been updated successfully.`,
            placement: NOTIFICATION_PLACEMENT,
        });

        return result;
    } catch (e: any) {
        notification.error({
            message: 'An error occurred',
            description: e.message,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const updateFeeStatuses = createAsyncThunk('fee/updateFeeStatuses', async (feeStatusCodes: IUpdateFeeStatusCode[]) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/fees/statuses`, {
            method: 'PATCH',
            body: JSON.stringify(feeStatusCodes),
        });

        if (response.status !== 200) {
            const result: any = await response.json();
            throw new Error(result.message || 'Could not update the fee.');
        }

        const result: IFee[] = await response.json();

        notification.success({
            message: 'Status updated',
            description: `The status of the fees have been updated successfully.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e: any) {
        notification.error({
            message: 'An error occurred',
            description: e.message || `Could not update the fee statuses. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const addFeeComment = createAsyncThunk('fee/addFeeComment', async (body: IAddFeeComment) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/fees/${body.id}/comments`, {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (response.status !== 201) {
            throw new Error('Could not add a comment to the fee.');
        }

        const result: IFeeComment = await response.json();

        notification.success({
            message: 'Comment added.',
            description: `The comment is successfully added to the fee.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not add the comment to the fee. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const addFee = createAsyncThunk('fee/addFee', async (body: IAddFee) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/fees`, {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (response.status !== 201) {
            throw new Error('Unable to add a new fee.');
        }

        const result: IFee = await response.json();

        notification.success({
            message: 'Fee created',
            description: `The fee is successfully created.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not add the fee. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const deleteFee = createAsyncThunk('fee/deleteFee', async (feeId: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/fees/${feeId}`, {
            method: 'DELETE',
        });

        if (response.status !== 200) {
            throw new Error('Unable to delete the fee.');
        }

        const result: IFee = await response.json();

        notification.success({
            message: 'Fee deleted',
            description: `The fee is successfully deleted.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not remove the fee. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const deleteFeesBatch = createAsyncThunk('fees/deleteFeesBatch', async (feeIds: number[]) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/fees`, {
            method: 'DELETE',
            body: JSON.stringify(feeIds),
        });

        if (response.status !== 200) {
            throw new Error('Unable to delete the fees.');
        }

        const result: IFee[] = await response.json();

        notification.success({
            message: 'Fees deleted',
            description: `The fees are successfully deleted.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not remove one of the fees. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const deleteFeeComment = createAsyncThunk('fee/deleteFeeComment', async (body: IDeleteFeeComment) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/fees/${body.feeId}/comments/${body.commentId}`, {
            method: 'DELETE',
        });

        if (response.status !== 200) {
            throw new Error('Unable to delete the fee comment.');
        }

        const result: IFeeComment = await response.json();

        notification.success({
            message: 'Fee comment deleted',
            description: `The fee comment is successfully deleted.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not remove the fee comment. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const duplicateFees = createAsyncThunk('fee/duplicateFees', async (body: IDuplicateFee[]) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/fees/duplicate`, {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (response.status !== 201) {
            throw new Error("Could not duplicate the fee's.");
        }

        const result: IFee[] = await response.json();

        notification.success({
            message: 'Fees duplicated.',
            description: `The fee's are successfully duplicated.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not duplicate the fee's. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const fetchPaymentDocumensByFeeId = createAsyncThunk('fees/fetchPaymentDocumensByFeeId', async (feeId: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/fees/${feeId}/payments`, {});

        if (response.status !== 200) {
            throw new Error(response.status.toString());
        }

        const result: IPaymentDocument[] = await response.json();
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not fetch the fee's payment files. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});
