import { ExclamationCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Row, Col, Avatar, Tooltip, Form, Button, Spin, App as AntApp } from 'antd';
import { Comment } from '@ant-design/compatible';
import TextArea from 'antd/lib/input/TextArea';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ICommentAvatar } from '../../../interfaces';
import { IBeneficiaryComment } from '../../../interfaces/beneficiaries/data/IBeneficiaryComment';
import { IFeeComment } from '../../../interfaces/fees/data/IFeeComment';
import { IAddCommentFormValues } from '../../../interfaces/IAddCommentFormValues';
import { IUser } from '../../../interfaces/users/data';
import { RootState } from '../../../store';
import { fetchPhotoByUserUpn } from '../../../store/userSlice/UserActions';
import { ROW_GUTTER } from '../../Constants';
import './Comments.less';

export interface ICommentsProps {
    comments: IFeeComment[] | IBeneficiaryComment[];
    canCreateComment: boolean;
    fetching?: boolean;
    submitting?: boolean;
    /**
     * If the onFinish prop is provided the Add Comment form is displayed.
     */
    onFinish?: (values: IAddCommentFormValues) => void;
    /**
     * If the onDeleteComment prop is provided the Add Comment can be deleted
     */
    onDeleteComment: (commentId: number) => void;
}

export const Comments = (props: ICommentsProps) => {
    const { modal } = AntApp.useApp();
    const { comments, onFinish, fetching, submitting } = props;
    const [form] = Form.useForm();
    const hasComments = comments && comments.length > 0;
    const { appSlice } = useSelector((state: RootState) => state);
    const [commentAvatars, setCommentAvatars] = useState<ICommentAvatar[]>([]);

    const fetchUserAvatar = (user: IUser) => {
        const fetchAvatar = async () => {
            try {
                let blobUrl = await fetchPhotoByUserUpn(user.userPrincipalName);
                let newCommentAvatars = [...commentAvatars];
                newCommentAvatars.push({
                    upn: user.userPrincipalName,
                    blobUrl: blobUrl,
                });
                setCommentAvatars(newCommentAvatars);
            } catch (_e) {}
        };
        fetchAvatar();
    };

    useEffect(() => {
        for (let i = 0; i < comments.length; i++) {
            const comment = comments[i];
            fetchUserAvatar(comment.createdByUser);
        }
    }, [comments]);

    const getUserAvatar = (user: IUser) => {
        let found = commentAvatars.filter((c) => c.upn === user.userPrincipalName);
        if (found.length > 0) {
            return <Avatar src={found[0].blobUrl} alt={user.name} />;
        }

        return <Avatar icon={<UserOutlined />} alt={user.name} />;
    };

    const removeComment = (commentId: number) => {
        modal.confirm({
            title: 'Delete comment',
            icon: <ExclamationCircleOutlined />,
            content: "Are you sure you want to delete this comment? This action can't be undone.",
            okText: 'Confirm',
            cancelText: 'Cancel',
            onOk: () => {
                if (props.onDeleteComment !== undefined) {
                    props.onDeleteComment(commentId);
                }
            },
        });
    };

    const meAvatar = appSlice.me.msGraph.data?.photo ? (
        <Avatar src={appSlice.me.msGraph.data?.photo} alt={appSlice.me.msGraph.data?.displayName} />
    ) : (
        <Avatar icon={<UserOutlined />} alt={appSlice.me.msGraph.data?.displayName} />
    );

    return (
        <Spin spinning={!!fetching}>
            <Row gutter={ROW_GUTTER}>
                <Col span={24}>
                    <label className="property">Internal comments</label>
                    <div className="comments">
                        <>
                            {!hasComments && <div className="no-comments">No comments given</div>}

                            {hasComments &&
                                comments.map((comment) => {
                                    return (
                                        <Comment
                                            actions={
                                                comment.createdByUser.id === appSlice.me.info.data?.id
                                                    ? [
                                                          <span key="comment-basic-reply-to" onClick={() => removeComment(comment.id)}>
                                                              Delete
                                                          </span>,
                                                      ]
                                                    : undefined
                                            }
                                            key={comment.id}
                                            author={comment.createdByUser.name}
                                            avatar={getUserAvatar(comment.createdByUser)}
                                            content={<p>{comment.comment}</p>}
                                            datetime={
                                                <Tooltip title={dayjs(comment.createdDate).format('YYYY-MM-DD HH:mm:ss')}>
                                                    <span>{dayjs(comment.createdDate).fromNow()}</span>
                                                </Tooltip>
                                            }
                                        />
                                    );
                                })}
                        </>
                    </div>
                </Col>
            </Row>

            {props.canCreateComment && onFinish !== undefined && (
                <Row gutter={ROW_GUTTER}>
                    <Col span={24}>
                        <Form
                            form={form}
                            name="control-hooks"
                            onFinish={(values) => {
                                // Reset the fields
                                form.resetFields();
                                // Call the onFinish function.
                                onFinish(values);
                            }}
                            layout="vertical"
                            onValuesChange={(_changedValue: any, values) => {
                                form.setFieldsValue(values);
                            }}
                        >
                            <Comment
                                className="input"
                                avatar={meAvatar}
                                content={
                                    <>
                                        <Form.Item name="comment" rules={[{ required: true, message: 'Text is required.' }]}>
                                            <TextArea rows={2} disabled={submitting} placeholder="Add a comment..." />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button htmlType="submit" type="primary" loading={submitting}>
                                                Add Comment
                                            </Button>
                                        </Form.Item>
                                    </>
                                }
                            />
                        </Form>
                    </Col>
                </Row>
            )}
        </Spin>
    );
};
