import { SaveOutlined, CloseOutlined } from '@ant-design/icons';
import { Form, Button, Spin, Row, Col, Space, Input, Select, Checkbox, Tag } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { StatePieceStatus } from '../../../enums';
import { IUpdateBeneficiary, IUpdateBeneficiaryFormValues } from '../../../interfaces/beneficiaries';
import { IDeleteBeneficiaryComment } from '../../../interfaces/beneficiaries/IDeleteBeneficiaryComment';
import { ContentHeader } from '../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { Comments } from '../../../shared/components/comments/Comments';
import { FetchResult } from '../../../shared/components/fetchResult/FetchResult';
import { MultiEditNavigation } from '../../../shared/components/multiEditNavigation/MultiEditNavigation';
import { Unauthorized } from '../../../shared/components/unauthorized/Unauthorized';
import { ROW_GUTTER } from '../../../shared/Constants';
import { beneficiary_isEditable, getReturnUrl, QueryParamHelpers } from '../../../shared/helpers';
import { isError } from '../../../shared/helpers/StatusHelpers';
import { AppDispatch, RootState } from '../../../store';
import {
    deleteBeneficiaryComment,
    fetchBeneficiaryById,
    fetchBeneficiaryComments,
    updateBeneficiary,
} from '../../../store/beneficiarySlice/BeneficiaryActions';
import { resetDetailsPage, setEids } from '../../../store/beneficiarySlice/BeneficiarySlice';
import {
    fetchBankCostOptionsLookupList,
    fetchCountriesLookupList,
    fetchCurrenciesLookupList,
} from '../../../store/lookupSlice/LookupActions';
import { NotFound } from '../../notFound/NotFound';

export const UpdateBeneficiary = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const { appSlice } = useSelector((state: RootState) => state);
    const { beneficiary, beneficiaryComments, eids } = useSelector((state: RootState) => state.beneficiarySlice.detailsPage);
    const { countries, bankCostOptions, currencies } = useSelector((state: RootState) => state.lookupSlice);
    const [form] = Form.useForm();
    const [formIsPrisinte, setFormIsPrisinte] = useState(true);

    const beneficiaryIsEditable = beneficiary_isEditable(beneficiary?.data) || appSlice.auth.admin.view;
    const beneficiaryId = params.id ? parseInt(params.id) : undefined;

    const isLoadingLookupLists =
        countries.lookup.fetchStatus === StatePieceStatus.IsFetching || bankCostOptions.lookup.fetchStatus === StatePieceStatus.IsFetching;
    const isLoading =
        beneficiary.fetchStatus === StatePieceStatus.IsFetching ||
        beneficiaryComments.fetchStatus === StatePieceStatus.IsFetching ||
        isLoadingLookupLists;

    const onFinish = (values: IUpdateBeneficiaryFormValues) => {
        const body: IUpdateBeneficiary = {
            id: beneficiary.data?.id,
            statusId: beneficiary.data?.status.id,
            ...(values as any),
        };

        dispatch(updateBeneficiary(body));
    };

    /**
     * OnInit
     */
    useEffect(() => {
        if (beneficiaryId) {
            dispatch(fetchBeneficiaryComments(beneficiaryId));
            dispatch(fetchBeneficiaryById(beneficiaryId));
            dispatch(fetchCountriesLookupList());
            dispatch(fetchBankCostOptionsLookupList());
            dispatch(fetchCurrenciesLookupList());
        }

        return () => {
            dispatch(resetDetailsPage());
        };
    }, []);

    useEffect(() => {
        setFormIsPrisinte(true);
        const eids = QueryParamHelpers.getEditIds();
        if (eids.isValid) {
            dispatch(setEids(eids.data));
        }

        if (beneficiaryId) {
            dispatch(fetchBeneficiaryById(beneficiaryId));
            dispatch(fetchBeneficiaryComments(beneficiaryId));
        }
    }, [beneficiaryId]);

    useEffect(() => {
        if (eids.length === 0 && beneficiary.updateStatus === StatePieceStatus.Success) {
            let returnUrl = getReturnUrl();
            navigate(returnUrl ? returnUrl : '/beneficiaries', { replace: true });
        }
    }, [beneficiary.updateStatus]);

    React.useEffect(() => {
        if (beneficiary.data) {
            form.setFieldsValue({
                name: beneficiary.data.name,
                searchName: beneficiary.data.searchName,
                addressline1: beneficiary.data.addressline1,
                addressline2: beneficiary.data.addressline2,
                postalCode: beneficiary.data.postalCode,
                city: beneficiary.data.city,
                countryId: beneficiary.data.country?.id,
                bankName: beneficiary.data.bankName,
                bankTransitNumber: beneficiary.data.bankTransitNumber,
                accountNumber: beneficiary.data.accountNumber,
                currencyId: beneficiary.data.currency?.id,
                bic: beneficiary.data.bic,
                iban: beneficiary.data.iban,
                sepa: beneficiary.data.sepa,
                bankAddress: beneficiary.data.bankAddress,
                bankPostalCode: beneficiary.data.bankPostalCode,
                bankCity: beneficiary.data.bankCity,
                bankCountryId: beneficiary.data.bankCountry?.id,
                bankCostOptionId: beneficiary.data.bankCostOption?.id,
                fixedCode: beneficiary.data.fixedCode,
                instructionsRa: beneficiary.data.instructionsRa,
                instructionsFa: beneficiary.data.instructionsFa,
                paymentDescription: beneficiary.data.paymentDescription,
            } as IUpdateBeneficiaryFormValues);
        }
    }, [beneficiary.data]);

    const beneficiaryName = beneficiary.data?.name || beneficiary.data?.searchName;

    if (appSlice.auth.validationCompleted && !appSlice.auth.beneficiaries.edit) {
        return <Unauthorized />;
    }

    if (!beneficiaryId) {
        return <NotFound />;
    }

    return (
        <React.Fragment>
            <Form
                form={form}
                name="control-hooks"
                onFinish={onFinish}
                layout="vertical"
                onValuesChange={(_changedValue, values) => {
                    form.setFieldsValue(values);
                    if (formIsPrisinte) {
                        setFormIsPrisinte(false);
                    }
                }}
                initialValues={
                    {
                        id: beneficiaryId,
                    } as IUpdateBeneficiaryFormValues
                }
            >
                <ContentHeader
                    title={`Beneficiary #${params.id} ${beneficiaryName || ''}`}
                    hideBackButton={eids.length > 0} // Hide the back button if the current view is the multi edit
                    breadcrumbs={[
                        {
                            breadcrumbName: 'Beneficiaries',
                            path: '/beneficiaries',
                        },
                        {
                            breadcrumbName: beneficiary.data?.name || beneficiary.data?.searchName || '-',
                            path: `/beneficiaries/${params.id}`,
                        },
                        {
                            breadcrumbName: 'Edit',
                            path: `/fees/${params.id}/edit`,
                        },
                    ]}
                >
                    {!isError(beneficiary.fetchStatus) && (
                        <Space>
                            {beneficiaryId && eids.length > 0 ? (
                                <MultiEditNavigation
                                    disabled={beneficiary.updateStatus === StatePieceStatus.IsFetching || isLoading}
                                    currentId={beneficiaryId}
                                    eids={eids}
                                    returnUrlFallback={'/invoices'}
                                    updateStatus={beneficiary.updateStatus}
                                    formIsPrisinte={formIsPrisinte}
                                    submitForm={() => form.submit()}
                                    isEditable={beneficiaryIsEditable}
                                />
                            ) : (
                                <>
                                    <Button
                                        icon={<CloseOutlined />}
                                        onClick={() => navigate(-1)}
                                        disabled={beneficiary.updateStatus === StatePieceStatus.IsFetching || isLoadingLookupLists}
                                    >
                                        Close
                                    </Button>

                                    {appSlice.auth.beneficiaries.edit && (
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            icon={<SaveOutlined />}
                                            disabled={!beneficiaryIsEditable || isLoadingLookupLists}
                                            loading={beneficiary.updateStatus === StatePieceStatus.IsFetching}
                                        >
                                            Save and close
                                        </Button>
                                    )}
                                </>
                            )}
                        </Space>
                    )}
                </ContentHeader>

                {/* Field is required to be on the page. */}
                {/* <Form.Item name="id" rules={[{ required: true }]} hidden></Form.Item> */}

                <div className="page-content">
                    <Spin
                        spinning={
                            beneficiary.fetchStatus === StatePieceStatus.IsFetching ||
                            beneficiary.updateStatus === StatePieceStatus.IsFetching
                        }
                    >
                        {isError(beneficiary.fetchStatus) ? (
                            <FetchResult status={beneficiary.fetchStatus} about={'Beneficiary'} />
                        ) : (
                            <>
                                {/* Beneficiary INFORMATION */}
                                <Row>
                                    <Col span={24}>
                                        <Space>
                                            <label className="group-label">Beneficiary Information</label>
                                        </Space>
                                    </Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={6}>
                                        <label className="property required">Name</label>
                                        <Form.Item name="name" rules={[{ required: true, message: "'Name' is required", max: 255 }]}>
                                            <Input placeholder="Provide a name..." disabled={!beneficiaryIsEditable} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <label className="property required">Search name</label>
                                        <Form.Item
                                            id="searchname"
                                            name="searchName"
                                            rules={[{ required: true, message: "'Search name' is required", max: 255 }]}
                                        >
                                            <Input placeholder="Provide a search name..." disabled={!beneficiaryIsEditable} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property required">Currency</label>
                                        <Form.Item name="currencyId" rules={[{ required: true, message: "'Currency' is required" }]}>
                                            <Select
                                                showSearch
                                                placeholder="Select a currency"
                                                disabled={!beneficiaryIsEditable}
                                                filterOption={(input, option) =>
                                                    (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                                                }
                                            >
                                                {currencies.lookup.data.map((currency) => (
                                                    <Select.Option key={currency.key} value={currency.key} disabled={currency.isDeleted}>
                                                        {currency.description}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {/* Address INFORMATION */}
                                <Row className="margin-top">
                                    <Col span={24}>
                                        <Space>
                                            <label className="group-label">Address information</label>
                                        </Space>
                                    </Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={6}>
                                        <label className="property required">Address line</label>
                                        <Form.Item
                                            name="addressline1"
                                            rules={[{ required: true, message: "'Address line' is required", max: 255 }]}
                                        >
                                            <Input placeholder="Provide an address..." disabled={!beneficiaryIsEditable} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property">Address line 2</label>
                                        <Form.Item name="addressline2" rules={[{ max: 255 }]}>
                                            <Input placeholder="Provide an address..." disabled={!beneficiaryIsEditable} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property required">Postal code</label>
                                        <Form.Item
                                            name="postalCode"
                                            rules={[{ required: true, message: "'Postal code' is required", max: 50 }]}
                                        >
                                            <Input placeholder="Provide a postal code..." disabled={!beneficiaryIsEditable} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property required">City</label>
                                        <Form.Item name="city" rules={[{ required: true, message: "'City' is required", max: 50 }]}>
                                            <Input placeholder="Provide a city..." disabled={!beneficiaryIsEditable} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={6}>
                                        <label className="property required">Country</label>
                                        <Form.Item name="countryId" rules={[{ required: true, message: "'Country' is required" }]}>
                                            <Select
                                                showSearch
                                                placeholder="Select a country..."
                                                disabled={!beneficiaryIsEditable}
                                                filterOption={(input, option) =>
                                                    (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                                                }
                                            >
                                                {countries.lookup.data.map((country) => (
                                                    <Select.Option key={country.key} value={country.key} disabled={country.isDeleted}>
                                                        {country.description}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                {/* Banking INFORMATION */}
                                <Row className="margin-top">
                                    <Col span={24}>
                                        <Space>
                                            <label className="group-label">Banking information</label>
                                        </Space>
                                    </Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={6}>
                                        <label className="property">Bank name</label>
                                        <Form.Item name="bankName" rules={[{ max: 255 }]}>
                                            <Input placeholder="Provide bank name..." disabled={!beneficiaryIsEditable} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={6}>
                                        <label className="property">IBAN</label>
                                        <Form.Item name="iban" rules={[{ max: 34, message: "'IBAN' cannot be longer than 34 characters" }]}>
                                            <Input placeholder="Provide IBAN..." disabled={!beneficiaryIsEditable} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property">Account number</label>
                                        <Form.Item name="accountNumber" rules={[{ max: 255 }]}>
                                            <Input placeholder="Provide bank account number..." disabled={!beneficiaryIsEditable} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property">BIC</label>
                                        <Form.Item name="bic" rules={[{ max: 255 }]}>
                                            <Input placeholder="Provide BIC..." disabled={!beneficiaryIsEditable} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property required">SEPA</label>
                                        <Form.Item
                                            name="sepa"
                                            valuePropName="checked"
                                            rules={[{ required: true, message: "'SEPA' is required" }]}
                                        >
                                            <Checkbox disabled={!beneficiaryIsEditable} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={6}>
                                        <label className="property">Address line</label>
                                        <Form.Item name="bankAddress" rules={[{ max: 255 }]}>
                                            <Input placeholder="Address Line" disabled={!beneficiaryIsEditable} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property">Postal code</label>
                                        <Form.Item name="bankPostalCode" rules={[{ max: 255 }]}>
                                            <Input placeholder="Postal code" disabled={!beneficiaryIsEditable} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property">City</label>
                                        <Form.Item name="bankCity" rules={[{ max: 255 }]}>
                                            <Input placeholder="City" disabled={!beneficiaryIsEditable} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property">Country</label>
                                        <Form.Item
                                            name="bankCountryId"
                                            rules={[{ required: false, message: "'Bank country' is required" }]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="Select a country..."
                                                disabled={!beneficiaryIsEditable}
                                                filterOption={(input, option) =>
                                                    (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                                                }
                                            >
                                                {countries.lookup.data.map((country) => (
                                                    <Select.Option key={country.key} value={country.key} disabled={country.isDeleted}>
                                                        {country.description}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                {/* Transaction INFORMATION */}
                                <Row className="margin-top">
                                    <Col span={24}>
                                        <Space>
                                            <label className="group-label">Transaction information</label>
                                        </Space>
                                    </Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={4}>
                                        <label className="property">Bank costs</label>
                                        <Form.Item name="bankCostOptionId">
                                            <Select
                                                showSearch
                                                placeholder="Select bank cost..."
                                                disabled={!beneficiaryIsEditable}
                                                filterOption={(input, option) =>
                                                    (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                                                }
                                            >
                                                {bankCostOptions.lookup.data.map((bankCostOption) => (
                                                    <Select.Option
                                                        key={bankCostOption.key}
                                                        value={bankCostOption.key}
                                                        disabled={bankCostOption.isDeleted}
                                                    >
                                                        {bankCostOption.description}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <label className="property">BIC</label>
                                        <Form.Item name="bic">
                                            <Input placeholder="Provide BIC..." disabled={!beneficiaryIsEditable} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <label className="property required">SEPA</label>
                                        <Form.Item
                                            name="sepa"
                                            valuePropName="checked"
                                            rules={[{ required: true, message: "'SEPA' is required" }]}
                                        >
                                            <Checkbox disabled={!beneficiaryIsEditable} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={6}>
                                        <label className="property">Payment description</label>
                                        <Form.Item name="paymentDescription">
                                            <Input placeholder="Provide payment description..." disabled={!beneficiaryIsEditable} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property">Account number</label>
                                        <Form.Item name="accountNumber">
                                            <Input placeholder="Provide bank account number..." disabled={!beneficiaryIsEditable} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <label className="property">Transit number</label>
                                        <Form.Item name="bankTransitNumber" rules={[{ max: 10 }]}>
                                            <Input placeholder="Provide bank transit number..." disabled={!beneficiaryIsEditable} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <label className="property">Fixed code</label>
                                        <Form.Item
                                            name="fixedCode"
                                            rules={[{ max: 10, message: "'Fixed code' cannot be longer than 10 characters" }]}
                                        >
                                            <Input placeholder="Provide fixed code..." disabled={!beneficiaryIsEditable} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <label className="property required">Currency</label>
                                        <Form.Item name="currencyId" rules={[{ required: true, message: "'Currency' is required" }]}>
                                            <Select
                                                showSearch
                                                placeholder="Select a currency"
                                                disabled={!beneficiaryIsEditable}
                                                filterOption={(input, option) =>
                                                    (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                                                }
                                            >
                                                {currencies.lookup.data.map((currency) => (
                                                    <Select.Option key={currency.key} value={currency.key} disabled={currency.isDeleted}>
                                                        {currency.description}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                {/* STATUS INFORMATION */}
                                <Row className="margin-top">
                                    <Col span={24}>
                                        <label className="group-label">Status Information</label>
                                    </Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={6}>
                                        <label className="property">Status</label>
                                        <p>
                                            <Tag>{beneficiary.data?.status?.description || '-'}</Tag>
                                        </p>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property">Changed on</label>
                                        <p className="capitalize">
                                            {beneficiary.data?.statusChangedDate
                                                ? dayjs(beneficiary.data?.statusChangedDate).format('MMMM D, YYYY HH:mm')
                                                : '-'}
                                        </p>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property">Changed by</label>
                                        <p>{beneficiary.data?.statusChangedByUser?.name || '-'}</p>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property">Comment</label>
                                        <p>{beneficiary.data?.statusChangedComment || '-'}</p>
                                    </Col>
                                </Row>

                                {/* INTERNAL INFORMATION */}
                                <Row className="margin-top">
                                    <Col span={24}>
                                        <label className="group-label">Internal Information</label>
                                    </Col>
                                </Row>

                                <Row gutter={ROW_GUTTER}>
                                    <Col span={12}>
                                        <label className="property">Created by</label>
                                        <p>{beneficiary.data?.createdByUser.name || '-'}</p>
                                    </Col>
                                    <Col span={12}>
                                        <label className="property">Created on</label>
                                        <p className="capitalize">
                                            {beneficiary.data?.createdDate
                                                ? dayjs(beneficiary.data?.createdDate).format('MMMM D, YYYY HH:mm')
                                                : '-'}
                                        </p>
                                    </Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={12}>
                                        <label className="property">Last modified by</label>
                                        <p>{beneficiary.data?.modifiedByUser?.name || '-'}</p>
                                    </Col>
                                    <Col span={12}>
                                        <label className="property">Last modified on</label>
                                        <p className="capitalize">
                                            {beneficiary.data?.modifiedDate
                                                ? dayjs(beneficiary.data?.modifiedDate).format('MMMM D, YYYY HH:mm')
                                                : '-'}
                                        </p>
                                    </Col>
                                </Row>

                                <Comments
                                    canCreateComment={appSlice.auth.beneficiaries.comment}
                                    comments={beneficiaryComments.data}
                                    fetching={beneficiaryComments.fetchStatus === StatePieceStatus.IsFetching}
                                    onDeleteComment={(commentId: number) => {
                                        if (beneficiaryId) {
                                            const body: IDeleteBeneficiaryComment = {
                                                beneficiaryId: beneficiaryId,
                                                commentId: commentId,
                                            };

                                            dispatch(deleteBeneficiaryComment(body));
                                        }
                                    }}
                                />
                            </>
                        )}
                    </Spin>
                </div>
            </Form>
        </React.Fragment>
    );
};
