import { DeleteFilled, DownloadOutlined, EditOutlined, ExclamationCircleOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Col, App as AntApp, Row, Space, Spin, Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { InvoiceStatuses, StatePieceStatus } from '../../../enums';
import { IAddInvoiceComment } from '../../../interfaces/invoices/IAddInvoiceComment';
import { IDeleteInvoiceComment } from '../../../interfaces/invoices/IDeleteInvoiceComment';
import { ContentHeader } from '../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { Comments } from '../../../shared/components/comments/Comments';
import { FetchResult } from '../../../shared/components/fetchResult/FetchResult';
import { Unauthorized } from '../../../shared/components/unauthorized/Unauthorized';
import { ROW_GUTTER } from '../../../shared/Constants';
import { generateReturnUrl, getReturnUrl, invoice_isEditable, isError } from '../../../shared/helpers';
import { formatAsCurrency } from '../../../shared/helpers/formatHelper';
import { AppDispatch, RootState } from '../../../store';
import {
    addInvoiceComment,
    deleteInvoice,
    deleteInvoiceComment,
    fetchInvoiceById,
    fetchInvoiceComments,
    fetchInvoiceDocuments,
    fetchInvoicesExportAsPDF,
} from '../../../store/invoiceSlice/InvoiceActions';
import { resetState } from '../../../store/invoiceSlice/InvoiceSlice';
import { InvoiceDocumentTable } from '../../fees/components/invoiceDocumentTable/InvoiceDocumentTable';
import { InvoiceStatusChangeModal } from '../invoiceStatusChangeModal/InvoiceStatusChangeModal';
import './InvoiceDetail.less';
import { NotFound } from '../../notFound/NotFound';

export const InvoiceDetail = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const params = useParams();
    const { modal } = AntApp.useApp();
    const { appSlice, invoiceSlice } = useSelector((state: RootState) => state);
    const [statusChangerVisible, setStatusChangerVisible] = useState(false);
    const invoiceIsEditable = invoice_isEditable(invoiceSlice.detailPage?.invoice?.data);
    const invoiceId = params.id ? parseInt(params.id) : undefined;

    const isLoadingContent = () => {
        return (
            invoiceSlice.detailPage?.invoice.fetchStatus === StatePieceStatus.IsFetching ||
            invoiceSlice.detailPage?.invoiceComments.fetchStatus === StatePieceStatus.IsFetching ||
            invoiceSlice.exportInvoicesAsPfdStatus === StatePieceStatus.IsFetching
        );
    };

    const removeInvoice = () => {
        modal.confirm({
            title: 'Delete invoice',
            icon: <ExclamationCircleOutlined />,
            content: "Are you sure you want to delete this invoice? This action can't be undone.",
            okText: 'Confirm',
            cancelText: 'Cancel',
            onOk: () => {
                if (invoiceId) dispatch(deleteInvoice(invoiceId));
            },
        });
    };

    useEffect(() => {
        if (invoiceId) {
            dispatch(fetchInvoiceById(invoiceId));
            dispatch(fetchInvoiceComments(invoiceId));
            dispatch(fetchInvoiceDocuments(invoiceId));
        }

        return () => {
            dispatch(resetState());
        };
    }, [invoiceId]);

    useEffect(() => {
        if (invoiceId) {
            dispatch(fetchInvoiceDocuments(invoiceId));
        }
    }, [invoiceSlice.exportInvoicesAsPfdStatus]);

    useEffect(() => {
        if (invoiceSlice.detailPage.invoice.deleteStatus === StatePieceStatus.Success) {
            let returnUrl = getReturnUrl();
            navigate(returnUrl ? returnUrl : '/invoices', { replace: true });
        }
    }, [invoiceSlice.detailPage.invoice.deleteStatus]);

    const pageTitle = isError(invoiceSlice.detailPage?.invoice.fetchStatus)
        ? `Invoice #${params.id}`
        : `Invoice #${params.id} of ${invoiceSlice.detailPage?.invoice.data?.fee?.beneficiary?.name || '-'}`;

    if (appSlice.auth.validationCompleted && !appSlice.auth.invoices.view) {
        return <Unauthorized />;
    }

    if (!invoiceId) {
        return <NotFound />;
    }

    return (
        <React.Fragment>
            <ContentHeader
                title={pageTitle}
                breadcrumbs={[
                    {
                        breadcrumbName: 'Invoices',
                        path: '/invoices',
                    },
                    {
                        breadcrumbName: invoiceSlice.detailPage?.invoice.data?.fee?.beneficiary?.name || '-',
                        path: `/invoices/${params.id}`,
                    },
                ]}
            >
                {!isError(invoiceSlice.detailPage?.invoice.fetchStatus) && (
                    <Space>
                        {appSlice.auth.invoices.delete && (
                            <Tooltip
                                title={
                                    !appSlice.auth.admin.view && !invoiceIsEditable
                                        ? 'This invoice has a status that you are not authorized to change.'
                                        : undefined
                                }
                            >
                                <Button
                                    type="primary"
                                    danger
                                    icon={<DeleteFilled />}
                                    loading={isLoadingContent()}
                                    onClick={() => removeInvoice()}
                                    disabled={!appSlice.auth.admin.view && !invoiceIsEditable}
                                >
                                    Delete invoice
                                </Button>
                            </Tooltip>
                        )}

                        {appSlice.auth.invoices.generatePdf && (
                            <Tooltip
                                title={
                                    invoiceSlice.detailPage?.invoice?.data?.status?.code !== InvoiceStatuses.ApprovedForPayment
                                        ? 'This invoice does not have the status Approved for Payment.'
                                        : undefined
                                }
                            >
                                <Button
                                    icon={<DownloadOutlined />}
                                    onClick={() => {
                                        if (invoiceId) dispatch(fetchInvoicesExportAsPDF([invoiceId]));
                                    }}
                                    loading={invoiceSlice.exportInvoicesAsPfdStatus === StatePieceStatus.IsFetching}
                                    disabled={invoiceSlice.detailPage?.invoice?.data?.status?.code !== InvoiceStatuses.ApprovedForPayment}
                                >
                                    Generate 1 attachment
                                </Button>
                            </Tooltip>
                        )}

                        {appSlice.auth.invoices.changeStatus && (
                            <Tooltip
                                title={
                                    !invoiceIsEditable && !appSlice.auth.admin.view
                                        ? 'This invoice has a status that you are not authorized to change.'
                                        : undefined
                                }
                            >
                                <Button
                                    type="primary"
                                    icon={<UndoOutlined />}
                                    loading={isLoadingContent() || appSlice.me.info.data === null}
                                    onClick={() => setStatusChangerVisible(true)}
                                    disabled={!invoiceIsEditable && !appSlice.auth.admin.view}
                                >
                                    Change status
                                </Button>
                            </Tooltip>
                        )}
                        {appSlice.auth.invoices.edit && (
                            <Tooltip title={!invoiceIsEditable ? 'The invoice cannot be updated with its current status' : undefined}>
                                <Button
                                    type="default"
                                    disabled={!invoiceIsEditable}
                                    icon={<EditOutlined />}
                                    onClick={() => navigate(`${generateReturnUrl(`edit`)}`)}
                                >
                                    Edit invoice details
                                </Button>
                            </Tooltip>
                        )}
                    </Space>
                )}
            </ContentHeader>

            <div className="page-content">
                <Spin spinning={isLoadingContent()}>
                    {isError(invoiceSlice.detailPage?.invoice.fetchStatus) ? (
                        <FetchResult status={invoiceSlice.detailPage?.invoice.fetchStatus} about={'Invoice'} />
                    ) : (
                        <>
                            {/* INVOICE INFORMATION */}
                            <Row>
                                <Col span={24}>
                                    <Space>
                                        <label className="group-label">Invoice Information</label>
                                    </Space>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={6}>
                                    <label className="property">Generated Invoice ID</label>
                                    <p>{invoiceSlice.detailPage?.invoice.data?.id || '-'}</p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">Billable</label>
                                    <p>{invoiceSlice.detailPage?.invoice.data?.fee?.billableOption?.description || '-'}</p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">Amount</label>
                                    <p>{formatAsCurrency(invoiceSlice.detailPage?.invoice.data?.amount)}</p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">Category</label>
                                    <p>{invoiceSlice.detailPage?.invoice.data?.fee?.category?.description || '-'}</p>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={6}>
                                    <label className="property">Type</label>
                                    <p>{invoiceSlice.detailPage?.invoice.data?.fee?.kindOfFee?.description || '-'}</p>
                                </Col>
                                <Col span={18}>&nbsp;</Col>
                            </Row>

                            {/* CLIENT INFORMATION */}
                            <Row className="margin-top">
                                <Col span={24}>
                                    <label className="group-label">Client Information</label>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={6}>
                                    <label className="property">Project</label>
                                    <p>
                                        <Tooltip title={invoiceSlice.detailPage?.invoice.data?.fee?.project?.description}>
                                            {invoiceSlice.detailPage?.invoice.data?.fee?.project?.code || '-'}
                                        </Tooltip>
                                    </p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">Brand name</label>
                                    <p>{invoiceSlice.detailPage?.invoice.data?.fee?.brandName || '-'}</p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">Beneficiary</label>
                                    <p>
                                        <Tooltip title={invoiceSlice.detailPage?.invoice.data?.fee?.beneficiary?.name}>
                                            {invoiceSlice.detailPage?.invoice.data?.fee?.beneficiary?.searchName || '-'}
                                        </Tooltip>
                                    </p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">Marketing authorization holder</label>
                                    <p>{invoiceSlice.detailPage?.invoice.data?.fee?.maHolder?.description || '-'}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    <label className="property">Customer</label>
                                    <p>
                                        <Tooltip title={invoiceSlice.detailPage?.invoice.data?.customer?.name}>
                                            {invoiceSlice.detailPage?.invoice.data?.customer?.searchName || '-'}
                                        </Tooltip>
                                    </p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">Contact person</label>
                                    <p>{invoiceSlice.detailPage?.invoice.data?.contactPerson || '-'}</p>
                                </Col>
                                <Col span={12}>&nbsp;</Col>
                            </Row>

                            {/* PAYMENT INFORMATION */}
                            <Row className="margin-top">
                                <Col span={24}>
                                    <label className="group-label">Payment Information</label>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={6}>
                                    <label className="property">Desired payment date</label>
                                    <p className="capitalize">
                                        {invoiceSlice.detailPage?.invoice.data?.fee?.paymentDate
                                            ? dayjs(invoiceSlice.detailPage?.invoice.data?.fee?.paymentDate).format('MMMM D, YYYY HH:mm')
                                            : '-'}
                                    </p>
                                </Col>
                                <Col span={18}>
                                    <label className="property">Reason of payment</label>
                                    <p>{invoiceSlice.detailPage?.invoice.data?.reasonOfPayment || '-'}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <label className="property">Payment description</label>
                                    <p>{invoiceSlice.detailPage?.invoice.data?.fee?.paymentDescription || '-'}</p>
                                </Col>
                            </Row>

                            {/* STATUS INFORMATION */}
                            <Row className="margin-top">
                                <Col span={24}>
                                    <label className="group-label">Status Information</label>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={6}>
                                    <label className="property">Status</label>
                                    <p>
                                        <Tag>{invoiceSlice.detailPage?.invoice.data?.status?.description || '-'}</Tag>
                                    </p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">Changed on</label>
                                    <p className="capitalize">
                                        {invoiceSlice.detailPage?.invoice.data?.statusChangedDate
                                            ? dayjs(invoiceSlice.detailPage?.invoice.data?.statusChangedDate).format('MMMM D, YYYY HH:mm')
                                            : '-'}
                                    </p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">Changed by</label>
                                    <p>{invoiceSlice.detailPage?.invoice.data?.statusChangedByUser?.name || '-'}</p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">Comment</label>
                                    <p>{invoiceSlice.detailPage?.invoice.data?.statusChangedComment || '-'}</p>
                                </Col>
                            </Row>

                            {/* INTERNAL INFORMATION */}
                            <Row className="margin-top">
                                <Col span={24}>
                                    <label className="group-label">Internal Information</label>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={12}>
                                    <label className="property">Created by</label>
                                    <p>{invoiceSlice.detailPage?.invoice.data?.createdByUser.name || '-'}</p>
                                </Col>
                                <Col span={12}>
                                    <label className="property">Created on</label>
                                    <p className="capitalize">
                                        {invoiceSlice.detailPage?.invoice.data?.createdDate
                                            ? dayjs(invoiceSlice.detailPage?.invoice.data?.createdDate).format('MMMM D, YYYY HH:mm')
                                            : '-'}
                                    </p>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={12}>
                                    <label className="property">Last modified by</label>
                                    <p>{invoiceSlice.detailPage?.invoice.data?.modifiedByUser?.name || '-'}</p>
                                </Col>
                                <Col span={12}>
                                    <label className="property">Last modified on</label>
                                    <p className="capitalize">
                                        {invoiceSlice.detailPage?.invoice.data?.modifiedDate
                                            ? dayjs(invoiceSlice.detailPage?.invoice.data?.modifiedDate).format('MMMM D, YYYY HH:mm')
                                            : '-'}
                                    </p>
                                </Col>
                            </Row>
                            {/* INVOICE DOCUMENTS */}
                            <Row className="margin-top">
                                <Col span={24}>
                                    <label className="group-label">Invoice documents</label>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={24}>
                                    <Spin spinning={invoiceSlice.detailPage.invoiceDocuments.fetchStatus === StatePieceStatus.IsFetching}>
                                        <InvoiceDocumentTable />
                                    </Spin>
                                </Col>
                            </Row>

                            <Row className="margin-top">
                                <Col span={24}>
                                    <Comments
                                        canCreateComment={appSlice.auth.invoices.comment}
                                        comments={invoiceSlice.detailPage?.invoiceComments.data}
                                        submitting={invoiceSlice.detailPage.invoiceComments.createStatus === StatePieceStatus.IsFetching}
                                        fetching={invoiceSlice.detailPage.invoiceComments.fetchStatus === StatePieceStatus.IsFetching}
                                        onFinish={(values) => {
                                            const body: IAddInvoiceComment = {
                                                id: invoiceSlice.detailPage.invoice.data?.id,
                                                ...(values as any),
                                            };

                                            dispatch(addInvoiceComment(body));
                                        }}
                                        onDeleteComment={(commentId: number) => {
                                            if (invoiceId) {
                                                const body: IDeleteInvoiceComment = {
                                                    invoiceId: invoiceId,
                                                    commentId: commentId,
                                                };

                                                dispatch(deleteInvoiceComment(body));
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                </Spin>
            </div>

            <InvoiceStatusChangeModal
                invoices={invoiceSlice.detailPage.invoice.data ? [{ ...invoiceSlice.detailPage.invoice.data }] : []}
                open={statusChangerVisible}
                onCancel={() => setStatusChangerVisible(false)}
            />
        </React.Fragment>
    );
};
