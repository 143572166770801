import { Route, Routes } from 'react-router';
import { NotFound } from '../notFound/NotFound';
import { AddFee } from './addFee/AddFee';
import { FeeDetail } from './feeDetail/FeeDetail';
import { FeeEdit } from './feeEdit/FeeEdit';
import { FeeOverview } from './feeOverview/FeeOverview';
import './Fees.less';

export const Fees = () => {
    return (
        <div className="app-page">
            <Routes>
                <Route path={'/'} element={<FeeOverview />} />
                <Route path={'add'} element={<AddFee />} />
                <Route path={':id'} element={<FeeDetail />} />
                <Route path={':id/edit'} element={<FeeEdit />} />
                <Route path={'/*'} element={<NotFound />} />
            </Routes>
        </div>
    );
};
