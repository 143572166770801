import { ArrowDownOutlined, ArrowLeftOutlined, ArrowUpOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, App as AntApp, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { StatePieceStatus } from '../../../enums';
import { getReturnUrl } from '../../helpers';

export interface IMultiEditNavigationProps {
    disabled: boolean;
    returnUrlFallback: string;
    currentId: number;
    eids: number[];
    updateStatus: StatePieceStatus | undefined;
    formIsPrisinte: boolean;
    isEditable: boolean;
    submitForm: () => void;
}

enum NavigateDirection {
    Previous,
    Next,
    BackToList,
    None,
}

/**
 * Renders the navigation in the case when the user edits multiple records.
 *
 * @param currentId The ID of the edit page that is currently presented.
 * @param eids The eids (edit ID's) that are provided via the query parameters.
 */
export const MultiEditNavigation = (props: IMultiEditNavigationProps) => {
    const navigate = useNavigate();
    const { modal } = AntApp.useApp();
    const itemExists = props.eids.indexOf(props.currentId) !== -1;
    const isLastItem = props.eids.indexOf(props.currentId) === props.eids.length - 1;
    // Used to determine which direction to navigate when the user presses 'Save & Next' after pressing the arrow navigation.
    const [navigateDirection, setNavigateDirection] = useState(NavigateDirection.None);

    // -===[ UseEffects ]===-
    useEffect(() => {
        if (props.updateStatus === StatePieceStatus.Success) {
            goNavigate(navigateDirection);
        }
    }, [props.updateStatus]);

    // -===[ Functions ]===-
    const showConfirmationDialog = (direction: NavigateDirection, isClose?: boolean) => {
        if (direction !== navigateDirection) {
            setNavigateDirection(direction);
        }

        if (!props.formIsPrisinte) {
            const confirmModal = modal.confirm({
                title: 'UNSAVED CHANGES',
                content:
                    'Do you want to keep the changes or do you want to revert your changes and move to the next item?\nAll changes will be lost when you continue without saving.',
                okText: isClose ? 'Save & go to list' : `Save & ${direction === NavigateDirection.Next ? 'Next' : 'Previous'}`,
                cancelText: isClose ? 'Ignore & go to list' : `Ignore & ${direction === NavigateDirection.Next ? 'Next' : 'Previous'}`,
                icon: null,
                onOk: () => {
                    props.submitForm();
                },
                closable: true,
                cancelButtonProps: {
                    onClick: () => {
                        goNavigate(direction);
                        confirmModal.destroy();
                    },
                },
            });
        }
    };

    const goNavigate = (direction: NavigateDirection) => {
        if (direction !== navigateDirection) {
            setNavigateDirection(direction);
        }

        if (direction === NavigateDirection.None) {
            return;
        }
        if (direction === NavigateDirection.BackToList) {
            let returnUrl = getReturnUrl();
            navigate(returnUrl ? returnUrl : props.returnUrlFallback, { replace: true });
            return;
        }

        const relativeIndex = direction === NavigateDirection.Next ? 1 : -1;
        const currentId = props.eids.indexOf(props.currentId);
        navigate(
            window.location.pathname.replace(`/${props.currentId}/edit`, `/${props.eids[currentId + relativeIndex]}/edit`) +
                window.location.search
        );
    };

    // -===[ Rendering ]===-
    const label = `${props.eids.indexOf(props.currentId) + 1} out of ${props.eids.length}`;
    return (
        <Space size={'large'}>
            <Space>
                <Button
                    icon={<ArrowLeftOutlined />}
                    onClick={() => {
                        props.formIsPrisinte
                            ? goNavigate(NavigateDirection.BackToList)
                            : showConfirmationDialog(NavigateDirection.BackToList, true);
                    }}
                    disabled={!itemExists || props.disabled}
                >
                    Back to list
                </Button>

                {isLastItem ? (
                    <Button
                        htmlType="submit"
                        type="primary"
                        onClick={() => setNavigateDirection(NavigateDirection.BackToList)}
                        icon={<SaveOutlined />}
                        disabled={!itemExists || props.disabled || !props.isEditable}
                    >
                        Save and close
                    </Button>
                ) : (
                    <Button
                        htmlType="submit"
                        type="primary"
                        onClick={() => setNavigateDirection(NavigateDirection.Next)}
                        icon={<SaveOutlined />}
                        disabled={!itemExists || props.disabled || !props.isEditable}
                    >
                        Save and next
                    </Button>
                )}
            </Space>

            <Space className="multi-edit-arrow-navigation" size={'small'}>
                <Button
                    icon={<ArrowUpOutlined />}
                    onClick={() => {
                        props.formIsPrisinte ? goNavigate(NavigateDirection.Previous) : showConfirmationDialog(NavigateDirection.Previous);
                    }}
                    disabled={!itemExists || props.disabled || props.eids.indexOf(props.currentId) === 0}
                />
                <Button
                    icon={<ArrowDownOutlined />}
                    onClick={() => {
                        props.formIsPrisinte ? goNavigate(NavigateDirection.Next) : showConfirmationDialog(NavigateDirection.Next);
                    }}
                    disabled={!itemExists || props.disabled || props.eids.indexOf(props.currentId) === props.eids.length - 1}
                />
                <span className="multi-edit-arrow-navigation-label">{label}</span>
            </Space>
        </Space>
    );
};
