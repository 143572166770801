import { InboxOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Modal, notification, Spin, Upload, UploadProps } from 'antd';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { StatePieceStatus } from '../../../../../enums';
import { IManualDocument } from '../../../../../interfaces/documents/data/IManualDocument';
import { uploadAPI } from '../../../../../MSALConfig';
import { NOTIFICATION_PLACEMENT } from '../../../../../shared/Constants';
import { RootState } from '../../../../../store';

export interface IAddManualDocument {
    open: boolean;
    onCancel: () => void;
    onUploadSuccess: (result: IManualDocument) => void;
}

export const AddManualDocumentModal = (props: IAddManualDocument) => {
    // const dispatch = useDispatch<AppDispatch>();
    const { userSlice } = useSelector((state: RootState) => state);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        if (!props.open) {
            setFileList([]);
        }
    }, [props.open]);

    const handleUpload = async () => {
        try {
            const formData = new FormData();
            formData.append('File', fileList[0] as RcFile);
            formData.append('Subject', fileList[0].name);

            setUploading(true);
            const response = await uploadAPI(`${process.env.REACT_APP_API_URL}/manuals/documents`, {
                method: 'POST',
                body: formData,
            });

            if (response.status !== 201) {
                throw new Error();
            }

            const res = await response.json();

            notification.success({
                message: `${res.subject} file uploaded successfully.`,
                placement: NOTIFICATION_PLACEMENT,
            });
            setFileList([]);
            props.onUploadSuccess(res);
        } catch (e) {
            notification.error({
                message: `${fileList[0].name} file uploaded successfully.`,
                placement: NOTIFICATION_PLACEMENT,
            });
        } finally {
            setUploading(false);
        }
    };

    const uploadProps: UploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            // Override the file list completely to override it, thus limiting the amount of files to one.
            setFileList([file]);

            return false;
        },
        fileList,
        multiple: false,
    };

    return (
        <React.Fragment>
            <Modal
                title="Upload document"
                open={props.open}
                width={1000}
                centered
                onCancel={() => props.onCancel()}
                footer={[
                    <React.Fragment key={0}>
                        <Button onClick={() => props.onCancel()}>Cancel</Button>
                        <Button
                            onClick={handleUpload}
                            type="primary"
                            icon={<SaveOutlined />}
                            disabled={fileList.length === 0}
                            loading={uploading}
                        >
                            Save and close
                        </Button>
                    </React.Fragment>,
                ]}
            >
                <Spin spinning={userSlice.detailUser?.user.fetchStatus === StatePieceStatus.IsFetching}>
                    <Upload.Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">Files will be uploaded when you press 'Save and close'.</p>
                    </Upload.Dragger>
                </Spin>
            </Modal>
        </React.Fragment>
    );
};
