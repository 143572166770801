import { SaveOutlined } from '@ant-design/icons';
import { Button, Form, Modal } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StatePieceStatus } from '../../../../enums';
import { IDispatchResponse } from '../../../../interfaces/IDispatchResponse';
import { IAddInvoice, IAddInvoiceFormValues, IInvoice } from '../../../../interfaces/invoices';
import { AppDispatch, RootState } from '../../../../store';
import { toggleAddInvoiceModal, updateFeeInvoices } from '../../../../store/feeSlice/FeeSlice';
import { addInvoice } from '../../../../store/invoiceSlice/InvoiceActions';
import { resetInvoiceCreateStatus } from '../../../../store/invoiceSlice/InvoiceSlice';
import { AddInvoiceFormFields } from '../../../invoices/addInvoice/components/addInvoiceFormFields/AddInvoiceFormFields';
import './AddInvoiceModal.less';

export interface IAddInvoiceModalProps {
    isOpen: boolean;
    defaultSelectedFeeId?: number;
    defaultAmount?: number | null;
    reasonOfPayment?: string | null;
}

export const AddInvoiceModal = (props: IAddInvoiceModalProps) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch<AppDispatch>();
    const { invoiceSlice } = useSelector((state: RootState) => state);

    const onCreateInvoice = async (values: IAddInvoiceFormValues) => {
        const body: IAddInvoice = {
            ...(values as any),
        };

        let createdInvoices: IDispatchResponse = await dispatch(addInvoice(body));
        dispatch(updateFeeInvoices({ feeId: values.feeId, invoices: (createdInvoices.payload as IInvoice).fee.invoices }));
    };

    useEffect(() => {
        if (invoiceSlice.detailPage.invoice.createStatus === StatePieceStatus.Success) {
            form.resetFields();
            dispatch(toggleAddInvoiceModal());
            dispatch(resetInvoiceCreateStatus());
        }
    }, [invoiceSlice.detailPage.invoice]);

    return (
        <Modal
            title={`Create new invoice for Fee #${props.defaultSelectedFeeId}`}
            centered
            open={props.isOpen}
            width={800}
            footer={null}
            onCancel={() => dispatch(toggleAddInvoiceModal())}
        >
            <Form
                form={form}
                name="control-hooks"
                onFinish={onCreateInvoice}
                layout="vertical"
                onValuesChange={(changedValue: any, values) => {
                    form.setFieldsValue(values);
                }}
            >
                <AddInvoiceFormFields
                    form={form}
                    defaultSelectedFeeId={props.defaultSelectedFeeId}
                    defaultAmount={props.defaultAmount || undefined}
                    reasonOfPayment={props.reasonOfPayment || undefined}
                />

                <div className="custom-modal-footer">
                    <Form.Item>
                        <Button onClick={() => dispatch(toggleAddInvoiceModal())}>Cancel</Button>
                        <Button
                            htmlType="submit"
                            type="primary"
                            icon={<SaveOutlined />}
                            loading={invoiceSlice.detailPage.invoice.createStatus === StatePieceStatus.IsFetching}
                        >
                            Create and close
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </Modal>
    );
};
