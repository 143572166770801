import { SaveOutlined, CloseOutlined } from '@ant-design/icons';
import { Form, Button, Spin, Row, Col, Space, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { StatePieceStatus } from '../../../enums';
import { IUpdateCustomer } from '../../../interfaces/customer/data';
import { IUpdateCustomerFormValues } from '../../../interfaces/customer/IUpdateCustomerFormValues';
import { ContentHeader } from '../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { MultiEditNavigation } from '../../../shared/components/multiEditNavigation/MultiEditNavigation';
import { Unauthorized } from '../../../shared/components/unauthorized/Unauthorized';
import { ROW_GUTTER } from '../../../shared/Constants';
import { customer_isEditable, getReturnUrl, QueryParamHelpers } from '../../../shared/helpers';
import { AppDispatch, RootState } from '../../../store';
import { fetchCustomerById, updateCustomer } from '../../../store/customerSlice/CustomerActions';
import { resetState, setEids } from '../../../store/customerSlice/CustomerSlice';
import { NotFound } from '../../notFound/NotFound';
import { Route } from '@ant-design/pro-layout/es/typing';

export const UpdateCustomer = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const { customerSlice } = useSelector((state: RootState) => state);
    const { customer } = useSelector((state: RootState) => state.customerSlice.detailsPage);
    const { appSlice } = useSelector((state: RootState) => state);
    const [form] = Form.useForm();
    const [formIsPrisinte, setFormIsPrisinte] = useState(true);
    const [breadcrumb, setBreadcrumb] = useState<Route[]>([]);
    const customerId = params.id ? parseInt(params.id) : undefined;

    const onFinish = (values: IUpdateCustomerFormValues) => {
        const body: IUpdateCustomer = {
            ...(values as any),
        };

        dispatch(updateCustomer(body));
    };

    /**
     * OnInit
     */
    useEffect(() => {
        return () => {
            dispatch(resetState());
        };
    }, []);

    useEffect(() => {
        if (customerSlice.detailsPage.eids.length === 0 && customer.updateStatus === StatePieceStatus.Success) {
            let returnUrl = getReturnUrl();
            navigate(returnUrl ? returnUrl : '/customers', { replace: true });
        }
    }, [customer.updateStatus]);

    useEffect(() => {
        setFormIsPrisinte(true);
        const eids = QueryParamHelpers.getEditIds();
        if (eids.isValid) {
            dispatch(setEids(eids.data));
        }

        if (customerId) {
            dispatch(fetchCustomerById(customerId));
        }
    }, [customerId]);

    React.useEffect(() => {
        if (customer.data) {
            form.setFieldsValue({
                id: customer.data.id,
                name: customer.data.name,
                searchName: customer.data.searchName,
            } as IUpdateCustomerFormValues);
        }

        const generatedBreadcrumb: Route[] = [
            {
                breadcrumbName: 'Customers',
                path: '/customers',
            },
        ];

        if (customer.data !== null) {
            generatedBreadcrumb.push({
                breadcrumbName: customer.data.name || customer.data.searchName || '',
                path: `/customers/${customer.data.id}`,
            });
            generatedBreadcrumb.push({
                breadcrumbName: 'Edit',
                path: window.location.pathname,
            });
        }

        setBreadcrumb(generatedBreadcrumb);
    }, [customer.data]);

    const customerName = customer.data?.name || customer.data?.searchName;

    if (appSlice.auth.validationCompleted && !appSlice.auth.customers.edit) {
        return <Unauthorized />;
    }

    if (!customerId) {
        return <NotFound />;
    }

    return (
        <React.Fragment>
            <Form
                form={form}
                name="control-hooks"
                onFinish={onFinish}
                layout="vertical"
                onValuesChange={(_changedValue, values) => {
                    form.setFieldsValue(values);
                    if (formIsPrisinte) {
                        setFormIsPrisinte(false);
                    }
                }}
                initialValues={
                    {
                        id: customerId,
                    } as IUpdateCustomerFormValues
                }
            >
                <ContentHeader
                    title={`Customer #${params.id} ${customerName || ''}`}
                    breadcrumbs={breadcrumb}
                    hideBackButton={customerSlice.detailsPage.eids.length > 0} // Hide the back button if the current view is the multi edit
                >
                    <Space>
                        {customerId && customerSlice.detailsPage.eids.length > 0 ? (
                            <MultiEditNavigation
                                disabled={
                                    customerSlice.detailsPage.customer.updateStatus === StatePieceStatus.IsFetching ||
                                    customerSlice.detailsPage.customer.fetchStatus === StatePieceStatus.IsFetching
                                }
                                currentId={customerId}
                                eids={customerSlice.detailsPage.eids}
                                returnUrlFallback={'/customers'}
                                updateStatus={customerSlice.detailsPage.customer.updateStatus}
                                formIsPrisinte={formIsPrisinte}
                                isEditable={customer_isEditable(customer.data)}
                                submitForm={() => form.submit()}
                            />
                        ) : (
                            <>
                                <Button
                                    icon={<CloseOutlined />}
                                    onClick={() => navigate(-1)}
                                    disabled={customer.updateStatus === StatePieceStatus.IsFetching}
                                >
                                    Close
                                </Button>

                                {appSlice.auth.customers.edit && (
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        icon={<SaveOutlined />}
                                        loading={customer.updateStatus === StatePieceStatus.IsFetching}
                                    >
                                        Save and close
                                    </Button>
                                )}
                            </>
                        )}
                    </Space>
                </ContentHeader>

                {/* Field is required to be on the page. */}
                <Form.Item name="id" rules={[{ required: true }]} hidden></Form.Item>

                <div className="page-content">
                    <Spin
                        spinning={
                            customer.fetchStatus === StatePieceStatus.IsFetching || customer.updateStatus === StatePieceStatus.IsFetching
                        }
                    >
                        {/* Customer INFORMATION */}
                        <Row>
                            <Col span={24}>
                                <Space>
                                    <label className="group-label">Customer Information</label>
                                </Space>
                            </Col>
                        </Row>
                        <Row gutter={ROW_GUTTER}>
                            <Col span={6}>
                                <label className="property required">Name</label>
                                <Form.Item name="name" rules={[{ required: true, message: "'Name' is required", max: 255 }]}>
                                    <Input placeholder="Provide a name..." />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <label className="property required">Search name</label>
                                <Form.Item
                                    id="searchname"
                                    name="searchName"
                                    rules={[{ required: true, message: "'Search name' is required", max: 255 }]}
                                >
                                    <Input placeholder="Provide a search name..." />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Spin>
                </div>
            </Form>
        </React.Fragment>
    );
};
