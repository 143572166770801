import { IPaginatedResult } from '../../interfaces/IPaginatedResult';
import { IAddBeneficiaryComment } from './../../interfaces/beneficiaries/data/IAddBeneficiaryComment';
import { IBeneficiaryComment } from './../../interfaces/beneficiaries/data/IBeneficiaryComment';
import { IUpdateBeneficiary } from './../../interfaces/beneficiaries/data/IUpdateBeneficiary';
import { IAddBeneficiary } from './../../interfaces/beneficiaries/data/IAddBeneficiary';
import { IBeneficiary } from './../../interfaces/beneficiaries/data/IBeneficiary';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAPI } from '../../MSALConfig';
import { notification } from 'antd';
import { NOTIFICATION_PLACEMENT } from '../../shared/Constants';
import { IDeleteBeneficiaryComment } from '../../interfaces/beneficiaries/IDeleteBeneficiaryComment';
import { IBeneficiaryFilterParams } from '../../interfaces/beneficiaries/IBeneficiaryFilterParams';
import { constructQueryParams } from '../../shared/helpers/FilterHelpers';
import { IUpdateBeneficiaryStatusCode } from '../../interfaces/beneficiaries/data/IUpdateBeneficiaryStatusCode';

const appendBeneficiaryFilterParams = (props: IBeneficiaryFilterParams, params: string) => {
    if (params === '') params = '?';

    if (props.statusId) {
        props.statusId.forEach((element) => {
            if (params.length > 1) params += '&';
            params += `StatusId=${element}`;
        });
    }

    if (props.countryId) {
        props.countryId.forEach((element) => {
            if (params.length > 1) params += '&';
            params += `CountryId=${element}`;
        });
    }

    return params;
};

export const fetchBeneficiaries = createAsyncThunk('beneficiary/fetchBeneficiaries', async (props: IBeneficiaryFilterParams) => {
    try {
        let params = constructQueryParams(props);
        params = appendBeneficiaryFilterParams(props, params);

        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/beneficiaries${params}`, {});
        const result: IPaginatedResult<IBeneficiary[]> = await response.json();

        if (response.status !== 200) {
            throw new Error();
        }

        return result;
    } catch (e) {
        console.error(e);
        notification.error({
            message: 'An error occurred.',
            description: 'An error occurred while retrieving the beneficiaries. Please contact your administrator if the problem persists.',
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        throw e;
    }
});
export const fetchBeneficiaryById = createAsyncThunk('beneficiary/fetchBeneficiaryById', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/beneficiaries/${id}`, {});

        if (response.status !== 200) {
            throw new Error(response.status.toString());
        }

        const result: IBeneficiary = await response.json();

        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const addBeneficiary = createAsyncThunk('beneficiary/addBeneficiary', async (body: IAddBeneficiary) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/beneficiaries/`, {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (response.status !== 201) {
            throw new Error('Could not add the beneficiary.');
        }

        const result: IBeneficiary = await response.json();

        notification.success({
            message: 'Beneficiary added',
            description: `The beneficiary with the name '${body.name}' has been added successfully.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not add the beneficiary with the name '${body.name}'. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});
export const updateBeneficiary = createAsyncThunk('beneficiary/updateBeneficiary', async (body: IUpdateBeneficiary) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/beneficiaries/${body.id}`, {
            method: 'PATCH',
            body: JSON.stringify(body),
        });

        if (response.status !== 200) {
            if (response.status === 409) {
                throw new Error(
                    'Could not update the beneficiary. The beneficiary is already updated by another user. Please refresh the page to retrieve new data.'
                );
            }
            throw new Error(`Could not update the beneficiary '${body.name}'. Please contact your administrator if the problem persists.`);
        }

        const result: IBeneficiary = await response.json();

        notification.success({
            message: 'Beneficiary updated',
            description: `The beneficiary '${body.name}' has been updated successfully.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e: any) {
        notification.error({
            message: 'An error occurred',
            description: e.message,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const fetchBeneficiaryComments = createAsyncThunk('beneficiary/fetchBeneficiaryComments', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/beneficiaries/${id}/comments`, {});
        const result: IBeneficiaryComment[] = await response.json();
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not retrieve the comments for this beneficiary. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const addBeneficiaryComment = createAsyncThunk('beneficiary/addBeneficiaryComment', async (body: IAddBeneficiaryComment) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/beneficiaries/${body.beneficiaryId}/comments`, {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (response.status !== 201) {
            throw new Error('Could not add a comment to the beneficiary.');
        }

        const result: IBeneficiaryComment = await response.json();

        notification.success({
            message: 'Comment added.',
            description: `The comment is successfully added to the beneficiary.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not add the comment to the beneficiary. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const deleteBeneficiary = createAsyncThunk('beneficiary/deleteBeneficiary', async (beneficiaryId: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/beneficiaries/${beneficiaryId}`, {
            method: 'DELETE',
        });

        if (response.status !== 200) {
            throw new Error('Unable to delete the beneficiary.');
        }

        const result: IBeneficiary = await response.json();

        notification.success({
            message: 'Beneficiary deleted',
            description: `The beneficiary is successfully deleted.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not remove the beneficiary. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const deleteBeneficiaryComment = createAsyncThunk(
    'beneficiary/deleteBeneficiaryComment',
    async (body: IDeleteBeneficiaryComment) => {
        try {
            const response = await fetchAPI(
                `${process.env.REACT_APP_API_URL}/beneficiaries/${body.beneficiaryId}/comments/${body.commentId}`,
                {
                    method: 'DELETE',
                }
            );

            if (response.status !== 200) {
                throw new Error('Unable to delete the beneficiary comment.');
            }

            const result: IBeneficiaryComment = await response.json();

            notification.success({
                message: 'Beneficiary comment deleted',
                description: `The beneficiary comment is successfully deleted.`,
                placement: NOTIFICATION_PLACEMENT,
            });
            return result;
        } catch (e) {
            notification.error({
                message: 'An error occurred',
                description: `Could not remove the beneficiary comment. Please contact your administrator if the problem persists.`,
                duration: 0,
                placement: NOTIFICATION_PLACEMENT,
            });
            console.error(e);
            throw e;
        }
    }
);

export const updateBeneficiaryStatuses = createAsyncThunk(
    'beneficiary/updateBeneficiaryStatuses',
    async (feeStatusCodes: IUpdateBeneficiaryStatusCode[]) => {
        try {
            const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/beneficiaries/statuses`, {
                method: 'PATCH',
                body: JSON.stringify(feeStatusCodes),
            });

            if (response.status !== 200) {
                const result: any = await response.json();
                throw new Error(result.message || 'Could not update the beneficiary.');
            }

            const result: IBeneficiary[] = await response.json();

            notification.success({
                message: 'Status updated',
                description: `The status of the beneficiary have been updated successfully.`,
                placement: NOTIFICATION_PLACEMENT,
            });
            return result;
        } catch (e: any) {
            notification.error({
                message: 'An error occurred',
                description: e.message || `Could not update the fee statuses. Please contact your administrator if the problem persists.`,
                duration: 0,
                placement: NOTIFICATION_PLACEMENT,
            });
            console.error(e);
            throw e;
        }
    }
);
