import { IUpdateFeeStatus } from './../../interfaces/lookup/feeStatus/data/IUpdateFeeStatus';
import { IInvoiceStatus } from './../../interfaces/lookup/invoiceStatus/data/IInvoiceStatus';
import { IAddInvoiceStatus } from './../../interfaces/lookup/invoiceStatus/data/IAddInvoiceStatus';
import { IUpdateInvoiceStatus } from './../../interfaces/lookup/invoiceStatus/data/IUpdateInvoiceStatus';
import { IUpdateMaHolder } from './../../interfaces/lookup/maHolder/data/IUpdateMaHolder';
import { IUpdateBeneficiaryStatus } from './../../interfaces/lookup/beneficiaryStatus/data/IUpdateBeneficiaryStatus';
import { IUpdateFeeKind } from './../../interfaces/lookup/feeKind/data/IUpdateFeeKind';
import { IAddFeeKind } from './../../interfaces/lookup/feeKind/data/IAddFeeKind';
import { IAddRegion } from './../../interfaces/lookup/region/data/IAddRegion';
import { IUpdateRegion } from './../../interfaces/lookup/region/data/IUpdateRegion';
import { IAddProject } from './../../interfaces/lookup/project/data/IAddProject';
import { IUpdateProject } from './../../interfaces/lookup/project/data/IUpdateProject';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAPI } from '../../MSALConfig';
import { notification } from 'antd';
import { NOTIFICATION_PLACEMENT } from '../../shared/Constants';
import { IAddCurrency, ICurrency, IUpdateCurrency } from '../../interfaces/lookup/currency/data';
import { IAddCountry, ICountry, IUpdateCountry } from '../../interfaces/lookup/country/data';
import { IAddBankCostOption, IBankCostOption, IUpdateBankCostOption } from '../../interfaces/lookup/bankCost/data';
import { IAddCategory, ICategory, IUpdateCategory } from '../../interfaces/lookup/category/data';
import { IAddBillableOption, IBillableOption, IUpdateBillableOption } from '../../interfaces/lookup/billable/data';
import { IProject } from '../../interfaces/lookup/project/data';
import { IAddBankAccount, IBankAccount, IUpdateBankAccount } from '../../interfaces/lookup/bankAccount/data';
import { IFeeKind } from '../../interfaces/lookup/feeKind/data';
import { IRegion } from '../../interfaces/lookup/region/data';
import { IAddFeeStatus, IFeeStatus } from '../../interfaces/lookup/feeStatus/data';
import { IAddBeneficiaryStatus, IBeneficiaryStatus } from '../../interfaces/lookup/beneficiaryStatus/data';
import { IAddMaHolder, IMaHolder } from '../../interfaces/lookup/maHolder/data';
import { ILookup } from '../../interfaces/lookup';

/** currencies */
export const fetchCurrenciesLookupList = createAsyncThunk('lookup/fetchCurrenciesLookupList', async () => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/lookup-lists/currencies`, {});
        const result: ILookup[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchCurrencies = createAsyncThunk('lookup/fetchCurrencies', async () => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/currencies`, {});
        const result: ICurrency[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchCurrencyById = createAsyncThunk('lookup/fetchCurrencyById', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/currencies/${id}`, {});
        const result: ICurrency = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const updateCurrency = createAsyncThunk('lookup/updateCurrency', async (option: IUpdateCurrency) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/currencies/${option.id}`, {
            method: 'PATCH',
            body: JSON.stringify(option),
        });

        if (response.status !== 200) {
            throw new Error('Could not update the currency.');
        }

        const result: ICurrency = await response.json();

        notification.success({
            message: 'Currency updated',
            description: `The currency has been updated successfully.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not update the currency. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const addCurrency = createAsyncThunk('lookup/addCurrency', async (body: IAddCurrency) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/currencies`, {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (response.status !== 201) {
            throw new Error('Unable to add a new currency.');
        }

        const result: ICurrency = await response.json();

        notification.success({
            message: 'Currency created',
            description: `The currency is successfully created.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not add the currency. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const deleteCurrency = createAsyncThunk('lookup/deleteCurrency', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/currencies/${id}`, {
            method: 'DELETE',
        });

        if (response.status !== 200) {
            throw new Error('Unable to delete the currency.');
        }

        const result: ICurrency = await response.json();

        notification.success({
            message: 'Currency deleted',
            description: `The currency is successfully deleted.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not remove the currency. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});
/** end currencies */

/** countries */
export const fetchCountriesLookupList = createAsyncThunk('lookup/fetchCountriesLookupList', async () => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/lookup-lists/countries`, {});
        const result: ILookup[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchCountries = createAsyncThunk('lookup/fetchCountries', async () => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/countries`, {});
        const result: ICountry[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchCountryById = createAsyncThunk('lookup/fetchCountryById', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/countries/${id}`, {});
        const result: ICountry = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const updateCountry = createAsyncThunk('lookup/updateCountry', async (option: IUpdateCountry) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/countries/${option.id}`, {
            method: 'PATCH',
            body: JSON.stringify(option),
        });

        if (response.status !== 200) {
            throw new Error('Could not update the country.');
        }

        const result: ICountry = await response.json();

        notification.success({
            message: 'Country updated',
            description: `The country has been updated successfully.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not update the country. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const addCountry = createAsyncThunk('lookup/addCountry', async (body: IAddCountry) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/countries`, {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (response.status !== 201) {
            throw new Error('Unable to add a new country.');
        }

        const result: ICountry = await response.json();

        notification.success({
            message: 'Country created',
            description: `The country is successfully created.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not add the country. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const deleteCountry = createAsyncThunk('lookup/deleteCountry', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/countries/${id}`, {
            method: 'DELETE',
        });

        if (response.status !== 200) {
            throw new Error('Unable to delete the country.');
        }

        const result: ICountry = await response.json();

        notification.success({
            message: 'Country deleted',
            description: `The country is successfully deleted.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not remove the country. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});
/** end countries */

/** bank cost */
export const fetchBankCostOptionsLookupList = createAsyncThunk('lookup/fetchBankCostOptionsLookupList', async () => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/lookup-lists/bank-cost-options`, {});
        const result: ILookup[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchBankCostOptions = createAsyncThunk('lookup/fetchBankCostOptions', async () => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/bank-cost-options`, {});
        const result: IBankCostOption[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchBankCostOptionById = createAsyncThunk('lookup/fetchBankCostOptionById', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/bank-cost-options/${id}`, {});
        const result: IBankCostOption = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const updateBankCostOption = createAsyncThunk('lookup/updateBankCostOption', async (option: IUpdateBankCostOption) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/bank-cost-options/${option.id}`, {
            method: 'PATCH',
            body: JSON.stringify(option),
        });

        if (response.status !== 200) {
            throw new Error('Could not update the bank cost option.');
        }

        const result: IBankCostOption = await response.json();

        notification.success({
            message: 'Bank cost updated',
            description: `The bank cost option has been updated successfully.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not update the bank cost option. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const addBankCostOption = createAsyncThunk('lookup/addBankCostOption', async (body: IAddBankCostOption) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/bank-cost-options`, {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (response.status !== 201) {
            throw new Error('Unable to add a new bank cost option.');
        }

        const result: IBankCostOption = await response.json();

        notification.success({
            message: 'Bank cost option created',
            description: `The bank cost option is successfully created.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not add the bank cost option. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const deleteBankCostOption = createAsyncThunk('lookup/deleteBankCostOption', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/bank-cost-options/${id}`, {
            method: 'DELETE',
        });

        if (response.status !== 200) {
            throw new Error('Unable to delete the bank cost option.');
        }

        const result: IBankCostOption = await response.json();

        notification.success({
            message: 'Bank cost option deleted',
            description: `The bank cost option is successfully deleted.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not remove the bank cost option. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});
/** end bank cost */

/** categories */
export const fetchCategoriesAsLookupList = createAsyncThunk('lookup/fetchCategoriesAsLookupList', async () => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/lookup-lists/categories`, {});
        const result: ILookup[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchCategories = createAsyncThunk('lookup/fetchCategories', async () => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/categories`, {});
        const result: ICategory[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchCategoryById = createAsyncThunk('lookup/fetchCategoryById', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/categories/${id}`, {});
        const result: ICategory = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const updateCategory = createAsyncThunk('lookup/updateCategory', async (option: IUpdateCategory) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/categories/${option.id}`, {
            method: 'PATCH',
            body: JSON.stringify(option),
        });

        if (response.status !== 200) {
            throw new Error('Could not update the category.');
        }

        const result: ICategory = await response.json();

        notification.success({
            message: 'Category updated',
            description: `The category has been updated successfully.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not update the category. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const addCategory = createAsyncThunk('lookup/addCategory', async (body: IAddCategory) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/categories`, {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (response.status !== 201) {
            throw new Error('Unable to add a new category.');
        }

        const result: ICategory = await response.json();

        notification.success({
            message: 'Category created',
            description: `The category is successfully created.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not add the category. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const deleteCategory = createAsyncThunk('lookup/deleteCategory', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/categories/${id}`, {
            method: 'DELETE',
        });

        if (response.status !== 200) {
            throw new Error('Unable to delete the category.');
        }

        const result: ICategory = await response.json();

        notification.success({
            message: 'Category deleted',
            description: `The category is successfully deleted.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not remove the category. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});
/** end of categories */

/** billable options */
export const fetchBillableOptionsLookupList = createAsyncThunk('lookup/fetchBillableOptionsLookupList', async () => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/lookup-lists/billable-options`, {});
        const result: ILookup[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchBillableOptions = createAsyncThunk('lookup/fetchBillableOptions', async () => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/billable-options`, {});
        const result: IBillableOption[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchBillableOptionById = createAsyncThunk('lookup/fetchBillableOptionById', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/billable-options/${id}`, {});
        const result: IBillableOption = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const addBillableOption = createAsyncThunk('lookup/addBillableOption', async (body: IAddBillableOption) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/billable-options`, {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (response.status !== 201) {
            throw new Error('Unable to add a new billable option.');
        }

        const result: IBillableOption = await response.json();

        notification.success({
            message: 'Billable option created',
            description: `The billable option is successfully created.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not add the billable option. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const updateDefaultBillableOption = createAsyncThunk('lookup/updateDefaultBillableOption', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/billable-options/${id}/setDefault`, {
            method: 'PATCH',
        });

        if (response.status !== 200) {
            throw new Error('Could not update the billable option default.');
        }

        const result: IBillableOption = await response.json();

        notification.success({
            message: 'Default billable option updated',
            description: `The default billable option updated successfully.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not update the default billable option. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const updateBillableOption = createAsyncThunk('lookup/updateBillableOption', async (option: IUpdateBillableOption) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/billable-options/${option.id}`, {
            method: 'PATCH',
            body: JSON.stringify(option),
        });

        if (response.status !== 200) {
            throw new Error('Could not update the billable option.');
        }

        const result: IBillableOption = await response.json();

        notification.success({
            message: 'Fee updated',
            description: `The billable option has been updated successfully.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not update the billable option. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const deleteBillableOption = createAsyncThunk('lookup/deleteBillableOption', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/billable-options/${id}`, {
            method: 'DELETE',
        });

        if (response.status !== 200) {
            throw new Error('Unable to delete the billable option.');
        }

        const result: IBillableOption = await response.json();

        notification.success({
            message: 'Billable option deleted',
            description: `The billable option is successfully deleted.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not remove the billable option. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});
/** end billable options */

/** bankAccounts */
export const fetchBankAccountsLookupList = createAsyncThunk('lookup/fetchBankAccountsLookupList', async () => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/lookup-lists/bank-accounts`, {});
        const result: ILookup[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchBankAccounts = createAsyncThunk('lookup/fetchBankAccounts', async () => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/bank-accounts`, {});
        const result: IBankAccount[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchBankAccountById = createAsyncThunk('lookup/fetchBankAccountyId', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/bank-accounts/${id}`, {});
        const result: IBankAccount = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const updateBankAccount = createAsyncThunk('lookup/updateBankAccount', async (option: IUpdateBankAccount) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/bank-accounts/${option.id}`, {
            method: 'PATCH',
            body: JSON.stringify(option),
        });

        if (response.status !== 200) {
            throw new Error('Could not update the bank account.');
        }

        const result: IBankAccount = await response.json();

        notification.success({
            message: 'Bank account updated',
            description: `The bank account has been updated successfully.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not update the bank account. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const updateDefaultBankAccount = createAsyncThunk('lookup/updateDefaultBankAccount', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/bank-accounts/${id}/setDefault`, {
            method: 'PATCH',
        });

        if (response.status !== 200) {
            throw new Error('Could not update the default bank account.');
        }

        const result: IBankAccount = await response.json();

        notification.success({
            message: 'Default bank account updated',
            description: `The default bank account has been updated successfully.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not update the default bank account. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const addBankAccount = createAsyncThunk('lookup/addBankAccount', async (body: IAddBankAccount) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/bank-accounts`, {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (response.status !== 201) {
            throw new Error('Unable to add a new bank account.');
        }

        const result: IBankAccount = await response.json();

        notification.success({
            message: 'Bank account created',
            description: `The bank account is successfully created.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not add the bank account. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const deleteBankAccount = createAsyncThunk('lookup/deleteBankAccount', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/bank-accounts/${id}`, {
            method: 'DELETE',
        });

        if (response.status !== 200) {
            throw new Error('Unable to delete the bank account.');
        }

        const result: IBankAccount = await response.json();

        notification.success({
            message: 'Bank account deleted',
            description: `The bank account is successfully deleted.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not remove the bank account. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});
/** end bankAccounts */

/** projects */
export const fetchProjectsLookupList = createAsyncThunk('lookup/fetchProjectsLookupList', async () => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/lookup-lists/projects`, {});
        const result: ILookup[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchProjects = createAsyncThunk('lookup/fetchProjects', async () => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/projects`, {});
        const result: IProject[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchProjectById = createAsyncThunk('lookup/fetchProjectById', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/projects/${id}`, {});
        const result: IProject = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const updateProject = createAsyncThunk('lookup/updateProject', async (option: IUpdateProject) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/projects/${option.id}`, {
            method: 'PATCH',
            body: JSON.stringify(option),
        });

        if (response.status !== 200) {
            throw new Error('Could not update the currency.');
        }

        const result: IProject = await response.json();

        notification.success({
            message: 'Project updated',
            description: `The project has been updated successfully.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not update the project. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const addProject = createAsyncThunk('lookup/addProject', async (body: IAddProject) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/projects`, {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (response.status !== 201) {
            throw new Error('Unable to add a new project.');
        }

        const result: IProject = await response.json();

        notification.success({
            message: 'Project created',
            description: `The project is successfully created.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not add the project. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const deleteProject = createAsyncThunk('lookup/deleteProject', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/projects/${id}`, {
            method: 'DELETE',
        });

        if (response.status !== 200) {
            throw new Error('Unable to delete the project.');
        }

        const result: IProject = await response.json();

        notification.success({
            message: 'Project deleted',
            description: `The project is successfully deleted.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not remove the project. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});
/**end projects */

/** regions */
export const fetchRegionsLookupList = createAsyncThunk('lookup/fetchRegionsLookupList', async () => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/lookup-lists/regions`, {});
        const result: ILookup[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchRegions = createAsyncThunk('lookup/fetchRegions', async () => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/regions`, {});
        const result: IRegion[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchRegionById = createAsyncThunk('lookup/fetchRegionById', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/regions/${id}`, {});
        const result: IRegion = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const updateRegion = createAsyncThunk('lookup/updateRegion', async (option: IUpdateRegion) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/regions/${option.id}`, {
            method: 'PATCH',
            body: JSON.stringify(option),
        });

        if (response.status !== 200) {
            throw new Error('Could not update the region.');
        }

        const result: IRegion = await response.json();

        notification.success({
            message: 'Region updated',
            description: `The region has been updated successfully.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not update the region. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const addRegion = createAsyncThunk('lookup/addRegion', async (body: IAddRegion) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/regions`, {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (response.status !== 201) {
            throw new Error('Unable to add a new region.');
        }

        const result: IRegion = await response.json();

        notification.success({
            message: 'Region created',
            description: `The region is successfully created.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not add the region. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const deleteRegion = createAsyncThunk('lookup/deleteRegion', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/regions/${id}`, {
            method: 'DELETE',
        });

        if (response.status !== 200) {
            throw new Error('Unable to delete the region.');
        }

        const result: IRegion = await response.json();

        notification.success({
            message: 'Region deleted',
            description: `The region is successfully deleted.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not remove the region. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});
/** end regions */

/**kind of fees */
export const fetchKindOfFeesLookupList = createAsyncThunk('lookup/fetchKindOfFeesLookupList', async () => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/lookup-lists/kind-of-fees`, {});
        const result: ILookup[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchKindOfFees = createAsyncThunk('lookup/fetchKindOfFees', async () => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/kind-of-fees`, {});
        const result: IFeeKind[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchKindOfFeeById = createAsyncThunk('lookup/fetchKindOfFeeById', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/kind-of-fees/${id}`, {});
        const result: IFeeKind = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const updateKindOfFee = createAsyncThunk('lookup/updateKindOfFee', async (option: IUpdateFeeKind) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/kind-of-fees/${option.id}`, {
            method: 'PATCH',
            body: JSON.stringify(option),
        });

        if (response.status !== 200) {
            throw new Error('Could not update the fee kind.');
        }

        const result: IFeeKind = await response.json();

        notification.success({
            message: 'Fee kind updated',
            description: `The fee kind has been updated successfully.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not update the Fee kind. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const addKindOfFee = createAsyncThunk('lookup/addKindOfFee', async (body: IAddFeeKind) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/kind-of-fees`, {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (response.status !== 201) {
            throw new Error('Unable to add a new fee kind.');
        }

        const result: IFeeKind = await response.json();

        notification.success({
            message: 'Fee kind created',
            description: `The fee kind is successfully created.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not add the fee kind. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const deleteKindOfFee = createAsyncThunk('lookup/deleteKindOfFee', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/kind-of-fees/${id}`, {
            method: 'DELETE',
        });

        if (response.status !== 200) {
            throw new Error('Unable to delete the fee kind.');
        }

        const result: IFeeKind = await response.json();

        notification.success({
            message: 'Fee kind deleted',
            description: `The fee kind is successfully deleted.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not remove the fee kind. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});
/** end kind of fees */

/** fetch fee status */
export const fetchFeeStatuses = createAsyncThunk('lookup/fetchFeeStatuses', async () => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/fee-statuses`, {});
        const result: IFeeStatus[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchFeeStatusById = createAsyncThunk('lookup/fetchFeeStatusById', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/fee-statuses/${id}`, {});
        const result: IFeeStatus = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const updateFeeStatus = createAsyncThunk('lookup/updateFeeStatus', async (option: IUpdateFeeStatus) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/fee-statuses/${option.id}`, {
            method: 'PATCH',
            body: JSON.stringify(option),
        });

        if (response.status !== 200) {
            throw new Error('Could not update the fee status.');
        }

        const result: IFeeStatus = await response.json();

        notification.success({
            message: 'Fee status updated',
            description: `The fee status has been updated successfully.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not update the fee status. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const addFeeStatus = createAsyncThunk('lookup/addFeeStatus', async (body: IAddFeeStatus) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/fee-statuses`, {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (response.status !== 201) {
            throw new Error('Unable to add a new fee status.');
        }

        const result: IFeeStatus = await response.json();

        notification.success({
            message: 'Fee status created',
            description: `The fee status is successfully created.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not add the fee status. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});
/** end fee status */

/** BeneficiaryStatuses */
export const fetchBeneficiaryStatuses = createAsyncThunk('lookup/fetchBeneficiaryStatuses', async () => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/beneficiary-statuses`, {});
        const result: IBeneficiaryStatus[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchBeneficiaryStatusById = createAsyncThunk('lookup/fetchBeneficiaryStatusById', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/beneficiary-statuses/${id}`, {});
        const result: IBeneficiaryStatus = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const updateBeneficiaryStatus = createAsyncThunk('lookup/updateBeneficiaryStatus', async (option: IUpdateBeneficiaryStatus) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/beneficiary-statuses/${option.id}`, {
            method: 'PATCH',
            body: JSON.stringify(option),
        });

        if (response.status !== 200) {
            throw new Error('Could not update the beneficiary status.');
        }

        const result: IBeneficiaryStatus = await response.json();

        notification.success({
            message: 'Beneficiary status updated',
            description: `The beneficiary status has been updated successfully.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not update the beneficiary status. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const addBeneficiaryStatus = createAsyncThunk('lookup/addBeneficiaryStatus', async (body: IAddBeneficiaryStatus) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/beneficiary-statuses`, {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (response.status !== 201) {
            throw new Error('Unable to add a new beneficiary status.');
        }

        const result: IBeneficiaryStatus = await response.json();

        notification.success({
            message: 'Beneficiary status created',
            description: `The beneficiary status is successfully created.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not add the beneficiary status. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});
/** end of BeneficiaryStatuses */

/** maHolders */
export const fetchMaHoldersLookupList = createAsyncThunk('lookup/fetchMaHoldersLookupList', async () => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/lookup-lists/ma-holders`, {});
        const result: ILookup[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchMaHolders = createAsyncThunk('lookup/fetchMaHolders', async () => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/ma-holders`, {});
        const result: IMaHolder[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchMaHolderById = createAsyncThunk('lookup/fetchMaHolderById', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/ma-holders/${id}`, {});
        const result: IMaHolder = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const updateMaHolder = createAsyncThunk('lookup/updateMaHolder', async (option: IUpdateMaHolder) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/ma-holders/${option.id}`, {
            method: 'PATCH',
            body: JSON.stringify(option),
        });

        if (response.status !== 200) {
            throw new Error('Could not update the MA holder.');
        }

        const result: IMaHolder = await response.json();

        notification.success({
            message: 'MA holder updated',
            description: `The MA holder has been updated successfully.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not update the MA holder. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const addMaHolder = createAsyncThunk('lookup/addMaHolder', async (body: IAddMaHolder) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/ma-holders`, {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (response.status !== 201) {
            throw new Error('Unable to add a new MA holder.');
        }

        const result: IMaHolder = await response.json();

        notification.success({
            message: 'MA holder created',
            description: `The MA holder is successfully created.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not add the MA holder. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const deleteMaHolder = createAsyncThunk('lookup/deleteMaHolder', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/ma-holders/${id}`, {
            method: 'DELETE',
        });

        if (response.status !== 200) {
            throw new Error('Unable to delete the MA holder.');
        }

        const result: IMaHolder = await response.json();

        notification.success({
            message: 'MA holder deleted',
            description: `The MA holder status is successfully deleted.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not remove the MA holder. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});
/** end maHolders */

/** invoiceStatuses */
export const fetchInvoiceStatuses = createAsyncThunk('lookup/fetchInvoiceStatuses', async () => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/invoice-statuses`, {});
        const result: IInvoiceStatus[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchInvoiceStatusById = createAsyncThunk('lookup/fetchInvoiceStatusById', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/invoice-statuses/${id}`, {});
        const result: IInvoiceStatus = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const updateInvoiceStatus = createAsyncThunk('lookup/updateInvoiceStatus', async (option: IUpdateInvoiceStatus) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/invoice-statuses/${option.id}`, {
            method: 'PATCH',
            body: JSON.stringify(option),
        });

        if (response.status !== 200) {
            throw new Error('Could not update the invouce status.');
        }

        const result: IInvoiceStatus = await response.json();

        notification.success({
            message: 'Invoice status updated',
            description: `The invoice status has been updated successfully.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not update the invoice status. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const addInvoiceStatus = createAsyncThunk('lookup/addInvoiceStatus', async (body: IAddInvoiceStatus) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/invoice-statuses`, {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (response.status !== 201) {
            throw new Error('Unable to add a new invoice status.');
        }

        const result: IInvoiceStatus = await response.json();

        notification.success({
            message: 'Invoice status created',
            description: `The invoice status is successfully created.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not add the invoice status. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});
/** end invoiceStatuses */

/** beneficiary */
export const fetchBeneficiariesLookupList = createAsyncThunk('lookup/fetchBeneficiariesLookupList', async () => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/lookup-lists/beneficiaries`, {});
        const result: ILookup[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});
/** end beneficiary */

/** users */
export const fetchUsersLookupList = createAsyncThunk('lookup/fetchUsersLookupList', async () => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/lookup-lists/users`, {});
        const result: ILookup[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});
/** end users */

/** customers */
export const fetchCustomersLookupList = createAsyncThunk('lookup/fetchCustomersLookupList', async () => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/lookup-lists/customers`, {});
        const result: ILookup[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});
/** end customers */
