import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Result, Row, Select, Space, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { StatePieceStatus } from '../../../../enums';
import { IUpdateUser } from '../../../../interfaces/users/data/IUpdateUser';
import { IUpdateUserFormValues } from '../../../../interfaces/users/IUpdateUserFormValues';
import { ContentHeader } from '../../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { FetchResult } from '../../../../shared/components/fetchResult/FetchResult';
import { ROW_GUTTER } from '../../../../shared/Constants';
import { getReturnUrl, isError } from '../../../../shared/helpers';
import { AppDispatch, RootState } from '../../../../store';
import { fetchUserById, fetchUserRoles, updateUser } from '../../../../store/userSlice/UserActions';
import './AdminUsersEdit.less';
import { NotFound } from '../../../notFound/NotFound';

export const AdminUsersEdit = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const { userSlice } = useSelector((state: RootState) => state);
    const [form] = Form.useForm();
    const userId = params.id ? parseInt(params.id) : undefined;

    const onFinish = (values: IUpdateUserFormValues) => {
        const body: IUpdateUser = {
            id: userSlice.detailUser.user.data?.id,
            ...(values as any),
        };

        dispatch(updateUser(body));
    };

    useEffect(() => {
        if (userSlice.detailUser.user.updateStatus === StatePieceStatus.Success) {
            let returnUrl = getReturnUrl();
            navigate(returnUrl ? returnUrl : '/admin/users', { replace: true });
        }
    }, [userSlice.detailUser.user.updateStatus]);

    useEffect(() => {
        if (userId) {
            dispatch(fetchUserById(userId));
        }
    }, [userId]);

    React.useEffect(() => {
        if (userSlice.detailUser.user.data) {
            form.setFieldsValue({
                id: userSlice.detailUser.user.data.id,
                initials: userSlice.detailUser.user.data.initials,
                name: userSlice.detailUser.user.data.name,
                userPrincipalName: userSlice.detailUser.user.data.userPrincipalName,
                roles: userSlice.detailUser.user.data.roles.map((r) => r.id),
            } as IUpdateUserFormValues);
        }
    }, [userSlice.detailUser.user.data]);

    /**
     * OnInit
     */
    useEffect(() => {
        dispatch(fetchUserRoles());
    }, []);

    if (userSlice.detailUser.user.fetchStatus === StatePieceStatus.Error) {
        return <Result status="warning" title="Data wasn't found or something went wrong." />;
    }

    if (!userId) {
        return <NotFound />;
    }

    return (
        <React.Fragment>
            <Form
                form={form}
                name="control-hooks"
                onFinish={onFinish}
                layout="vertical"
                onValuesChange={(changedValue: any, values) => {
                    form.setFieldsValue(values);
                }}
                initialValues={
                    {
                        id: userId,
                    } as IUpdateUserFormValues
                }
            >
                <ContentHeader
                    title={`User #${params.id} for ${userSlice.detailUser?.user.data?.name || '-'}`}
                    breadcrumbs={[
                        {
                            breadcrumbName: 'Users',
                            path: '/admin/users',
                        },
                        {
                            breadcrumbName: userSlice.detailUser?.user.data?.name || '-',
                            path: `/admin/users/${params.id}`,
                        },
                        {
                            breadcrumbName: 'Edit',
                            path: `/admin/users/${params.id}/edit`,
                        },
                    ]}
                >
                    {!isError(userSlice.detailUser.user.fetchStatus) && (
                        <Form.Item>
                            <Space>
                                <Button
                                    icon={<CloseOutlined />}
                                    onClick={() => navigate(-1)}
                                    disabled={
                                        userSlice.detailUser.user.updateStatus === StatePieceStatus.IsFetching ||
                                        userSlice.roles.fetchStatus === StatePieceStatus.IsFetching
                                    }
                                >
                                    Close
                                </Button>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    icon={<SaveOutlined />}
                                    loading={
                                        userSlice.detailUser.user.updateStatus === StatePieceStatus.IsFetching ||
                                        userSlice.roles.fetchStatus === StatePieceStatus.IsFetching
                                    }
                                >
                                    Save and close
                                </Button>
                            </Space>
                        </Form.Item>
                    )}
                </ContentHeader>

                <div className="page-content">
                    <Spin
                        spinning={
                            userSlice.detailUser?.user.fetchStatus === StatePieceStatus.IsFetching ||
                            userSlice.roles.fetchStatus === StatePieceStatus.IsFetching
                        }
                    >
                        {isError(userSlice.detailUser?.user.fetchStatus) ? (
                            <FetchResult status={userSlice.detailUser?.user.fetchStatus} about={'User'} />
                        ) : (
                            <>
                                <Row>
                                    <Col span={24}>
                                        <Space>
                                            <label className="group-label">User Information</label>
                                        </Space>
                                    </Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={6}>
                                        <label className="property required">Initials</label>
                                        <Form.Item name="initials" rules={[{ required: true, message: "'Initials' is required" }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property required">Name</label>
                                        <Form.Item name="name" rules={[{ required: true, message: "'Name' is required" }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <label className="property required">Username</label>
                                        <Form.Item name="userPrincipalName" rules={[{ required: true, message: "'Username' is required" }]}>
                                            <Input placeholder="username@synthon.com" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={ROW_GUTTER}>
                                    <Col span={12}>
                                        <label className="property required">Roles</label>
                                        <Form.Item name="roles">
                                            <Select
                                                mode="multiple"
                                                allowClear
                                                showSearch
                                                placeholder="Select roles"
                                                filterOption={(input, option) =>
                                                    (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                                                }
                                            >
                                                {userSlice.roles.data.map((role) => (
                                                    <Select.Option key={role.id} value={role.id}>
                                                        {role.description}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Spin>
                </div>
            </Form>
        </React.Fragment>
    );
};
