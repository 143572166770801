import {
    BankOutlined,
    CopyOutlined,
    EditOutlined,
    DownloadOutlined,
    PlusCircleOutlined,
    RightOutlined,
    SearchOutlined,
    UndoOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    FileDoneOutlined,
    EllipsisOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Input, InputRef, MenuProps, Space, Table, TableColumnsType, Tooltip, App as AntApp } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { ColumnType, FilterConfirmProps, FilterValue, SorterResult } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { FeeStatuses, StatePieceStatus } from '../../../enums';
import { RowSelection } from '../../../interfaces';
import { IBeneficiary } from '../../../interfaces/beneficiaries';
import { IFee, IFeeTableRow } from '../../../interfaces/fees';
import { IInvoice } from '../../../interfaces/invoices';
import { ITableFilter } from '../../../interfaces/ITableFilter';
import { IBillableOption } from '../../../interfaces/lookup/billable/data';
import { ICurrency } from '../../../interfaces/lookup/currency/data';
import { IFeeKind } from '../../../interfaces/lookup/feeKind/data';
import { IFeeStatus } from '../../../interfaces/lookup/feeStatus/data';
import { IMaHolder } from '../../../interfaces/lookup/maHolder/data';
import { IUser } from '../../../interfaces/users/data';
import { ContentHeader } from '../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { SearchBar } from '../../../shared/components/filters/SearchBar';
import { HighlightedText } from '../../../shared/components/highlightedText/HighlightedText';
import { Unauthorized } from '../../../shared/components/unauthorized/Unauthorized';
import { fee_isEditable, generateReturnUrl } from '../../../shared/helpers';
import { formatAsCurrency } from '../../../shared/helpers/formatHelper';
import { getFilteringValues, QueryParamHelpers, tableHasFiltersActive, updateQueryParams } from '../../../shared/helpers/QueryParamHelpers';
import { calculateOverviewTableHeight } from '../../../shared/helpers/TableHeightHelper';
import { AppDispatch, RootState } from '../../../store';
import { fetchMe } from '../../../store/appSlice/AppActions';
import { deleteFeesBatch, fetchFeeExportData, fetchFees, generatePaymentFile } from '../../../store/feeSlice/FeeActions';
import { resetState, toggleAddInvoiceModal, toggleDuplicateFeesModal } from '../../../store/feeSlice/FeeSlice';
import {
    fetchBeneficiariesLookupList,
    fetchBillableOptionsLookupList,
    fetchCategoriesAsLookupList,
    fetchCurrenciesLookupList,
    fetchFeeStatuses,
    fetchKindOfFeesLookupList,
    fetchUsersLookupList,
} from '../../../store/lookupSlice/LookupActions';
import { AddInvoiceModal } from '../components/addInvoiceModal/AddInvoiceModal';
import { FeeStatusChangeModal } from '../feeStatusChangeModal/FeeStatusChangeModal';
import { FeeDuplicateForm } from './components/FeeDuplicateForm/FeeDuplicateForm';
import './FeeOverview.less';

interface DataType {
    procedureCode: string;
    reasonOfPayment: string;
}

type DataIndex = keyof DataType;

export const FeeOverview = () => {
    const { modal } = AntApp.useApp();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const searchInput = useRef<InputRef>(null);
    const { appSlice, feeSlice, lookupSlice } = useSelector((state: RootState) => state);
    const [tableRowSelection, setTableRowSelection] = useState<RowSelection<IFeeTableRow>>({}); // Accross all pages
    const [selectedTableRecords, setSelectedTabelRecords] = useState<IFeeTableRow[]>([]); // Accumulated results from TableRowSelection
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]); // Accumulated results from TableRowSelection
    const [generatePaymentFileInvalidStatus, setGeneratePaymentFileInvalidStatus] = useState(false);
    const [statusChangerInvalidStatus, setStatusChangerInvalidStatus] = useState(false);
    const [statusChangerVisible, setStatusChangerVisible] = useState(false);
    const [tableRenderHeight, setTableRenderHeight] = useState<number>();
    const [procedureCodeSearchText, setProcedureCodeSearchText] = useState('');
    const [reasonOfPaymentSearchText, setReasonOfPaymentSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [columns, setColumns] = useState<ColumnsType<IFeeTableRow>>([]);

    const [filtering, setFiltering] = useState<ITableFilter>(
        getFilteringValues(window.location.search, columns, feeSlice.fees.data.paging.totalItems)
    );

    const getColumnSearchProps = (dataIndex: DataIndex, label: string): ColumnType<IFeeTableRow> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
            let searchText = dataIndex === 'procedureCode' ? procedureCodeSearchText : reasonOfPaymentSearchText;
            let currentInputValue = searchInput.current?.input?.value || undefined;
            selectedKeys = currentInputValue !== undefined ? [searchText] : [];

            return (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        ref={searchInput}
                        // Required to be this dynamic to force React to rerender this input (no changes are noticed because we use it uncontrolled (defaultValue)).
                        // Making this a controlled component is not feasible due to the amount of rerenders otherwise, the user will experience input lag.
                        key={searchText}
                        placeholder={`Search ${label}`}
                        defaultValue={searchText}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                    </Space>
                </div>
            );
        },
        filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => {
            return record && record[dataIndex]
                ? //@ts-ignore
                  record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes((value as string).toLowerCase())
                : false;
        },
        onFilterDropdownOpenChange(open) {
            if (open) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[dataIndex === 'procedureCode' ? procedureCodeSearchText : reasonOfPaymentSearchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const resetFilters = () => {
        setFiltering({});
        setProcedureCodeSearchText('');
        setReasonOfPaymentSearchText('');
        navigate({ search: '' }, { replace: true });
    };

    const updateQuery = (
        filters: Record<string, FilterValue | null> | undefined,
        sorter: SorterResult<IFeeTableRow> | undefined,
        searchQuery: string,
        pagination: TablePaginationConfig | undefined
    ) => {
        setFiltering({ ...filtering, _sorter: sorter, filters: filters, searchQuery: searchQuery, pagination: pagination });
        let newQueryUrl = updateQueryParams(filters, sorter, searchQuery, pagination);
        navigate({ search: newQueryUrl }, { replace: true });
    };

    const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => {
        //if we update a searchbox of procedureCode to having NO value we need to trigger the filtering
        //as AntDesign confirm() prevents a filtering update because there is no value.
        if (dataIndex === 'procedureCode' && selectedKeys[0] === undefined) {
            setFiltering({
                ...filtering,
                filters: {
                    ...filtering.filters,
                    procedureCode: null,
                },
            });
        } else if (dataIndex === 'reasonOfPayment' && selectedKeys[0] === undefined) {
            setFiltering({
                ...filtering,
                filters: {
                    ...filtering.filters,
                    reasonOfPayment: null,
                },
            });
        } else {
            if (dataIndex === 'procedureCode') {
                setProcedureCodeSearchText(selectedKeys[0]);
            }
            if (dataIndex === 'reasonOfPayment') {
                setReasonOfPaymentSearchText(selectedKeys[0]);
            }
            setSearchedColumn(dataIndex);
        }
        confirm();
    };

    const checkForInvalidPaymentStatus = (selectedRowKeys: React.Key[]) => {
        // We retrieve the item from the store here since it can be updated in the meanwhile, while the local state hasn't updated.
        // This occurs when the status is changed.
        let accumulatedSelectedRecords = feeSlice.fees.data.items.filter((r) => selectedRowKeys.indexOf(r.id.toString()) !== -1);

        let hasInvalidStatuses = false;
        accumulatedSelectedRecords.forEach((record) => {
            if (record.status.code !== FeeStatuses.ApprovedForPayment) {
                hasInvalidStatuses = true;
            }
        });

        setGeneratePaymentFileInvalidStatus(hasInvalidStatuses);
    };

    const checkForInvalidStatuses = (selectedRowKeys: React.Key[]) => {
        // We retrieve the item from the store here since it can be updated in the meanwhile, while the local state hasn't updated.
        // This occurs when the status is changed.
        let accumulatedSelectedRecords = feeSlice.fees.data.items.filter((r) => selectedRowKeys.indexOf(r.id.toString()) !== -1);

        let hasInvalidStatuses = false;
        accumulatedSelectedRecords.forEach((record) => {
            if (!fee_isEditable(record)) {
                hasInvalidStatuses = true;
            }
        });
        setStatusChangerInvalidStatus(hasInvalidStatuses);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[], selectedRows: IFeeTableRow[]) => {
            // #region determine the new tableRowSelection and selection keys
            const currentPage = filtering.pagination?.current || 1;
            let newSelectedTableRecordsObj = { ...tableRowSelection };
            if (selectedRows.length === 0) {
                delete newSelectedTableRecordsObj[currentPage];
            } else {
                newSelectedTableRecordsObj[currentPage] = selectedRows;
            }
            setTableRowSelection(newSelectedTableRecordsObj);

            let accumulatedSelectedRecords = [] as IFeeTableRow[];
            Object.keys(newSelectedTableRecordsObj).forEach((key: string) => {
                accumulatedSelectedRecords = [...accumulatedSelectedRecords, ...newSelectedTableRecordsObj[key]];
            });
            setSelectedTabelRecords(accumulatedSelectedRecords);
            const accumulatedSelectedKeys = accumulatedSelectedRecords.map((r) => r.id.toString());
            setSelectedRowKeys(accumulatedSelectedKeys);
            // #endregion

            checkForInvalidPaymentStatus(accumulatedSelectedKeys);
            checkForInvalidStatuses(accumulatedSelectedKeys);
        },
    };

    const expandedRowRender = (record: IFee) => {
        const columns: TableColumnsType<any> = [
            {
                title: 'Invoice Id',
                dataIndex: 'invoiceId',
                key: 'key',
                render: (_, invoice: IInvoice) => <span>{invoice.id.toString()}</span>,
            },
            {
                title: 'Created date',
                dataIndex: 'createdDate',
                key: 'createdDate',

                render: (text: string) => <>{text ? dayjs(text).format('DD-MM-YYYY') : '-'}</>,
            },
            {
                title: 'Customer',
                dataIndex: 'customer',
                key: 'customer',
                render: (_, invoice: IInvoice) => <span>{invoice.customer.name}</span>,
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                render: (_, invoice: IInvoice) => <span>{formatAsCurrency(invoice.amount)}</span>,
            },
            {
                title: 'Invoice status',
                dataIndex: 'status',
                key: 'status',
                render: (_, invoice: IInvoice) => (
                    <>
                        {invoice.status && invoice.status.code && (
                            <Tooltip title={invoice.status.description}>{invoice.status.code}</Tooltip>
                        )}
                    </>
                ),
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                width: 65,
                fixed: 'right',
                render: (_, record) => (
                    <Space size="middle">
                        <Button type="text" icon={<RightOutlined />} onClick={() => navigate(`/invoices/${record.id}`)} />
                    </Space>
                ),
            },
        ];

        return (
            <Table
                columns={columns}
                dataSource={record.invoices.map((invoice) => {
                    return {
                        ...invoice,
                        key: invoice.id,
                    };
                })}
                pagination={false}
                size="small"
                bordered={true}
            />
        );
    };

    const fetchPageFees = () => {
        const sorter = filtering._sorter as SorterResult<IFeeTableRow[]>;

        dispatch(
            fetchFees({
                pageSize: filtering.pagination?.pageSize,
                pageNumber: filtering.pagination?.current,
                sorter,
                statusCode: (filtering.filters?.status as string[]) || undefined,
                kindOfFee: (filtering.filters?.kindOfFee as string[]) || undefined,
                billableOptionId: filtering.filters ? (filtering.filters['billableOption'] as string[]) : undefined,
                projectId: filtering.filters ? (filtering.filters['project.code'] as string[]) : undefined,
                regionId: filtering.filters ? (filtering.filters['region.code'] as string[]) : undefined,
                maHolderId: filtering.filters ? (filtering.filters['maHolder'] as string[]) : undefined,
                createdById: filtering.filters ? (filtering.filters['createdByUser'] as string[]) : undefined,
                currenyCode: filtering.filters ? (filtering.filters['currency'] as string[]) : undefined,
                beneficiaryId: filtering.filters ? (filtering.filters['beneficiary'] as string[]) : undefined,
                categoryId: filtering.filters ? (filtering.filters['fee.category'] as string[]) : undefined,
                procedureCode:
                    filtering.filters && filtering.filters['procedureCode'] && filtering.filters['procedureCode']?.length > 0
                        ? (filtering.filters['procedureCode'][0] as string)
                        : undefined,
                reasonOfPayment:
                    filtering.filters && filtering.filters['reasonOfPayment'] && filtering.filters['reasonOfPayment']?.length > 0
                        ? (filtering.filters['reasonOfPayment'][0] as string)
                        : undefined,
                searchQuery: filtering.searchQuery,
            })
        );
    };

    useEffect(() => {
        let renderTableHeight = calculateOverviewTableHeight();
        setTableRenderHeight(renderTableHeight);
        dispatch(fetchBeneficiariesLookupList());
        dispatch(fetchUsersLookupList());
        dispatch(fetchFeeStatuses());
        dispatch(fetchKindOfFeesLookupList());
        dispatch(fetchCurrenciesLookupList());
        dispatch(fetchBillableOptionsLookupList());
        dispatch(fetchCategoriesAsLookupList());

        // Fixes occasional issue with me being null
        if (appSlice.me.info.data === null && appSlice.me.info.fetchStatus !== StatePieceStatus.IsFetching) {
            dispatch(fetchMe());
        }

        return () => {
            dispatch(resetState());
        };
    }, []);

    /**
     * Create a useEffect based on the params. If the param changes to blanco and the properties
     * '_sorter' or 'filters' exists on state 'filtering' then the user navigated back to this page
     * from the left main menu.
     *
     * If '_sorter' or 'filters' does not exists the user was already on this page without any filters
     * or sortings active.
     */
    useEffect(() => {
        if (window.location.search === '' && (filtering._sorter || filtering.filters)) {
            resetFilters();
        }
    }, [window.location.search]);

    useEffect(() => {
        //onLoad set the searchText of procedure code if it's given
        if (filtering.filters && filtering.filters['procedureCode'] && filtering.filters['procedureCode']?.length > 0) {
            setProcedureCodeSearchText(filtering.filters['procedureCode'][0].toString());
        }
        if (filtering.filters && filtering.filters['reasonOfPayment'] && filtering.filters['reasonOfPayment']?.length > 0) {
            setReasonOfPaymentSearchText(filtering.filters['reasonOfPayment'][0].toString());
        }

        setColumns([
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
                fixed: 'left',
                width: 80,
                sorter: true,
                filteredValue: null,
                sortOrder:
                    (filtering._sorter as SorterResult<IFeeTableRow>)?.columnKey === 'id'
                        ? (filtering._sorter as SorterResult<IFeeTableRow>)?.order
                        : undefined,
                render: (_, record) => (
                    <span>
                        <HighlightedText text={record.id.toString() || ''} textToHighlight={filtering.searchQuery} />
                    </span>
                ),
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                fixed: 'left',
                width: 100,
                render: (status: IFeeStatus) => <>{status && status.code && <Tooltip title={status.description}>{status.code}</Tooltip>}</>,
                filterSearch: (input: string, record: any) => record.text.toLowerCase().startsWith(input.toLowerCase()),
                filters: lookupSlice.feeStatuses.data.map((s) => ({ text: s.code, value: s.code })) || undefined,
                filteredValue: filtering.filters?.status || null,
            },
            {
                title: 'By',
                dataIndex: 'createdByUser',
                key: 'createdByUser',
                sorter: false,
                width: 90,
                filterSearch: (input: string, record: any) => record.text.toLowerCase().startsWith(input.toLowerCase()),
                filters: [
                    ...lookupSlice.users.data.filter((b) => !b.isDeleted).map((s) => ({ text: s.value || '', value: s.key || '' })),
                    {
                        text: 'Removed users',
                        value: '',
                        children: lookupSlice.users.data
                            .filter((b) => b.isDeleted)
                            .map((s) => ({ text: s.value || '', value: s.key || '' })),
                    },
                ],
                filteredValue: filtering.filters?.createdByUser || null,
                render: (createdByUser: IUser) => (
                    <>{createdByUser && createdByUser.initials && <Tooltip title={createdByUser.name}>{createdByUser.initials}</Tooltip>}</>
                ),
            },
            {
                title: 'Project',
                dataIndex: 'project.code',
                key: 'project.code',
                sorter: false,
                ellipsis: {
                    showTitle: true,
                },
                width: 110,
                render: (_, record) => (
                    <span>
                        <Tooltip title={record.project?.description}>
                            <span>
                                <HighlightedText text={record.project?.code?.toString() || ''} textToHighlight={filtering.searchQuery} />
                            </span>
                        </Tooltip>
                    </span>
                ),
                filterSearch: (input: string, record: any) => record.text.toLowerCase().startsWith(input.toLowerCase()),
                filteredValue: (filtering.filters && filtering.filters['project.code']) || null,
                filters: [
                    ...lookupSlice.projects.lookup.data
                        .filter((b) => !b.isDeleted)
                        .map((s) => ({ text: s.value || '', value: s.key || '' })),
                    {
                        text: 'Removed projects',
                        value: '',
                        children: lookupSlice.projects.lookup.data
                            .filter((b) => b.isDeleted)
                            .map((s) => ({ text: s.value || '', value: s.key || '' })),
                    },
                ],
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                sorter: true,
                sortOrder:
                    (filtering._sorter as SorterResult<IFeeTableRow>)?.columnKey === 'amount'
                        ? (filtering._sorter as SorterResult<IFeeTableRow>)?.order
                        : undefined,
                width: 110,
                filteredValue: null,
                render: (_, record) => (
                    <span>
                        <HighlightedText text={formatAsCurrency(record.amount || 0) || ''} textToHighlight={filtering.searchQuery} />
                    </span>
                ),
            },
            {
                title: 'Currency',
                dataIndex: 'currency',
                key: 'currency',
                filterSearch: (input: string, record: any) => record.text.toLowerCase().startsWith(input.toLowerCase()),
                width: 120,
                filters: lookupSlice.currencies.lookup.data.map((s) => ({ text: s.description, value: s.value || s.key })) || undefined,
                filteredValue: filtering.filters?.currency || null,
                render: (currency: ICurrency) => (
                    <>{currency && currency.code && <Tooltip title={currency.description}>{currency.code.toUpperCase()}</Tooltip>}</>
                ),
            },
            {
                title: 'Amount €',
                dataIndex: 'amountInEur',
                key: 'amountInEur',
                sorter: true,
                sortOrder:
                    (filtering._sorter as SorterResult<IFeeTableRow>)?.columnKey === 'amountInEur'
                        ? (filtering._sorter as SorterResult<IFeeTableRow>)?.order
                        : undefined,
                width: 110,
                filteredValue: null,
                render: (_, record) => (
                    <span>
                        <HighlightedText text={formatAsCurrency(record.amountInEur || 0) || ''} textToHighlight={filtering.searchQuery} />
                    </span>
                ),
            },
            {
                title: 'Payment date',
                dataIndex: 'paymentDate',
                key: 'paymentDate',
                sorter: true,
                sortOrder:
                    (filtering._sorter as SorterResult<IFeeTableRow>)?.columnKey === 'paymentDate'
                        ? (filtering._sorter as SorterResult<IFeeTableRow>)?.order
                        : undefined,
                width: 150,
                filteredValue: null,
                ellipsis: {
                    showTitle: true,
                },
                render: (text: string) => <>{text ? dayjs(text).format('DD-MM-YYYY') : ''}</>,
            },
            {
                title: 'Beneficiary',
                dataIndex: 'beneficiary',
                key: 'beneficiary',
                sorter: true,
                sortOrder:
                    (filtering._sorter as SorterResult<IFeeTableRow>)?.columnKey === 'beneficiary'
                        ? (filtering._sorter as SorterResult<IFeeTableRow>)?.order
                        : undefined,
                ellipsis: {
                    showTitle: true,
                },
                filterSearch: (input: string, record: any) => record.text.toLowerCase().startsWith(input.toLowerCase()),
                filters: [
                    ...lookupSlice.beneficiaries.data.filter((b) => !b.isDeleted).map((s) => ({ text: s.value || '', value: s.key || '' })),
                    {
                        text: 'Removed beneficiaries',
                        value: '',
                        children: lookupSlice.beneficiaries.data
                            .filter((b) => b.isDeleted)
                            .map((s) => ({ text: s.value || '', value: s.key || '' })),
                    },
                ],
                filteredValue: filtering.filters?.beneficiary || null,
                render: (beneficiary: IBeneficiary) => (
                    <Tooltip title={beneficiary.name}>
                        <span>
                            <HighlightedText text={beneficiary.searchName || ''} textToHighlight={filtering.searchQuery} />
                        </span>
                    </Tooltip>
                ),
            },
            {
                title: 'Procedure code',
                dataIndex: 'procedureCode',
                key: 'procedureCode',
                ellipsis: {
                    showTitle: true,
                },
                filteredValue: null,
                ...getColumnSearchProps('procedureCode', 'Procedure code'),
                render: (_, record) => (
                    <Tooltip title={record.procedureCode}>
                        <span>
                            <HighlightedText text={record.procedureCode || ''} textToHighlight={filtering.searchQuery} />
                        </span>
                    </Tooltip>
                ),
            },
            {
                title: 'Brand name',
                dataIndex: 'brandName',
                key: 'brandName',
                sorter: false,
                ellipsis: {
                    showTitle: true,
                },
                filteredValue: null,
                render: (brandName: string) => (
                    <Tooltip title={brandName}>
                        <span>
                            <HighlightedText text={brandName || ''} textToHighlight={filtering.searchQuery} />
                        </span>
                    </Tooltip>
                ),
            },
            {
                title: 'MA holder',
                dataIndex: 'maHolder',
                key: 'maHolder',
                sorter: false,
                width: 150,
                ellipsis: {
                    showTitle: true,
                },
                render: (maHolder: IMaHolder) => (
                    <Tooltip title={maHolder.description}>
                        <span>
                            <HighlightedText text={maHolder.description || ''} textToHighlight={filtering.searchQuery} />
                        </span>
                    </Tooltip>
                ),
                filterSearch: (input: string, record: any) => record.text.toLowerCase().startsWith(input.toLowerCase()),
                filteredValue: filtering.filters?.maHolder || null,
                filters: [
                    ...lookupSlice.maHolders.lookup.data
                        .filter((b) => !b.isDeleted)
                        .map((s) => ({ text: s.value || '', value: s.key || '' })),
                    {
                        text: 'Removed MA holders',
                        value: '',
                        children: lookupSlice.maHolders.lookup.data
                            .filter((b) => b.isDeleted)
                            .map((s) => ({ text: s.value || '', value: s.key || '' })),
                    },
                ],
            },
            {
                title: 'Reason of payment',
                dataIndex: 'reasonOfPayment',
                key: 'reasonOfPayment',
                sorter: false,
                ellipsis: {
                    showTitle: true,
                },
                filteredValue: null,
                ...getColumnSearchProps('reasonOfPayment', 'Reason of payment'),
                render: (_, record) => (
                    <Tooltip title={record.reasonOfPayment}>
                        <span>
                            <HighlightedText text={record.reasonOfPayment || ''} textToHighlight={filtering.searchQuery} />
                        </span>
                    </Tooltip>
                ),
            },
            {
                title: 'Type',
                dataIndex: 'kindOfFee',
                key: 'kindOfFee',
                width: 80,
                render: (kindOfFee: IFeeKind) => (
                    <>{kindOfFee && kindOfFee.code && <Tooltip title={kindOfFee.description}>{kindOfFee.code}</Tooltip>}</>
                ),
                filterSearch: (input: string, record: any) => record.text.toLowerCase().startsWith(input.toLowerCase()),
                filters: lookupSlice.kindOfFees.lookup.data.map((s) => ({ text: s.description || '', value: s.value || '' })) || undefined,
                filteredValue: filtering.filters?.kindOfFee || null,
            },
            {
                title: 'Region',
                dataIndex: 'region.code',
                key: 'region.code',
                sorter: false,
                width: 100,
                render: (_, record) => (
                    <>
                        {record.region && (
                            <Tooltip title={record.region.description}>
                                <span>
                                    <HighlightedText text={record.region.code?.toString() || ''} textToHighlight={filtering.searchQuery} />
                                </span>
                            </Tooltip>
                        )}
                    </>
                ),
                filterSearch: (input: string, record: any) => record.text.toLowerCase().startsWith(input.toLowerCase()),
                filters: lookupSlice.regions.lookup.data.map((s) => ({ text: s.description, value: s.key })) || undefined,
                filteredValue: (filtering.filters && filtering.filters['region.code']) || null,
            },
            {
                title: 'Billable',
                dataIndex: 'billableOption',
                key: 'billableOption',
                filterSearch: (input: string, record: any) => record.text.toLowerCase().startsWith(input.toLowerCase()),
                width: 100,
                filters:
                    lookupSlice.billableOptions.lookup.data.map((s) => ({ text: s.description || '', value: s.key || '' })) || undefined,
                render: (option: IBillableOption) => <> {option && option.description && option.description}</>,
                filteredValue: filtering.filters?.billableOption || null,
            },
            {
                title: 'Category',
                dataIndex: 'fee.category',
                key: 'fee.category',
                sorter: false,
                width: 120,
                render: (_, record) => (
                    <Tooltip title={record.category?.description}>
                        <span>
                            <HighlightedText text={record.category?.code || ''} textToHighlight={filtering.searchQuery} />
                        </span>
                    </Tooltip>
                ),
                filterSearch: (input: string, record: any) => record.text.toLowerCase().startsWith(input.toLowerCase()),
                filters: lookupSlice.categories.lookup.data.map((s) => ({ text: s.description || '', value: s.key || '' })) || undefined,
                filteredValue: (filtering.filters && filtering.filters['fee.category']) || null,
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                width: 65,
                fixed: 'right',
                filteredValue: null,
                render: (_, record) => (
                    <Space size="middle">
                        <Button type="text" icon={<RightOutlined />} onClick={() => navigate(`${generateReturnUrl(`${record.id}`)}`)} />
                    </Space>
                ),
            },
        ]);
    }, [feeSlice.fees.data, filtering, reasonOfPaymentSearchText, procedureCodeSearchText]);

    useEffect(() => {
        fetchPageFees();
    }, [filtering]);

    useEffect(() => {
        if (feeSlice.fees.createStatus === StatePieceStatus.Success || feeSlice.deleteFeesBatchStatus === StatePieceStatus.Success) {
            fetchPageFees();
            setSelectedTabelRecords([]);
            setSelectedRowKeys([]);
            setTableRowSelection({});
        }
    }, [feeSlice.fees.createStatus, feeSlice.deleteFeesBatchStatus]);

    useEffect(() => {
        if (feeSlice.updateFeeStatusesStatus === StatePieceStatus.Success) {
            checkForInvalidStatuses(selectedRowKeys);
            checkForInvalidPaymentStatus(selectedRowKeys);
        }
    }, [feeSlice.updateFeeStatusesStatus]);

    useEffect(() => {
        if (feeSlice.generatePaymentStatus === StatePieceStatus.Success) {
            checkForInvalidPaymentStatus(selectedRowKeys);
            checkForInvalidStatuses(selectedRowKeys);
        }
    }, [feeSlice.generatePaymentStatus]);
    /**
     * We use a random key attribute to force a re-render on the component. This is required
     * because we cannot update the defaultValue of the AntDesign's search input component. We need
     * the ability to clear the value for resetting.
     *
     * See more: https://stackoverflow.com/questions/30626030/can-you-force-a-react-component-to-rerender-without-calling-setstate
     * @returns
     */
    const renderSearchBar = () => {
        return (
            <SearchBar
                key={Math.random()}
                loading={feeSlice.fees.fetchStatus === StatePieceStatus.IsFetching}
                placeholder="Search in fees..."
                onSearch={(value) => {
                    updateQuery(
                        { ...filtering.filters },
                        { ...(filtering._sorter as SorterResult<IFeeTableRow>) },
                        value,
                        filtering.pagination
                    );
                }}
                defaultValue={filtering?.searchQuery}
            />
        );
    };

    const getSelectedRecordsMenuItems = (): MenuProps => {
        const menuProps: MenuProps = { items: [] };

        if (appSlice.auth.fees.createPayFile) {
            menuProps.items!.push({
                key: '1',
                icon: <BankOutlined />,
                onClick: () => dispatch(generatePaymentFile(selectedTableRecords.map((i) => i.id))),
                disabled: generatePaymentFileInvalidStatus || feeSlice.generatePaymentStatus === StatePieceStatus.IsFetching,
                label: (
                    <Tooltip
                        placement="rightTop"
                        title={`Create payment file. ${
                            generatePaymentFileInvalidStatus
                                ? 'You have selected a fee with a status that is not authorized to create a payment file.'
                                : ''
                        }`}
                    >
                        Create payment file.
                    </Tooltip>
                ),
            });
        }

        if (appSlice.auth.fees.view) {
            menuProps.items!.push({
                key: '2',
                icon: <DownloadOutlined />,
                onClick: () => dispatch(fetchFeeExportData(selectedTableRecords.map((i) => i.id))),
                disabled: feeSlice.exportFeesStatus === StatePieceStatus.IsFetching,
                label: <>{`Export ${selectedTableRecords.length} ${selectedTableRecords.length > 1 ? 'fees' : 'fee'}`}</>,
            });
        }

        if (appSlice.auth.fees.create) {
            menuProps.items!.push({
                key: '3',
                icon: <CopyOutlined />,
                onClick: () => dispatch(toggleDuplicateFeesModal()),
                disabled: feeSlice.exportFeesStatus === StatePieceStatus.IsFetching,
                label: <>{` Duplicate ${selectedTableRecords.length} ${selectedTableRecords.length > 1 ? 'fees' : 'fee'}`}</>,
            });
        }
        if (appSlice.auth.fees.delete) {
            menuProps.items!.push({
                key: '4',
                icon: <DeleteOutlined />,
                onClick: () =>
                    modal.confirm({
                        title: `Delete ${selectedTableRecords.length} ${selectedTableRecords.length > 1 ? 'fees' : 'fee'}`,
                        icon: <ExclamationCircleOutlined />,
                        content: "Are you sure you want to delete the selected fees? This action can't be undone.",
                        okText: 'Confirm',
                        cancelText: 'Cancel',
                        onOk: () => {
                            dispatch(deleteFeesBatch(selectedTableRecords.map((x) => x.id)));
                        },
                    }),
                disabled: appSlice.me.info.data === null || feeSlice.deleteFeesBatchStatus === StatePieceStatus.IsFetching,
                label: <>{`Delete ${selectedTableRecords.length} ${selectedTableRecords.length > 1 ? 'fees' : 'fee'}`}</>,
            });
        }

        return menuProps;
    };

    if (appSlice.auth.validationCompleted && !appSlice.auth.fees.view) {
        return <Unauthorized />;
    }

    return (
        <React.Fragment>
            <ContentHeader
                title="Fees"
                breadcrumbs={[
                    {
                        breadcrumbName: 'Fees',
                        path: '/fees',
                    },
                ]}
            >
                <Space>
                    {selectedTableRecords.length > 0 && (
                        <>
                            <Dropdown placement="bottomRight" menu={getSelectedRecordsMenuItems()}>
                                <Button icon={<EllipsisOutlined />} />
                            </Dropdown>

                            {appSlice.auth.invoices.create && (
                                <Tooltip
                                    title={`Create invoice. ${
                                        selectedTableRecords.length > 1
                                            ? 'You have selected to many records. Please select only one Fee.'
                                            : ''
                                    }`}
                                >
                                    <Button
                                        icon={<FileDoneOutlined />}
                                        onClick={() => dispatch(toggleAddInvoiceModal())}
                                        loading={feeSlice.generatePaymentStatus === StatePieceStatus.IsFetching}
                                        disabled={selectedTableRecords.length !== 1}
                                    />
                                </Tooltip>
                            )}

                            {appSlice.auth.fees.changeStatus && (
                                <Tooltip
                                    title={
                                        statusChangerInvalidStatus && !appSlice.auth.admin.view
                                            ? 'You have selected a fee with a status that you are not authorized to change.'
                                            : undefined
                                    }
                                >
                                    <Button
                                        icon={<UndoOutlined />}
                                        onClick={() => setStatusChangerVisible(true)}
                                        loading={appSlice.me.info.data === null}
                                        disabled={statusChangerInvalidStatus && !appSlice.auth.admin.view}
                                    >
                                        Change status for {selectedTableRecords.length} {selectedTableRecords.length > 1 ? 'fees' : 'fee'}
                                    </Button>
                                </Tooltip>
                            )}

                            {appSlice.auth.fees.edit && (
                                <Button
                                    type="primary"
                                    icon={<EditOutlined />}
                                    onClick={() => {
                                        var returnUrl = generateReturnUrl(`${selectedTableRecords[0].id}/edit`);
                                        returnUrl = QueryParamHelpers.appendEidsQueryParam(returnUrl, selectedTableRecords);
                                        navigate(returnUrl);
                                    }}
                                >
                                    Edit {selectedTableRecords.length} {selectedTableRecords.length > 1 ? 'fees' : 'fee'}
                                </Button>
                            )}
                        </>
                    )}

                    {appSlice.auth.fees.create && (
                        <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => navigate(`add`)}>
                            Add new fee
                        </Button>
                    )}
                </Space>
            </ContentHeader>
            <div className="page-content">
                <div className="searchbar-wrapper">
                    {renderSearchBar()}

                    {tableHasFiltersActive(filtering) && (
                        <Button onClick={() => resetFilters()} className="reset-filters-button">
                            Reset filters
                        </Button>
                    )}
                </div>

                <Table
                    size="small"
                    bordered={true}
                    scroll={{ x: 2210, y: tableRenderHeight }}
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                    }}
                    expandable={{
                        expandedRowRender,
                        rowExpandable: (record: IFeeTableRow) => record.invoices && record.invoices.length > 0,
                    }}
                    loading={
                        feeSlice.fees.fetchStatus === StatePieceStatus.IsFetching ||
                        feeSlice.deleteFeesBatchStatus === StatePieceStatus.IsFetching
                    }
                    columns={columns}
                    dataSource={
                        // Map fees to an array of IFeeTableRow
                        feeSlice.fees.data.items.map((i) => {
                            return { ...i, key: i.id.toString() } as IFeeTableRow;
                        })
                    }
                    pagination={{
                        current: feeSlice.fees.data.paging.pageNumber,
                        pageSize: feeSlice.fees.data.paging.pageSize,
                        total: feeSlice.fees.data.paging.totalItems,
                        showSizeChanger: selectedTableRecords.length === 0,
                    }}
                    onChange={(pagination, filters, _sorter) => {
                        updateQuery(filters, _sorter as SorterResult<IFeeTableRow>, filtering.searchQuery || '', pagination);
                    }}
                />
            </div>
            <FeeStatusChangeModal fees={selectedTableRecords} open={statusChangerVisible} onCancel={() => setStatusChangerVisible(false)} />
            <FeeDuplicateForm selectedTableRecords={selectedTableRecords} countSelectedTableRecords={selectedTableRecords.length} />
            <AddInvoiceModal
                isOpen={feeSlice.detailFee.addInvoiceModalVisible}
                defaultSelectedFeeId={selectedTableRecords.length > 0 ? selectedTableRecords[0].id : undefined}
                defaultAmount={selectedTableRecords.length > 0 ? selectedTableRecords[0].amountInEur : undefined}
                reasonOfPayment={selectedTableRecords.length > 0 ? selectedTableRecords[0].reasonOfPayment : undefined}
            />
        </React.Fragment>
    );
};
