// Import styles
import './App.less';
import { AppSkeleton } from '../shared/components/appSkeleton/AppSkeleton';
import { Route, Routes, useNavigate } from 'react-router';
import { Profile } from './profile/Profile';
import { Fees } from './fees/Fees';
import { Invoices } from './invoices/Invoices';
import { Beneficiaries } from './beneficiaries/Beneficiaries';
import { useEffect } from 'react';
import { NotFound } from './notFound/NotFound';
import { Admin } from './admin/Admin';
import { Customers } from './customers/Customers';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { setAuthorisation } from '../store/appSlice/AppSlice';
import { Documents } from './documents/Documents';
import { fetchMe, fetchMeGraph, fetchMyPhoto } from '../store/appSlice/AppActions';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.locale('en-gb');

var localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

dayjs.extend(relativeTime);

export const App = () => {
    const { appSlice } = useSelector((state: RootState) => state);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    /**
     * OnInit
     */
    useEffect(() => {
        // When the user reaches the '/' path, navigate them to '/fees'
        if (window.location.pathname === '/') {
            navigate('fees');
        }

        dispatch(fetchMe());
        dispatch(fetchMeGraph());
        dispatch(fetchMyPhoto());
    }, []);

    useEffect(() => {
        if (appSlice.me.info.data) {
            dispatch(setAuthorisation(appSlice.me.info.data));
        }
    }, [appSlice.me.info.data]);

    return (
        <div className="App">
            <AppSkeleton>
                <Routes>
                    <Route path="/profile" element={<Profile />} />
                    <Route path={'/fees/*'} element={<Fees />} />
                    <Route path={'/beneficiaries/*'} element={<Beneficiaries />} />
                    <Route path={'/invoices/*'} element={<Invoices />} />
                    <Route path={'/admin/*'} element={<Admin />} />
                    <Route path={'/customers/*'} element={<Customers />} />
                    <Route path={'/documents/*'} element={<Documents />} />
                    <Route path={'/*'} element={<NotFound />} />
                </Routes>
            </AppSkeleton>
        </div>
    );
};
