import { EditOutlined, PlusCircleOutlined, RightOutlined, UndoOutlined } from '@ant-design/icons';
import { Tooltip, Space, Button, Table } from 'antd';
import { ColumnsType, FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { StatePieceStatus } from '../../../enums';
import { ITableFilter, RowSelection } from '../../../interfaces';
import { IBeneficiaryTableRow } from '../../../interfaces/beneficiaries';
import { ContentHeader } from '../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { SearchBar } from '../../../shared/components/filters/SearchBar';
import { HighlightedText } from '../../../shared/components/highlightedText/HighlightedText';
import { Unauthorized } from '../../../shared/components/unauthorized/Unauthorized';
import { beneficiary_isEditable, generateReturnUrl } from '../../../shared/helpers';
import { getFilteringValues, QueryParamHelpers, tableHasFiltersActive, updateQueryParams } from '../../../shared/helpers/QueryParamHelpers';
import { calculateOverviewTableHeight } from '../../../shared/helpers/TableHeightHelper';
import { AppDispatch, RootState } from '../../../store';
import { fetchMe } from '../../../store/appSlice/AppActions';
import { fetchBeneficiaries } from '../../../store/beneficiarySlice/BeneficiaryActions';
import { resetState } from '../../../store/beneficiarySlice/BeneficiarySlice';
import { fetchBeneficiaryStatuses, fetchCountriesLookupList } from '../../../store/lookupSlice/LookupActions';
import { BeneficiarytatusChangeModal } from '../beneficiaryStatusChangeModal/BeneficiaryStatusChangeModal';

export const BeneficiariesOverview = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { appSlice, beneficiarySlice, lookupSlice } = useSelector((state: RootState) => state);
    const [tableRowSelection, setTableRowSelection] = useState<RowSelection<IBeneficiaryTableRow>>({}); // Accross all pages
    const [selectedTableRecords, setSelectedTabelRecords] = useState<IBeneficiaryTableRow[]>([]); // Accumulated results from TableRowSelection
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]); // Accumulated results from TableRowSelection
    const [statusChangerVisible, setStatusChangerVisible] = useState(false);
    const [statusChangerInvalidStatus, setStatusChangerInvalidStatus] = useState(false);
    const [tableRenderHeight, setTableRenderHeight] = useState<number>();
    const [columns, setColumns] = useState<ColumnsType<IBeneficiaryTableRow>>([]);

    const [filtering, setFiltering] = useState<ITableFilter>(
        getFilteringValues(window.location.search, columns, beneficiarySlice.beneficiaries.data.paging.totalItems)
    );

    const resetFilters = () => {
        setFiltering({});
        navigate({ search: '' }, { replace: true });
    };

    const updateQuery = (
        filters: Record<string, FilterValue | null> | undefined,
        sorter: SorterResult<IBeneficiaryTableRow> | undefined,
        searchQuery: string,
        pagination: TablePaginationConfig | undefined
    ) => {
        setFiltering({ ...filtering, _sorter: sorter, filters: filters, searchQuery: searchQuery, pagination: pagination });
        let newQueryUrl = updateQueryParams(filters, sorter, searchQuery, pagination);
        navigate({ search: newQueryUrl }, { replace: true });
    };

    const checkForInvalidStatuses = (selectedRowKeys: React.Key[]) => {
        // We retrieve the item from the store here since it can be updated in the meanwhile, while the local state hasn't updated.
        // This occurs when the status is changed.
        let accumulatedSelectedRecords = beneficiarySlice.beneficiaries.data.items.filter(
            (r) => selectedRowKeys.indexOf(r.id.toString()) !== -1
        );

        let hasInvalidStatuses = false;
        accumulatedSelectedRecords.forEach((record) => {
            if (!beneficiary_isEditable(record)) {
                hasInvalidStatuses = true;
            }
        });
        setStatusChangerInvalidStatus(hasInvalidStatuses);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (_selectedRowKeys: React.Key[], selectedRows: IBeneficiaryTableRow[]) => {
            // #region determine the new tableRowSelection and selection keys
            const currentPage = filtering.pagination?.current || 1;
            let newSelectedTableRecordsObj = { ...tableRowSelection };
            if (selectedRows.length === 0) {
                delete newSelectedTableRecordsObj[currentPage];
            } else {
                newSelectedTableRecordsObj[currentPage] = selectedRows;
            }
            setTableRowSelection(newSelectedTableRecordsObj);

            let accumulatedSelectedRecords = [] as IBeneficiaryTableRow[];
            Object.keys(newSelectedTableRecordsObj).forEach((key: string) => {
                accumulatedSelectedRecords = [...accumulatedSelectedRecords, ...newSelectedTableRecordsObj[key]];
            });
            setSelectedTabelRecords(accumulatedSelectedRecords);
            const accumulatedSelectedKeys = accumulatedSelectedRecords.map((r) => r.id.toString());
            setSelectedRowKeys(accumulatedSelectedKeys);
            // #endregion

            checkForInvalidStatuses(accumulatedSelectedKeys);
        },
        getCheckboxProps: (record: IBeneficiaryTableRow) => ({
            name: record.id.toString(),
        }),
    };

    /**
     * Create a useEffect based on the params. If the param changes to blanco and the properties
     * '_sorter' or 'filters' exists on state 'filtering' then the user navigated back to this page
     * from the left main menu.
     *
     * If '_sorter' or 'filters' does not exists the user was already on this page without any filters
     * or sortings active.
     */
    useEffect(() => {
        if (window.location.search === '' && (filtering._sorter || filtering.filters)) {
            resetFilters();
        }
    }, [window.location.search]);

    useEffect(() => {
        if (beneficiarySlice.updateBeneficiaryStatusesStatus === StatePieceStatus.Success) {
            checkForInvalidStatuses(selectedRowKeys);
        }
    }, [beneficiarySlice.updateBeneficiaryStatusesStatus]);

    /**
     * OnInit
     */
    useEffect(() => {
        let renderTableHeight = calculateOverviewTableHeight();
        setTableRenderHeight(renderTableHeight);

        dispatch(fetchCountriesLookupList());
        dispatch(fetchBeneficiaryStatuses());

        // Fixes occasional issue with me being null
        if (appSlice.me.info.data === null && appSlice.me.info.fetchStatus !== StatePieceStatus.IsFetching) {
            dispatch(fetchMe());
        }

        return () => {
            dispatch(resetState());
        };
    }, []);

    useEffect(() => {
        setColumns([
            {
                title: 'Beneficiary name',
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                sortOrder:
                    (filtering._sorter as SorterResult<IBeneficiaryTableRow>)?.columnKey === 'name'
                        ? (filtering._sorter as SorterResult<IBeneficiaryTableRow>)?.order
                        : undefined,
                fixed: 'left',
                filteredValue: null,
                render: (_, record) => (
                    <span>
                        <HighlightedText text={record.name || ''} textToHighlight={filtering.searchQuery} />
                    </span>
                ),
            },
            {
                title: 'Search name',
                dataIndex: 'searchName',
                key: 'searchName',
                sorter: true,
                sortOrder:
                    (filtering._sorter as SorterResult<IBeneficiaryTableRow>)?.columnKey === 'searchName'
                        ? (filtering._sorter as SorterResult<IBeneficiaryTableRow>)?.order
                        : undefined,
                fixed: 'left',
                filteredValue: null,
                render: (_, record) => (
                    <span>
                        <HighlightedText text={record.searchName || ''} textToHighlight={filtering.searchQuery} />
                    </span>
                ),
            },
            {
                title: 'Address',
                dataIndex: 'addressline1',
                key: 'addressline1',
                sorter: false,
                filteredValue: null,
                render: (text) => <HighlightedText text={text || ''} textToHighlight={filtering.searchQuery} />,
            },
            {
                title: 'Postal code',
                dataIndex: 'postalCode',
                key: 'postalCode',
                filteredValue: null,
                render: (text) => <HighlightedText text={text || ''} textToHighlight={filtering.searchQuery} />,
            },
            {
                title: 'Country',
                dataIndex: 'country',
                key: 'country',
                width: 100,
                sorter: false,
                render: (_, record) => (
                    <Tooltip title={record.country.description}>
                        <span>{record.country.code}</span>
                    </Tooltip>
                ),
                filters: lookupSlice.countries.lookup.data.map((s) => ({ text: s.description, value: s.key })) || undefined,
                filterSearch: (input: string, record: any) => record.text.toLowerCase().startsWith(input.toLowerCase()),
                filteredValue: filtering.filters?.country || null,
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                width: 100,
                render: (_, record) => (
                    <Tooltip title={record.status.description}>
                        <span>{record.status.code}</span>
                    </Tooltip>
                ),
                filters: lookupSlice.beneficiaryStatuses.data.map((s) => ({ text: s.code, value: s.id })) || undefined,
                filterSearch: (input: string, record: any) => record.text.toLowerCase().startsWith(input.toLowerCase()),
                filteredValue: filtering.filters?.status || null,
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                width: 65,
                fixed: 'right',
                filteredValue: null,
                render: (_, record) => (
                    <Space size="middle">
                        <Tooltip title="Beneficiary details">
                            <Button type="text" icon={<RightOutlined />} onClick={() => navigate(`${generateReturnUrl(`${record.id}`)}`)} />
                        </Tooltip>
                    </Space>
                ),
            },
        ]);
    }, [beneficiarySlice.beneficiaries.data, filtering]);

    useEffect(() => {
        const sorter = filtering._sorter as SorterResult<IBeneficiaryTableRow[]>;
        dispatch(
            fetchBeneficiaries({
                pageSize: filtering.pagination?.pageSize,
                pageNumber: filtering.pagination?.current,
                sorter,
                statusId: (filtering.filters?.status as string[]) || undefined,
                countryId: (filtering.filters?.country as string[]) || undefined,
                searchQuery: filtering.searchQuery,
            })
        );
    }, [filtering]);

    /**
     * We use a random key attribute to force a re-render on the component. This is required
     * because we cannot update the defaultValue of the AntDesign's search input component. We need
     * the ability to clear the value for resetting.
     *
     * See more: https://stackoverflow.com/questions/30626030/can-you-force-a-react-component-to-rerender-without-calling-setstate
     * @returns
     */
    const renderSearchBar = () => {
        return (
            <SearchBar
                key={Math.random()}
                loading={beneficiarySlice.beneficiaries.fetchStatus === StatePieceStatus.IsFetching}
                placeholder="Search beneficiaries by name, address, or postal code..."
                onSearch={(value) => {
                    updateQuery(
                        { ...filtering.filters },
                        { ...(filtering._sorter as SorterResult<IBeneficiaryTableRow>) },
                        value,
                        filtering.pagination
                    );
                }}
                defaultValue={filtering?.searchQuery}
            />
        );
    };

    if (appSlice.auth.validationCompleted && !appSlice.auth.beneficiaries.view) {
        return <Unauthorized />;
    }

    return (
        <React.Fragment>
            <ContentHeader
                title="BENEFICIARIES"
                breadcrumbs={[
                    {
                        breadcrumbName: 'Beneficiaries',
                        path: '/beneficiaries',
                    },
                ]}
            >
                <Space>
                    {selectedTableRecords.length > 0 && (
                        <>
                            {appSlice.auth.beneficiaries.changeStatus && (
                                <Tooltip
                                    title={
                                        statusChangerInvalidStatus && !appSlice.auth.admin.view
                                            ? 'You have selected a fee with a status that you are not authorized to change.'
                                            : undefined
                                    }
                                >
                                    <Button
                                        icon={<UndoOutlined />}
                                        onClick={() => setStatusChangerVisible(true)}
                                        loading={appSlice.me.info.data === null}
                                        disabled={statusChangerInvalidStatus && !appSlice.auth.admin.view}
                                    >
                                        Change status for {selectedTableRecords.length}{' '}
                                        {selectedTableRecords.length > 1 ? 'beneficiaries' : 'beneficiary'}
                                    </Button>
                                </Tooltip>
                            )}
                            {appSlice.auth.beneficiaries.edit && (
                                <Button
                                    type="primary"
                                    icon={<EditOutlined />}
                                    onClick={() => {
                                        var returnUrl = generateReturnUrl(`${selectedTableRecords[0].id}/edit`);
                                        returnUrl = QueryParamHelpers.appendEidsQueryParam(returnUrl, selectedTableRecords);
                                        navigate(returnUrl);
                                    }}
                                >
                                    Edit {selectedTableRecords.length} {selectedTableRecords.length > 1 ? 'beneficiaries' : 'beneficiary'}
                                </Button>
                            )}
                        </>
                    )}

                    {appSlice.auth.beneficiaries.create && (
                        <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => navigate('add')}>
                            Add new beneficiary
                        </Button>
                    )}
                </Space>
            </ContentHeader>

            <div className="page-content">
                <div className="searchbar-wrapper">
                    {renderSearchBar()}

                    {tableHasFiltersActive(filtering) && (
                        <Button onClick={() => resetFilters()} className="reset-filters-button">
                            Reset filters
                        </Button>
                    )}
                </div>

                <Table
                    size="small"
                    bordered={true}
                    scroll={{ x: true, y: tableRenderHeight }}
                    rowSelection={
                        appSlice.auth.beneficiaries.create || appSlice.auth.beneficiaries.edit || appSlice.auth.beneficiaries.changeStatus
                            ? {
                                  type: 'checkbox',
                                  ...rowSelection,
                              }
                            : undefined
                    }
                    loading={beneficiarySlice.beneficiaries.fetchStatus === StatePieceStatus.IsFetching}
                    dataSource={
                        // Map beneficiaries to an array of IBenaficiaryTableRow
                        beneficiarySlice.beneficiaries.data.items.map((i) => {
                            return { ...i, key: i.id.toString() } as IBeneficiaryTableRow;
                        })
                    }
                    pagination={{
                        current: beneficiarySlice.beneficiaries.data.paging.pageNumber,
                        pageSize: beneficiarySlice.beneficiaries.data.paging.pageSize,
                        total: beneficiarySlice.beneficiaries.data.paging.totalItems,
                        showSizeChanger: selectedTableRecords.length === 0,
                    }}
                    columns={columns}
                    onChange={(pagination, filters, _sorter) => {
                        updateQuery(filters, _sorter as SorterResult<IBeneficiaryTableRow>, filtering.searchQuery || '', pagination);
                    }}
                />
            </div>

            <BeneficiarytatusChangeModal
                beneficiaries={selectedTableRecords}
                open={statusChangerVisible}
                onCancel={() => setStatusChangerVisible(false)}
            />
        </React.Fragment>
    );
};
