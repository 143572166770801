import {
    ContactsOutlined,
    ControlOutlined,
    CreditCardOutlined,
    FilePdfOutlined,
    FileTextOutlined,
    IdcardOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import { Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { StatePieceStatus } from '../../../../enums';
import { RootState } from '../../../../store';
import { getActiveMenuItems } from '../../../helpers/NavigationHelpers';
import './AppMenu.less';

export const AppMenu = () => {
    const navigate = useNavigate();
    const { accounts } = useMsal();
    const { appSlice } = useSelector((state: RootState) => state);

    let profileLabel = 'PROFILE';
    if (accounts[0] !== undefined && accounts[0].name) {
        const splittedName = accounts[0].name.split(' ');
        profileLabel = splittedName[0];
    }

    const getUserProfilePicture = () => {
        if (appSlice.me.msGraph.fetchStatus === StatePieceStatus.Success && appSlice.me.msGraph.data?.photo) {
            return <div className="profile photo" style={{ backgroundImage: `url(${appSlice.me.msGraph.data.photo})` }}></div>;
        }

        return <UserOutlined className="profile" />;
    };

    /**
     * All the main menu items.
     *
     * Note: the key must be equal to the URL you're going
     * to push to the history. The function 'getActiveMenuItems'
     * is using the key-attribute to get- and set the active
     * menu items.
     */
    const menuItems: ItemType[] = [
        {
            key: 'profile',
            icon: getUserProfilePicture(),
            label: profileLabel,
            onClick: () => navigate('/profile', { replace: true }),
        },
        {
            type: 'divider',
        },
    ];

    if (appSlice.auth.validationCompleted && appSlice.auth.fees.view) {
        menuItems.push({
            key: 'fees',
            icon: <CreditCardOutlined />,
            label: 'Fees',
            onClick: () => navigate('/fees', { replace: true }),
        });
    }

    if (appSlice.auth.validationCompleted && appSlice.auth.invoices.view) {
        menuItems.push({
            key: 'invoices',
            icon: <FileTextOutlined />,
            label: 'Invoices',
            onClick: () => navigate('/invoices', { replace: true }),
        });
    }

    if (appSlice.auth.validationCompleted && appSlice.auth.beneficiaries.view) {
        menuItems.push({
            key: 'beneficiaries',
            icon: <IdcardOutlined />,
            label: 'Beneficiaries',
            onClick: () => navigate('/beneficiaries', { replace: true }),
        });
    }

    if (appSlice.auth.validationCompleted && appSlice.auth.customers.view) {
        menuItems.push({
            key: 'customers',
            icon: <ContactsOutlined />,
            label: 'Customers',
            onClick: () => navigate('/customers', { replace: true }),
        });
    }

    menuItems.push({
        key: 'documents',
        icon: <FilePdfOutlined />,
        label: 'documents',
        onClick: () => navigate('/documents/manuals', { replace: true }),
    });

    //if user can view admin module
    if (appSlice.auth.validationCompleted && appSlice.auth.admin.view) {
        menuItems.push({
            key: 'admin',
            icon: <ControlOutlined />,
            label: 'Admin',
            onClick: () => navigate('/admin/users', { replace: true }),
        });
    }

    return <Menu selectedKeys={getActiveMenuItems(menuItems)} items={menuItems} />;
};
