import { UnorderedListOutlined, UserOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { useNavigate } from 'react-router';
import { getActiveMenuItems } from '../../../shared/helpers/NavigationHelpers';

export const AdminTabNavigation = () => {
    const navigate = useNavigate();

    const menuItems = [
        {
            key: 'users',
            label: 'Users',
            icon: <UserOutlined />,
            onClick: () => {
                navigate('/admin/users');
            },
        },
        {
            key: 'lookup-lists',
            label: 'Lookup lists',
            icon: <UnorderedListOutlined />,
            onClick: () => {
                navigate('/admin/lookup-lists');
            },
        },
    ];

    return <Menu mode="horizontal" defaultSelectedKeys={getActiveMenuItems(menuItems, 1)} items={menuItems} />;
};
