import { DeleteFilled, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Space, Button, Spin, Row, Col, App as AntApp, notification } from 'antd';
import { Route } from '@ant-design/pro-layout/es/typing';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { StatePieceStatus } from '../../../enums';
import { ContentHeader } from '../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { Unauthorized } from '../../../shared/components/unauthorized/Unauthorized';
import { NOTIFICATION_PLACEMENT, ROW_GUTTER } from '../../../shared/Constants';
import { generateReturnUrl, getReturnUrl } from '../../../shared/helpers';
import { AppDispatch, RootState } from '../../../store';
import { deleteCustomer, fetchCustomerById } from '../../../store/customerSlice/CustomerActions';
import { resetState } from '../../../store/customerSlice/CustomerSlice';
import { NotFound } from '../../notFound/NotFound';

export const CustomerDetails = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const { modal } = AntApp.useApp();
    const { appSlice } = useSelector((state: RootState) => state);
    const { customer } = useSelector((state: RootState) => state.customerSlice.detailsPage);
    const [breadcrumb, setBreadcrumb] = useState<Route[]>([]);
    const customerId = params.id ? parseInt(params.id) : undefined;

    const removeCustomer = () => {
        modal.confirm({
            title: 'Delete fee',
            icon: <ExclamationCircleOutlined />,
            content: "Are you sure you want to delete this customer? This action can't be undone.",
            okText: 'Confirm',
            cancelText: 'Cancel',
            onOk: () => {
                if (customerId) dispatch(deleteCustomer(customerId));
            },
        });
    };

    useEffect(() => {
        const generatedBreadcrumb: Route[] = [
            {
                breadcrumbName: 'Customers',
                path: '/customers',
            },
        ];

        if (customer.data !== null) {
            generatedBreadcrumb.push({
                breadcrumbName: customer.data.name || customer.data.searchName || '',
                path: `/customers/${customer.data.id}`,
            });
        }

        setBreadcrumb(generatedBreadcrumb);
    }, [customer.data]);

    useEffect(() => {
        if (customerId) {
            dispatch(fetchCustomerById(customerId));
        }

        return () => {
            dispatch(resetState());
        };
    }, [customerId]);

    useEffect(() => {
        if (customer.deleteStatus === StatePieceStatus.Success) {
            let returnUrl = getReturnUrl();
            navigate(returnUrl ? returnUrl : '/customers', { replace: true });
        }
    }, [customer.deleteStatus]);

    useEffect(() => {
        if (customer.fetchStatus === StatePieceStatus.Error) {
            notification.error({
                message: 'An error occurred.',
                duration: 0,
                description: 'An error occurred while retrieving the customers. Please contact your administrator if the problem persists.',
                placement: NOTIFICATION_PLACEMENT,
            });
        }
    }, [customer.fetchStatus]);

    const customerName = customer.data?.name || customer.data?.searchName;

    if (appSlice.auth.validationCompleted && !appSlice.auth.customers.view) {
        return <Unauthorized />;
    }

    if (!customerId) {
        return <NotFound />;
    }

    return (
        <React.Fragment>
            <ContentHeader title={`Customer #${params.id} ${customerName || ''}`} breadcrumbs={breadcrumb}>
                <Space>
                    {appSlice.auth.customers.delete && (
                        <Button
                            type="primary"
                            danger
                            icon={<DeleteFilled />}
                            loading={customer.fetchStatus === StatePieceStatus.IsFetching}
                            onClick={() => removeCustomer()}
                        >
                            Delete customer
                        </Button>
                    )}

                    {appSlice.auth.customers.edit && (
                        <Button
                            type="default"
                            icon={<EditOutlined />}
                            onClick={() => navigate(`${generateReturnUrl(`${window.location.pathname}/edit`)}`)}
                        >
                            Edit customer details
                        </Button>
                    )}
                </Space>
            </ContentHeader>

            <div className="page-content">
                <Spin spinning={customer.fetchStatus === StatePieceStatus.IsFetching}>
                    {/* Customer INFORMATION */}
                    <Row>
                        <Col span={24}>
                            <Space>
                                <label className="group-label">Customer Information</label>
                            </Space>
                        </Col>
                    </Row>
                    <Row gutter={ROW_GUTTER}>
                        <Col span={6}>
                            <label className="property">Name</label>
                            <p>{customer.data?.name || '-'}</p>
                        </Col>
                        <Col span={6}>
                            <label className="property">Search name</label>
                            <p>{customer.data?.searchName || '-'}</p>
                        </Col>
                    </Row>

                    {/* INTERNAL INFORMATION */}
                    <Row className="margin-top">
                        <Col span={24}>
                            <label className="group-label">Internal Information</label>
                        </Col>
                    </Row>

                    <Row gutter={ROW_GUTTER}>
                        <Col span={12}>
                            <label className="property">Created by</label>
                            <p>{customer.data?.createdByUser?.name || '-'}</p>
                        </Col>
                        <Col span={12}>
                            <label className="property">Created on</label>
                            <p className="capitalize">
                                {customer.data?.createdDate ? dayjs(customer.data?.createdDate).format('MMMM D, YYYY HH:mm') : '-'}
                            </p>
                        </Col>
                    </Row>
                    <Row gutter={ROW_GUTTER}>
                        <Col span={12}>
                            <label className="property">Last modified by</label>
                            <p>{customer.data?.modifiedByUser?.name || '-'}</p>
                        </Col>
                        <Col span={12}>
                            <label className="property">Last modified on</label>
                            <p className="capitalize">
                                {customer.data?.modifiedDate ? dayjs(customer.data?.modifiedDate).format('MMMM D, YYYY HH:mm') : '-'}
                            </p>
                        </Col>
                    </Row>
                </Spin>
            </div>
        </React.Fragment>
    );
};
