import { ICustomer } from './../../interfaces/customer/data/ICustomer';
import { IBeneficiary } from './../../interfaces/beneficiaries/data/IBeneficiary';
import { IInvoice } from './../../interfaces/invoices/data/IInvoice';
import { BeneficiaryStatuses, FeeStatuses } from '../../enums';
import { IFee } from './../../interfaces/fees/data/IFee';

export const fee_isEditable = (fee: IFee | undefined | null) => {
    if (fee) {
        return fee.status.code !== FeeStatuses.ApprovedForPayment && fee.status.code !== FeeStatuses.Paid;
    }

    return false;
};

export const invoice_isEditable = (invoice: IInvoice | undefined | null) => {
    if (invoice) {
        return invoice.status.code !== FeeStatuses.ApprovedForPayment && invoice.status.code !== FeeStatuses.Paid;
    }

    return false;
};

export const beneficiary_isEditable = (beneficiary: IBeneficiary | undefined | null) => {
    if (beneficiary) {
        return beneficiary.status.code !== BeneficiaryStatuses.Approved;
    }

    return false;
};

export const customer_isEditable = (_customer: ICustomer | undefined | null) => {
    return true;
};
