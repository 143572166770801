import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select, Space, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { StatePieceStatus } from '../../../../enums';
import { IAddUser } from '../../../../interfaces/users/data';
import { IAddUserFormValues } from '../../../../interfaces/users/IAddUserFormValues';
import { ContentHeader } from '../../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { ROW_GUTTER } from '../../../../shared/Constants';
import { AppDispatch, RootState } from '../../../../store';
import { addUser, fetchUserRoles } from '../../../../store/userSlice/UserActions';
import './AdminUsersAdd.less';

export const AdminUsersAdd = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { userSlice } = useSelector((state: RootState) => state);
    const [form] = Form.useForm();

    const isLoading = userSlice.roles.fetchStatus === StatePieceStatus.IsFetching;

    const onFinish = (values: IAddUserFormValues) => {
        const body: IAddUser = {
            ...(values as any),
        };

        dispatch(addUser(body));
    };

    useEffect(() => {
        if (userSlice.detailUser.user.createStatus === StatePieceStatus.Success) {
            navigate('/admin/users', { replace: true });
        }
    }, [userSlice.detailUser.user.createStatus]);

    /**
     * OnInit
     */
    useEffect(() => {
        dispatch(fetchUserRoles());
    }, []);

    return (
        <React.Fragment>
            <Form
                form={form}
                name="control-hooks"
                onFinish={onFinish}
                layout="vertical"
                onValuesChange={(changedValue: any, values) => {
                    form.setFieldsValue(values);
                }}
            >
                <ContentHeader
                    title={`Create a new user`}
                    breadcrumbs={[
                        {
                            breadcrumbName: 'Users',
                            path: '/admin/users',
                        },
                        {
                            breadcrumbName: 'Create',
                            path: `/admin/users/add`,
                        },
                    ]}
                >
                    <Form.Item>
                        <Space>
                            <Button
                                icon={<CloseOutlined />}
                                onClick={() => navigate(-1)}
                                disabled={userSlice.detailUser.user.updateStatus === StatePieceStatus.IsFetching || isLoading}
                            >
                                Close
                            </Button>
                            <Button
                                htmlType="submit"
                                type="primary"
                                icon={<SaveOutlined />}
                                loading={userSlice.detailUser.user.updateStatus === StatePieceStatus.IsFetching || isLoading}
                            >
                                Save and close
                            </Button>
                        </Space>
                    </Form.Item>
                </ContentHeader>

                <div className="page-content">
                    <Spin spinning={userSlice.detailUser?.user.fetchStatus === StatePieceStatus.IsFetching}>
                        <Row>
                            <Col span={24}>
                                <Space>
                                    <label className="group-label">User Information</label>
                                </Space>
                            </Col>
                        </Row>
                        <Row gutter={ROW_GUTTER}>
                            <Col span={6}>
                                <label className="property required">Initials</label>
                                <Form.Item name="initials" rules={[{ required: true, message: "'Initials' is required" }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <label className="property required">Name</label>
                                <Form.Item name="name" rules={[{ required: true, message: "'Name' is required" }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <label className="property required">Username</label>
                                <Form.Item name="userPrincipalName" rules={[{ required: true, message: "'Username' is required" }]}>
                                    <Input placeholder="username@synthon.com" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={ROW_GUTTER}>
                            <Col span={12}>
                                <label className="property required">Roles</label>
                                <Form.Item name="roles">
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        showSearch
                                        placeholder="Select roles"
                                        filterOption={(input, option) =>
                                            (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                                        }
                                    >
                                        {userSlice.roles.data.map((role) => (
                                            <Select.Option key={role.id} value={role.id}>
                                                {role.description}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Spin>
                </div>
            </Form>
        </React.Fragment>
    );
};
