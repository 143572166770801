import { IPaymentDocument } from './../../interfaces/documents/data/IPaymentDocument';
import { IInvoiceDocument } from './../../interfaces/documents/data/IInvoiceDocument';
import { IManualDocument } from './../../interfaces/documents/data/IManualDocument';
import { createSlice } from '@reduxjs/toolkit';
import { StatePieceStatus } from '../../enums';
import { IStatePiece } from '../../interfaces';
import {
    deleteManualDocument,
    downloadDocument,
    fetchAllInvoiceDocuments,
    fetchManualDocuments,
    fetchAllPaymentDocuments,
} from './DocumentActions';
import { IPaginatedResult } from '../../interfaces/IPaginatedResult';

export interface DocumentSliceState {
    manualDocuments: IStatePiece<IPaginatedResult<IManualDocument[]>>;
    invoiceDocuments: IStatePiece<IPaginatedResult<IInvoiceDocument[]>>;
    paymentDocuments: IStatePiece<IPaginatedResult<IPaymentDocument[]>>;
    deleteManualStatus: StatePieceStatus;
    documentDownloadStatus: StatePieceStatus;
}

const initialState: DocumentSliceState = {
    manualDocuments: {
        data: {
            items: [],
            links: {},
            paging: {
                pageNumber: 0,
                pageSize: 0,
                totalItems: 0,
                totalPages: 0,
            },
        },
        fetchStatus: StatePieceStatus.None,
    },
    invoiceDocuments: {
        data: {
            items: [],
            links: {},
            paging: {
                pageNumber: 0,
                pageSize: 0,
                totalItems: 0,
                totalPages: 0,
            },
        },
        fetchStatus: StatePieceStatus.None,
    },
    paymentDocuments: {
        data: {
            items: [],
            links: {},
            paging: {
                pageNumber: 0,
                pageSize: 0,
                totalItems: 0,
                totalPages: 0,
            },
        },
        fetchStatus: StatePieceStatus.None,
    },
    deleteManualStatus: StatePieceStatus.None,
    documentDownloadStatus: StatePieceStatus.None,
};

export const documentSlice = createSlice({
    name: 'document',
    initialState,
    reducers: {
        resetState: () => initialState,
        appendManualDocument: (state, action) => {
            const newList = [...state.manualDocuments.data.items];
            newList.push(action.payload);
            state.manualDocuments.data.items = newList;
        },
    },

    extraReducers(builder) {
        //#region fetchManualDocuments
        builder.addCase(fetchManualDocuments.pending, (state) => {
            state.manualDocuments.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchManualDocuments.fulfilled, (state, action) => {
            state.manualDocuments.data = action.payload;
            state.manualDocuments.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchManualDocuments.rejected, (state) => {
            state.manualDocuments.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region deleteManualDocument
        builder.addCase(deleteManualDocument.pending, (state) => {
            state.deleteManualStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(deleteManualDocument.fulfilled, (state, action) => {
            state.manualDocuments.data.items = [...state.manualDocuments.data.items].filter((m) => m.id !== action.payload);
            state.deleteManualStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(deleteManualDocument.rejected, (state) => {
            state.deleteManualStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region downloadDocument
        builder.addCase(downloadDocument.pending, (state) => {
            state.documentDownloadStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(downloadDocument.fulfilled, (state, action) => {
            state.documentDownloadStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(downloadDocument.rejected, (state) => {
            state.documentDownloadStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchAllInvoiceDocuments
        builder.addCase(fetchAllInvoiceDocuments.pending, (state) => {
            state.invoiceDocuments.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchAllInvoiceDocuments.fulfilled, (state, action) => {
            state.invoiceDocuments.data = action.payload;
            state.invoiceDocuments.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchAllInvoiceDocuments.rejected, (state) => {
            state.invoiceDocuments.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchAllPaymentDocuments
        builder.addCase(fetchAllPaymentDocuments.pending, (state) => {
            state.paymentDocuments.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchAllPaymentDocuments.fulfilled, (state, action) => {
            state.paymentDocuments.data = action.payload;
            state.paymentDocuments.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchAllPaymentDocuments.rejected, (state) => {
            state.paymentDocuments.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion
    },
});

// Action creators are generated for each case reducer function
export const { resetState, appendManualDocument } = documentSlice.actions;
export default documentSlice.reducer;
