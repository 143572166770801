import { HomeOutlined } from '@ant-design/icons';
import { Affix, Breadcrumb } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import './ContentHeader.less';
import { Route } from '@ant-design/pro-layout/es/typing';
import { PageHeader } from '@ant-design/pro-layout';
import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb';

interface IContentHeaderProps {
    title: string;
    children?: React.ReactNode;
    breadcrumbs?: Route[];
    tabNavigation?: React.ReactNode;
    hideBackButton?: boolean;
}

export const ContentHeader = (props: IContentHeaderProps) => {
    const [showBackground, setShowBackground] = useState<boolean | undefined>(false);
    const navigate = useNavigate();

    let onBack = undefined;
    if (props.hideBackButton !== true) {
        // Check if the current page is a root page (E.g. /fees or /beneficiaries).
        // Render a back button in the PageHeader when the page is NOT a root page.
        const currentPageIsRoot = window.location.pathname.split('/').length === 2;
        if (!currentPageIsRoot) {
            onBack = () => navigate(-1);
        }
    }

    let breacrumbItems: BreadcrumbItemType[] = [
        {
            title: <HomeOutlined />,
        },
    ];

    if (props.breadcrumbs) {
        for (let i = 0; i < props.breadcrumbs.length; i++) {
            const item = props.breadcrumbs[i];

            breacrumbItems.push({
                key: item.path,
                title: item.breadcrumbName,
                onClick: () => navigate(item.path),
                className: 'breadcrumb-link',
            });
        }
    }

    return (
        <Affix offsetTop={1} onChange={(affixed) => setShowBackground(affixed)}>
            <div className={`site-page-header-wrapper ${showBackground ? 'filled' : ''}`}>
                <div className="site-page-header-top-row">
                    <PageHeader className="site-page-header" title={props.title} onBack={onBack} />
                    {props.children && <div className="actions">{props.children}</div>}
                </div>

                {props.breadcrumbs && props.breadcrumbs.length > 0 && (
                    <Breadcrumb className="site-page-header-breadcrumb" items={breacrumbItems}></Breadcrumb>
                )}

                {props.tabNavigation && <div className="site-page-header-tab-navigation">{props.tabNavigation}</div>}
            </div>
        </Affix>
    );
};
