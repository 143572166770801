import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Spin } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { StatePieceStatus } from '../../../../../enums';
import { IAddCurrencyLookupListFormValues } from '../../../../../interfaces/lookup/currency';
import { IAddCurrency } from '../../../../../interfaces/lookup/currency/data';
import { ContentHeader } from '../../../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { FetchResult } from '../../../../../shared/components/fetchResult/FetchResult';
import { ROW_GUTTER } from '../../../../../shared/Constants';
import { isError } from '../../../../../shared/helpers';
import { AppDispatch, RootState } from '../../../../../store';
import { addCurrency, fetchBankAccountsLookupList } from '../../../../../store/lookupSlice/LookupActions';

export const CurrencyLookupListAdd = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { lookupSlice } = useSelector((state: RootState) => state);
    const { bankAccounts } = useSelector((state: RootState) => state.lookupSlice);

    const onFinish = (values: IAddCurrencyLookupListFormValues) => {
        const body: IAddCurrency = {
            ...(values as any),
            conversionRateDate: values.conversionRateDate?.format('YYYY-MM-DDTHH:mm:ss'),
        };

        dispatch(addCurrency(body));
    };

    useEffect(() => {
        if (lookupSlice.detailsPage.currency.createStatus === StatePieceStatus.Success) {
            navigate('/admin/lookup-lists/currency', { replace: true });
        }
    }, [lookupSlice.detailsPage.currency.createStatus]);

    useEffect(() => {
        dispatch(fetchBankAccountsLookupList());
    }, []);

    return (
        <Form
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            layout="vertical"
            onValuesChange={(changedValue: any, values) => {
                form.setFieldsValue(values);
            }}
        >
            <ContentHeader
                title="Manage currency lookup list"
                breadcrumbs={[
                    {
                        breadcrumbName: 'Admin',
                        path: '/admin',
                    },
                    {
                        breadcrumbName: 'Manage lookup lists',
                        path: '/admin/lookup-lists',
                    },
                    {
                        breadcrumbName: 'Currency',
                        path: '/admin/lookup-lists/currency',
                    },
                    {
                        breadcrumbName: 'Create',
                        path: `/admin/lookup-lists/currency/add`,
                    },
                ]}
            >
                {!isError(lookupSlice.detailsPage.currency.fetchStatus) && (
                    <Form.Item>
                        <Space>
                            <Button
                                icon={<CloseOutlined />}
                                onClick={() => navigate(-1)}
                                disabled={
                                    lookupSlice.detailsPage.currency.updateStatus === StatePieceStatus.IsFetching ||
                                    lookupSlice.detailsPage.currency.fetchStatus === StatePieceStatus.IsFetching
                                }
                            >
                                Close
                            </Button>
                            <Button
                                htmlType="submit"
                                type="primary"
                                icon={<SaveOutlined />}
                                loading={
                                    lookupSlice.detailsPage.currency.updateStatus === StatePieceStatus.IsFetching ||
                                    lookupSlice.detailsPage.currency.fetchStatus === StatePieceStatus.IsFetching
                                }
                            >
                                Save and close
                            </Button>
                        </Space>
                    </Form.Item>
                )}
            </ContentHeader>

            <div className="page-content">
                <Spin spinning={lookupSlice.detailsPage.currency.fetchStatus === StatePieceStatus.IsFetching}>
                    {isError(lookupSlice.detailsPage.currency.fetchStatus) ? (
                        <FetchResult status={lookupSlice.detailsPage.currency.fetchStatus} about={'Currency'} />
                    ) : (
                        <>
                            <Row>
                                <Col span={24}>
                                    <Space>
                                        <label className="group-label">Currency option Information</label>
                                    </Space>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={8}>
                                    <label className="property required">Code</label>
                                    <Form.Item name="code" rules={[{ required: true, message: "'Code' is required" }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <label className="property required">Description</label>
                                    <Form.Item name="description" rules={[{ required: true, message: "'Description' is required" }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <label className="property">Bank account</label>
                                    <Form.Item name="defaultBankAccountId">
                                        <Select
                                            showSearch
                                            placeholder="Select a bank account"
                                            filterOption={(input, option) =>
                                                (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                                            }
                                        >
                                            {bankAccounts.lookup.data
                                                .filter((f) => !f.isDeleted)
                                                .map((bankAccount) => (
                                                    <Select.Option
                                                        key={bankAccount.key}
                                                        value={bankAccount.key}
                                                        disabled={bankAccount.isDeleted}
                                                    >
                                                        {bankAccount.value}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={8}>
                                    <label className="property required">Conversion rate</label>
                                    <Form.Item name="conversionRate" rules={[{ required: true, message: "'Conversion rate' is required" }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <label className="property required">Conversion date</label>
                                    <Form.Item
                                        name="conversionRateDate"
                                        rules={[{ required: true, message: "'Conversion date' is required" }]}
                                    >
                                        <DatePicker />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>&nbsp;</Col>
                            </Row>
                        </>
                    )}
                </Spin>
            </div>
        </Form>
    );
};
