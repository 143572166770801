import { IDeleteInvoiceComment } from './../../interfaces/invoices/IDeleteInvoiceComment';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAPI } from '../../MSALConfig';
import { notification } from 'antd';
import { NOTIFICATION_PLACEMENT } from '../../shared/Constants';
import { IInvoiceComment } from '../../interfaces/invoices/data/IInvoiceComment';
import { IUpdateInvoice } from '../../interfaces/invoices/data/IUpdateInvoice';
import { IAddInvoiceComment } from '../../interfaces/invoices/IAddInvoiceComment';
import { IInvoice } from '../../interfaces/invoices/data/IInvoice';
import { IAddInvoice } from '../../interfaces/invoices/data/IAddInvoice';
import { IInvoiceFilterParams } from '../../interfaces/invoices';
import { constructQueryParams } from '../../shared/helpers/FilterHelpers';
import { IPaginatedResult } from '../../interfaces/IPaginatedResult';
import { IUpdateInvoiceStatusCode } from '../../interfaces/invoices/data/IUpdateInvoiceStatusCode';
import { ExportHelpers } from '../../shared/helpers';
import { IInvoiceDocument } from '../../interfaces/documents/data/IInvoiceDocument';
import { IErrorResponse } from '../../interfaces/error/IErrorReponse';

const appendInvoiceFilterParams = (props: IInvoiceFilterParams, params: string) => {
    if (params === '') params = '?';

    if (props.searchQuery) {
        if (params.length > 1) params += '&';
        params += `SearchQuery=${props.searchQuery}`;
    }

    if (props.statusCode) {
        props.statusCode.forEach((element) => {
            if (params.length > 1) params += '&';
            params += `StatusCode=${element}`;
        });
    }

    if (props.feeStatusCode) {
        props.feeStatusCode.forEach((element) => {
            if (params.length > 1) params += '&';
            params += `FeeStatusCode=${element}`;
        });
    }

    if (props.projectId) {
        props.projectId.forEach((element) => {
            if (params.length > 1) params += '&';
            params += `ProjectId=${element}`;
        });
    }

    if (props.customerId) {
        props.customerId.forEach((element) => {
            if (params.length > 1) params += '&';
            params += `CustomerId=${element}`;
        });
    }

    if (props.beneficiaryId) {
        props.beneficiaryId.forEach((element) => {
            if (params.length > 1) params += '&';
            params += `BeneficiaryId=${element}`;
        });
    }

    if (props.createdById) {
        props.createdById.forEach((element) => {
            if (params.length > 1) params += '&';
            params += `CreatedById=${element}`;
        });
    }

    if (props.procedureCode) {
        if (params.length > 1) params += '&';
        params += `ProcedureCode=${props.procedureCode}`;
    }

    if (props.reasonOfPayment) {
        if (params.length > 1) params += '&';
        params += `ReasonOfPayment=${props.reasonOfPayment}`;
    }

    return params;
};

export const fetchInvoices = createAsyncThunk('invoices/fetchInvoices', async (props: IInvoiceFilterParams) => {
    try {
        if (props.sorter && props.sorter.field && props.sorter.order) {
            if (props.sorter.field === 'customer') {
                props.sorter.field = 'customer.searchName';
            }
        }

        let params = constructQueryParams(props);
        params = appendInvoiceFilterParams(props, params);

        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/invoices${params}`, {});

        if (response.status !== 200) {
            throw new Error();
        }
        const result: IPaginatedResult<IInvoice[]> = await response.json();

        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred.',
            duration: 0,
            description: 'An error occurred while retrieving the invoices. Please contact your administrator if the problem persists.',
            placement: NOTIFICATION_PLACEMENT,
        });

        console.error(e);
        throw e;
    }
});

export const fetchInvoiceById = createAsyncThunk('invoices/fetchInvoiceById', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/invoices/${id}`, {});

        if (response.status !== 200) {
            throw new Error(response.status.toString());
        }

        const result: IInvoice = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchInvoiceComments = createAsyncThunk('invoices/fetchInvoiceComments', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/invoices/${id}/comments`, {});
        const result: IInvoiceComment[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const updateInvoice = createAsyncThunk('invoices/updateInvoice', async (invoice: IUpdateInvoice) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/invoices/${invoice.id}`, {
            method: 'PATCH',
            body: JSON.stringify(invoice),
        });

        if (response.status !== 200) {
            if (response.status === 409) {
                throw new Error(
                    'Could not update the invoice. The invoice is already updated by another user. Please refresh the page to retrieve new data.'
                );
            }
            throw new Error(`Could not update the invoice. Please contact your administrator if the problem persists.`);
        }

        const result: IInvoice = await response.json();

        notification.success({
            message: 'Invoice updated',
            description: `The invoice has been updated successfully.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e: any) {
        notification.error({
            message: 'An error occurred',
            description: e.message,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const updateInvoiceStatuses = createAsyncThunk(
    'invoices/updateInvoiceStatuses',
    async (invoiceStatusCodes: IUpdateInvoiceStatusCode[]) => {
        try {
            const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/invoices/statuses`, {
                method: 'PATCH',
                body: JSON.stringify(invoiceStatusCodes),
            });

            if (response.status !== 200) {
                const result: any = await response.json();
                throw new Error(result.message || 'Could not update the invoice.');
            }

            const result: IInvoice[] = await response.json();

            notification.success({
                message: 'Status updated',
                description: `The status of the invoices have been updated successfully.`,
                placement: NOTIFICATION_PLACEMENT,
            });
            return result;
        } catch (e: any) {
            notification.error({
                message: 'An error occurred',
                description:
                    e.message || `Could not update the invoice statuses. Please contact your administrator if the problem persists.`,
                duration: 0,
                placement: NOTIFICATION_PLACEMENT,
            });
            console.error(e);
            throw e;
        }
    }
);

export const addInvoiceComment = createAsyncThunk('invoices/addInvoiceComment', async (body: IAddInvoiceComment) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/invoices/${body.id}/comments`, {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (response.status !== 201) {
            throw new Error('Could not add a comment to the invoice.');
        }

        const result: IInvoiceComment = await response.json();

        notification.success({
            message: 'Comment added.',
            description: `The comment is successfully added to the invoice.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not add the comment to the invoice. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const addInvoice = createAsyncThunk('invoices/addInvoice', async (body: IAddInvoice) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/invoices`, {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (response.status !== 201) {
            throw new Error('Unable to add a new invoice.');
        }

        const result: IInvoice = await response.json();

        notification.success({
            message: 'Invoice created',
            description: `The invoice is successfully created.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not add the invoice. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const deleteInvoiceComment = createAsyncThunk('invoices/deleteInvoiceComment', async (body: IDeleteInvoiceComment) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/invoices/${body.invoiceId}/comments/${body.commentId}`, {
            method: 'DELETE',
        });

        if (response.status !== 200) {
            throw new Error('Unable to delete the invoice comment.');
        }

        const result: IInvoiceComment = await response.json();

        notification.success({
            message: 'Invoice comment deleted',
            description: `The invoice comment is successfully deleted.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not remove the invoice comment. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const deleteInvoice = createAsyncThunk('invoice/deleteInvoice', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/invoices/${id}`, {
            method: 'DELETE',
        });

        if (response.status !== 200) {
            throw new Error('Unable to delete the invoice.');
        }

        const result: IInvoice = await response.json();

        notification.success({
            message: 'Invoice deleted',
            description: `The invoice is successfully deleted.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not remove the invoice. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const deleteInvoicesBatch = createAsyncThunk('fees/deleteInvoicesBatch', async (invoiceIds: number[]) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/invoices`, {
            method: 'DELETE',
            body: JSON.stringify(invoiceIds),
        });

        if (response.status !== 200) {
            throw new Error('Unable to delete the invoices.');
        }

        const result: IInvoice[] = await response.json();

        notification.success({
            message: 'Invoices deleted',
            description: `The invoices are successfully deleted.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not remove one of the invoices. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const fetchInvoicesExportData = createAsyncThunk('invoices/fetchInvoicesExportData', async (itemIds: string[] | number[]) => {
    try {
        await ExportHelpers.exportCsv(`${process.env.REACT_APP_API_URL}/invoices/export`, itemIds, 'invoices.csv');
        return true;
    } catch (e) {
        console.error(e);

        notification.error({
            message: 'An error occurred',
            description: `Could not export the invoices. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        throw e;
    }
});

export const fetchInvoicesExportAsPDF = createAsyncThunk('invoices/generateInvoices', async (ids: number[]) => {
    try {
        let params = ``;
        if (ids.length > 0) {
            params = `?`;

            for (let i = 0; i < ids.length; i++) {
                params += `${i > 0 ? '&' : ''}id=${ids[i]}`;
            }
        }

        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/invoices/generateInvoices${params}`, {});
        const result: any = await response.json();

        //if error is returned
        if (result.code && result.message) {
            throw result;
        }

        notification.success({
            message: 'Invoices successfully generated',
            description: `The invoices are generated successfully`,
            placement: NOTIFICATION_PLACEMENT,
        });

        return result as IInvoice[];
    } catch (e: any) {
        console.error(e);
        let err = e as IErrorResponse;

        notification.error({
            message: 'An error occurred',
            description: `Could not export the invoices.  Reason: ${err.message}. Code: ${err.code}`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        throw e;
    }
});

export const fetchInvoiceDocuments = createAsyncThunk('invoice/id/documents', async (invoiceId: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/invoices/${invoiceId}/documents`, {});
        const result: IInvoiceDocument[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        notification.error({
            message: 'An error occurred.',
            duration: 0,
            description:
                'An error occurred while retrieving the invoice documents. Please contact your administrator if the problem persists.',
            placement: NOTIFICATION_PLACEMENT,
        });
        throw e;
    }
});
