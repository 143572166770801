import { Route, Routes } from 'react-router';
import { useNavigate } from 'react-router';
import './Admin.less';
import { useEffect } from 'react';
import { AdminLookupLists } from './adminLookupLists/AdminLookupLists';
import { NotFound } from '../notFound/NotFound';
import {
    BankAccountLookupListAdd,
    BankAccountLookupListDetail,
    BankAccountLookupListEdit,
    BankAccountLookupListOverview,
} from './adminLookupLists/bankAccountLookupList';
import {
    BankCostLookupListAdd,
    BankCostLookupListDetail,
    BankCostLookupListEdit,
    BankCostLookupListOverview,
} from './adminLookupLists/bankCostLookupList';
import {
    BeneficiaryStatusLookupListAdd,
    BeneficiaryStatusLookupListDetail,
    BeneficiaryStatusLookupListEdit,
    BeneficiaryStatusLookupListOverview,
} from './adminLookupLists/beneficiaryStatusLookupList';
import {
    BillableLookupListAdd,
    BillableLookupListDetail,
    BillableLookupListEdit,
    BillableLookupListOverview,
} from './adminLookupLists/billableLookupList';
import {
    CategoryLookupListOverview,
    CategoryLookupListAdd,
    CategoryLookupListDetail,
    CategoryLookupListEdit,
} from './adminLookupLists/categoryLookupList';
import {
    CountryLookupListOverview,
    CountryLookupListAdd,
    CountryLookupListDetail,
    CountryLookupListEdit,
} from './adminLookupLists/countryLookupList';
import {
    CurrencyLookupListOverview,
    CurrencyLookupListAdd,
    CurrencyLookupListDetail,
    CurrencyLookupListEdit,
} from './adminLookupLists/currencyLookupList';
import {
    FeeStatusLookupListAdd,
    FeeStatusLookupListDetail,
    FeeStatusLookupListEdit,
    FeeStatusLookupListOverview,
} from './adminLookupLists/feeStatusLookupList';
import {
    InvoiceStatusLookupListOverview,
    InvoiceStatusLookupListAdd,
    InvoiceStatusLookupListDetail,
    InvoiceStatusLookupListEdit,
} from './adminLookupLists/invoiceStatusLookupList';
import {
    KindOfFeeLookupListOverview,
    KindOfFeeLookupListAdd,
    KindOfFeeLookupListDetail,
    KindOfFeeLookupListEdit,
} from './adminLookupLists/kindOfFeeLookupList';
import {
    MaHolderLookupListOverview,
    MaHolderLookupListAdd,
    MaHolderLookupListDetail,
    MaHolderLookupListEdit,
} from './adminLookupLists/maHolderLookupList';
import {
    ProjectLookupListOverview,
    ProjectLookupListAdd,
    ProjectLookupListDetail,
    ProjectLookupListEdit,
} from './adminLookupLists/projectLookupList';
import {
    RegionLookupListOverview,
    RegionLookupListAdd,
    RegionLookupListDetail,
    RegionLookupListEdit,
} from './adminLookupLists/regionLookupList';
import { AdminUsersAdd, AdminUsersDetail, AdminUsersEdit, AdminUsersOverview } from './adminUsers';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Unauthorized } from '../../shared/components/unauthorized/Unauthorized';

export const Admin = () => {
    const history = useNavigate();
    const { appSlice } = useSelector((state: RootState) => state);

    useEffect(() => {
        // When the user reaches the '/admin' path, navigate them to 'users'
        if (window.location.pathname === '/admin') {
            history('users');
        }
    }, [window.location.pathname]);

    if (appSlice.auth.validationCompleted && !appSlice.auth.admin.view) {
        return <Unauthorized />;
    }

    return (
        <div className="app-page">
            <Routes>
                <Route path={'users'} element={<AdminUsersOverview />} />
                <Route path={'users/add'} element={<AdminUsersAdd />} />
                <Route path={'users/:id'} element={<AdminUsersDetail />} />
                <Route path={'users/:id/edit'} element={<AdminUsersEdit />} />

                <Route path={'lookup-lists'} element={<AdminLookupLists />} />

                <Route path={'lookup-lists/billable'} element={<BillableLookupListOverview />} />
                <Route path={'lookup-lists/billable/add'} element={<BillableLookupListAdd />} />
                <Route path={'lookup-lists/billable/:id'} element={<BillableLookupListDetail />} />
                <Route path={'lookup-lists/billable/:id/edit'} element={<BillableLookupListEdit />} />

                <Route path={'lookup-lists/bankcost'} element={<BankCostLookupListOverview />} />
                <Route path={'lookup-lists/bankcost/add'} element={<BankCostLookupListAdd />} />
                <Route path={'lookup-lists/bankcost/:id'} element={<BankCostLookupListDetail />} />
                <Route path={'lookup-lists/bankcost/:id/edit'} element={<BankCostLookupListEdit />} />

                <Route path={'lookup-lists/category'} element={<CategoryLookupListOverview />} />
                <Route path={'lookup-lists/category/add'} element={<CategoryLookupListAdd />} />
                <Route path={'lookup-lists/category/:id'} element={<CategoryLookupListDetail />} />
                <Route path={'lookup-lists/category/:id/edit'} element={<CategoryLookupListEdit />} />

                <Route path={'lookup-lists/country'} element={<CountryLookupListOverview />} />
                <Route path={'lookup-lists/country/add'} element={<CountryLookupListAdd />} />
                <Route path={'lookup-lists/country/:id'} element={<CountryLookupListDetail />} />
                <Route path={'lookup-lists/country/:id/edit'} element={<CountryLookupListEdit />} />

                <Route path={'lookup-lists/currency'} element={<CurrencyLookupListOverview />} />
                <Route path={'lookup-lists/currency/add'} element={<CurrencyLookupListAdd />} />
                <Route path={'lookup-lists/currency/:id'} element={<CurrencyLookupListDetail />} />
                <Route path={'lookup-lists/currency/:id/edit'} element={<CurrencyLookupListEdit />} />

                <Route path={'lookup-lists/project'} element={<ProjectLookupListOverview />} />
                <Route path={'lookup-lists/project/add'} element={<ProjectLookupListAdd />} />
                <Route path={'lookup-lists/project/:id'} element={<ProjectLookupListDetail />} />
                <Route path={'lookup-lists/project/:id/edit'} element={<ProjectLookupListEdit />} />

                <Route path={'lookup-lists/ma-holder'} element={<MaHolderLookupListOverview />} />
                <Route path={'lookup-lists/ma-holder/add'} element={<MaHolderLookupListAdd />} />
                <Route path={'lookup-lists/ma-holder/:id'} element={<MaHolderLookupListDetail />} />
                <Route path={'lookup-lists/ma-holder/:id/edit'} element={<MaHolderLookupListEdit />} />

                <Route path={'lookup-lists/kind-of-fee'} element={<KindOfFeeLookupListOverview />} />
                <Route path={'lookup-lists/kind-of-fee/add'} element={<KindOfFeeLookupListAdd />} />
                <Route path={'lookup-lists/kind-of-fee/:id'} element={<KindOfFeeLookupListDetail />} />
                <Route path={'lookup-lists/kind-of-fee/:id/edit'} element={<KindOfFeeLookupListEdit />} />

                <Route path={'lookup-lists/region'} element={<RegionLookupListOverview />} />
                <Route path={'lookup-lists/region/add'} element={<RegionLookupListAdd />} />
                <Route path={'lookup-lists/region/:id'} element={<RegionLookupListDetail />} />
                <Route path={'lookup-lists/region/:id/edit'} element={<RegionLookupListEdit />} />

                <Route path={'lookup-lists/fee-status'} element={<FeeStatusLookupListOverview />} />
                <Route path={'lookup-lists/fee-status/add'} element={<FeeStatusLookupListAdd />} />
                <Route path={'lookup-lists/fee-status/:id'} element={<FeeStatusLookupListDetail />} />
                <Route path={'lookup-lists/fee-status/:id/edit'} element={<FeeStatusLookupListEdit />} />

                <Route path={'lookup-lists/invoice-status'} element={<InvoiceStatusLookupListOverview />} />
                <Route path={'lookup-lists/invoice-status/add'} element={<InvoiceStatusLookupListAdd />} />
                <Route path={'lookup-lists/invoice-status/:id'} element={<InvoiceStatusLookupListDetail />} />
                <Route path={'lookup-lists/invoice-status/:id/edit'} element={<InvoiceStatusLookupListEdit />} />

                <Route path={'lookup-lists/beneficiary-status'} element={<BeneficiaryStatusLookupListOverview />} />
                <Route path={'lookup-lists/beneficiary-status/add'} element={<BeneficiaryStatusLookupListAdd />} />
                <Route path={'lookup-lists/beneficiary-status/:id'} element={<BeneficiaryStatusLookupListDetail />} />
                <Route path={'lookup-lists/beneficiary-status/:id/edit'} element={<BeneficiaryStatusLookupListEdit />} />

                <Route path={'lookup-lists/bank-account'} element={<BankAccountLookupListOverview />} />
                <Route path={'lookup-lists/bank-account/add'} element={<BankAccountLookupListAdd />} />
                <Route path={'lookup-lists/bank-account/:id'} element={<BankAccountLookupListDetail />} />
                <Route path={'lookup-lists/bank-account/:id/edit'} element={<BankAccountLookupListEdit />} />

                <Route path={'/*'} element={<NotFound />} />
            </Routes>
        </div>
    );
};
