import { Route, Routes } from 'react-router';
import { NotFound } from '../notFound/NotFound';
import { AddCustomer } from './addCustomer/AddCustomer';
import { CustomerDetails } from './customerDetails/CustomerDetails';
import './Customers.less';
import { CustomersOverview } from './customersOverview/CustomersOverview';
import { UpdateCustomer } from './updateCustomer/UpdateCustomer';

export const Customers = () => {
    return (
        <div className="app-page">
            <Routes>
                <Route path={'/'} element={<CustomersOverview />} />
                <Route path={'add'} element={<AddCustomer />} />
                <Route path={':id'} element={<CustomerDetails />} />
                <Route path={':id/edit'} element={<UpdateCustomer />} />
                <Route path={'/*'} element={<NotFound />} />
            </Routes>
        </div>
    );
};
