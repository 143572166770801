import { SorterResult } from 'antd/lib/table/interface';
import { IFilterParams } from '../../interfaces';

/**
 * Constructs the query parameters for the generic filters.
 */
export const constructQueryParams = (props: IFilterParams) => {
    const { pageNumber, pageSize, sorter } = props;
    let params = `?`;

    if (pageNumber) {
        if (params.length > 1) params += '&';
        params += `PageNumber=${pageNumber}`;
    }

    if (pageSize) {
        if (params.length > 1) params += '&';
        params += `PageSize=${pageSize}`;
    }

    if (sorter && sorter.field && sorter.order) {
        if (params.length > 1) params += '&';
        params += `SortField=${sorter.field}`;

        const sortOrder = sorter.order === 'descend' ? 'desc' : 'asc';
        params += `&SortOrder=${sortOrder}`;
    }

    if (props.searchQuery) {
        if (params.length > 1) params += '&';
        params += `SearchQuery=${props.searchQuery}`;
    }

    return params;
};

export const appendDefaultSorting = (sorter: SorterResult<any> | undefined, field: string, order: 'ascend' | 'descend') => {
    if (sorter?.column === undefined && sorter?.order === undefined) {
        if (sorter === undefined) {
            sorter = {};
        }
        sorter.column = {
            dataIndex: field,
            key: field,
        };
        sorter.order = order;
        sorter.field = field;
    }
    return sorter;
};
