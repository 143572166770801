import { ILookup } from './../../interfaces/lookup/ILookup';
import { IInvoiceStatus } from './../../interfaces/lookup/invoiceStatus/data/IInvoiceStatus';
import {
    fetchBankCostOptions,
    fetchCountries,
    fetchKindOfFees,
    fetchProjects,
    fetchRegions,
    fetchCategories,
    fetchFeeStatuses,
    fetchBillableOptions,
    fetchBeneficiaryStatuses,
    fetchMaHolders,
    fetchBillableOptionById,
    deleteBillableOption,
    updateBillableOption,
    addBillableOption,
    fetchBankCostOptionById,
    deleteBankCostOption,
    updateBankCostOption,
    addBankCostOption,
    fetchCategoryById,
    updateCountry,
    fetchCountryById,
    addCountry,
    deleteCountry,
    addCurrency,
    fetchCurrencies,
    fetchCurrencyById,
    updateCurrency,
    deleteCurrency,
    addBankAccount,
    deleteBankAccount,
    fetchBankAccounts,
    updateBankAccount,
    fetchBankAccountById,
    updateProject,
    fetchProjectById,
    addProject,
    deleteProject,
    fetchRegionById,
    updateRegion,
    addRegion,
    deleteRegion,
    fetchKindOfFeeById,
    updateKindOfFee,
    addKindOfFee,
    deleteKindOfFee,
    updateFeeStatus,
    fetchFeeStatusById,
    addFeeStatus,
    updateBeneficiaryStatus,
    fetchBeneficiaryStatusById,
    addBeneficiaryStatus,
    updateMaHolder,
    fetchMaHolderById,
    addMaHolder,
    deleteMaHolder,
    fetchInvoiceStatuses,
    addInvoiceStatus,
    fetchInvoiceStatusById,
    updateInvoiceStatus,
    fetchCategoriesAsLookupList,
    addCategory,
    updateCategory,
    deleteCategory,
    fetchCountriesLookupList,
    fetchProjectsLookupList,
    fetchRegionsLookupList,
    fetchMaHoldersLookupList,
    fetchBeneficiariesLookupList,
    fetchUsersLookupList,
    fetchCustomersLookupList,
    fetchCurrenciesLookupList,
    fetchBankAccountsLookupList,
    fetchBillableOptionsLookupList,
    fetchKindOfFeesLookupList,
    fetchBankCostOptionsLookupList,
    updateDefaultBillableOption,
    updateDefaultBankAccount,
} from './LookupActions';
import { createSlice } from '@reduxjs/toolkit';
import { StatePieceStatus } from '../../enums';
import { IStatePiece } from '../../interfaces';
import { ICurrency } from '../../interfaces/lookup/currency/data';
import { ICountry } from '../../interfaces/lookup/country/data';
import { IBankCostOption } from '../../interfaces/lookup/bankCost/data';
import { ICategory } from '../../interfaces/lookup/category/data';
import { IBillableOption } from '../../interfaces/lookup/billable/data';
import { IBankAccount } from '../../interfaces/lookup/bankAccount/data';
import { IRegion } from '../../interfaces/lookup/region/data';
import { IFeeKind } from '../../interfaces/lookup/feeKind/data';
import { IFeeStatus } from '../../interfaces/lookup/feeStatus/data';
import { IBeneficiaryStatus } from '../../interfaces/lookup/beneficiaryStatus/data';
import { IMaHolder } from '../../interfaces/lookup/maHolder/data';
import { IProject } from '../../interfaces/lookup/project/data';

export interface LookupSliceState {
    countries: {
        model: IStatePiece<ICountry[]>;
        lookup: IStatePiece<ILookup[]>;
    };
    users: IStatePiece<ILookup[]>;
    customers: IStatePiece<ILookup[]>;
    beneficiaries: IStatePiece<ILookup[]>;
    bankAccounts: {
        model: IStatePiece<IBankAccount[]>;
        lookup: IStatePiece<ILookup[]>;
    };
    bankCostOptions: {
        model: IStatePiece<IBankCostOption[]>;
        lookup: IStatePiece<ILookup[]>;
    };
    regions: {
        model: IStatePiece<IRegion[]>;
        lookup: IStatePiece<ILookup[]>;
    };
    projects: {
        model: IStatePiece<IProject[]>;
        lookup: IStatePiece<ILookup[]>;
    };
    kindOfFees: {
        model: IStatePiece<IFeeKind[]>;
        lookup: IStatePiece<ILookup[]>;
    };
    categories: {
        model: IStatePiece<ICategory[]>;
        lookup: IStatePiece<ILookup[]>;
    };
    feeStatuses: IStatePiece<IFeeStatus[]>;
    billableOptions: {
        model: IStatePiece<IBillableOption[]>;
        lookup: IStatePiece<ILookup[]>;
    };
    beneficiaryStatuses: IStatePiece<IBeneficiaryStatus[]>;
    maHolders: {
        model: IStatePiece<IMaHolder[]>;
        lookup: IStatePiece<ILookup[]>;
    };
    currencies: {
        model: IStatePiece<ICurrency[]>;
        lookup: IStatePiece<ILookup[]>;
    };
    invoiceStatuses: IStatePiece<IInvoiceStatus[]>;
    detailsPage: {
        country: IStatePiece<ICountry | null>;
        currency: IStatePiece<ICurrency | null>;
        billableOption: IStatePiece<IBillableOption | null>;
        bankAccount: IStatePiece<IBankAccount | null>;
        bankCostOption: IStatePiece<IBankCostOption | null>;
        region: IStatePiece<IRegion | null>;
        project: IStatePiece<IRegion | null>;
        kindOfFee: IStatePiece<IFeeKind | null>;
        category: IStatePiece<ICategory | null>;
        feeStatus: IStatePiece<IFeeStatus | null>;
        beneficiaryStatus: IStatePiece<IBeneficiaryStatus | null>;
        maHolder: IStatePiece<IMaHolder | null>;
        invoiceStatus: IStatePiece<IInvoiceStatus | null>;
    };
}

const initialState: LookupSliceState = {
    users: {
        data: [],
        fetchStatus: StatePieceStatus.None,
    },
    customers: {
        data: [],
        fetchStatus: StatePieceStatus.None,
    },
    beneficiaries: {
        data: [],
        fetchStatus: StatePieceStatus.None,
    },
    countries: {
        model: {
            data: [],
            fetchStatus: StatePieceStatus.None,
        },
        lookup: {
            data: [],
            fetchStatus: StatePieceStatus.None,
        },
    },
    bankCostOptions: {
        model: {
            data: [],
            fetchStatus: StatePieceStatus.None,
        },
        lookup: {
            data: [],
            fetchStatus: StatePieceStatus.None,
        },
    },
    regions: {
        model: {
            data: [],
            fetchStatus: StatePieceStatus.None,
        },
        lookup: {
            data: [],
            fetchStatus: StatePieceStatus.None,
        },
    },
    projects: {
        model: {
            data: [],
            fetchStatus: StatePieceStatus.None,
        },
        lookup: {
            data: [],
            fetchStatus: StatePieceStatus.None,
        },
    },
    kindOfFees: {
        model: {
            data: [],
            fetchStatus: StatePieceStatus.None,
        },
        lookup: {
            data: [],
            fetchStatus: StatePieceStatus.None,
        },
    },
    categories: {
        model: {
            data: [],
            fetchStatus: StatePieceStatus.None,
        },
        lookup: {
            data: [],
            fetchStatus: StatePieceStatus.None,
        },
    },
    feeStatuses: {
        data: [],
        fetchStatus: StatePieceStatus.None,
    },
    billableOptions: {
        model: {
            data: [],
            fetchStatus: StatePieceStatus.None,
        },
        lookup: {
            data: [],
            fetchStatus: StatePieceStatus.None,
        },
    },
    bankAccounts: {
        model: {
            data: [],
            fetchStatus: StatePieceStatus.None,
        },
        lookup: {
            data: [],
            fetchStatus: StatePieceStatus.None,
        },
    },
    beneficiaryStatuses: {
        data: [],
        fetchStatus: StatePieceStatus.None,
    },
    invoiceStatuses: {
        data: [],
        fetchStatus: StatePieceStatus.None,
    },
    maHolders: {
        model: {
            data: [],
            fetchStatus: StatePieceStatus.None,
        },
        lookup: {
            data: [],
            fetchStatus: StatePieceStatus.None,
        },
    },
    currencies: {
        model: {
            data: [],
            fetchStatus: StatePieceStatus.None,
        },
        lookup: {
            data: [],
            fetchStatus: StatePieceStatus.None,
        },
    },
    detailsPage: {
        billableOption: {
            data: null,
            fetchStatus: StatePieceStatus.None,
            deleteStatus: StatePieceStatus.None,
        },
        bankAccount: {
            data: null,
            fetchStatus: StatePieceStatus.None,
            deleteStatus: StatePieceStatus.None,
        },
        country: {
            data: null,
            fetchStatus: StatePieceStatus.None,
            deleteStatus: StatePieceStatus.None,
        },
        bankCostOption: {
            data: null,
            fetchStatus: StatePieceStatus.None,
            deleteStatus: StatePieceStatus.None,
        },
        region: {
            data: null,
            fetchStatus: StatePieceStatus.None,
            deleteStatus: StatePieceStatus.None,
        },
        project: {
            data: null,
            fetchStatus: StatePieceStatus.None,
            deleteStatus: StatePieceStatus.None,
        },
        kindOfFee: {
            data: null,
            fetchStatus: StatePieceStatus.None,
            deleteStatus: StatePieceStatus.None,
        },
        category: {
            data: null,
            fetchStatus: StatePieceStatus.None,
            deleteStatus: StatePieceStatus.None,
        },
        currency: {
            data: null,
            fetchStatus: StatePieceStatus.None,
            deleteStatus: StatePieceStatus.None,
        },
        feeStatus: {
            data: null,
            fetchStatus: StatePieceStatus.None,
            deleteStatus: StatePieceStatus.None,
        },
        beneficiaryStatus: {
            data: null,
            fetchStatus: StatePieceStatus.None,
            deleteStatus: StatePieceStatus.None,
        },
        maHolder: {
            data: null,
            fetchStatus: StatePieceStatus.None,
            deleteStatus: StatePieceStatus.None,
        },
        invoiceStatus: {
            data: null,
            fetchStatus: StatePieceStatus.None,
            deleteStatus: StatePieceStatus.None,
        },
    },
};

export const lookupSlice = createSlice({
    name: 'lookup',
    initialState,
    reducers: {
        resetState: () => initialState,
    },

    extraReducers(builder) {
        //#region fetchUsersLookupList
        builder.addCase(fetchUsersLookupList.pending, (state) => {
            state.users.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchUsersLookupList.fulfilled, (state, action) => {
            state.users.data = action.payload;
            state.users.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchUsersLookupList.rejected, (state) => {
            state.users.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchCustomersLookupList
        builder.addCase(fetchCustomersLookupList.pending, (state) => {
            state.customers.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchCustomersLookupList.fulfilled, (state, action) => {
            state.customers.data = action.payload;
            state.customers.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchCustomersLookupList.rejected, (state) => {
            state.customers.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchBeneficiariesLookupList
        builder.addCase(fetchBeneficiariesLookupList.pending, (state) => {
            state.beneficiaries.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchBeneficiariesLookupList.fulfilled, (state, action) => {
            state.beneficiaries.data = action.payload;
            state.beneficiaries.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchBeneficiariesLookupList.rejected, (state) => {
            state.beneficiaries.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchCurrenciesLookupList
        builder.addCase(fetchCurrenciesLookupList.pending, (state) => {
            state.currencies.lookup.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchCurrenciesLookupList.fulfilled, (state, action) => {
            state.currencies.lookup.data = action.payload;
            state.currencies.lookup.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchCurrenciesLookupList.rejected, (state) => {
            state.currencies.lookup.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchCurrencies
        builder.addCase(fetchCurrencies.pending, (state) => {
            state.currencies.model.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchCurrencies.fulfilled, (state, action) => {
            state.currencies.model.data = action.payload;
            state.currencies.model.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchCurrencies.rejected, (state) => {
            state.currencies.model.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region updateCurrency
        builder.addCase(updateCurrency.pending, (state) => {
            state.detailsPage.currency.updateStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(updateCurrency.fulfilled, (state, action) => {
            let updatedOptions = [...state.currencies.model.data].map((option) => {
                if (option.id === action.payload.id) {
                    return action.payload;
                }
                return option;
            });
            state.currencies.model.data = updatedOptions;

            state.detailsPage.currency.data = action.payload;
            state.detailsPage.currency.updateStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(updateCurrency.rejected, (state) => {
            state.detailsPage.currency.updateStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchCurrencyById
        builder.addCase(fetchCurrencyById.pending, (state) => {
            state.detailsPage.currency.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });

        builder.addCase(fetchCurrencyById.fulfilled, (state, action) => {
            state.detailsPage.currency.data = action.payload;
            state.detailsPage.currency.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchCurrencyById.rejected, (state) => {
            state.detailsPage.currency.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region addCurrency
        builder.addCase(addCurrency.pending, (state) => {
            state.detailsPage.currency.createStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(addCurrency.fulfilled, (state, action) => {
            state.currencies.model.data = [...state.currencies.model.data, action.payload];

            state.detailsPage.currency.data = action.payload;
            state.detailsPage.currency.createStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(addCurrency.rejected, (state) => {
            state.detailsPage.currency.createStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region deleteCurrency
        builder.addCase(deleteCurrency.pending, (state) => {
            state.detailsPage.currency.deleteStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(deleteCurrency.fulfilled, (state, action) => {
            let options = [...state.currencies.model.data].filter((f) => f.id !== action.payload.id);
            state.currencies.model.data = options;
            state.detailsPage.currency.deleteStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(deleteCurrency.rejected, (state) => {
            state.detailsPage.currency.deleteStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchBankCostOptionsLookupList
        builder.addCase(fetchBankCostOptionsLookupList.pending, (state) => {
            state.bankCostOptions.lookup.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchBankCostOptionsLookupList.fulfilled, (state, action) => {
            state.bankCostOptions.lookup.data = action.payload;
            state.bankCostOptions.lookup.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchBankCostOptionsLookupList.rejected, (state) => {
            state.bankCostOptions.lookup.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchBankCostOptions
        builder.addCase(fetchBankCostOptions.pending, (state) => {
            state.bankCostOptions.model.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchBankCostOptions.fulfilled, (state, action) => {
            state.bankCostOptions.model.data = action.payload;
            state.bankCostOptions.model.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchBankCostOptions.rejected, (state) => {
            state.bankCostOptions.model.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region updateBankCostOption
        builder.addCase(updateBankCostOption.pending, (state) => {
            state.detailsPage.bankCostOption.updateStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(updateBankCostOption.fulfilled, (state, action) => {
            let updatedOptions = [...state.bankCostOptions.model.data].map((option) => {
                if (option.id === action.payload.id) {
                    return action.payload;
                }
                return option;
            });
            state.bankCostOptions.model.data = updatedOptions;

            state.detailsPage.bankCostOption.data = action.payload;
            state.detailsPage.bankCostOption.updateStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(updateBankCostOption.rejected, (state) => {
            state.detailsPage.bankCostOption.updateStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchBankCostOptionById
        builder.addCase(fetchBankCostOptionById.pending, (state) => {
            state.detailsPage.bankCostOption.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });

        builder.addCase(fetchBankCostOptionById.fulfilled, (state, action) => {
            state.detailsPage.bankCostOption.data = action.payload;
            state.detailsPage.bankCostOption.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchBankCostOptionById.rejected, (state) => {
            state.detailsPage.bankCostOption.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region addBankCostOption
        builder.addCase(addBankCostOption.pending, (state) => {
            state.detailsPage.bankCostOption.createStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(addBankCostOption.fulfilled, (state, action) => {
            state.bankCostOptions.model.data = [...state.bankCostOptions.model.data, action.payload];

            state.detailsPage.bankCostOption.data = action.payload;
            state.detailsPage.bankCostOption.createStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(addBankCostOption.rejected, (state) => {
            state.detailsPage.bankCostOption.createStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region deleteBankCostOption
        builder.addCase(deleteBankCostOption.pending, (state) => {
            state.detailsPage.bankCostOption.deleteStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(deleteBankCostOption.fulfilled, (state, action) => {
            let options = [...state.bankCostOptions.model.data].filter((f) => f.id !== action.payload.id);
            state.bankCostOptions.model.data = options;
            state.detailsPage.bankCostOption.deleteStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(deleteBankCostOption.rejected, (state) => {
            state.detailsPage.bankCostOption.deleteStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchBillableOptionsLookupList
        builder.addCase(fetchBillableOptionsLookupList.pending, (state) => {
            state.billableOptions.lookup.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchBillableOptionsLookupList.fulfilled, (state, action) => {
            state.billableOptions.lookup.data = action.payload;
            state.billableOptions.lookup.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchBillableOptionsLookupList.rejected, (state) => {
            state.billableOptions.lookup.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region fetchBillableOptions
        builder.addCase(fetchBillableOptions.pending, (state) => {
            state.billableOptions.lookup.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchBillableOptions.fulfilled, (state, action) => {
            state.billableOptions.model.data = action.payload;
            state.billableOptions.model.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchBillableOptions.rejected, (state) => {
            state.billableOptions.model.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region updateDefaultBillableOption
        builder.addCase(updateDefaultBillableOption.pending, (state) => {
            state.billableOptions.model.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(updateDefaultBillableOption.fulfilled, (state, action) => {
            let updatedBillableOptions = [...state.billableOptions.model.data].map((b) => {
                if (b.id === action.payload.id) {
                    return action.payload;
                }

                return {
                    ...b,
                    isDefault: false,
                };
            });
            state.billableOptions.model.data = updatedBillableOptions;
            state.billableOptions.model.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(updateDefaultBillableOption.rejected, (state) => {
            state.billableOptions.model.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region fetchBillableOptionById
        builder.addCase(fetchBillableOptionById.pending, (state) => {
            state.detailsPage.billableOption.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchBillableOptionById.fulfilled, (state, action) => {
            state.detailsPage.billableOption.data = action.payload;
            state.detailsPage.billableOption.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchBillableOptionById.rejected, (state) => {
            state.detailsPage.billableOption.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region addBillableOption
        builder.addCase(addBillableOption.pending, (state) => {
            state.detailsPage.billableOption.createStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(addBillableOption.fulfilled, (state, action) => {
            state.billableOptions.model.data = [...state.billableOptions.model.data, action.payload];

            state.detailsPage.billableOption.data = action.payload;
            state.detailsPage.billableOption.createStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(addBillableOption.rejected, (state) => {
            state.detailsPage.billableOption.createStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region updateBillableOption
        builder.addCase(updateBillableOption.pending, (state) => {
            state.detailsPage.billableOption.updateStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(updateBillableOption.fulfilled, (state, action) => {
            let updatedOptions = [...state.billableOptions.model.data].map((option) => {
                if (option.id === action.payload.id) {
                    return action.payload;
                }
                return option;
            });
            state.billableOptions.model.data = updatedOptions;

            state.detailsPage.billableOption.data = action.payload;
            state.detailsPage.billableOption.updateStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(updateBillableOption.rejected, (state) => {
            state.detailsPage.billableOption.updateStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region deleteBillableOption
        builder.addCase(deleteBillableOption.pending, (state) => {
            state.detailsPage.billableOption.deleteStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(deleteBillableOption.fulfilled, (state, action) => {
            let options = [...state.billableOptions.model.data].filter((f) => f.id !== action.payload.id);
            state.billableOptions.model.data = options;
            state.detailsPage.billableOption.deleteStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(deleteBillableOption.rejected, (state) => {
            state.detailsPage.billableOption.deleteStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchCategoriesAsLookupList
        builder.addCase(fetchCategoriesAsLookupList.pending, (state) => {
            state.categories.lookup.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchCategoriesAsLookupList.fulfilled, (state, action) => {
            state.categories.lookup.data = action.payload;
            state.categories.lookup.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchCategoriesAsLookupList.rejected, (state) => {
            state.categories.lookup.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region fetchCategories
        builder.addCase(fetchCategories.pending, (state) => {
            state.categories.model.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchCategories.fulfilled, (state, action) => {
            state.categories.model.data = action.payload;
            state.categories.model.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchCategories.rejected, (state) => {
            state.categories.model.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region fetchCategoryById
        builder.addCase(fetchCategoryById.pending, (state) => {
            state.detailsPage.category.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchCategoryById.fulfilled, (state, action) => {
            state.detailsPage.category.data = action.payload;
            state.detailsPage.category.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchCategoryById.rejected, (state) => {
            state.detailsPage.category.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region addCategory
        builder.addCase(addCategory.pending, (state) => {
            state.detailsPage.category.createStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(addCategory.fulfilled, (state, action) => {
            state.categories.model.data = [...state.categories.model.data, action.payload];

            state.detailsPage.category.data = action.payload;
            state.detailsPage.category.createStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(addCategory.rejected, (state) => {
            state.detailsPage.category.createStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region updateCategory
        builder.addCase(updateCategory.pending, (state) => {
            state.detailsPage.category.updateStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(updateCategory.fulfilled, (state, action) => {
            let updatedOptions = [...state.categories.model.data].map((option) => {
                if (option.id === action.payload.id) {
                    return action.payload;
                }
                return option;
            });
            state.categories.model.data = updatedOptions;

            state.detailsPage.category.data = action.payload;
            state.detailsPage.category.updateStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(updateCategory.rejected, (state) => {
            state.detailsPage.category.updateStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region deleteCategory
        builder.addCase(deleteCategory.pending, (state) => {
            state.detailsPage.category.deleteStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(deleteCategory.fulfilled, (state, action) => {
            let options = [...state.categories.model.data].filter((f) => f.id !== action.payload.id);
            state.categories.model.data = options;
            state.detailsPage.category.deleteStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(deleteCategory.rejected, (state) => {
            state.detailsPage.category.deleteStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region countries
        builder.addCase(fetchCountriesLookupList.pending, (state) => {
            state.countries.lookup.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchCountriesLookupList.fulfilled, (state, action) => {
            state.countries.lookup.data = action.payload;
            state.countries.lookup.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchCountriesLookupList.rejected, (state) => {
            state.countries.lookup.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region countries
        builder.addCase(fetchCountries.pending, (state) => {
            state.countries.model.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchCountries.fulfilled, (state, action) => {
            state.countries.model.data = action.payload;
            state.countries.model.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchCountries.rejected, (state) => {
            state.countries.model.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region updateCountry
        builder.addCase(updateCountry.pending, (state) => {
            state.detailsPage.country.updateStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(updateCountry.fulfilled, (state, action) => {
            let updatedOptions = [...state.countries.model.data].map((option) => {
                if (option.id === action.payload.id) {
                    return action.payload;
                }
                return option;
            });
            state.countries.model.data = updatedOptions;

            state.detailsPage.country.data = action.payload;
            state.detailsPage.country.updateStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(updateCountry.rejected, (state) => {
            state.detailsPage.country.updateStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchCountryById
        builder.addCase(fetchCountryById.pending, (state) => {
            state.detailsPage.country.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });

        builder.addCase(fetchCountryById.fulfilled, (state, action) => {
            state.detailsPage.country.data = action.payload;
            state.detailsPage.country.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchCountryById.rejected, (state) => {
            state.detailsPage.country.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region addCountry
        builder.addCase(addCountry.pending, (state) => {
            state.detailsPage.country.createStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(addCountry.fulfilled, (state, action) => {
            state.countries.model.data = [...state.countries.model.data, action.payload];

            state.detailsPage.country.data = action.payload;
            state.detailsPage.country.createStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(addCountry.rejected, (state) => {
            state.detailsPage.country.createStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region deleteCountry
        builder.addCase(deleteCountry.pending, (state) => {
            state.detailsPage.country.deleteStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(deleteCountry.fulfilled, (state, action) => {
            let options = [...state.countries.model.data].filter((f) => f.id !== action.payload.id);
            state.countries.model.data = options;
            state.detailsPage.country.deleteStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(deleteCountry.rejected, (state) => {
            state.detailsPage.country.deleteStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchBankAccountsLookupList
        builder.addCase(fetchBankAccountsLookupList.pending, (state) => {
            state.bankAccounts.lookup.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchBankAccountsLookupList.fulfilled, (state, action) => {
            state.bankAccounts.lookup.data = action.payload;
            state.bankAccounts.lookup.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchBankAccountsLookupList.rejected, (state) => {
            state.bankAccounts.lookup.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchBankAccounts
        builder.addCase(fetchBankAccounts.pending, (state) => {
            state.bankAccounts.model.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchBankAccounts.fulfilled, (state, action) => {
            state.bankAccounts.model.data = action.payload;
            state.bankAccounts.model.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchBankAccounts.rejected, (state) => {
            state.bankAccounts.model.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region updateBankAccount
        builder.addCase(updateBankAccount.pending, (state) => {
            state.detailsPage.bankAccount.updateStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(updateBankAccount.fulfilled, (state, action) => {
            let updatedOptions = [...state.bankAccounts.model.data].map((option) => {
                if (option.id === action.payload.id) {
                    return action.payload;
                }
                return option;
            });
            state.bankAccounts.model.data = updatedOptions;

            state.detailsPage.bankAccount.data = action.payload;
            state.detailsPage.bankAccount.updateStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(updateBankAccount.rejected, (state) => {
            state.detailsPage.bankAccount.updateStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region updateDefaultBankAccount
        builder.addCase(updateDefaultBankAccount.pending, (state) => {
            state.bankAccounts.model.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(updateDefaultBankAccount.fulfilled, (state, action) => {
            let updatedOptions = [...state.bankAccounts.model.data].map((option) => {
                if (option.id === action.payload.id) {
                    return action.payload;
                }
                return {
                    ...option,
                    isDefault: false,
                };
            });
            state.bankAccounts.model.data = updatedOptions;
            state.bankAccounts.model.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(updateDefaultBankAccount.rejected, (state) => {
            state.bankAccounts.model.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchBankAccountById
        builder.addCase(fetchBankAccountById.pending, (state) => {
            state.detailsPage.bankAccount.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });

        builder.addCase(fetchBankAccountById.fulfilled, (state, action) => {
            state.detailsPage.bankAccount.data = action.payload;
            state.detailsPage.bankAccount.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchBankAccountById.rejected, (state) => {
            state.detailsPage.bankAccount.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region addBankAccount
        builder.addCase(addBankAccount.pending, (state) => {
            state.detailsPage.bankAccount.createStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(addBankAccount.fulfilled, (state, action) => {
            state.bankAccounts.model.data = [...state.bankAccounts.model.data, action.payload];

            state.detailsPage.bankAccount.data = action.payload;
            state.detailsPage.bankAccount.createStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(addBankAccount.rejected, (state) => {
            state.detailsPage.bankAccount.createStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region deleteBankAccount
        builder.addCase(deleteBankAccount.pending, (state) => {
            state.detailsPage.bankAccount.deleteStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(deleteBankAccount.fulfilled, (state, action) => {
            let options = [...state.bankAccounts.model.data].filter((f) => f.id !== action.payload.id);
            state.bankAccounts.model.data = options;
            state.detailsPage.bankAccount.deleteStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(deleteBankAccount.rejected, (state) => {
            state.detailsPage.bankAccount.deleteStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region regionsLookupList
        builder.addCase(fetchRegionsLookupList.pending, (state) => {
            state.regions.lookup.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchRegionsLookupList.fulfilled, (state, action) => {
            state.regions.lookup.data = action.payload;
            state.regions.lookup.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchRegionsLookupList.rejected, (state) => {
            state.regions.lookup.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region regions
        builder.addCase(fetchRegions.pending, (state) => {
            state.regions.model.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchRegions.fulfilled, (state, action) => {
            state.regions.model.data = action.payload;
            state.regions.model.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchRegions.rejected, (state) => {
            state.regions.model.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region updateRegion
        builder.addCase(updateRegion.pending, (state) => {
            state.detailsPage.region.updateStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(updateRegion.fulfilled, (state, action) => {
            let updatedOptions = [...state.regions.model.data].map((option) => {
                if (option.id === action.payload.id) {
                    return action.payload;
                }
                return option;
            });
            state.regions.model.data = updatedOptions;

            state.detailsPage.region.data = action.payload;
            state.detailsPage.region.updateStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(updateRegion.rejected, (state) => {
            state.detailsPage.region.updateStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchRegionById
        builder.addCase(fetchRegionById.pending, (state) => {
            state.detailsPage.region.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });

        builder.addCase(fetchRegionById.fulfilled, (state, action) => {
            state.detailsPage.region.data = action.payload;
            state.detailsPage.region.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchRegionById.rejected, (state) => {
            state.detailsPage.region.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region addRegion
        builder.addCase(addRegion.pending, (state) => {
            state.detailsPage.region.createStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(addRegion.fulfilled, (state, action) => {
            state.regions.model.data = [...state.regions.model.data, action.payload];

            state.detailsPage.region.data = action.payload;
            state.detailsPage.region.createStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(addRegion.rejected, (state) => {
            state.detailsPage.region.createStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region deleteRegion
        builder.addCase(deleteRegion.pending, (state) => {
            state.detailsPage.region.deleteStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(deleteRegion.fulfilled, (state, action) => {
            let options = [...state.regions.model.data].filter((f) => f.id !== action.payload.id);
            state.regions.model.data = options;
            state.detailsPage.region.deleteStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(deleteRegion.rejected, (state) => {
            state.detailsPage.region.deleteStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchProjectsLookupList
        builder.addCase(fetchProjectsLookupList.pending, (state) => {
            state.projects.lookup.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchProjectsLookupList.fulfilled, (state, action) => {
            state.projects.lookup.data = action.payload;
            state.projects.lookup.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchProjectsLookupList.rejected, (state) => {
            state.projects.lookup.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region projects
        builder.addCase(fetchProjects.pending, (state) => {
            state.projects.model.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchProjects.fulfilled, (state, action) => {
            state.projects.model.data = action.payload;
            state.projects.model.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchProjects.rejected, (state) => {
            state.projects.model.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region updateProject
        builder.addCase(updateProject.pending, (state) => {
            state.detailsPage.project.updateStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(updateProject.fulfilled, (state, action) => {
            let updatedOptions = [...state.projects.model.data].map((option) => {
                if (option.id === action.payload.id) {
                    return action.payload;
                }
                return option;
            });
            state.projects.model.data = updatedOptions;

            state.detailsPage.project.data = action.payload;
            state.detailsPage.project.updateStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(updateProject.rejected, (state) => {
            state.detailsPage.project.updateStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchProjectById
        builder.addCase(fetchProjectById.pending, (state) => {
            state.detailsPage.project.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });

        builder.addCase(fetchProjectById.fulfilled, (state, action) => {
            state.detailsPage.project.data = action.payload;
            state.detailsPage.project.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchProjectById.rejected, (state) => {
            state.detailsPage.project.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region addProject
        builder.addCase(addProject.pending, (state) => {
            state.detailsPage.project.createStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(addProject.fulfilled, (state, action) => {
            state.projects.model.data = [...state.projects.model.data, action.payload];

            state.detailsPage.project.data = action.payload;
            state.detailsPage.project.createStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(addProject.rejected, (state) => {
            state.detailsPage.project.createStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region deleteProject
        builder.addCase(deleteProject.pending, (state) => {
            state.detailsPage.project.deleteStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(deleteProject.fulfilled, (state, action) => {
            let options = [...state.projects.model.data].filter((f) => f.id !== action.payload.id);
            state.projects.model.data = options;
            state.detailsPage.project.deleteStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(deleteProject.rejected, (state) => {
            state.detailsPage.project.deleteStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchKindOfFeesLookupList
        builder.addCase(fetchKindOfFeesLookupList.pending, (state) => {
            state.kindOfFees.lookup.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchKindOfFeesLookupList.fulfilled, (state, action) => {
            state.kindOfFees.lookup.data = action.payload;
            state.kindOfFees.lookup.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchKindOfFeesLookupList.rejected, (state) => {
            state.kindOfFees.lookup.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region fetchKindOfFees
        builder.addCase(fetchKindOfFees.pending, (state) => {
            state.kindOfFees.model.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchKindOfFees.fulfilled, (state, action) => {
            state.kindOfFees.model.data = action.payload;
            state.kindOfFees.model.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchKindOfFees.rejected, (state) => {
            state.kindOfFees.model.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region updateKindOfFee
        builder.addCase(updateKindOfFee.pending, (state) => {
            state.detailsPage.kindOfFee.updateStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(updateKindOfFee.fulfilled, (state, action) => {
            let updatedOptions = [...state.kindOfFees.model.data].map((option) => {
                if (option.id === action.payload.id) {
                    return action.payload;
                }
                return option;
            });
            state.kindOfFees.model.data = updatedOptions;

            state.detailsPage.kindOfFee.data = action.payload;
            state.detailsPage.kindOfFee.updateStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(updateKindOfFee.rejected, (state) => {
            state.detailsPage.kindOfFee.updateStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchKindOfFeeById
        builder.addCase(fetchKindOfFeeById.pending, (state) => {
            state.detailsPage.kindOfFee.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });

        builder.addCase(fetchKindOfFeeById.fulfilled, (state, action) => {
            state.detailsPage.kindOfFee.data = action.payload;
            state.detailsPage.kindOfFee.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchKindOfFeeById.rejected, (state) => {
            state.detailsPage.kindOfFee.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region addKindOfFee
        builder.addCase(addKindOfFee.pending, (state) => {
            state.detailsPage.kindOfFee.createStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(addKindOfFee.fulfilled, (state, action) => {
            state.kindOfFees.model.data = [...state.kindOfFees.model.data, action.payload];

            state.detailsPage.kindOfFee.data = action.payload;
            state.detailsPage.kindOfFee.createStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(addKindOfFee.rejected, (state) => {
            state.detailsPage.kindOfFee.createStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region deleteKindOfFee
        builder.addCase(deleteKindOfFee.pending, (state) => {
            state.detailsPage.kindOfFee.deleteStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(deleteKindOfFee.fulfilled, (state, action) => {
            let options = [...state.kindOfFees.model.data].filter((f) => f.id !== action.payload.id);
            state.kindOfFees.model.data = options;
            state.detailsPage.kindOfFee.deleteStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(deleteKindOfFee.rejected, (state) => {
            state.detailsPage.kindOfFee.deleteStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchFeeStatuses
        builder.addCase(fetchFeeStatuses.pending, (state) => {
            state.feeStatuses.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchFeeStatuses.fulfilled, (state, action) => {
            state.feeStatuses.data = action.payload;
            state.feeStatuses.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchFeeStatuses.rejected, (state) => {
            state.feeStatuses.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region updateFeeStatus
        builder.addCase(updateFeeStatus.pending, (state) => {
            state.detailsPage.feeStatus.updateStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(updateFeeStatus.fulfilled, (state, action) => {
            let updatedOptions = [...state.feeStatuses.data].map((option) => {
                if (option.id === action.payload.id) {
                    return action.payload;
                }
                return option;
            });
            state.feeStatuses.data = updatedOptions;

            state.detailsPage.feeStatus.data = action.payload;
            state.detailsPage.feeStatus.updateStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(updateFeeStatus.rejected, (state) => {
            state.detailsPage.feeStatus.updateStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchFeeStatusById
        builder.addCase(fetchFeeStatusById.pending, (state) => {
            state.detailsPage.feeStatus.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });

        builder.addCase(fetchFeeStatusById.fulfilled, (state, action) => {
            state.detailsPage.feeStatus.data = action.payload;
            state.detailsPage.feeStatus.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchFeeStatusById.rejected, (state) => {
            state.detailsPage.feeStatus.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region addFeeStatus
        builder.addCase(addFeeStatus.pending, (state) => {
            state.detailsPage.feeStatus.createStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(addFeeStatus.fulfilled, (state, action) => {
            state.feeStatuses.data = [...state.feeStatuses.data, action.payload];

            state.detailsPage.feeStatus.data = action.payload;
            state.detailsPage.feeStatus.createStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(addFeeStatus.rejected, (state) => {
            state.detailsPage.feeStatus.createStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region beneficiaryStatuses
        builder.addCase(fetchBeneficiaryStatuses.pending, (state) => {
            state.beneficiaryStatuses.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchBeneficiaryStatuses.fulfilled, (state, action) => {
            state.beneficiaryStatuses.data = action.payload;
            state.beneficiaryStatuses.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchBeneficiaryStatuses.rejected, (state) => {
            state.beneficiaryStatuses.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region updateBeneficiaryStatus
        builder.addCase(updateBeneficiaryStatus.pending, (state) => {
            state.detailsPage.beneficiaryStatus.updateStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(updateBeneficiaryStatus.fulfilled, (state, action) => {
            let updatedOptions = [...state.beneficiaryStatuses.data].map((option) => {
                if (option.id === action.payload.id) {
                    return action.payload;
                }
                return option;
            });
            state.beneficiaryStatuses.data = updatedOptions;

            state.detailsPage.beneficiaryStatus.data = action.payload;
            state.detailsPage.beneficiaryStatus.updateStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(updateBeneficiaryStatus.rejected, (state) => {
            state.detailsPage.beneficiaryStatus.updateStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchBeneficiaryStatusById
        builder.addCase(fetchBeneficiaryStatusById.pending, (state) => {
            state.detailsPage.beneficiaryStatus.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });

        builder.addCase(fetchBeneficiaryStatusById.fulfilled, (state, action) => {
            state.detailsPage.beneficiaryStatus.data = action.payload;
            state.detailsPage.beneficiaryStatus.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchBeneficiaryStatusById.rejected, (state) => {
            state.detailsPage.beneficiaryStatus.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region addBeneficiaryStatus
        builder.addCase(addBeneficiaryStatus.pending, (state) => {
            state.detailsPage.beneficiaryStatus.createStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(addBeneficiaryStatus.fulfilled, (state, action) => {
            state.beneficiaryStatuses.data = [...state.beneficiaryStatuses.data, action.payload];

            state.detailsPage.beneficiaryStatus.data = action.payload;
            state.detailsPage.beneficiaryStatus.createStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(addBeneficiaryStatus.rejected, (state) => {
            state.detailsPage.beneficiaryStatus.createStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchMaHoldersLookupList
        builder.addCase(fetchMaHoldersLookupList.pending, (state) => {
            state.maHolders.lookup.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchMaHoldersLookupList.fulfilled, (state, action) => {
            state.maHolders.lookup.data = action.payload;
            state.maHolders.lookup.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchMaHoldersLookupList.rejected, (state) => {
            state.maHolders.lookup.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region maHolders
        builder.addCase(fetchMaHolders.pending, (state) => {
            state.maHolders.model.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchMaHolders.fulfilled, (state, action) => {
            state.maHolders.model.data = action.payload;
            state.maHolders.model.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchMaHolders.rejected, (state) => {
            state.maHolders.model.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region updateMaHolder
        builder.addCase(updateMaHolder.pending, (state) => {
            state.detailsPage.maHolder.updateStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(updateMaHolder.fulfilled, (state, action) => {
            let updatedOptions = [...state.maHolders.model.data].map((option) => {
                if (option.id === action.payload.id) {
                    return action.payload;
                }
                return option;
            });
            state.maHolders.model.data = updatedOptions;

            state.detailsPage.maHolder.data = action.payload;
            state.detailsPage.maHolder.updateStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(updateMaHolder.rejected, (state) => {
            state.detailsPage.maHolder.updateStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchMaHolderById
        builder.addCase(fetchMaHolderById.pending, (state) => {
            state.detailsPage.maHolder.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });

        builder.addCase(fetchMaHolderById.fulfilled, (state, action) => {
            state.detailsPage.maHolder.data = action.payload;
            state.detailsPage.maHolder.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchMaHolderById.rejected, (state) => {
            state.detailsPage.maHolder.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region addMaHolder
        builder.addCase(addMaHolder.pending, (state) => {
            state.detailsPage.maHolder.createStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(addMaHolder.fulfilled, (state, action) => {
            state.maHolders.model.data = [...state.maHolders.model.data, action.payload];

            state.detailsPage.maHolder.data = action.payload;
            state.detailsPage.maHolder.createStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(addMaHolder.rejected, (state) => {
            state.detailsPage.maHolder.createStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region deleteMaHolder
        builder.addCase(deleteMaHolder.pending, (state) => {
            state.detailsPage.maHolder.deleteStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(deleteMaHolder.fulfilled, (state, action) => {
            let options = [...state.maHolders.model.data].filter((f) => f.id !== action.payload.id);
            state.maHolders.model.data = options;
            state.detailsPage.maHolder.deleteStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(deleteMaHolder.rejected, (state) => {
            state.detailsPage.maHolder.deleteStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchInvoiceStatuses
        builder.addCase(fetchInvoiceStatuses.pending, (state) => {
            state.invoiceStatuses.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchInvoiceStatuses.fulfilled, (state, action) => {
            state.invoiceStatuses.data = action.payload;
            state.invoiceStatuses.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchInvoiceStatuses.rejected, (state) => {
            state.invoiceStatuses.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region updateInvoiceStatus
        builder.addCase(updateInvoiceStatus.pending, (state) => {
            state.detailsPage.invoiceStatus.updateStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(updateInvoiceStatus.fulfilled, (state, action) => {
            let updatedOptions = [...state.invoiceStatuses.data].map((option) => {
                if (option.id === action.payload.id) {
                    return action.payload;
                }
                return option;
            });
            state.invoiceStatuses.data = updatedOptions;

            state.detailsPage.invoiceStatus.data = action.payload;
            state.detailsPage.invoiceStatus.updateStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(updateInvoiceStatus.rejected, (state) => {
            state.detailsPage.invoiceStatus.updateStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchInvoiceStatusById
        builder.addCase(fetchInvoiceStatusById.pending, (state) => {
            state.detailsPage.invoiceStatus.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });

        builder.addCase(fetchInvoiceStatusById.fulfilled, (state, action) => {
            state.detailsPage.invoiceStatus.data = action.payload;
            state.detailsPage.invoiceStatus.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchInvoiceStatusById.rejected, (state) => {
            state.detailsPage.invoiceStatus.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#regions

        //#region addInvoiceStatus
        builder.addCase(addInvoiceStatus.pending, (state) => {
            state.detailsPage.maHolder.createStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(addInvoiceStatus.fulfilled, (state, action) => {
            state.invoiceStatuses.data = [...state.invoiceStatuses.data, action.payload];

            state.detailsPage.invoiceStatus.data = action.payload;
            state.detailsPage.invoiceStatus.createStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(addInvoiceStatus.rejected, (state) => {
            state.detailsPage.invoiceStatus.createStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion
    },
});

// Action creators are generated for each case reducer function
export const { resetState } = lookupSlice.actions;

export default lookupSlice.reducer;
