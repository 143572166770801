import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './app/App';
import { Provider } from 'react-redux';
import { store } from './store/index';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { msalInstance } from './MSALConfig';

import './index.less';
import { UnAuthenticated } from './app/unAuthenticated/UnAuthenticated';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider, App as AntApp } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <ConfigProvider
        modal={{
            style: {
                color: 'red',
            },
        }}
        theme={{
            token: {
                colorPrimary: '#005cb1',
                colorPrimaryBorder: '#005cb1',
                colorSuccess: '#45ba94',
                colorError: '#ec3c3c',
                colorWarning: '#f1ca40',
                colorLink: '#1DA57A',
                colorTextPlaceholder: '#cdcdcd',
                borderRadius: 2,
                borderRadiusXS: 2,
                borderRadiusSM: 2,
                borderRadiusLG: 2,
                borderRadiusOuter: 2,
                fontFamily: 'Poppins',
                fontWeightStrong: 500,
            },
        }}
    >
        <AntApp>
            <MsalProvider instance={msalInstance}>
                <Provider store={store}>
                    <BrowserRouter>
                        <AuthenticatedTemplate>
                            <App />
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>
                            <UnAuthenticated />
                        </UnauthenticatedTemplate>
                    </BrowserRouter>
                </Provider>
            </MsalProvider>
        </AntApp>
    </ConfigProvider>
);
