import { DownloadOutlined } from '@ant-design/icons';
import { Button, Space, Tooltip, Table } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StatePieceStatus } from '../../../../enums';
import { IDocument } from '../../../../interfaces/documents/data/IDocument';
import { IPaymentDocumentTableRow } from '../../../../interfaces/documents/IPaymentDocumentTableRow';
import { ContentHeader } from '../../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { SearchBar } from '../../../../shared/components/filters/SearchBar';
import { HighlightedText } from '../../../../shared/components/highlightedText/HighlightedText';
import { Unauthorized } from '../../../../shared/components/unauthorized/Unauthorized';
import { calculateOverviewTableHeight } from '../../../../shared/helpers/TableHeightHelper';
import { AppDispatch, RootState } from '../../../../store';
import { resetState } from '../../../../store/beneficiarySlice/BeneficiarySlice';
import { downloadDocument, fetchAllPaymentDocuments } from '../../../../store/documentSlice/DocumentActions';
import { DocumentTabNavigation } from '../../documentTabNavigation/DocumentTabNavigation';

export const PaymentDocumentsOverview = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { documentSlice, appSlice } = useSelector((state: RootState) => state);
    const [tableRenderHeight, setTableRenderHeight] = useState<number>();

    const [filtering, setFiltering] = useState<{
        pagination?: TablePaginationConfig;
        filters?: Record<string, FilterValue | null>;
        _sorter?: SorterResult<IPaymentDocumentTableRow> | SorterResult<IPaymentDocumentTableRow>[];
        searchQuery?: string;
    }>({});

    const columns: ColumnsType<IPaymentDocumentTableRow> = [
        {
            title: 'Document Id',
            dataIndex: 'document',
            key: 'document',
            render: (document: IDocument) => (
                <HighlightedText text={document.id.toString() || ''} textToHighlight={filtering.searchQuery} />
            ),
        },
        {
            title: 'File',
            dataIndex: 'subject',
            key: 'subject',
            sorter: true,
            render: (_, record) => <HighlightedText text={record.document.fileName || ''} textToHighlight={filtering.searchQuery} />,
        },
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
            sorter: true,
            render: (text: string) => <>{dayjs(text).format('DD-MM-YYYY - HH:mm')}</>,
        },
        {
            title: 'Created by',
            dataIndex: 'createdByUser',
            key: 'createdByUser',
            render: (_, record) => (
                <>
                    {record.createdBy && record.createdBy.initials && (
                        <Tooltip title={record.createdBy.name}>
                            <HighlightedText text={record.createdBy.initials || ''} textToHighlight={filtering.searchQuery} />
                        </Tooltip>
                    )}
                </>
            ),
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: 65,
            fixed: 'right',
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip title="Download">
                        <Button
                            type="text"
                            icon={<DownloadOutlined />}
                            onClick={() => {
                                dispatch(downloadDocument({ id: record.document.id, fileName: record.document.fileName || '' }));
                            }}
                        />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        let renderTableHeight = calculateOverviewTableHeight();
        setTableRenderHeight(renderTableHeight);

        return () => {
            dispatch(resetState());
        };
    }, []);

    useEffect(() => {
        const sorter = filtering._sorter as SorterResult<IPaymentDocumentTableRow[]>;
        dispatch(
            fetchAllPaymentDocuments({
                pageSize: filtering.pagination?.pageSize,
                pageNumber: filtering.pagination?.current,
                sorter,
                searchQuery: filtering.searchQuery,
            })
        );
    }, [filtering]);

    if (appSlice.auth.validationCompleted && !appSlice.auth.documents.payments.view) {
        return <Unauthorized />;
    }

    return (
        <div className="app-page">
            <ContentHeader
                title="Payments"
                tabNavigation={<DocumentTabNavigation />}
                hideBackButton
                breadcrumbs={[
                    {
                        breadcrumbName: 'Documents',
                        path: '/documents',
                    },
                    {
                        breadcrumbName: 'Payments',
                        path: '/documents/payments',
                    },
                ]}
            ></ContentHeader>

            <div className="page-content">
                <SearchBar
                    loading={documentSlice.paymentDocuments.fetchStatus === StatePieceStatus.IsFetching}
                    placeholder="Search in payment documents..."
                    onSearch={(value) => {
                        setFiltering({ ...filtering, searchQuery: value });
                    }}
                />

                <Table
                    size="small"
                    bordered={true}
                    scroll={{ y: tableRenderHeight }}
                    columns={columns}
                    loading={documentSlice.paymentDocuments.fetchStatus === StatePieceStatus.IsFetching}
                    dataSource={documentSlice.paymentDocuments.data.items.map((i) => {
                        return { ...i, key: i.id.toString() } as IPaymentDocumentTableRow;
                    })}
                    pagination={{
                        current: documentSlice.paymentDocuments.data.paging.pageNumber,
                        pageSize: documentSlice.paymentDocuments.data.paging.pageSize,
                        total: documentSlice.paymentDocuments.data.paging.totalItems,
                        showSizeChanger: true,
                    }}
                    onChange={(pagination, filters, _sorter) => {
                        setFiltering({ ...filtering, pagination, filters, _sorter: _sorter });
                    }}
                />
            </div>
        </div>
    );
};
