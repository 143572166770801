import { PlusCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Button, notification, Space, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { StatePieceStatus } from '../../../../../enums';
import { IMaHolderLookupListTableRow } from '../../../../../interfaces/lookup/maHolder';
import { ContentHeader } from '../../../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { NOTIFICATION_PLACEMENT } from '../../../../../shared/Constants';
import { AppDispatch, RootState } from '../../../../../store';
import { fetchMaHolders } from '../../../../../store/lookupSlice/LookupActions';
import { resetState } from '../../../../../store/lookupSlice/LookupSlice';

export const MaHolderLookupListOverview = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { maHolders } = useSelector((state: RootState) => state.lookupSlice);

    const columns: ColumnsType<IMaHolderLookupListTableRow> = [
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            render: (_, record) => (
                <>
                    <Tooltip title={record.description}>{record.code}</Tooltip>
                </>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: 100,
            render: (_, record) => (
                <Space size="middle">
                    <Button type="text" icon={<RightOutlined />} onClick={() => navigate(`/admin/lookup-lists/ma-holder/${record.id}`)} />
                </Space>
            ),
        },
    ];

    useEffect(() => {
        if (maHolders.model.updateStatus === StatePieceStatus.Success) {
            navigate('/admin/lookup-lists/ma-holder', { replace: true });
        }
    }, [maHolders.model.updateStatus]);

    useEffect(() => {
        dispatch(fetchMaHolders());

        return () => {
            dispatch(resetState());
        };
    }, []);

    useEffect(() => {
        if (maHolders.model.fetchStatus === StatePieceStatus.Error) {
            notification.error({
                message: 'An error occurred.',
                duration: 0,
                description: 'An error occurred while retrieving the data. Please contact your administrator if the problem persists.',
                placement: NOTIFICATION_PLACEMENT,
            });
        }
    }, [maHolders.model.fetchStatus]);

    return (
        <div className="app-page">
            <ContentHeader
                title="Manage MA Holders lookup list"
                breadcrumbs={[
                    {
                        breadcrumbName: 'Admin',
                        path: '/admin',
                    },
                    {
                        breadcrumbName: 'Manage lookup lists',
                        path: '/admin/lookup-lists',
                    },
                    {
                        breadcrumbName: 'MA Holders',
                        path: '/admin/lookup-lists/ma-holder',
                    },
                ]}
            >
                <>
                    <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => navigate('/admin/lookup-lists/ma-holder/add')}>
                        Add new MA Holder
                    </Button>
                </>
            </ContentHeader>

            <div className="page-content">
                <Table
                    size="small"
                    bordered={true}
                    loading={maHolders.model.fetchStatus === StatePieceStatus.IsFetching}
                    dataSource={maHolders.model.data.map((option, i) => {
                        return { ...option, key: i } as IMaHolderLookupListTableRow;
                    })}
                    columns={columns}
                    pagination={{
                        defaultPageSize: 100,
                    }}
                />
            </div>
        </div>
    );
};
