import { Input } from 'antd';
import './SearchBar.less';

export interface ISearchBarProps {
    loading?: boolean;
    placeholder?: string;
    defaultValue?: string;
    onSearch?: (value: string) => void;
}

export const SearchBar = (props: ISearchBarProps) => {
    return (
        <div className="search-bar">
            <Input.Search
                id="custom-searchbar"
                placeholder={props.placeholder}
                loading={props.loading}
                onSearch={props.onSearch}
                allowClear
                defaultValue={props.defaultValue}
            />
        </div>
    );
};
