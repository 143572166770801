import { AppRoles } from './../../enums/AppRoles';
import { IUser } from './../../interfaces/users/data/IUser';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStatePiece } from '../../interfaces';
import { IGraphUser } from '../../interfaces/users/IGraphUser';
import { StatePieceStatus } from '../../enums';
import { fetchMe, fetchMeGraph, fetchMyPhoto } from './AppActions';

export interface AppSliceState {
    me: {
        //info is based on our own database
        info: IStatePiece<IUser | null>;

        //msGraph is data from MsGraph
        msGraph: IStatePiece<IGraphUser | null>;
    };
    isOnMobile: boolean;
    auth: {
        validationCompleted: boolean;
        admin: {
            view: boolean;
        };
        fees: {
            view: boolean;
            create: boolean;
            edit: boolean;
            delete: boolean;
            changeStatus: boolean;
            createPayFile: boolean;
            comment: boolean;
        };
        invoices: {
            view: boolean;
            create: boolean;
            edit: boolean;
            delete: boolean;
            changeStatus: boolean;
            comment: boolean;
            generatePdf: boolean;
        };
        beneficiaries: {
            view: boolean;
            create: boolean;
            edit: boolean;
            delete: boolean;
            changeStatus: boolean;
            comment: boolean;
        };
        customers: {
            view: boolean;
            create: boolean;
            edit: boolean;
            delete: boolean;
        };
        documents: {
            invoices: {
                view: boolean;
            };
            manuals: {
                view: boolean;
                create: boolean;
                edit: boolean;
                delete: boolean;
            };
            payments: {
                view: boolean;
            };
        };
    };
}

const initialState: AppSliceState = {
    me: {
        info: {
            data: null,
            fetchStatus: StatePieceStatus.None,
            deleteStatus: StatePieceStatus.None,
        },
        msGraph: {
            data: null,
            fetchStatus: StatePieceStatus.None,
            deleteStatus: StatePieceStatus.None,
        },
    },
    isOnMobile: false,
    auth: {
        validationCompleted: false,
        admin: {
            view: false,
        },
        fees: {
            view: true,
            create: false,
            edit: false,
            delete: false,
            changeStatus: false,
            createPayFile: false,
            comment: false,
        },
        invoices: {
            view: true,
            create: false,
            edit: false,
            delete: false,
            changeStatus: false,
            comment: false,
            generatePdf: false,
        },
        beneficiaries: {
            view: true,
            create: false,
            edit: false,
            delete: false,
            changeStatus: false,
            comment: false,
        },
        customers: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        documents: {
            invoices: {
                view: false,
            },
            manuals: {
                view: true,
                create: false,
                edit: false,
                delete: false,
            },
            payments: {
                view: false,
            },
        },
    },
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setIsOnMobile: (state, action: PayloadAction<boolean>) => {
            state.isOnMobile = action.payload;
        },
        setAuthorisation: (state, action: PayloadAction<IUser>) => {
            let roles = action.payload.roles;

            // const isViewer = roles.filter((r) => r.code === AppRoles.Viewer).length > 0;
            const isAdmin = roles.filter((r) => r.code === AppRoles.Admin).length > 0;
            const isPayer = roles.filter((r) => r.code === AppRoles.Payer).length > 0;
            const isDataEntry = roles.filter((r) => r.code === AppRoles.DataEntry).length > 0;
            const isDataEntryPlus = roles.filter((r) => r.code === AppRoles.DataEntryPlus).length > 0;
            const isApprover = roles.filter((r) => r.code === AppRoles.Approver).length > 0;

            //fees permissions
            let canCreateFees = isAdmin || isPayer || isDataEntry || isDataEntryPlus || isApprover;
            let canCreatePayFiles = isAdmin || isPayer;

            let canEditFees = isAdmin || isPayer || isDataEntry || isDataEntryPlus || isApprover;
            let changeFeeStatus = roles.filter((r) => r.code !== AppRoles.Viewer).length > 0; // NOTE: inverted

            let canCommentFees = canEditFees;
            let canDeleteFees = canEditFees;

            //invoices permissions
            let canCreateInvoices = isAdmin || isPayer || isDataEntry || isDataEntryPlus || isApprover;
            let canEditInvoices = isAdmin || isPayer || isDataEntry || isDataEntryPlus || isApprover;
            let changeInvoiceStatus = roles.filter((r) => r.code !== AppRoles.Viewer).length > 0; // NOTE: inverted
            let canCommentInvoices = canEditInvoices;
            let canDeleteInvoices = canEditInvoices;

            //beneficiary permissions
            let canCreateBeneficiaries = isDataEntryPlus || isPayer || isAdmin;
            let canEditBeneficiaries = isDataEntryPlus || isAdmin;
            let changeBeneficiaryStatus = isDataEntryPlus || isPayer || isAdmin;
            let canDeleteBeneficiaries = isDataEntryPlus || isAdmin;

            let canCommentBeneficiaries =
                roles.filter((r) => r.code === AppRoles.Admin || r.code === AppRoles.DataEntryPlus || r.code === AppRoles.Payer).length > 0;

            //customers permissions
            let canCreateCustomers = isDataEntryPlus || isAdmin;
            let canEditCustomers = isDataEntryPlus || isAdmin;
            let canDeleteCustomers = isDataEntryPlus || isAdmin;
            let canGenerateInvoicePdf = isAdmin;

            // document permissions
            let canCreateManuals = isAdmin;
            let canDeleteManuals = isAdmin;

            //document invoices
            let canViewDocumentInvoices = true;

            //document payments
            let canViewDocumentPayments = isAdmin || isPayer;

            state.auth = {
                validationCompleted: true,
                admin: {
                    view: action.payload.roles.filter((r) => r.code === AppRoles.Admin).length === 1,
                },
                fees: {
                    view: true,
                    create: canCreateFees,
                    edit: canEditFees,
                    delete: canDeleteFees,
                    changeStatus: changeFeeStatus,
                    createPayFile: canCreatePayFiles,
                    comment: canCommentFees,
                },
                invoices: {
                    view: true,
                    create: canCreateInvoices,
                    edit: canEditInvoices,
                    delete: canDeleteInvoices,
                    changeStatus: changeInvoiceStatus,
                    comment: canCommentInvoices,
                    generatePdf: canGenerateInvoicePdf,
                },
                beneficiaries: {
                    view: true,
                    create: canCreateBeneficiaries,
                    edit: canEditBeneficiaries,
                    delete: canDeleteBeneficiaries,
                    changeStatus: changeBeneficiaryStatus,
                    comment: canCommentBeneficiaries,
                },
                customers: {
                    view: true,
                    create: canCreateCustomers,
                    edit: canEditCustomers,
                    delete: canDeleteCustomers,
                },
                documents: {
                    invoices: {
                        view: canViewDocumentInvoices,
                    },
                    manuals: {
                        view: true,
                        create: canCreateManuals,
                        edit: false, // Not implemented
                        delete: canDeleteManuals,
                    },
                    payments: {
                        view: canViewDocumentPayments,
                    },
                },
            };
        },
    },

    extraReducers(builder) {
        //fetchMe
        builder.addCase(fetchMe.pending, (state) => {
            state.me.info.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchMe.fulfilled, (state, action) => {
            state.me.info.data = action.payload;
            state.me.info.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchMe.rejected, (state) => {
            state.me.info.fetchStatus = StatePieceStatus.Error;
            return state;
        });

        //fetchMeGraph
        builder.addCase(fetchMeGraph.pending, (state) => {
            state.me.msGraph.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchMeGraph.fulfilled, (state, action) => {
            state.me.msGraph.data = action.payload;
            state.me.msGraph.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchMeGraph.rejected, (state) => {
            state.me.msGraph.fetchStatus = StatePieceStatus.Error;
            return state;
        });

        //fetchMyPhoto
        builder.addCase(fetchMyPhoto.pending, (state) => {
            state.me.msGraph.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchMyPhoto.fulfilled, (state, action) => {
            if (state.me.msGraph.data) {
                let newData: IGraphUser = { ...state.me.msGraph.data };
                newData.photo = action.payload;
                state.me.msGraph.data = newData;
            }
            state.me.msGraph.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchMyPhoto.rejected, (state) => {
            state.me.msGraph.fetchStatus = StatePieceStatus.Error;
            return state;
        });

        //fetchPhotoByUserUpn
        // builder.addCase(fetchPhotoByUserUpn.pending, (state) => {
        //     state.me.msGraph.fetchStatus = StatePieceStatus.IsFetching;
        //     return state;
        // });
        // builder.addCase(fetchPhotoByUserUpn.fulfilled, (state, action) => {
        //     state.me.msGraph.data = action.payload;
        //     state.me.msGraph.fetchStatus = StatePieceStatus.Success;
        //     return state;
        // });
        // builder.addCase(fetchPhotoByUserUpn.rejected, (state) => {
        //     state.me.msGraph.fetchStatus = StatePieceStatus.Error;
        //     return state;
        // });
    },
});

// Action creators are generated for each case reducer function
export const { setIsOnMobile, setAuthorisation } = appSlice.actions;

export default appSlice.reducer;
