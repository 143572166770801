export const formatAsCurrency = (value: any, includeEuroPrefix: boolean = false) => {
    let formatRules: Intl.NumberFormatOptions = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    };

    if (includeEuroPrefix) {
        formatRules.style = 'currency';
        formatRules.currency = 'EUR';
    }

    var formatter = new Intl.NumberFormat('en-gb', formatRules);
    return formatter.format(value);
};
