import { Col, Form, FormInstance, Input, InputNumber, Row, Select, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StatePieceStatus } from '../../../../../enums';
import { IAddInvoiceFormValues } from '../../../../../interfaces/invoices/IAddInvoiceFormValues';
import { DECIMAL_SEPARATOR, ROW_GUTTER } from '../../../../../shared/Constants';
import { AppDispatch, RootState } from '../../../../../store';
import { fetchFeeById } from '../../../../../store/feeSlice/FeeActions';
import { fetchCustomersLookupList } from '../../../../../store/lookupSlice/LookupActions';
import './AddInvoiceFormFields.less';

export interface IAddInvoiceFormFieldsProps {
    form?: FormInstance<any>;
    defaultSelectedFeeId?: number;
    defaultAmount?: number;
    reasonOfPayment?: string;
}

export const AddInvoiceFormFields = (props: IAddInvoiceFormFieldsProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { lookupSlice } = useSelector((state: RootState) => state);
    const { detailFee } = useSelector((state: RootState) => state.feeSlice);
    const [isValidFeeId, setIsValidFeeId] = useState(false);
    const [feeIdFieldIsTouched, setFeeIdFieldIsTouched] = useState(false);

    /**
     * OnInit
     */
    useEffect(() => {
        dispatch(fetchCustomersLookupList());
    }, []);

    const validateFeeId = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(fetchFeeById(parseInt(e.target.value)));

        if (!feeIdFieldIsTouched) {
            setFeeIdFieldIsTouched(true);
        }
    };

    useEffect(() => {
        let isValid = detailFee.fee.fetchStatus === StatePieceStatus.Success;
        setIsValidFeeId(isValid);
    }, [detailFee.fee.fetchStatus]);

    useEffect(() => {
        if (props.form && (props.defaultSelectedFeeId || props.defaultAmount || props.reasonOfPayment)) {
            props.form.setFieldsValue({
                feeId: props.defaultSelectedFeeId ? props.defaultSelectedFeeId : null,
                amount: props.defaultAmount ? props.defaultAmount : null,
                reasonOfPayment: props.reasonOfPayment ? props.reasonOfPayment : null,
                contactPerson: null,
                customerId: null,
                invoiceDate: null,
            } as IAddInvoiceFormValues);
        }
    }, [props]);

    return (
        <>
            <Row>
                <Col span={24}>
                    <Space>
                        <label className="group-label">Invoice information</label>
                    </Space>
                </Col>
            </Row>
            <Row gutter={ROW_GUTTER}>
                <Col span={8}>
                    <label className="property required">Fee Id</label>
                    <Form.Item
                        name="feeId"
                        rules={[{ required: true, message: "'Fee Id' is required" }]}
                        validateStatus={!isValidFeeId && feeIdFieldIsTouched ? 'error' : undefined}
                        help={!isValidFeeId && feeIdFieldIsTouched ? "'Invalid 'fee Id' is provided." : undefined}
                    >
                        <Input
                            disabled={props.form && props.defaultSelectedFeeId ? true : false}
                            placeholder="Enter a fee Id"
                            onChange={validateFeeId}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <label className="property required">Customer</label>
                    <Form.Item name="customerId" rules={[{ required: true, message: "'Customer' is required" }]}>
                        <Select
                            showSearch
                            placeholder="Select a customer"
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                            }
                        >
                            {lookupSlice.customers.data
                                .filter((f) => !f.isDeleted)
                                .map((c) => (
                                    <Select.Option key={c.key} value={c.key} disabled={c.isDeleted}>
                                        {c.value}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <label className="property">Amount</label>
                    <Form.Item name="amount">
                        <InputNumber decimalSeparator={DECIMAL_SEPARATOR}></InputNumber>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={ROW_GUTTER}>
                <Col span={8}>
                    <label className="property">Contact person</label>
                    <Form.Item name="contactPerson" rules={[{ max: 255 }]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <label className="property required">Reason of payment</label>
                    <Form.Item name="reasonOfPayment" rules={[{ required: true, message: "'Reason of Payment' is required", max: 255 }]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>&nbsp;</Col>
            </Row>
        </>
    );
};
