import { Route, Routes } from 'react-router';
import { NotFound } from '../notFound/NotFound';
import './Beneficiaries.less';
import { AddBeneficiaries } from './addBeneficiaries/AddBeneficiary';
import { BeneficiariesDetails } from './beneficiariesDetails/BeneficiaryDetails';
import { UpdateBeneficiary } from './updateBeneficiaries/UpdateBeneficiary';
import { BeneficiariesOverview } from './beneficiariesOverview/BeneficiariesOverview';

export const Beneficiaries = () => {
    return (
        <div className="app-page">
            <Routes>
                <Route path={'/'} element={<BeneficiariesOverview />} />
                <Route path={'add'} element={<AddBeneficiaries />} />
                <Route path={':id'} element={<BeneficiariesDetails />} />
                <Route path={':id/edit'} element={<UpdateBeneficiary />} />
                <Route path={'/*'} element={<NotFound />} />
            </Routes>
        </div>
    );
};
