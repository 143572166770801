import { IPaginatedResult } from './../../interfaces/IPaginatedResult';
import { IUpdateCustomer } from './../../interfaces/customer/data/IUpdateCustomer';
import { ICustomer } from '../../interfaces/customer/data/ICustomer';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAPI } from '../../MSALConfig';
import { notification } from 'antd';
import { IAddCustomer } from '../../interfaces/customer/data';
import { NOTIFICATION_PLACEMENT } from '../../shared/Constants';
import { ICustomerFilterParams } from '../../interfaces/customer/ICustomerFilterParams';
import { constructQueryParams } from '../../shared/helpers/FilterHelpers';

const appendCustomerFilterParams = (props: ICustomerFilterParams, params: string) => {
    if (params === '') params = '?';

    return params;
};

export const fetchCustomers = createAsyncThunk('customer/fetchCustomers', async (props: ICustomerFilterParams) => {
    try {
        let params = constructQueryParams(props);
        params = appendCustomerFilterParams(props, params);

        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/customers${params}`, {});
        const result: IPaginatedResult<ICustomer[]> = await response.json();

        if (response.status !== 200) {
            throw new Error();
        }

        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not retrieve the customers. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const fetchCustomerById = createAsyncThunk('customer/fetchCustomerById', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/customers/${id}`, {});
        const result: ICustomer = await response.json();

        if (response.status !== 200) {
            throw new Error();
        }

        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const addCustomer = createAsyncThunk('customer/addCustomer', async (body: IAddCustomer) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/customers/`, {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (response.status !== 201) {
            throw new Error('Could not add the customer.');
        }

        const result: ICustomer = await response.json();

        notification.success({
            message: 'Customer added',
            description: `The customer with the name '${body.name}' has been added successfully.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not add the customer with the name '${body.name}'. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});
export const updateCustomer = createAsyncThunk('customer/updateCustomer', async (body: IUpdateCustomer) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/customers/${body.id}`, {
            method: 'PATCH',
            body: JSON.stringify(body),
        });

        if (response.status !== 200) {
            throw new Error('Could not update the customer.');
        }

        const result: ICustomer = await response.json();

        notification.success({
            message: 'Customer updated',
            description: `The customer '${body.name}' has been updated successfully.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not update the customer '${body.name}'. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const deleteCustomer = createAsyncThunk('customer/deleteCustomer', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/customers/${id}`, {
            method: 'DELETE',
        });

        if (response.status !== 200) {
            throw new Error('Unable to delete the custiner.');
        }

        const result: ICustomer = await response.json();

        notification.success({
            message: 'Customer deleted',
            description: `The customer is successfully deleted.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not remove the customer. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});
