import { createSlice } from '@reduxjs/toolkit';
import { StatePieceStatus } from '../../enums';
import { IStatePiece } from '../../interfaces';
import { fetchUsers, fetchUserRoles, fetchUserById, updateUser, deleteUser, addUser } from './UserActions';
import { IPaginatedResult } from '../../interfaces/IPaginatedResult';
import { IRole, IUser } from '../../interfaces/users/data';

export interface UserSliceState {
    detailUser: {
        user: IStatePiece<IUser | null>;
    };
    users: IStatePiece<IPaginatedResult<IUser[]>>;
    roles: IStatePiece<IRole[]>;
}

const initialState: UserSliceState = {
    detailUser: {
        user: {
            data: null,
            fetchStatus: StatePieceStatus.None,
            deleteStatus: StatePieceStatus.None,
        },
    },
    users: {
        data: {
            items: [],
            links: {},
            paging: {
                pageNumber: 0,
                pageSize: 0,
                totalItems: 0,
                totalPages: 0,
            },
        },
        fetchStatus: StatePieceStatus.None,
    },
    roles: {
        data: [],
        fetchStatus: StatePieceStatus.None,
    },
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetState: () => initialState,
    },

    extraReducers(builder) {
        //#region fetchUsers
        builder.addCase(fetchUsers.pending, (state) => {
            state.users.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchUsers.fulfilled, (state, action) => {
            state.users.data = action.payload;
            state.users.fetchStatus = StatePieceStatus.Success;
            state.detailUser.user.createStatus = StatePieceStatus.None;
            return state;
        });
        builder.addCase(fetchUsers.rejected, (state) => {
            state.users.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchUsers
        builder.addCase(fetchUserById.pending, (state) => {
            state.detailUser.user.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchUserById.fulfilled, (state, action) => {
            state.detailUser = {
                ...state.detailUser,
                user: {
                    ...state.detailUser.user,
                    data: action.payload,
                    fetchStatus: StatePieceStatus.Success,
                },
            };
            return state;
        });
        builder.addCase(fetchUserById.rejected, (state, action) => {
            switch (action.error.message) {
                case '403':
                    state.detailUser.user.fetchStatus = StatePieceStatus.UnAuthorized;
                    break;
                case '404':
                    state.detailUser.user.fetchStatus = StatePieceStatus.NotFound;
                    break;
                default:
                    state.detailUser.user.fetchStatus = StatePieceStatus.Error;
            }
            return state;
        });
        //#endregion

        //#region updateUser
        builder.addCase(updateUser.pending, (state) => {
            state.detailUser.user.updateStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(updateUser.fulfilled, (state, action) => {
            let updatedUsers = [...state.users.data.items].map((user) => {
                if (user.id === action.payload.id) {
                    return action.payload;
                }
                return user;
            });
            state.users.data.items = updatedUsers;

            state.detailUser.user.data = action.payload;
            state.detailUser.user.updateStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(updateUser.rejected, (state) => {
            state.detailUser.user.updateStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region addUser
        builder.addCase(addUser.pending, (state) => {
            state.detailUser.user.createStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(addUser.fulfilled, (state, action) => {
            state.users.data.items = [...state.users.data.items, action.payload];

            state.detailUser.user.data = action.payload;
            state.detailUser.user.createStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(addUser.rejected, (state) => {
            state.detailUser.user.createStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region deleteUser
        builder.addCase(deleteUser.pending, (state) => {
            state.detailUser.user.deleteStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(deleteUser.fulfilled, (state, action) => {
            let fees = [...state.users.data.items].filter((f) => f.id !== action.payload.id);
            state.users.data.items = fees;
            state.detailUser.user.deleteStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(deleteUser.rejected, (state) => {
            state.detailUser.user.deleteStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion

        //#region fetchRoles
        builder.addCase(fetchUserRoles.pending, (state) => {
            state.roles.fetchStatus = StatePieceStatus.IsFetching;
            return state;
        });
        builder.addCase(fetchUserRoles.fulfilled, (state, action) => {
            state.roles.data = action.payload;
            state.roles.fetchStatus = StatePieceStatus.Success;
            return state;
        });
        builder.addCase(fetchUserRoles.rejected, (state) => {
            state.roles.fetchStatus = StatePieceStatus.Error;
            return state;
        });
        //#endregion
    },
});

// Action creators are generated for each case reducer function
export const { resetState } = userSlice.actions;
export default userSlice.reducer;
