import { IAddUser } from './../../interfaces/users/data/IAddUser';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAPI, fetchMsGraph } from '../../MSALConfig';
import { IPaginatedResult } from '../../interfaces/IPaginatedResult';
import { constructQueryParams } from '../../shared/helpers/FilterHelpers';
import { IUserFilterParams } from '../../interfaces/users';
import { IUpdateUser } from '../../interfaces/users/data/IUpdateUser';
import { notification } from 'antd';
import { NOTIFICATION_PLACEMENT } from '../../shared/Constants';
import { IRole, IUser } from '../../interfaces/users/data';

const appendUserFilterParams = (props: IUserFilterParams, params: string) => {
    if (params === '') params = '?';

    if (props.searchQuery) {
        if (params.length > 1) params += '&';
        params += `SearchQuery=${props.searchQuery}`;
    }
    return params;
};

/** users */
export const fetchUsers = createAsyncThunk('users/fetchUsers', async (props: IUserFilterParams) => {
    try {
        let params = constructQueryParams(props);
        params = appendUserFilterParams(props, params);

        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/users${params}`, {});

        if (response.status !== 200) {
            throw new Error('');
        }

        const result: IPaginatedResult<IUser[]> = await response.json();
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred.',
            duration: 0,
            description: 'An error occurred while retrieving the users. Please contact your administrator if the problem persists.',
            placement: NOTIFICATION_PLACEMENT,
        });

        console.error(e);
        throw e;
    }
});

export const fetchUserById = createAsyncThunk('users/fetchUserById', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/users/${id}`, {});

        if (response.status !== 200) {
            throw new Error(response.status.toString());
        }

        const result: IUser = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const addUser = createAsyncThunk('users/addUser', async (body: IAddUser) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/users`, {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (response.status !== 201) {
            throw new Error('Unable to add a new fee.');
        }

        const result: IUser = await response.json();

        notification.success({
            message: 'User created',
            description: `The user is successfully created.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not add the user. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const updateUser = createAsyncThunk('users/updateUser', async (user: IUpdateUser) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/users/${user.id}`, {
            method: 'PATCH',
            body: JSON.stringify(user),
        });

        if (response.status !== 200) {
            throw new Error('Could not update the user.');
        }

        const result: IUser = await response.json();

        notification.success({
            message: 'User updated',
            description: `The user has been updated successfully.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not update the user. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

export const deleteUser = createAsyncThunk('users/deleteUser', async (id: number) => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/users/${id}`, {
            method: 'DELETE',
        });

        if (response.status !== 200) {
            throw new Error('Unable to delete the user.');
        }

        const result: IUser = await response.json();

        notification.success({
            message: 'User deleted',
            description: `The user is successfully deleted.`,
            placement: NOTIFICATION_PLACEMENT,
        });
        return result;
    } catch (e) {
        notification.error({
            message: 'An error occurred',
            description: `Could not remove the user. Please contact your administrator if the problem persists.`,
            duration: 0,
            placement: NOTIFICATION_PLACEMENT,
        });
        console.error(e);
        throw e;
    }
});

/** roles */
export const fetchUserRoles = createAsyncThunk('users/fetchUserRoles', async () => {
    try {
        const response = await fetchAPI(`${process.env.REACT_APP_API_URL}/roles`, {});
        const result: IRole[] = await response.json();
        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
});

export const fetchPhotoByUserUpn = async (upn: string) => {
    try {
        return new Promise<string>(async (resolve, reject) => {
            const response = await fetchMsGraph(`https://graph.microsoft.com/v1.0/users/${upn}/photo/$value`, {});
            if (response.status === 200) {
                const blob = await response.blob();
                const newBlob = new Blob([blob]);
                const blobUrl = window.URL.createObjectURL(newBlob);
                return resolve(blobUrl);
            }

            return reject(null);
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
};
