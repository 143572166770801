import { CreditCardOutlined, EuroOutlined, FileMarkdownOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getActiveMenuItems } from '../../../shared/helpers/NavigationHelpers';
import { RootState } from '../../../store';

export const DocumentTabNavigation = () => {
    const navigate = useNavigate();
    const { appSlice } = useSelector((state: RootState) => state);

    const menuItems = [
        {
            key: 'manuals',
            label: 'Manuals',
            icon: <FileMarkdownOutlined />,
            onClick: () => {
                navigate('/documents/manuals');
            },
        },
        {
            key: 'invoices',
            label: 'Invoices',
            icon: <EuroOutlined />,
            onClick: () => {
                navigate('/documents/invoices');
            },
        },
    ];

    if (appSlice.auth.validationCompleted && (appSlice.auth.admin.view || appSlice.auth.documents.payments.view)) {
        menuItems.push({
            key: 'payments',
            icon: <CreditCardOutlined />,
            label: 'Payments',
            onClick: () => navigate('/documents/payments', { replace: true }),
        });
    }

    return <Menu mode="horizontal" defaultSelectedKeys={getActiveMenuItems(menuItems, 1)} items={menuItems} />;
};
