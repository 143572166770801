import { EditOutlined, PlusCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Space, Table, Tooltip } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { StatePieceStatus } from '../../../enums';
import { ITableFilter, RowSelection } from '../../../interfaces';
import { ICustomerTableRow } from '../../../interfaces/customer/ICustomerTableRow';
import { ContentHeader } from '../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { SearchBar } from '../../../shared/components/filters/SearchBar';
import { HighlightedText } from '../../../shared/components/highlightedText/HighlightedText';
import { Unauthorized } from '../../../shared/components/unauthorized/Unauthorized';
import { generateReturnUrl } from '../../../shared/helpers';
import { getFilteringValues, QueryParamHelpers, tableHasFiltersActive, updateQueryParams } from '../../../shared/helpers/QueryParamHelpers';
import { calculateOverviewTableHeight } from '../../../shared/helpers/TableHeightHelper';
import { AppDispatch, RootState } from '../../../store';
import { fetchCustomers } from '../../../store/customerSlice/CustomerActions';
import { resetState } from '../../../store/customerSlice/CustomerSlice';

export const CustomersOverview = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { appSlice } = useSelector((state: RootState) => state);
    const { customers } = useSelector((state: RootState) => state.customerSlice);
    const [tableRenderHeight, setTableRenderHeight] = useState<number>();
    const [columns, setColumns] = useState<ColumnsType<ICustomerTableRow>>([]);

    const [tableRowSelection, setTableRowSelection] = useState<RowSelection<ICustomerTableRow>>({}); // Accross all pages
    const [selectedTableRecords, setSelectedTabelRecords] = useState<ICustomerTableRow[]>([]); // Accumulated results from TableRowSelection
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]); // Accumulated results from TableRowSelection

    const [filtering, setFiltering] = useState<ITableFilter>(
        getFilteringValues(window.location.search, columns, customers.data.paging.totalItems)
    );

    const resetFilters = () => {
        setFiltering({});
        navigate({ search: '' }, { replace: true });
    };

    const updateQuery = (
        filters: Record<string, FilterValue | null> | undefined,
        sorter: SorterResult<ICustomerTableRow> | undefined,
        searchQuery: string,
        pagination: TablePaginationConfig | undefined
    ) => {
        setFiltering({ ...filtering, _sorter: sorter, filters: filters, searchQuery: searchQuery, pagination: pagination });
        let newQueryUrl = updateQueryParams(filters, sorter, searchQuery, pagination);
        navigate({ search: newQueryUrl }, { replace: true });
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[], selectedRows: ICustomerTableRow[]) => {
            // #region determine the new tableRowSelection and selection keys
            const currentPage = filtering.pagination?.current || 1;
            let newSelectedTableRecordsObj = { ...tableRowSelection };
            if (selectedRows.length === 0) {
                delete newSelectedTableRecordsObj[currentPage];
            } else {
                newSelectedTableRecordsObj[currentPage] = selectedRows;
            }
            setTableRowSelection(newSelectedTableRecordsObj);

            let accumulatedSelectedRecords = [] as ICustomerTableRow[];
            Object.keys(newSelectedTableRecordsObj).forEach((key: string) => {
                accumulatedSelectedRecords = [...accumulatedSelectedRecords, ...newSelectedTableRecordsObj[key]];
            });
            setSelectedTabelRecords(accumulatedSelectedRecords);
            const accumulatedSelectedKeys = accumulatedSelectedRecords.map((r) => r.id.toString());
            setSelectedRowKeys(accumulatedSelectedKeys);
            // #endregion
        },
    };

    /**
     * OnInit
     */
    useEffect(() => {
        let renderTableHeight = calculateOverviewTableHeight();
        setTableRenderHeight(renderTableHeight);

        return () => {
            dispatch(resetState());
        };
    }, []);

    /**
     * Create a useEffect based on the params. If the param changes to blanco and the properties
     * '_sorter' or 'filters' exists on state 'filtering' then the user navigated back to this page
     * from the left main menu.
     *
     * If '_sorter' or 'filters' does not exists the user was already on this page without any filters
     * or sortings active.
     */
    useEffect(() => {
        if (window.location.search === '' && (filtering._sorter || filtering.filters)) {
            resetFilters();
        }
    }, [window.location.search]);

    useEffect(() => {
        setColumns([
            {
                title: 'Customer name',
                dataIndex: 'name',
                key: 'name',
                fixed: 'left',
                filteredValue: null,
                render: (_, record) => (
                    <span>
                        <HighlightedText text={record.name || ''} textToHighlight={filtering.searchQuery} />
                    </span>
                ),
                sorter: true,
                sortOrder:
                    (filtering._sorter as SorterResult<ICustomerTableRow>)?.columnKey === 'name'
                        ? (filtering._sorter as SorterResult<ICustomerTableRow>)?.order
                        : undefined,
            },
            {
                title: 'Search name',
                dataIndex: 'searchName',
                key: 'searchName',
                filteredValue: null,
                render: (_, record) => (
                    <span>
                        <HighlightedText text={record.searchName || ''} textToHighlight={filtering.searchQuery} />
                    </span>
                ),
                sorter: true,
                sortOrder:
                    (filtering._sorter as SorterResult<ICustomerTableRow>)?.columnKey === 'searchName'
                        ? (filtering._sorter as SorterResult<ICustomerTableRow>)?.order
                        : undefined,
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                width: 65,
                fixed: 'right',
                filteredValue: null,
                render: (_, record) => (
                    <Space size="middle">
                        <Tooltip title="Customer details">
                            <Button
                                type="text"
                                icon={<RightOutlined />}
                                onClick={() => {
                                    navigate(`${generateReturnUrl(`${record.id}`)}`);
                                }}
                            />
                        </Tooltip>
                    </Space>
                ),
            },
        ]);
    }, [customers.data, filtering]);

    useEffect(() => {
        const sorter = filtering._sorter as SorterResult<ICustomerTableRow[]>;
        dispatch(
            fetchCustomers({
                pageSize: filtering.pagination?.pageSize,
                pageNumber: filtering.pagination?.current,
                sorter,
                searchQuery: filtering.searchQuery,
            })
        );
    }, [filtering]);

    /**
     * We use a random key attribute to force a re-render on the component. This is required
     * because we cannot update the defaultValue of the AntDesign's search input component. We need
     * the ability to clear the value for resetting.
     *
     * See more: https://stackoverflow.com/questions/30626030/can-you-force-a-react-component-to-rerender-without-calling-setstate
     * @returns
     */
    const renderSearchBar = () => {
        return (
            <SearchBar
                key={Math.random()}
                loading={customers.fetchStatus === StatePieceStatus.IsFetching}
                placeholder="Search customers by name or search name..."
                onSearch={(value) => {
                    updateQuery(
                        { ...filtering.filters },
                        { ...(filtering._sorter as SorterResult<ICustomerTableRow>) },
                        value,
                        filtering.pagination
                    );
                }}
                defaultValue={filtering?.searchQuery}
            />
        );
    };

    if (appSlice.auth.validationCompleted && !appSlice.auth.customers.view) {
        return <Unauthorized />;
    }

    return (
        <React.Fragment>
            <ContentHeader
                title="Customers"
                breadcrumbs={[
                    {
                        breadcrumbName: 'Customers',
                        path: '/customers',
                    },
                ]}
            >
                {selectedTableRecords.length > 0 && (
                    <Space>
                        {appSlice.auth.customers.edit && (
                            <Button
                                type="primary"
                                icon={<EditOutlined />}
                                onClick={() => {
                                    var returnUrl = generateReturnUrl(`${selectedTableRecords[0].id}/edit`);
                                    returnUrl = QueryParamHelpers.appendEidsQueryParam(returnUrl, selectedTableRecords);
                                    navigate(returnUrl);
                                }}
                            >
                                Edit {selectedTableRecords.length} {selectedTableRecords.length > 1 ? 'customers' : 'customer'}
                            </Button>
                        )}
                    </Space>
                )}

                {appSlice.auth.customers.create && (
                    <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => navigate('add')}>
                        Add new customer
                    </Button>
                )}
            </ContentHeader>

            <div className="page-content">
                <div className="searchbar-wrapper">
                    {renderSearchBar()}

                    {tableHasFiltersActive(filtering) && (
                        <Button onClick={() => resetFilters()} className="reset-filters-button">
                            Reset filters
                        </Button>
                    )}
                </div>
                <Table
                    size="small"
                    bordered={true}
                    scroll={{ x: true, y: tableRenderHeight }}
                    rowSelection={
                        appSlice.auth.customers.create || appSlice.auth.customers.edit
                            ? {
                                  type: 'checkbox',
                                  ...rowSelection,
                              }
                            : undefined
                    }
                    loading={customers.fetchStatus === StatePieceStatus.IsFetching}
                    dataSource={
                        // Map customers to an array of ICustomerTableRow
                        customers.data.items.map((i) => {
                            return { ...i, key: i.id.toString() } as ICustomerTableRow;
                        })
                    }
                    columns={columns}
                    pagination={{
                        current: customers.data.paging.pageNumber,
                        pageSize: customers.data.paging.pageSize,
                        total: customers.data.paging.totalItems,
                        showSizeChanger: selectedTableRecords.length === 0,
                    }}
                    onChange={(pagination, filters, _sorter) => {
                        updateQuery(filters, _sorter as SorterResult<ICustomerTableRow>, filtering.searchQuery || '', pagination);
                    }}
                />
            </div>
        </React.Fragment>
    );
};
