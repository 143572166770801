import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, Space, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { StatePieceStatus } from '../../../enums';
import { IAddFee } from '../../../interfaces/fees/data/IAddFee';
import { IAddFeeFormValues } from '../../../interfaces/fees/IAddFeeFormValues';
import { IUpdateFeeFormValues } from '../../../interfaces/fees/IUpdateFeeFormValues';
import { ContentHeader } from '../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { Unauthorized } from '../../../shared/components/unauthorized/Unauthorized';
import { DECIMAL_SEPARATOR, ROW_GUTTER } from '../../../shared/Constants';
import { AppDispatch, RootState } from '../../../store';
import { fetchBeneficiaryById } from '../../../store/beneficiarySlice/BeneficiaryActions';
import { addFee } from '../../../store/feeSlice/FeeActions';
import {
    fetchBeneficiariesLookupList,
    fetchBillableOptionsLookupList,
    fetchCategoriesAsLookupList,
    fetchCurrenciesLookupList,
    fetchKindOfFeesLookupList,
    fetchMaHoldersLookupList,
    fetchProjectsLookupList,
    fetchRegionsLookupList,
} from '../../../store/lookupSlice/LookupActions';
import './AddFee.less';
import dayjs from 'dayjs';

export const AddFee = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { appSlice, feeSlice, lookupSlice, beneficiarySlice } = useSelector((state: RootState) => state);
    const { beneficiaries } = useSelector((state: RootState) => state.beneficiarySlice);
    const { regions, projects, kindOfFees, categories, billableOptions, maHolders, currencies } = useSelector(
        (state: RootState) => state.lookupSlice
    );
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const isLoadingLookupLists =
        beneficiaries.fetchStatus === StatePieceStatus.IsFetching ||
        maHolders.lookup.fetchStatus === StatePieceStatus.IsFetching ||
        regions.lookup.fetchStatus === StatePieceStatus.IsFetching ||
        projects.lookup.fetchStatus === StatePieceStatus.IsFetching ||
        kindOfFees.lookup.fetchStatus === StatePieceStatus.IsFetching ||
        categories.lookup.fetchStatus === StatePieceStatus.IsFetching ||
        billableOptions.lookup.fetchStatus === StatePieceStatus.IsFetching;

    const onFinish = (values: IAddFeeFormValues) => {
        const body: IAddFee = {
            ...(values as any),
            paymentDate: values.paymentDate?.format('YYYY-MM-DDTHH:mm:ss'),
        };

        dispatch(addFee(body));
    };

    const onBeneficiaryChange = (beneficiaryId: number) => {
        dispatch(fetchBeneficiaryById(beneficiaryId));
    };

    //triggered by onBeneficiaryChange.
    useEffect(() => {
        if (beneficiarySlice.detailsPage.beneficiary.fetchStatus === StatePieceStatus.Success) {
            form.setFieldsValue({
                currencyId: beneficiarySlice.detailsPage.beneficiary.data?.currency?.id || undefined,
            });
        }
    }, [beneficiarySlice.detailsPage.beneficiary.fetchStatus]);

    useEffect(() => {
        if (feeSlice.detailFee.fee.createStatus === StatePieceStatus.Success) {
            navigate('/fees', { replace: true });
        }
    }, [feeSlice.detailFee.fee.createStatus]);

    useEffect(() => {
        let defaultBillableOption = billableOptions.lookup.data.filter((b) => b.isDefault);
        let defaultBillableOptionValue = defaultBillableOption.length > 0 ? defaultBillableOption[0].key : undefined;

        form.setFieldsValue({
            paymentDate: dayjs(),
            billableOptionId: defaultBillableOptionValue,
        });
    }, [billableOptions.lookup.data]);

    /**
     * OnInit
     */
    useEffect(() => {
        dispatch(fetchRegionsLookupList());
        dispatch(fetchProjectsLookupList());
        dispatch(fetchCategoriesAsLookupList());
        dispatch(fetchKindOfFeesLookupList());
        dispatch(fetchBillableOptionsLookupList());
        dispatch(fetchBeneficiariesLookupList());
        dispatch(fetchMaHoldersLookupList());
        dispatch(fetchCurrenciesLookupList());
    }, []);

    if (appSlice.auth.validationCompleted && !appSlice.auth.fees.create) {
        return <Unauthorized />;
    }

    return (
        <React.Fragment>
            <Form
                form={form}
                name="control-hooks"
                onFinish={onFinish}
                layout="vertical"
                onValuesChange={(changedValue: any, values) => {
                    form.setFieldsValue(values);
                }}
                initialValues={{} as IUpdateFeeFormValues}
            >
                <ContentHeader
                    title={`Create a new fee`}
                    breadcrumbs={[
                        {
                            breadcrumbName: 'Fees',
                            path: '/fees',
                        },
                        {
                            breadcrumbName: 'Create',
                            path: `/fees/add`,
                        },
                    ]}
                >
                    <Form.Item>
                        <Space>
                            <Button
                                icon={<CloseOutlined />}
                                onClick={() => navigate(-1)}
                                disabled={feeSlice.detailFee.fee.updateStatus === StatePieceStatus.IsFetching || isLoadingLookupLists}
                            >
                                Close
                            </Button>
                            <Button
                                htmlType="submit"
                                type="primary"
                                icon={<SaveOutlined />}
                                loading={feeSlice.detailFee.fee.updateStatus === StatePieceStatus.IsFetching || isLoadingLookupLists}
                            >
                                Save and close
                            </Button>
                        </Space>
                    </Form.Item>
                </ContentHeader>

                <div className="page-content">
                    <Spin spinning={feeSlice.detailFee?.fee.fetchStatus === StatePieceStatus.IsFetching}>
                        {/* CLIENT INFORMATION */}
                        <Row>
                            <Col span={24}>
                                <label className="group-label">Client Information</label>
                            </Col>
                        </Row>
                        <Row gutter={ROW_GUTTER}>
                            <Col span={12}>
                                <label className="property required">Project</label>
                                <Form.Item name="projectId" rules={[{ required: true, message: "'Project' is required" }]}>
                                    <Select
                                        showSearch
                                        placeholder="Select a project"
                                        filterOption={(input, option) =>
                                            (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                                        }
                                    >
                                        {projects.lookup.data
                                            .filter((f) => !f.isDeleted)
                                            .map((project) => (
                                                <Select.Option key={project.key} value={project.key} disabled={project.isDeleted}>
                                                    {project.value}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <label className="property">Brand name</label>
                                <Form.Item name="brandName" rules={[{ max: 70 }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={ROW_GUTTER}>
                            <Col span={12}>
                                <label className="property required">Beneficiary</label>
                                <Form.Item name="beneficiaryId" rules={[{ required: true, message: "'Beneficiary' is required" }]}>
                                    <Select
                                        showSearch
                                        placeholder="Select a beneficiary"
                                        onChange={onBeneficiaryChange}
                                        filterOption={(input, option) =>
                                            (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                                        }
                                    >
                                        {lookupSlice.beneficiaries.data
                                            .filter((b) => !b.isDeleted)
                                            .map((b) => (
                                                <Select.Option key={b.key} value={b.key}>
                                                    {b.value}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <label className="property required">Marketing authorization holder</label>
                                <Form.Item
                                    name="maHolderId"
                                    rules={[{ required: true, message: "'Marketing authorization holder' is required" }]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select a MA holder"
                                        filterOption={(input, option) =>
                                            (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                                        }
                                    >
                                        {maHolders.lookup.data
                                            .filter((f) => !f.isDeleted)
                                            .map((mah) => (
                                                <Select.Option key={mah.key} value={mah.key} disabled={mah.isDeleted}>
                                                    {mah.description}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={ROW_GUTTER}>
                            <Col span={12}>
                                <label className="property">Procedure code</label>
                                <Form.Item name="procedureCode" rules={[{ max: 50 }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <label className="property">Variable code</label>
                                <Form.Item name="variableCode" rules={[{ max: 50 }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        {/* FEE INFORMATION */}
                        <Row className="margin-top">
                            <Col span={24}>
                                <Space>
                                    <label className="group-label">Fee Information</label>
                                </Space>
                            </Col>
                        </Row>
                        <Row gutter={ROW_GUTTER}>
                            <Col span={8}>
                                <label className="property">Type</label>
                                <Form.Item name="kindOfFeeId">
                                    <Select placeholder="Select a kind of fee">
                                        {kindOfFees.lookup.data
                                            .filter((f) => !f.isDeleted)
                                            .map((k) => (
                                                <Select.Option key={k.key} value={k.key} disabled={k.isDeleted}>
                                                    {k.description}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <label className="property required">Category</label>
                                <Form.Item name="categoryId" rules={[{ required: true, message: "'Category' is required" }]}>
                                    <Select
                                        showSearch
                                        placeholder="Select a category"
                                        filterOption={(input, option) =>
                                            (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                                        }
                                    >
                                        {categories.lookup.data
                                            .filter((f) => !f.isDeleted)
                                            .map((category) => (
                                                <Select.Option key={category.key} value={category.key} disabled={category.isDeleted}>
                                                    {category.description}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <label className="property">Billable</label>
                                <Form.Item name="billableOptionId">
                                    <Select placeholder="Select a option">
                                        {billableOptions.lookup.data
                                            .filter((f) => !f.isDeleted)
                                            .map((opt) => (
                                                <Select.Option key={opt.key} value={opt.key} disabled={opt.isDeleted}>
                                                    {opt.description}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={ROW_GUTTER}>
                            <Col span={8}>
                                <label className="property">Region</label>
                                <Form.Item name="regionId">
                                    <Select
                                        showSearch
                                        placeholder="Select a region"
                                        filterOption={(input, option) =>
                                            (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                                        }
                                    >
                                        {regions.lookup.data
                                            .filter((f) => !f.isDeleted)
                                            .map((region) => (
                                                <Select.Option key={region.key} value={region.key} disabled={region.isDeleted}>
                                                    {region.description}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <label className="property required">Amount</label>
                                <Form.Item name="amount" rules={[{ required: true, message: "'Amount' is required" }]}>
                                    <InputNumber decimalSeparator={DECIMAL_SEPARATOR}></InputNumber>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <label className="property required">Currency</label>
                                <Form.Item name="currencyId" rules={[{ required: true, message: "'Currency' is required" }]}>
                                    <Select
                                        showSearch
                                        placeholder="Select a currency"
                                        filterOption={(input, option) =>
                                            (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                                        }
                                    >
                                        {currencies.lookup.data
                                            .filter((f) => !f.isDeleted)
                                            .map((currency) => (
                                                <Select.Option key={currency.key} value={currency.key} disabled={currency.isDeleted}>
                                                    {currency.description}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        {/* PAYMENT INFORMATION */}
                        <Row className="margin-top">
                            <Col span={24}>
                                <label className="group-label">Payment Information</label>
                            </Col>
                        </Row>
                        <Row gutter={ROW_GUTTER}>
                            <Col span={8}>
                                <label className="property">Desired payment date</label>
                                <Form.Item name="paymentDate">
                                    <DatePicker />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <label className="property">Invoice number</label>
                                <Form.Item name="invoiceNumber" rules={[{ max: 255 }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <label className="property required">Reason of payment</label>
                                <Form.Item
                                    name="reasonOfPayment"
                                    rules={[{ required: true, message: "'Reason of payment' is required", max: 255 }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Spin>
                </div>
            </Form>
        </React.Fragment>
    );
};
