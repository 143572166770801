import { DeleteFilled, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, App as AntApp, Row, Space, Spin, Tag, Tooltip } from 'antd';
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { StatePieceStatus } from '../../../../enums';
import { ContentHeader } from '../../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { FetchResult } from '../../../../shared/components/fetchResult/FetchResult';
import { ROW_GUTTER } from '../../../../shared/Constants';
import { generateReturnUrl, getReturnUrl, isError } from '../../../../shared/helpers';
import { AppDispatch, RootState } from '../../../../store';
import { deleteUser, fetchUserById } from '../../../../store/userSlice/UserActions';
import { resetState } from '../../../../store/userSlice/UserSlice';
import './AdminUsersDetail.less';
import { NotFound } from '../../../notFound/NotFound';

export const AdminUsersDetail = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const { modal } = AntApp.useApp();
    const { userSlice } = useSelector((state: RootState) => state);
    const userId = params.id ? parseInt(params.id) : undefined;

    const removeUser = () => {
        modal.confirm({
            title: 'Delete user',
            icon: <ExclamationCircleOutlined />,
            content: "Are you sure you want to delete this user? This action can't be undone.",
            okText: 'Confirm',
            cancelText: 'Cancel',
            onOk: () => {
                if (userId) dispatch(deleteUser(userId));
            },
        });
    };

    const isLoadingContent = () => {
        return userSlice.detailUser?.user.fetchStatus === StatePieceStatus.IsFetching;
    };

    useEffect(() => {
        if (userId) {
            dispatch(fetchUserById(userId));
        }

        return () => {
            dispatch(resetState());
        };
    }, [userId]);

    useEffect(() => {
        if (userSlice.detailUser.user.deleteStatus === StatePieceStatus.Success) {
            let returnUrl = getReturnUrl();
            navigate(returnUrl ? returnUrl : '/admin/users', { replace: true });
        }
    }, [userSlice.detailUser.user.deleteStatus]);

    const pageTitle = isError(userSlice.detailUser?.user.fetchStatus)
        ? `User #${params.id}`
        : `User #${params.id} for ${userSlice.detailUser?.user.data?.name || '-'}`;

    if (!userId) {
        return <NotFound />;
    }

    return (
        <React.Fragment>
            <ContentHeader
                title={pageTitle}
                breadcrumbs={[
                    {
                        breadcrumbName: 'Users',
                        path: '/admin/users',
                    },
                ]}
            >
                {!isError(userSlice.detailUser?.user.fetchStatus) && (
                    <>
                        <Button type="primary" danger icon={<DeleteFilled />} loading={isLoadingContent()} onClick={() => removeUser()}>
                            Delete user
                        </Button>

                        <Button
                            type="default"
                            icon={<EditOutlined />}
                            onClick={() => navigate(`${generateReturnUrl(`${window.location.pathname}/edit`)}`)}
                        >
                            Edit user details
                        </Button>
                    </>
                )}
            </ContentHeader>

            <div className="page-content">
                <Spin spinning={isLoadingContent()}>
                    {isError(userSlice.detailUser?.user.fetchStatus) ? (
                        <FetchResult status={userSlice.detailUser?.user.fetchStatus} about={'User'} />
                    ) : (
                        <>
                            <Row>
                                <Col span={24}>
                                    <Space>
                                        <label className="group-label">User Information</label>
                                    </Space>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={6}>
                                    <label className="property">Id</label>
                                    <p>{userSlice.detailUser?.user.data?.id || '-'}</p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">Initials</label>
                                    <p>{userSlice.detailUser?.user.data?.initials || '-'}</p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">Name</label>
                                    <p>{userSlice.detailUser?.user.data?.name || '-'}</p>
                                </Col>
                                <Col span={6}>
                                    <label className="property">Username</label>
                                    <p>{userSlice.detailUser?.user.data?.userPrincipalName || '-'}</p>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={12}>
                                    <label className="property">Roles</label>
                                    {userSlice.detailUser?.user?.data?.roles.map((userRole, i) => {
                                        return (
                                            <Tooltip title={userRole.description} key={i}>
                                                <Tag key={userRole.code}>{userRole.code}</Tag>
                                            </Tooltip>
                                        );
                                    })}
                                </Col>
                            </Row>

                            {/* INTERNAL INFORMATION */}
                            <Row className="margin-top">
                                <Col span={24}>
                                    <label className="group-label">Internal Information</label>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={8}>
                                    <label className="property">Created by</label>
                                    <p>{userSlice.detailUser.user.data?.createdByUser?.name || '-'}</p>
                                </Col>
                                <Col span={8}>
                                    <label className="property">Created on</label>
                                    <p className="capitalize">
                                        {userSlice.detailUser.user.data?.createdDate
                                            ? dayjs(userSlice.detailUser.user.data?.createdDate).format('MMMM D, YYYY HH:mm')
                                            : '-'}
                                    </p>
                                </Col>
                                <Col span={8}>&nbsp;</Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={8}>
                                    <label className="property">Last modified by</label>
                                    <p>{userSlice.detailUser.user.data?.modifiedByUser?.name || '-'}</p>
                                </Col>
                                <Col span={8}>
                                    <label className="property">Last modified on</label>
                                    <p className="capitalize">
                                        {userSlice.detailUser.user.data?.modifiedDate
                                            ? dayjs(userSlice.detailUser.user.data?.modifiedDate).format('MMMM D, YYYY HH:mm')
                                            : '-'}
                                    </p>
                                </Col>
                                <Col span={8}>&nbsp;</Col>
                            </Row>
                        </>
                    )}
                </Spin>
            </div>
        </React.Fragment>
    );
};
