import { ItemType } from 'antd/lib/menu/hooks/useItems';

/**
 * Returns an Array of string which items in the main menu
 * should be marked as active, based of the Routes current
 * path.
 * @param partOfUrl default = 0, for tab navigation this should be 1.
 */
export const getActiveMenuItems = (menuItems: ItemType[], partOfUrl = 0) => {
    let route = window.location.pathname.slice(1);
    let firstPartOfUrl = route.includes('/') ? route.split('/')[partOfUrl] : route;

    if (firstPartOfUrl.trim() === '') {
        return undefined;
    }

    let activeMenuItems = menuItems
        .filter((m) => m && m.key && m.key.toString().toLowerCase().startsWith(firstPartOfUrl.toLowerCase()))
        .map((m) => m?.key?.toString() || '');

    return activeMenuItems;
};

/**
 * Returns the current browsers URL without the protocol and host.
 * @returns string
 */
export const getCurrentBrowserUrl = () => {
    return window.location.href.replace(window.location.origin, '');
};

/**
 * Generates a URI encoded string as a GET parameter.
 * @returns string
 */
export const generateReturnUrl = (prefix: string, overide?: boolean) => {
    const returnUrlPrefix = `${prefix}?returnUrl=`;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    //return URL already in URL
    if (!overide && params.returnUrl) {
        return `${returnUrlPrefix}${encodeURIComponent(params.returnUrl)}`;
    }

    let currentURL = getCurrentBrowserUrl();
    return currentURL !== '' ? `${returnUrlPrefix}${encodeURIComponent(currentURL)}` : '';
};

/**
 * Returns a decoded string from the returnURL parameter. This is used to redirect
 * users back based on the given URL.
 * @returns string
 */
export const getReturnUrl = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    return params.returnUrl && params.returnUrl.length > 0 ? decodeURIComponent(params.returnUrl) : '';
};
