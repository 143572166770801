import {
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    EllipsisOutlined,
    ExclamationCircleOutlined,
    FilePdfOutlined,
    PlusCircleOutlined,
    RightOutlined,
    SearchOutlined,
    UndoOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Input, InputRef, App as AntApp, Space, Table, Tooltip, MenuProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ColumnType, FilterConfirmProps, FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { InvoiceStatuses, StatePieceStatus } from '../../../enums';
import { RowSelection } from '../../../interfaces';
import { IInvoiceTableRow } from '../../../interfaces/fees';
import { IUser } from '../../../interfaces/users/data';
import { ContentHeader } from '../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { SearchBar } from '../../../shared/components/filters/SearchBar';
import { HighlightedText } from '../../../shared/components/highlightedText/HighlightedText';
import { Unauthorized } from '../../../shared/components/unauthorized/Unauthorized';
import { generateReturnUrl, invoice_isEditable } from '../../../shared/helpers';
import { formatAsCurrency } from '../../../shared/helpers/formatHelper';
import { calculateOverviewTableHeight } from '../../../shared/helpers/TableHeightHelper';
import { AppDispatch, RootState } from '../../../store';
import {
    deleteInvoicesBatch,
    fetchInvoices,
    fetchInvoicesExportAsPDF,
    fetchInvoicesExportData,
} from '../../../store/invoiceSlice/InvoiceActions';
import { resetState } from '../../../store/invoiceSlice/InvoiceSlice';
import {
    fetchBeneficiariesLookupList,
    fetchCustomersLookupList,
    fetchFeeStatuses,
    fetchInvoiceStatuses,
    fetchProjectsLookupList,
    fetchUsersLookupList,
} from '../../../store/lookupSlice/LookupActions';
import { InvoiceStatusChangeModal } from '../invoiceStatusChangeModal/InvoiceStatusChangeModal';
import './InvoiceOverview.less';
import { getFilteringValues, QueryParamHelpers, tableHasFiltersActive, updateQueryParams } from '../../../shared/helpers/QueryParamHelpers';
import { IFeeStatus } from '../../../interfaces/lookup/feeStatus/data';
import { ITableFilter } from '../../../interfaces/ITableFilter';
import { ICustomer } from '../../../interfaces/customer/data';

interface DataType {
    procedureCode: string;
    reasonOfPayment: string;
}

type DataIndex = keyof DataType;

export const InvoiceOverview = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { modal } = AntApp.useApp();
    const { appSlice, invoiceSlice, lookupSlice } = useSelector((state: RootState) => state);
    const [tableRowSelection, setTableRowSelection] = useState<RowSelection<IInvoiceTableRow>>({}); // Accross all pages
    const [selectedTableRecords, setSelectedTabelRecords] = useState<IInvoiceTableRow[]>([]); // Accumulated results from TableRowSelection
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]); // Accumulated results from TableRowSelection
    const [statusChangerVisible, setStatusChangerVisible] = useState(false);
    const [statusChangerInvalidStatus, setStatusChangerInvalidStatus] = useState(false);
    const [generateInvoicesInvalidStatus, setGenerateInvoicesInvalidStatus] = useState(false);
    const [tableRenderHeight, setTableRenderHeight] = useState<number>();
    const [procedureCodeSearchText, setProcedureCodeSearchText] = useState('');
    const [reasonOfPaymentSearchText, setReasonOfPaymentSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);
    const [columns, setColumns] = useState<ColumnsType<IInvoiceTableRow>>([]);

    const [filtering, setFiltering] = useState<ITableFilter>(
        getFilteringValues(window.location.search, columns, invoiceSlice.invoices.data.paging.totalItems)
    );

    const getColumnSearchProps = (dataIndex: DataIndex, label: string): ColumnType<IInvoiceTableRow> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
            let searchText = dataIndex === 'procedureCode' ? procedureCodeSearchText : reasonOfPaymentSearchText;
            let currentInputValue = searchInput.current?.input?.value || undefined;
            selectedKeys = currentInputValue !== undefined ? [searchText] : [];

            return (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        ref={searchInput}
                        // Required to be this dynamic to force React to rerender this input (no changes are noticed because we use it uncontrolled (defaultValue)).
                        // Making this a controlled component is not feasible due to the amount of rerenders otherwise, the user will experience input lag.
                        key={searchText}
                        placeholder={`Search ${label}`}
                        defaultValue={searchText}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                    </Space>
                </div>
            );
        },
        filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => {
            //NOTE: this now only works for properties in Fees!
            return record && record[dataIndex]
                ? //@ts-ignore
                  record.fee[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes((value as string).toLowerCase())
                : false;
        },
        onFilterDropdownOpenChange(open) {
            if (open) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[dataIndex === 'procedureCode' ? procedureCodeSearchText : reasonOfPaymentSearchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const resetFilters = () => {
        setFiltering({});
        setProcedureCodeSearchText('');
        setReasonOfPaymentSearchText('');
        navigate({ search: '' }, { replace: true });
    };

    const updateQuery = (
        filters: Record<string, FilterValue | null> | undefined,
        sorter: SorterResult<IInvoiceTableRow> | undefined,
        searchQuery: string,
        pagination: TablePaginationConfig | undefined
    ) => {
        setFiltering({ ...filtering, _sorter: sorter, filters: filters, searchQuery: searchQuery, pagination: pagination });
        let newQueryUrl = updateQueryParams(filters, sorter, searchQuery, pagination);
        navigate({ search: newQueryUrl }, { replace: true });
    };

    const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => {
        //if we update a searchbox of procedureCode to having NO value we need to trigger the filtering
        //as AntDesign confirm() prevents a filtering update because there is no value.
        if (dataIndex === 'procedureCode' && selectedKeys[0] === undefined) {
            setFiltering({
                ...filtering,
                filters: {
                    ...filtering.filters,
                    'fee.procedureCode': null,
                },
            });
        } else if (dataIndex === 'reasonOfPayment' && selectedKeys[0] === undefined) {
            setFiltering({
                ...filtering,
                filters: {
                    ...filtering.filters,
                    reasonOfPayment: null,
                },
            });
        } else {
            if (dataIndex === 'procedureCode') {
                setProcedureCodeSearchText(selectedKeys[0]);
            }
            if (dataIndex === 'reasonOfPayment') {
                setReasonOfPaymentSearchText(selectedKeys[0]);
            }
            setSearchedColumn(dataIndex);
        }
        confirm();
    };

    const fetchInvoicesPage = () => {
        const sorter = filtering._sorter as SorterResult<IInvoiceTableRow[]>;

        dispatch(
            fetchInvoices({
                pageSize: filtering.pagination?.pageSize,
                pageNumber: filtering.pagination?.current,
                sorter,
                statusCode: (filtering.filters?.status as string[]) || undefined,
                feeStatusCode: filtering.filters ? (filtering.filters['fee.status.code'] as string[]) : undefined,
                projectId: filtering.filters ? (filtering.filters['fee.project.code'] as string[]) : undefined,
                customerId: filtering.filters ? (filtering.filters['customer'] as string[]) : undefined,
                beneficiaryId: filtering.filters ? (filtering.filters['fee.beneficiary'] as string[]) : undefined,
                createdById: filtering.filters ? (filtering.filters['createdByUser'] as string[]) : undefined,
                procedureCode:
                    filtering.filters && filtering.filters['fee.procedureCode'] && filtering.filters['fee.procedureCode']?.length > 0
                        ? (filtering.filters['fee.procedureCode'][0] as string)
                        : undefined,
                reasonOfPayment:
                    filtering.filters && filtering.filters['reasonOfPayment'] && filtering.filters['reasonOfPayment']?.length > 0
                        ? (filtering.filters['reasonOfPayment'][0] as string)
                        : undefined,
                searchQuery: filtering.searchQuery,
            })
        );
    };

    /**
     * Create a useEffect based on the params. If the param changes to blanco and the properties
     * '_sorter' or 'filters' exists on state 'filtering' then the user navigated back to this page
     * from the left main menu.
     *
     * If '_sorter' or 'filters' does not exists the user was already on this page without any filters
     * or sortings active.
     */
    useEffect(() => {
        if (window.location.search === '' && (filtering._sorter || filtering.filters)) {
            resetFilters();
        }
    }, [window.location.search]);

    useEffect(() => {
        //onLoad set the searchText of procedure code if it's given
        if (filtering.filters && filtering.filters['procedureCode'] && filtering.filters['procedureCode']?.length > 0) {
            setProcedureCodeSearchText(filtering.filters['procedureCode'][0].toString());
        }
        if (filtering.filters && filtering.filters['reasonOfPayment'] && filtering.filters['reasonOfPayment']?.length > 0) {
            setReasonOfPaymentSearchText(filtering.filters['reasonOfPayment'][0].toString());
        }

        setColumns([
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
                fixed: 'left',
                width: 80,
                sorter: true,
                filteredValue: null,
                sortOrder:
                    (filtering._sorter as SorterResult<IInvoiceTableRow>)?.columnKey === 'id'
                        ? (filtering._sorter as SorterResult<IInvoiceTableRow>)?.order
                        : undefined,
            },
            {
                title: 'Fee status',
                dataIndex: 'fee.status.code',
                key: 'fee.status.code',
                fixed: 'left',
                width: 110,
                filters: lookupSlice.feeStatuses.data.map((s) => ({ text: s.code, value: s.code })) || undefined,
                filterSearch: (input: string, record: any) => record.text.toLowerCase().startsWith(input.toLowerCase()),
                render: (_, record) => (
                    <>
                        {record.fee.status && record.fee.status.code && (
                            <Tooltip title={record.fee.status.description}>{record.fee.status.code}</Tooltip>
                        )}
                    </>
                ),
                filteredValue: (filtering.filters && filtering.filters['fee.status.code']) || null,
            },
            {
                title: 'Payment date',
                dataIndex: 'fee.paymentDate',
                key: 'fee.paymentDate',
                sorter: true,
                ellipsis: {
                    showTitle: true,
                },
                width: 140,
                filteredValue: null,
                render: (_, record) => <>{record.fee.paymentDate ? dayjs(record.fee.paymentDate).format('DD-MM-YYYY') : ''}</>,
                sortOrder:
                    (filtering._sorter as SorterResult<IInvoiceTableRow>)?.columnKey === 'fee.paymentDate'
                        ? (filtering._sorter as SorterResult<IInvoiceTableRow>)?.order
                        : undefined,
            },
            {
                title: 'Fee Id',
                dataIndex: 'fee.id',
                key: 'fee.id',
                width: 80,
                filteredValue: null,
                sorter: true,
                sortOrder:
                    (filtering._sorter as SorterResult<IInvoiceTableRow>)?.columnKey === 'fee.id'
                        ? (filtering._sorter as SorterResult<IInvoiceTableRow>)?.order
                        : undefined,
                render: (_, record) => (
                    <span>
                        <HighlightedText text={record.fee.id.toString() || ''} textToHighlight={filtering.searchQuery} />
                    </span>
                ),
            },
            {
                title: 'By',
                dataIndex: 'createdByUser',
                key: 'createdByUser',
                sorter: false,
                width: 80,
                filterSearch: (input: string, record: any) => record.text.toLowerCase().startsWith(input.toLowerCase()),
                filters: [
                    ...lookupSlice.users.data.filter((b) => !b.isDeleted).map((s) => ({ text: s.value || '', value: s.key || '' })),
                    {
                        text: 'Removed users',
                        value: '',
                        children: lookupSlice.users.data
                            .filter((b) => b.isDeleted)
                            .map((s) => ({ text: s.value || '', value: s.key || '' })),
                    },
                ],
                render: (createdByUser: IUser) => (
                    <>{createdByUser && createdByUser.initials && <Tooltip title={createdByUser.name}>{createdByUser.initials}</Tooltip>}</>
                ),
                filteredValue: filtering.filters?.createdByUser || null,
            },
            {
                title: 'Customer',
                dataIndex: 'customer',
                key: 'customer',
                sorter: true,
                sortOrder:
                    (filtering._sorter as SorterResult<IInvoiceTableRow>)?.columnKey === 'customer'
                        ? (filtering._sorter as SorterResult<IInvoiceTableRow>)?.order
                        : undefined,
                ellipsis: {
                    showTitle: true,
                },
                filterSearch: (input: string, record: any) => record.text.toLowerCase().startsWith(input.toLowerCase()),
                filters: [
                    ...lookupSlice.customers.data.filter((b) => !b.isDeleted).map((s) => ({ text: s.value || '', value: s.key || '' })),
                    {
                        text: 'Removed customers',
                        value: '',
                        children: lookupSlice.customers.data
                            .filter((b) => b.isDeleted)
                            .map((s) => ({ text: s.value || '', value: s.key || '' })),
                    },
                ],
                filteredValue: filtering.filters?.customer || null,
                render: (customer: ICustomer) => (
                    <Tooltip title={customer.name}>
                        <span>
                            <HighlightedText text={customer.searchName || ''} textToHighlight={filtering.searchQuery} />
                        </span>
                    </Tooltip>
                ),
            },
            {
                title: 'Project',
                dataIndex: 'fee.project.code',
                key: 'fee.project.code',
                sorter: false,
                ellipsis: {
                    showTitle: true,
                },
                width: 110,
                render: (_, record) => (
                    <Tooltip title={record.fee?.project?.description}>
                        <span>
                            <HighlightedText text={record.fee?.project?.code || ''} textToHighlight={filtering.searchQuery} />
                        </span>
                    </Tooltip>
                ),
                filterSearch: (input: string, record: any) => record.text.toLowerCase().startsWith(input.toLowerCase()),
                filters: [
                    ...lookupSlice.projects.lookup.data
                        .filter((b) => !b.isDeleted)
                        .map((s) => ({ text: s.value || '', value: s.key || '' })),
                    {
                        text: 'Removed projects',
                        value: '',
                        children: lookupSlice.projects.lookup.data
                            .filter((b) => b.isDeleted)
                            .map((s) => ({ text: s.value || '', value: s.key || '' })),
                    },
                ],
                filteredValue: (filtering.filters && filtering.filters['fee.project.code']) || null,
            },
            {
                title: 'Created date',
                dataIndex: 'createdDate',
                key: 'createdDate',
                sorter: true,
                ellipsis: {
                    showTitle: true,
                },
                width: 140,
                filteredValue: null,
                render: (text: string) => <>{text ? dayjs(text).format('DD-MM-YYYY') : ''}</>,
                sortOrder:
                    (filtering._sorter as SorterResult<IInvoiceTableRow>)?.columnKey === 'createdDate'
                        ? (filtering._sorter as SorterResult<IInvoiceTableRow>)?.order
                        : undefined,
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                sorter: true,
                width: 100,
                filteredValue: null,
                sortOrder:
                    (filtering._sorter as SorterResult<IInvoiceTableRow>)?.columnKey === 'amount'
                        ? (filtering._sorter as SorterResult<IInvoiceTableRow>)?.order
                        : undefined,
                render: (_, record) => (
                    <span>
                        <HighlightedText text={formatAsCurrency(record.amount) || ''} textToHighlight={filtering.searchQuery} />
                    </span>
                ),
            },
            {
                title: 'Procedure code',
                dataIndex: 'fee.procedureCode',
                key: 'fee.procedureCode',
                sorter: false,
                ellipsis: {
                    showTitle: true,
                },
                filteredValue: null,
                ...getColumnSearchProps('procedureCode', 'Procedure code'),
                render: (_, record) => (
                    <Tooltip title={record.fee.procedureCode || undefined}>
                        <span>
                            <HighlightedText text={record.fee.procedureCode || ''} textToHighlight={filtering.searchQuery} />
                        </span>
                    </Tooltip>
                ),
            },
            {
                title: 'Beneficiary',
                dataIndex: 'fee.beneficiary',
                key: 'fee.beneficiary',
                sorter: false,
                ellipsis: {
                    showTitle: true,
                },
                filteredValue: (filtering.filters && filtering.filters['fee.beneficiary']) || null,
                filterSearch: (input: string, record: any) => record.text.toLowerCase().startsWith(input.toLowerCase()),
                filters: [
                    ...lookupSlice.beneficiaries.data.filter((b) => !b.isDeleted).map((s) => ({ text: s.value || '', value: s.key || '' })),
                    {
                        text: 'Removed beneficiaries',
                        value: '',
                        children: lookupSlice.beneficiaries.data
                            .filter((b) => b.isDeleted)
                            .map((s) => ({ text: s.value || '', value: s.key || '' })),
                    },
                ],
                render: (_, record) => (
                    <Tooltip title={record.fee.beneficiary.searchName || undefined}>
                        <span>
                            <HighlightedText text={record.fee.beneficiary.searchName || ''} textToHighlight={filtering.searchQuery} />
                        </span>
                    </Tooltip>
                ),
            },
            {
                title: 'Brand name',
                dataIndex: 'fee.brandName',
                key: 'fee.brandName',
                sorter: false,
                ellipsis: {
                    showTitle: true,
                },
                filteredValue: null,
                render: (_, record) => (
                    <Tooltip title={record.fee.brandName || undefined}>
                        <span>
                            <HighlightedText text={record.fee.brandName || ''} textToHighlight={filtering.searchQuery} />
                        </span>
                    </Tooltip>
                ),
            },
            {
                title: 'Reason of payment',
                dataIndex: 'reasonOfPayment',
                key: 'reasonOfPayment',
                sorter: false,
                ellipsis: {
                    showTitle: true,
                },
                filteredValue: null,
                ...getColumnSearchProps('reasonOfPayment', 'Reason of payment'),
                render: (_, record) => (
                    <Tooltip title={record.reasonOfPayment || undefined}>
                        <span>
                            <HighlightedText text={record.reasonOfPayment || ''} textToHighlight={filtering.searchQuery} />
                        </span>
                    </Tooltip>
                ),
            },
            {
                title: 'Status date',
                dataIndex: 'statusChangedDate',
                key: 'statusChangedDate',
                sorter: true,
                ellipsis: {
                    showTitle: true,
                },
                width: 120,
                filteredValue: null,
                render: (text: string) => <>{text ? dayjs(text).format('DD-MM-YYYY') : ''}</>,
                sortOrder:
                    (filtering._sorter as SorterResult<IInvoiceTableRow>)?.columnKey === 'statusChangedDate'
                        ? (filtering._sorter as SorterResult<IInvoiceTableRow>)?.order
                        : undefined,
            },
            {
                title: 'DocID',
                dataIndex: 'documentId',
                key: 'documentId',
                width: 80,
                render: (_, record) => (
                    <>
                        {record.documents?.map((d) => (
                            <Space>
                                <span>{d.documentId}</span>
                            </Space>
                        ))}
                    </>
                ),
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                fixed: 'right',
                width: 80,
                filteredValue: filtering.filters?.status || null,
                filters: lookupSlice.invoiceStatuses.data.map((s) => ({ text: s.code, value: s.code })) || undefined,
                filterSearch: (input: string, record: any) => record.text.toLowerCase().startsWith(input.toLowerCase()),
                render: (status: IFeeStatus) => <>{status && status.code && <Tooltip title={status.description}>{status.code}</Tooltip>}</>,
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                width: 65,
                fixed: 'right',
                filteredValue: null,
                render: (_, record) => (
                    <Space size="middle">
                        <Button type="text" icon={<RightOutlined />} onClick={() => navigate(`${generateReturnUrl(`${record.id}`)}`)} />
                    </Space>
                ),
            },
        ]);
    }, [invoiceSlice.invoices.data, filtering]);

    const checkForInvalidStatuses = (selectedRowKeys: React.Key[]) => {
        // We retrieve the item from the store here since it can be updated in the meanwhile, while the local state hasn't updated.
        // This occurs when the status is changed.
        let accumulatedSelectedRecords = invoiceSlice.invoices.data.items.filter((r) => selectedRowKeys.indexOf(r.id.toString()) !== -1);

        let hasInvalidStatuses = false;
        accumulatedSelectedRecords.forEach((record) => {
            if (!appSlice.auth.admin.view && !invoice_isEditable(record)) {
                hasInvalidStatuses = true;
            }
        });
        setStatusChangerInvalidStatus(hasInvalidStatuses);

        //update status for
        let hasInvalidGenereateInvoiceStatuses = false;
        accumulatedSelectedRecords.forEach((record) => {
            if (record.status.code !== InvoiceStatuses.ApprovedForPayment) {
                hasInvalidGenereateInvoiceStatuses = true;
            }
        });
        setGenerateInvoicesInvalidStatus(hasInvalidGenereateInvoiceStatuses);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[], selectedRows: IInvoiceTableRow[]) => {
            // #region determine the new tableRowSelection and selection keys
            const currentPage = filtering.pagination?.current || 1;
            let newSelectedTableRecordsObj = { ...tableRowSelection };
            if (selectedRows.length === 0) {
                delete newSelectedTableRecordsObj[currentPage];
            } else {
                newSelectedTableRecordsObj[currentPage] = selectedRows;
            }
            setTableRowSelection(newSelectedTableRecordsObj);

            let accumulatedSelectedRecords = [] as IInvoiceTableRow[];
            Object.keys(newSelectedTableRecordsObj).forEach((key: string) => {
                accumulatedSelectedRecords = [...accumulatedSelectedRecords, ...newSelectedTableRecordsObj[key]];
            });
            setSelectedTabelRecords(accumulatedSelectedRecords);
            const accumulatedSelectedKeys = accumulatedSelectedRecords.map((r) => r.id.toString());
            setSelectedRowKeys(accumulatedSelectedKeys);
            // #endregion

            checkForInvalidStatuses(accumulatedSelectedKeys);
        },
    };

    useEffect(() => {
        if (invoiceSlice.updateInvoiceStatusesStatus === StatePieceStatus.Success) {
            checkForInvalidStatuses(selectedRowKeys);
        }
    }, [invoiceSlice.updateInvoiceStatusesStatus]);

    useEffect(() => {
        let renderTableHeight = calculateOverviewTableHeight();
        setTableRenderHeight(renderTableHeight);

        // Invoices are fetched via the UseEffect on the filtering
        dispatch(fetchInvoiceStatuses());
        dispatch(fetchFeeStatuses());
        dispatch(fetchProjectsLookupList());
        dispatch(fetchCustomersLookupList());
        dispatch(fetchBeneficiariesLookupList());
        dispatch(fetchUsersLookupList());

        return () => {
            dispatch(resetState());
        };
    }, []);

    useEffect(() => {
        fetchInvoicesPage();
    }, [filtering]);

    useEffect(() => {
        if (invoiceSlice.deleteInvoicesBatchStatus === StatePieceStatus.Success) {
            fetchInvoicesPage();
            setSelectedTabelRecords([]);
            setSelectedRowKeys([]);
            setTableRowSelection({});
        }
    }, [invoiceSlice.deleteInvoicesBatchStatus]);

    /**
     * We use a random key attribute to force a re-render on the component. This is required
     * because we cannot updae the defaultValue of the AntDesign's search input component. We need
     * the ability to clear the value for resetting.
     *
     * See more: https://stackoverflow.com/questions/30626030/can-you-force-a-react-component-to-rerender-without-calling-setstate
     * @param defaultValue
     * @returns
     */
    const renderSearchBar = () => {
        return (
            <SearchBar
                key={Math.random()}
                loading={invoiceSlice.invoices.fetchStatus === StatePieceStatus.IsFetching}
                placeholder="Search in invoices..."
                onSearch={(value) => {
                    updateQuery(
                        { ...filtering.filters },
                        { ...(filtering._sorter as SorterResult<IInvoiceTableRow>) },
                        value,
                        filtering.pagination
                    );
                }}
                defaultValue={filtering?.searchQuery}
            />
        );
    };
    const getSelectedRecordsMenuItems = (): MenuProps => {
        const menuProps: MenuProps = { items: [] };

        if (appSlice.auth.invoices.generatePdf) {
            menuProps.items!.push({
                key: '1',
                icon: <FilePdfOutlined />,
                onClick: () => dispatch(fetchInvoicesExportAsPDF(selectedTableRecords.map((i) => i.id))),
                disabled: generateInvoicesInvalidStatus || invoiceSlice.exportInvoicesAsPfdStatus === StatePieceStatus.IsFetching,
                label: (
                    <Tooltip
                        placement="rightTop"
                        title={`${
                            generateInvoicesInvalidStatus ? 'One or more invoices does not have the status Approved for Payment.' : ''
                        }`}
                    >
                        {`Generate ${selectedTableRecords.length} ${selectedTableRecords.length > 1 ? 'attachments' : 'attachment'}`}
                    </Tooltip>
                ),
            });
        }
        if (appSlice.auth.invoices.view) {
            menuProps.items!.push({
                key: '2',
                icon: <DownloadOutlined />,
                onClick: () => dispatch(fetchInvoicesExportData(selectedTableRecords.map((i) => i.id))),
                disabled:
                    invoiceSlice.exportInvoicesStatus === StatePieceStatus.IsFetching ||
                    invoiceSlice.exportInvoicesAsPfdStatus === StatePieceStatus.IsFetching,
                label: <>{`Export ${selectedTableRecords.length} ${selectedTableRecords.length > 1 ? 'invoices' : 'invoice'}`}</>,
            });
        }
        if (appSlice.auth.invoices.delete) {
            menuProps.items!.push({
                key: '3',
                icon: <DeleteOutlined />,
                onClick: () =>
                    modal.confirm({
                        title: `Delete ${selectedTableRecords.length} ${selectedTableRecords.length > 1 ? 'invoices' : 'invoice'}`,
                        icon: <ExclamationCircleOutlined />,
                        content: "Are you sure you want to delete the selected invoices? This action can't be undone.",
                        okText: 'Confirm',
                        cancelText: 'Cancel',
                        onOk: () => {
                            dispatch(deleteInvoicesBatch(selectedTableRecords.map((x) => x.id)));
                        },
                    }),
                disabled: appSlice.me.info.data === null || invoiceSlice.deleteInvoicesBatchStatus === StatePieceStatus.IsFetching,
                label: <>{`Delete ${selectedTableRecords.length} ${selectedTableRecords.length > 1 ? 'invoices' : 'invoice'} `}</>,
            });
        }

        return menuProps;
    };

    if (appSlice.auth.validationCompleted && !appSlice.auth.invoices.view) {
        return <Unauthorized />;
    }

    return (
        <React.Fragment>
            <ContentHeader
                title="Invoices"
                breadcrumbs={[
                    {
                        breadcrumbName: 'Invoices',
                        path: '/invoices',
                    },
                ]}
            >
                <Space>
                    {selectedTableRecords.length > 0 && (
                        <>
                            <Dropdown placement="bottomRight" menu={getSelectedRecordsMenuItems()}>
                                <Button icon={<EllipsisOutlined />} />
                            </Dropdown>

                            {appSlice.auth.invoices.changeStatus && (
                                <Tooltip
                                    title={
                                        statusChangerInvalidStatus
                                            ? 'You have selected an invoice with a status that you are not authorized to change.'
                                            : undefined
                                    }
                                >
                                    <Button
                                        icon={<UndoOutlined />}
                                        onClick={() => setStatusChangerVisible(true)}
                                        loading={appSlice.me.info.data === null}
                                        disabled={
                                            statusChangerInvalidStatus ||
                                            invoiceSlice.exportInvoicesAsPfdStatus === StatePieceStatus.IsFetching
                                        }
                                    >
                                        Change status for {selectedTableRecords.length}{' '}
                                        {selectedTableRecords.length > 1 ? 'invoices' : 'invoice'}
                                    </Button>
                                </Tooltip>
                            )}
                            {appSlice.auth.invoices.edit && (
                                <Button
                                    type="primary"
                                    icon={<EditOutlined />}
                                    onClick={() => {
                                        var returnUrl = generateReturnUrl(`${selectedTableRecords[0].id}/edit`);
                                        returnUrl = QueryParamHelpers.appendEidsQueryParam(returnUrl, selectedTableRecords);
                                        navigate(returnUrl);
                                    }}
                                >
                                    Edit {selectedTableRecords.length} {selectedTableRecords.length > 1 ? 'invoices' : 'invoice'}
                                </Button>
                            )}
                        </>
                    )}

                    {appSlice.auth.invoices.create && (
                        <Button
                            type="primary"
                            icon={<PlusCircleOutlined />}
                            onClick={() => navigate(`add`)}
                            disabled={invoiceSlice.exportInvoicesAsPfdStatus === StatePieceStatus.IsFetching}
                        >
                            Add new invoice
                        </Button>
                    )}
                </Space>
            </ContentHeader>

            <div className="page-content">
                <div className="searchbar-wrapper">
                    {renderSearchBar()}

                    {tableHasFiltersActive(filtering) && (
                        <Button onClick={() => resetFilters()} className="reset-filters-button">
                            Reset filters
                        </Button>
                    )}
                </div>

                <Table
                    size="small"
                    bordered={true}
                    scroll={{ x: 2215, y: tableRenderHeight }}
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                    }}
                    loading={
                        invoiceSlice.invoices.fetchStatus === StatePieceStatus.IsFetching ||
                        invoiceSlice.exportInvoicesAsPfdStatus === StatePieceStatus.IsFetching ||
                        invoiceSlice.deleteInvoicesBatchStatus === StatePieceStatus.IsFetching
                    }
                    columns={columns}
                    dataSource={
                        // Map fees to an array of IInvoiceTableRow
                        invoiceSlice.invoices.data.items.map((i) => {
                            return { ...i, key: i.id.toString() } as IInvoiceTableRow;
                        })
                    }
                    onChange={(pagination, filters, _sorter) => {
                        updateQuery(filters, _sorter as SorterResult<IInvoiceTableRow>, filtering.searchQuery || '', pagination);
                    }}
                    pagination={{
                        current: invoiceSlice.invoices.data.paging.pageNumber,
                        pageSize: invoiceSlice.invoices.data.paging.pageSize,
                        total: invoiceSlice.invoices.data.paging.totalItems,
                        showSizeChanger: selectedTableRecords.length === 0,
                    }}
                />
            </div>

            <InvoiceStatusChangeModal
                invoices={selectedTableRecords}
                open={statusChangerVisible}
                onCancel={() => setStatusChangerVisible(false)}
            />
        </React.Fragment>
    );
};
