import React from 'react';
import { Result, Button } from 'antd';
import { useMsal } from '@azure/msal-react';
import { WindowsOutlined } from '@ant-design/icons';

import './UnAuthenticated.less';

export const UnAuthenticated = () => {
    const { instance, accounts } = useMsal();

    React.useEffect(() => {
        if (accounts === null || accounts === undefined || accounts.length === 0) {
            instance.loginRedirect();
            return;
        }
    }, []);

    return (
        <div className="unauthenticated">
            <Result
                status="403"
                title="RA Fee Database"
                subTitle="Log in om verder te gaan."
                extra={
                    <Button icon={<WindowsOutlined />} type="primary" onClick={() => instance.loginRedirect()}>
                        Sign in with Microsoft
                    </Button>
                }
            />
        </div>
    );
};
