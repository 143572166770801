import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppRoles, BeneficiaryStatuses, StatePieceStatus } from '../../../enums';
import { IBeneficiary } from '../../../interfaces/beneficiaries';
import { StatusChangeModal } from '../../../shared/components/statusChangeModal/StatusChangeModal';
import { AppDispatch, RootState } from '../../../store';
import { fetchMe } from '../../../store/appSlice/AppActions';
import { updateBeneficiaryStatuses } from '../../../store/beneficiarySlice/BeneficiaryActions';
import { fetchBeneficiaryStatuses } from '../../../store/lookupSlice/LookupActions';

export interface IBeneficiaryStatusChangeModalProps {
    beneficiaries: IBeneficiary[];
    open: boolean;
    onCancel: () => void;
}
export const BeneficiarytatusChangeModal = (props: IBeneficiaryStatusChangeModalProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { beneficiarySlice, lookupSlice, appSlice } = useSelector((state: RootState) => state);

    const isOptionAllowed = (code: string) => {
        // Check if the user is allowed to assign this status.
        if (appSlice.me?.info?.data) {
            var roles = appSlice.me.info.data.roles;
            if (roles.filter((r) => r.code === AppRoles.Admin).length > 0) {
                return true;
            }

            let currentStatusCode = beneficiarySlice.detailsPage.beneficiary.data?.status.code;
            if (currentStatusCode === code) {
                return false;
            }

            switch (code) {
                case BeneficiaryStatuses.Draft:
                    if (currentStatusCode === BeneficiaryStatuses.QueuedForApproval) {
                        return (
                            roles.filter(
                                (r) => r.code === AppRoles.DataEntry || r.code === AppRoles.DataEntryPlus || r.code === AppRoles.Approver
                            ).length > 0
                        );
                    }
                    return false;
                case BeneficiaryStatuses.QueuedForApproval:
                    return (
                        roles.filter(
                            (r) => r.code === AppRoles.DataEntry || r.code === AppRoles.DataEntryPlus || r.code === AppRoles.Approver
                        ).length > 0
                    );
                case BeneficiaryStatuses.Approved:
                    return roles.filter((r) => r.code === AppRoles.Approver || r.code === AppRoles.Payer).length > 0;
            }
        }

        return false;
    };

    const onFinish = (values: any) => {
        dispatch(
            updateBeneficiaryStatuses(
                props.beneficiaries.map((i) => {
                    return {
                        beneficiaryId: i.id,
                        statusCode: values.code,
                        comment: values.comment || '',
                    };
                })
            )
        );
    };

    React.useEffect(() => {
        if (
            lookupSlice.beneficiaryStatuses.fetchStatus === StatePieceStatus.None ||
            lookupSlice.beneficiaryStatuses.fetchStatus === StatePieceStatus.Error
        ) {
            dispatch(fetchBeneficiaryStatuses());
        }

        if (appSlice.me?.info?.data && appSlice.me.info.fetchStatus === StatePieceStatus.None) {
            dispatch(fetchMe());
        }
    }, []);

    React.useEffect(() => {
        if (beneficiarySlice.updateBeneficiaryStatusesStatus === StatePieceStatus.Success) {
            props.onCancel();
        }
    }, [beneficiarySlice.updateBeneficiaryStatusesStatus]);

    const title =
        props.beneficiaries.length === 1
            ? `Change the status of beneficiary #${props.beneficiaries[0].id}`
            : 'Change the status of the selected beneficiaries';
    return (
        <StatusChangeModal
            title={title}
            open={props.open}
            onCancel={props.onCancel}
            loading={beneficiarySlice.updateBeneficiaryStatusesStatus === StatePieceStatus.IsFetching}
            statuses={lookupSlice.beneficiaryStatuses.data}
            isOptionAllowed={(s) => isOptionAllowed(s)}
            onFinish={onFinish}
        />
    );
};
