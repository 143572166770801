// export const getOverviewTableContentHeight = () => {
//     let height = 0;

//     let antTableBodyElement = document.getElementsByClassName('ant-table-body');
//     if (antTableBodyElement.length > 0) {
//         height = antTableBodyElement[0].clientHeight;
//     }

//     return height;
// };

export const calculateOverviewTableHeight = () => {
    let headerElements = document.getElementsByClassName('site-page-header-wrapper');

    if (headerElements.length === 1) {
        /**
         * Get the window screen height
         */
        let screenHeight = window.innerHeight;

        /**
         * Get the height of the header
         */
        let headerHeight = document.getElementsByClassName('site-page-header-wrapper')[0].clientHeight;

        /**
         * Get the height of the searchbar, if shown
         */
        let searchbarElement = document.getElementsByClassName('search-bar');
        let searchbarHeight = 0;
        if (searchbarElement.length === 1) {
            searchbarHeight =
                searchbarElement[0].clientHeight +
                getMarginTopOfElement(searchbarElement[0]) +
                getMarginBottomOfElement(searchbarElement[0]);
        }

        /**
         * Get the height of all the paginations of the table. We don't calculate this
         * because it is rendered later en it would cause a flickering in the UI.
         */
        let paginationHeight = 64;
        // let paginationElements = document.getElementsByClassName('ant-table-pagination');
        // for (let i = 0; i < paginationElements.length; i++) {
        //     const el = paginationElements[i];
        //     paginationHeight += el.clientHeight + getMarginTopOfElement(el) + getMarginBottomOfElement(el);
        // }

        /**
         * Get the height of the content's padding bottom
         */
        let paddingBottomContentDiv = 0;
        let contentElements = document.getElementsByClassName('page-content');
        if (contentElements.length > 0) {
            paddingBottomContentDiv = getPaddingBottomOfElement(contentElements[0]);
        }

        /**
         * Get the height of the table header
         */
        let tableHeaderHeight = 0;
        let tableHeaderElements = document.getElementsByClassName('ant-table-thead');
        if (tableHeaderElements.length > 0) {
            tableHeaderHeight = tableHeaderElements[0].clientHeight;
        }

        //calculate the table height
        let height = screenHeight - headerHeight - searchbarHeight - paddingBottomContentDiv - paginationHeight - tableHeaderHeight;
        return height - 15;
    }
};

const getMarginTopOfElement = (el: Element) => {
    const style = window.getComputedStyle(el);
    let marginTop = style.marginTop.replace('px', '').replace('%', '');
    return parseInt(marginTop);
};

const getMarginBottomOfElement = (el: Element) => {
    const style = window.getComputedStyle(el);
    let marginBottom = style.marginBottom.replace('px', '').replace('%', '');
    return parseInt(marginBottom);
};

const getPaddingBottomOfElement = (el: Element) => {
    const style = window.getComputedStyle(el);
    let paddingBottom = style.paddingBottom.replace('px', '').replace('%', '');
    return parseInt(paddingBottom);
};
