import { Route, Routes } from 'react-router';
import { NotFound } from '../notFound/NotFound';
import { AddInvoice } from './addInvoice/AddInvoice';
import { InvoiceDetail } from './invoiceDetail/InvoiceDetail';
import { InvoiceEdit } from './invoiceEdit/InvoiceEdit';
import { InvoiceOverview } from './invoiceOverview/InvoiceOverview';

export const Invoices = () => {
    return (
        <div className="app-page">
            <Routes>
                <Route path={'/'} element={<InvoiceOverview />} />
                <Route path={'add'} element={<AddInvoice />} />
                <Route path={':id'} element={<InvoiceDetail />} />
                <Route path={':id/edit'} element={<InvoiceEdit />} />
                <Route path={'/*'} element={<NotFound />} />
            </Routes>
        </div>
    );
};
