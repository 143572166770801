import * as React from 'react';
import { AppMenu } from './appMenu/AppMenu';
import './AppSkeleton.less';

interface IAppSkeletonProps {
    children?: React.ReactNode;
}

export const AppSkeleton = (props: IAppSkeletonProps) => {
    return (
        <div className="app-skeleton">
            {/* Menu bar left */}
            <div className="app-menu">
                <AppMenu />
            </div>

            {/* Page content */}
            {props.children}
        </div>
    );
};
