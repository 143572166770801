import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Space, Spin } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { StatePieceStatus } from '../../../../../enums';
import { IAddInvoiceStatusLookupListFormValues } from '../../../../../interfaces/lookup/invoiceStatus';
import { IAddInvoiceStatus } from '../../../../../interfaces/lookup/invoiceStatus/data';
import { ContentHeader } from '../../../../../shared/components/appSkeleton/contentHeader/ContentHeader';
import { FetchResult } from '../../../../../shared/components/fetchResult/FetchResult';
import { ROW_GUTTER } from '../../../../../shared/Constants';
import { isError } from '../../../../../shared/helpers';
import { AppDispatch, RootState } from '../../../../../store';
import { addInvoiceStatus } from '../../../../../store/lookupSlice/LookupActions';

export const InvoiceStatusLookupListAdd = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { lookupSlice } = useSelector((state: RootState) => state);

    const onFinish = (values: IAddInvoiceStatusLookupListFormValues) => {
        const body: IAddInvoiceStatus = {
            ...(values as any),
        };

        dispatch(addInvoiceStatus(body));
    };

    useEffect(() => {
        if (lookupSlice.detailsPage.invoiceStatus.createStatus === StatePieceStatus.Success) {
            navigate('/admin/lookup-lists/invoice-status', { replace: true });
        }
    }, [lookupSlice.detailsPage.invoiceStatus.createStatus]);

    return (
        <Form
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            layout="vertical"
            onValuesChange={(changedValue: any, values) => {
                form.setFieldsValue(values);
            }}
        >
            <ContentHeader
                title="Manage invoice status lookup list"
                breadcrumbs={[
                    {
                        breadcrumbName: 'Admin',
                        path: '/admin',
                    },
                    {
                        breadcrumbName: 'Manage lookup lists',
                        path: '/admin/lookup-lists',
                    },
                    {
                        breadcrumbName: 'Invoice status',
                        path: '/admin/lookup-lists/invoice-status',
                    },
                    {
                        breadcrumbName: 'Create',
                        path: `/admin/lookup-lists/invoice-status/add`,
                    },
                ]}
            >
                {!isError(lookupSlice.detailsPage.invoiceStatus.fetchStatus) && (
                    <Form.Item>
                        <Space>
                            <Button
                                icon={<CloseOutlined />}
                                onClick={() => navigate(-1)}
                                disabled={
                                    lookupSlice.detailsPage.invoiceStatus.updateStatus === StatePieceStatus.IsFetching ||
                                    lookupSlice.detailsPage.invoiceStatus.fetchStatus === StatePieceStatus.IsFetching
                                }
                            >
                                Close
                            </Button>
                            <Button
                                htmlType="submit"
                                type="primary"
                                icon={<SaveOutlined />}
                                loading={
                                    lookupSlice.detailsPage.invoiceStatus.updateStatus === StatePieceStatus.IsFetching ||
                                    lookupSlice.detailsPage.invoiceStatus.fetchStatus === StatePieceStatus.IsFetching
                                }
                            >
                                Save and close
                            </Button>
                        </Space>
                    </Form.Item>
                )}
            </ContentHeader>

            <div className="page-content">
                <Spin spinning={lookupSlice.detailsPage.invoiceStatus.fetchStatus === StatePieceStatus.IsFetching}>
                    {isError(lookupSlice.detailsPage.invoiceStatus.fetchStatus) ? (
                        <FetchResult status={lookupSlice.detailsPage.invoiceStatus.fetchStatus} about={'Invoice status'} />
                    ) : (
                        <>
                            <Row>
                                <Col span={24}>
                                    <Space>
                                        <label className="group-label">Invoice status option Information</label>
                                    </Space>
                                </Col>
                            </Row>
                            <Row gutter={ROW_GUTTER}>
                                <Col span={8}>
                                    <label className="property required">Code</label>
                                    <Form.Item name="code" rules={[{ required: true, message: "'Code' is required" }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <label className="property required">Description</label>
                                    <Form.Item name="description" rules={[{ required: true, message: "'Description' is required" }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>&nbsp;</Col>
                            </Row>
                        </>
                    )}
                </Spin>
            </div>
        </Form>
    );
};
