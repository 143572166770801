import { Route, Routes } from 'react-router';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { NotFound } from '../notFound/NotFound';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Unauthorized } from '../../shared/components/unauthorized/Unauthorized';
import { ManualDocumentsOverview } from './manualDocuments/manualDocumentsOverview/ManualDocumentsOverview';
import { InvoiceDocumentsOverview } from './invoiceDocuments';
import { PaymentDocumentsOverview } from './paymentDocuments';

export const Documents = () => {
    const navigate = useNavigate();
    const { appSlice } = useSelector((state: RootState) => state);

    useEffect(() => {
        // When the user reaches the '/documents' path, navigate them to '/documents/manuals'
        if (window.location.pathname === '/documents') {
            navigate('/documents/manuals');
        }
    }, [window.location.pathname]);

    if (appSlice.auth.validationCompleted && !appSlice.auth.documents.manuals.view && !appSlice.auth.documents.invoices.view) {
        return <Unauthorized />;
    }

    return (
        <div className="app-page">
            <Routes>
                <Route path={'invoices'} element={<InvoiceDocumentsOverview />} />
                <Route path={'manuals'} element={<ManualDocumentsOverview />} />
                <Route path={'/payments'} element={<PaymentDocumentsOverview />} />

                <Route path={'/*'} element={<NotFound />} />
            </Routes>
        </div>
    );
};
