import { SaveOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, DatePicker, Form, Input, InputNumber, Modal, notification, Row, Select, Space } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StatePieceStatus } from '../../../../../enums';
import { IFeeTableRow } from '../../../../../interfaces/fees';
import { IDuplicateFee } from '../../../../../interfaces/fees/data/IDuplicateFee';
import { IDuplicateFeeFormValues } from '../../../../../interfaces/fees/IDuplicateFeeFormValues';
import { DECIMAL_SEPARATOR, NOTIFICATION_PLACEMENT, ROW_GUTTER } from '../../../../../shared/Constants';
import { AppDispatch, RootState } from '../../../../../store';
import { fetchBeneficiaryById } from '../../../../../store/beneficiarySlice/BeneficiaryActions';
import { duplicateFees } from '../../../../../store/feeSlice/FeeActions';
import { toggleDuplicateFeesModal } from '../../../../../store/feeSlice/FeeSlice';
import {
    fetchCategoriesAsLookupList,
    fetchMaHoldersLookupList,
    fetchRegionsLookupList,
    fetchProjectsLookupList,
    fetchCurrenciesLookupList,
    fetchBillableOptionsLookupList,
    fetchKindOfFeesLookupList,
    fetchBeneficiariesLookupList,
} from '../../../../../store/lookupSlice/LookupActions';

import './FeeDuplicateForm.less';

export interface IDuplicateFeeFormProps {
    selectedTableRecords: IFeeTableRow[];
    countSelectedTableRecords: number;
}

export const FeeDuplicateForm = (props: IDuplicateFeeFormProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { feeSlice, beneficiarySlice } = useSelector((state: RootState) => state);
    const { regions, projects, kindOfFees, categories, billableOptions, maHolders, currencies, beneficiaries } = useSelector(
        (state: RootState) => state.lookupSlice
    );
    const [form] = Form.useForm();
    const { Panel } = Collapse;
    const [currentFeeIndex, setCurrentFeeIndex] = useState<number>();

    const onFinish = (values: any) => {
        try {
            const body: IDuplicateFee[] = [...values.fees];
            body.forEach((fee) => {
                var beneficiary = beneficiaries.data.filter((x) => x.key === fee.beneficiaryId)[0];
                if (beneficiary && beneficiary.isDeleted) {
                    throw new Error(`The beneficiary selected for fee #${fee.id} has been removed. Please select a different beneficiary.`);
                }
                var region = regions.lookup.data.filter((x) => x.key === fee.regionId)[0];
                if (region && region.isDeleted) {
                    throw new Error(`The region selected for fee #${fee.id} has been removed. Please select a different region.`);
                }
                var project = projects.lookup.data.filter((x) => x.key === fee.projectId)[0];
                if (project && project.isDeleted) {
                    throw new Error(`The project selected for fee #${fee.id} has been removed. Please select a different project.`);
                }
                var kindOfFee = kindOfFees.lookup.data.filter((x) => x.key === fee.kindOfFeeId)[0];
                if (kindOfFee && kindOfFee.isDeleted) {
                    throw new Error(`The kind of fee selected for fee #${fee.id} has been removed. Please select a different kind of fee.`);
                }
                var category = categories.lookup.data.filter((x) => x.key === fee.categoryId)[0];
                if (category && category.isDeleted) {
                    throw new Error(`The category selected for fee #${fee.id} has been removed. Please select a different category.`);
                }
                var billableOption = billableOptions.lookup.data.filter((x) => x.key === fee.billableOptionId)[0];
                if (billableOption && billableOption.isDeleted) {
                    throw new Error(
                        `The billable option selected for fee #${fee.id} has been removed. Please select a different billable option.`
                    );
                }
                var maHolder = maHolders.lookup.data.filter((x) => x.key === fee.maHolderId)[0];
                if (maHolder && maHolder.isDeleted) {
                    throw new Error(`The MA holder selected for fee #${fee.id} has been removed. Please select a different MA holder.`);
                }
                var currency = currencies.lookup.data.filter((x) => x.key === fee.currencyId)[0];
                if (currency && currency.isDeleted) {
                    throw new Error(`The currency selected for fee #${fee.id} has been removed. Please select a different currency.`);
                }
            });
            dispatch(duplicateFees(body));
        } catch (e: any) {
            notification.warning({
                message: 'An error occurred',
                description: e.message,
                placement: NOTIFICATION_PLACEMENT,
            });
        }
    };

    const onBeneficiaryChange = (beneficiaryId: number, feeIndex: number) => {
        setCurrentFeeIndex(feeIndex);
        dispatch(fetchBeneficiaryById(beneficiaryId));
    };

    //triggered by onBeneficiaryChange.
    useEffect(() => {
        if (beneficiarySlice.detailsPage.beneficiary.fetchStatus === StatePieceStatus.Success && currentFeeIndex !== undefined) {
            let curentFeesInForm = form.getFieldsValue(['fees']);
            curentFeesInForm.fees[currentFeeIndex].currencyId = beneficiarySlice.detailsPage.beneficiary.data?.currency?.id || undefined;

            form.setFieldsValue({ fees: curentFeesInForm.fees });
        }
    }, [beneficiarySlice.detailsPage.beneficiary.fetchStatus]);

    useEffect(() => {
        let defaultBillableOption = billableOptions.lookup.data.filter((b) => b.isDefault);
        let defaultBillableOptionValue = defaultBillableOption.length > 0 ? defaultBillableOption[0].key : undefined;

        let formItemsToUpdate: IDuplicateFeeFormValues[] = props.selectedTableRecords.map((record) => {
            return {
                id: record.id,
                invoiceNumber: '',
                amount: 0,
                kindOfFeeId: record.kindOfFee?.id,
                categoryId: record.category?.id,
                billableOptionId: defaultBillableOptionValue,
                reasonOfPayment: record.reasonOfPayment,
                maHolderId: record.maHolder?.id,
                projectId: record.project?.id,
                beneficiaryId: record.beneficiary?.id,
                brandName: record.brandName,
                paymentDate: record.paymentDate ? dayjs(record.paymentDate) : undefined,
                procedureCode: record.procedureCode,
                regionId: record.region?.id,
                variableCode: record.variableCode,
                currencyId: record.currency?.id,
            } as IDuplicateFeeFormValues;
        });
        form.setFieldsValue({ fees: formItemsToUpdate });
    }, [props.selectedTableRecords]);

    useEffect(() => {
        dispatch(fetchRegionsLookupList());
        dispatch(fetchProjectsLookupList());
        dispatch(fetchCategoriesAsLookupList());
        dispatch(fetchKindOfFeesLookupList());
        dispatch(fetchBillableOptionsLookupList());
        dispatch(fetchBeneficiariesLookupList());
        dispatch(fetchMaHoldersLookupList());
        dispatch(fetchCurrenciesLookupList());
    }, []);

    return (
        <Form id="duplicateForm" form={form} onFinish={onFinish}>
            <Modal
                title="Duplicate Fee's"
                open={feeSlice.detailFee.duplicateFeesModalVisible}
                width={1000}
                centered
                onCancel={() => dispatch(toggleDuplicateFeesModal())}
                footer={[
                    <React.Fragment key={0}>
                        <Button onClick={() => dispatch(toggleDuplicateFeesModal())}>Cancel</Button>
                        <Button
                            form="duplicateForm"
                            htmlType="submit"
                            type="primary"
                            icon={<SaveOutlined />}
                            loading={feeSlice.fees.createStatus === StatePieceStatus.IsFetching}
                        >
                            Duplicate {props.countSelectedTableRecords} {props.countSelectedTableRecords > 1 ? "fee's" : 'fee'} and close
                        </Button>
                    </React.Fragment>,
                ]}
            >
                <Form.List name="fees">
                    {(fields) => (
                        <Collapse defaultActiveKey={[0]} accordion={true}>
                            {fields.map((field, index) => (
                                <Panel header={`Fee #${props.selectedTableRecords[index]?.id || '-'}`} key={index} forceRender>
                                    <Form.Item required={false} key={index} className="root-form-item">
                                        <Row>
                                            <Col span={24}>
                                                <Space>
                                                    <label className="group-label">Fee Information</label>
                                                </Space>
                                            </Col>
                                        </Row>
                                        <Row gutter={ROW_GUTTER}>
                                            <Col span={8}>
                                                <label className="property">Kind of fee</label>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'kindOfFeeId']}
                                                    fieldKey={[field.key, 'kindOfFeeId']}
                                                >
                                                    <Select placeholder="Select a kind of fee">
                                                        {kindOfFees.lookup.data
                                                            .filter(
                                                                (k) =>
                                                                    !k.isDeleted ||
                                                                    (k.isDeleted &&
                                                                        k.key === props.selectedTableRecords[index].kindOfFee?.id)
                                                            )
                                                            .map((k) => (
                                                                <Select.Option key={k.key} value={k.key} disabled={k.isDeleted}>
                                                                    {k.description}
                                                                </Select.Option>
                                                            ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <label className="property required">Category</label>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'categoryId']}
                                                    fieldKey={[field.key, 'categoryId']}
                                                    rules={[{ required: true, message: "'Category' is required" }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder="Select a category"
                                                        filterOption={(input, option) =>
                                                            (option!.children as unknown as string)
                                                                .toLowerCase()
                                                                .startsWith(input.toLowerCase())
                                                        }
                                                    >
                                                        {categories.lookup.data
                                                            .filter(
                                                                (c) =>
                                                                    !c.isDeleted ||
                                                                    (c.isDeleted &&
                                                                        c.key === props?.selectedTableRecords[index]?.category?.id)
                                                            )
                                                            .map((category) => (
                                                                <Select.Option
                                                                    key={category.key}
                                                                    value={category.key}
                                                                    disabled={category.isDeleted}
                                                                >
                                                                    {category.description}
                                                                </Select.Option>
                                                            ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <label className="property">Billable</label>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'billableOptionId']}
                                                    fieldKey={[field.key, 'billableOptionId']}
                                                >
                                                    <Select placeholder="Select a option">
                                                        {billableOptions.lookup.data
                                                            .filter(
                                                                (b) =>
                                                                    !b.isDeleted ||
                                                                    (b.isDeleted &&
                                                                        b.key === props?.selectedTableRecords[index]?.billableOption?.id)
                                                            )
                                                            .map((opt) => (
                                                                <Select.Option key={opt.key} value={opt.key} disabled={opt.isDeleted}>
                                                                    {opt.description}
                                                                </Select.Option>
                                                            ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={ROW_GUTTER}>
                                            <Col span={8}>
                                                <label className="property">Region</label>
                                                <Form.Item {...field} name={[field.name, 'regionId']} fieldKey={[field.key, 'regionId']}>
                                                    <Select
                                                        showSearch
                                                        placeholder="Select a region"
                                                        filterOption={(input, option) =>
                                                            (option!.children as unknown as string)
                                                                .toLowerCase()
                                                                .startsWith(input.toLowerCase())
                                                        }
                                                    >
                                                        {regions.lookup.data
                                                            .filter(
                                                                (r) =>
                                                                    !r.isDeleted ||
                                                                    (r.isDeleted &&
                                                                        r.key === props?.selectedTableRecords[index]?.region?.id)
                                                            )
                                                            .map((region) => (
                                                                <Select.Option
                                                                    key={region.key}
                                                                    value={region.key}
                                                                    disabled={region.isDeleted}
                                                                >
                                                                    {region.description}
                                                                </Select.Option>
                                                            ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <label className="property required">Amount</label>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'amount']}
                                                    fieldKey={[field.key, 'amount']}
                                                    rules={[{ required: true, message: "Amount' is required." }]}
                                                >
                                                    <InputNumber decimalSeparator={DECIMAL_SEPARATOR}></InputNumber>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <label className="property required">Currency</label>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'currencyId']}
                                                    fieldKey={[field.key, 'currencyId']}
                                                    rules={[{ required: true, message: "'Currency' is required" }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder="Select a currency"
                                                        filterOption={(input, option) =>
                                                            (option!.children as unknown as string)
                                                                .toLowerCase()
                                                                .startsWith(input.toLowerCase())
                                                        }
                                                    >
                                                        {currencies.lookup.data
                                                            .filter(
                                                                (c) =>
                                                                    !c.isDeleted ||
                                                                    (c.isDeleted &&
                                                                        c.key === props?.selectedTableRecords[index]?.currency?.id)
                                                            )
                                                            .map((currency) => (
                                                                <Select.Option
                                                                    key={currency.key}
                                                                    value={currency.key}
                                                                    disabled={currency.isDeleted}
                                                                >
                                                                    {currency.description}
                                                                </Select.Option>
                                                            ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        {/* CLIENT INFORMATION */}
                                        <Row>
                                            <Col span={24}>
                                                <label className="group-label">Client Information</label>
                                            </Col>
                                        </Row>
                                        <Row gutter={ROW_GUTTER}>
                                            <Col span={12}>
                                                <label className="property required">Project</label>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'projectId']}
                                                    fieldKey={[field.key, 'projectId']}
                                                    rules={[{ required: true, message: "'Project' is required" }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder="Select a project"
                                                        filterOption={(input, option) =>
                                                            (option!.children as unknown as string)
                                                                .toLowerCase()
                                                                .startsWith(input.toLowerCase())
                                                        }
                                                    >
                                                        {projects.lookup.data
                                                            .filter(
                                                                (p) =>
                                                                    !p.isDeleted ||
                                                                    (p.isDeleted &&
                                                                        p.key === props?.selectedTableRecords[index]?.project?.id)
                                                            )
                                                            .map((project) => (
                                                                <Select.Option
                                                                    key={project.key}
                                                                    value={project.key}
                                                                    disabled={project.isDeleted}
                                                                >
                                                                    {project.description}
                                                                </Select.Option>
                                                            ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <label className="property">Brand name</label>
                                                <Form.Item {...field} name={[field.name, 'brandName']} fieldKey={[field.key, 'brandName']}>
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={ROW_GUTTER}>
                                            <Col span={12}>
                                                <label className="property required">Beneficiary</label>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'beneficiaryId']}
                                                    fieldKey={[field.key, 'beneficiaryId']}
                                                    rules={[{ required: true, message: "'Beneficiary' is required" }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder="Select a beneficiary"
                                                        onChange={(value) => onBeneficiaryChange(value, index)}
                                                        filterOption={(input, option) =>
                                                            (option!.children as unknown as string)
                                                                .toLowerCase()
                                                                .startsWith(input.toLowerCase())
                                                        }
                                                    >
                                                        {beneficiaries.data
                                                            .filter(
                                                                (b) =>
                                                                    !b.isDeleted ||
                                                                    (b.isDeleted &&
                                                                        b.key === props?.selectedTableRecords[index]?.beneficiary?.id)
                                                            )
                                                            .map((b) => (
                                                                <Select.Option key={b.key} value={b.key} disabled={b.isDeleted}>
                                                                    {b.value}
                                                                </Select.Option>
                                                            ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <label className="property required">Marketing authorization holder</label>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'maHolderId']}
                                                    fieldKey={[field.key, 'maHolderId']}
                                                    rules={[{ required: true, message: "'Marketing authorization holder' is required" }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder="Select a MA holder"
                                                        filterOption={(input, option) =>
                                                            (option!.children as unknown as string)
                                                                .toLowerCase()
                                                                .startsWith(input.toLowerCase())
                                                        }
                                                    >
                                                        {maHolders.lookup.data
                                                            .filter(
                                                                (m) =>
                                                                    !m.isDeleted ||
                                                                    (m.isDeleted &&
                                                                        m.key === props?.selectedTableRecords[index]?.maHolder?.id)
                                                            )
                                                            .map((mah) => (
                                                                <Select.Option key={mah.key} value={mah.key} disabled={mah.isDeleted}>
                                                                    {mah.description}
                                                                </Select.Option>
                                                            ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={ROW_GUTTER}>
                                            <Col span={12}>
                                                <label className="property">Procedure code</label>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'procedureCode']}
                                                    fieldKey={[field.key, 'procedureCode']}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <label className="property">Variable code</label>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'variableCode']}
                                                    fieldKey={[field.key, 'variableCode']}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        {/* PAYMENT INFORMATION */}
                                        <Row>
                                            <Col span={24}>
                                                <label className="group-label">Payment Information</label>
                                            </Col>
                                        </Row>
                                        <Row gutter={ROW_GUTTER}>
                                            <Col span={8}>
                                                <label className="property">Desired payment date</label>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'paymentDate']}
                                                    fieldKey={[field.key, 'paymentDate']}
                                                >
                                                    <DatePicker />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <label className="property">Invoice number</label>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'invoiceNumber']}
                                                    fieldKey={[field.key, 'invoiceNumber']}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <label className="property required">Reason of payment</label>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'reasonOfPayment']}
                                                    fieldKey={[field.key, 'reasonOfPayment']}
                                                    rules={[{ required: true, message: "Reason of payment' is required." }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Panel>
                            ))}
                        </Collapse>
                    )}
                </Form.List>
            </Modal>
        </Form>
    );
};
